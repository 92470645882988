import { Injectable } from '@angular/core';
import { FileArtifactType } from '@entities/artifact';
import { isCertOnlyArtifactRiskRecommendation, isExpiredArtifactRiskRecommendation } from '@entities/recommendation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NOOP } from '@shared/redux/actions';
import { combineLatest, filter, first, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
    AdditionalAssessmentQuestionViewModel,
    CyberInsuranceQuestion,
    ExpiredDocumentQuestion,
    generateExpiredDocumentationQuestionId,
    generateSiblingDocumentationQuestionId,
    isExpiredDocumentationQuestion,
    isSiblingDocumentationQuestion,
    PrivacyQuestion,
    PublicAssessmentRTPFileArtifact,
    SiblingDocumentQuestion,
    SubprocessorsQuestion,
    ThirdPartyAuditQuestion,
    ThirdPartyPenTestQuestion,
} from '../../models';
import {
    addAdditionalAssessmentQuestion,
    onRtpFileArtifactCreation,
    refreshScopeRelatedAdditionalQuestions,
    refreshSiblingOrExpiredAdditionalQuestions,
    removeAdditionalAssessmentQuestion,
    removeArtifactRequestSuccess,
    setAdditionalAssessmentQuestion,
    setAssessmentAdditionalQuestions,
    uploadFiles,
    uploadFilesRequestSuccess,
    uploadSubprocessorsFile,
} from '../actions';
import {
    getAdditionalAssessmentQuestions,
    getAssessmentRiskRecommendations,
    getExpiredDocumentQuestions,
    getSiblingDocumentationQuestions,
    getSortedRtpFileArtifacts,
} from '../selectors';
import * as ControlDomainSelectors from '../selectors/control-domain.selectors';
import { AttestationType } from '@entities/attestation';
import { SubprocessorSubmissionType } from '../../models/subprocessors';
import * as ArtifactSelectors from '../selectors/artifacts.selectors';

@Injectable()
export class AdditionalQuestionEffects {
    refreshAdditionalQuestionsForInScopeDimensionsForNonArtifactUpdateAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshScopeRelatedAdditionalQuestions),
            withLatestFrom(
                this._store$.select(getSortedRtpFileArtifacts),
                this._store$.select(getAdditionalAssessmentQuestions),
            ),
            switchMap(([, rtpFileArtifacts, existingAdditionalQuestions]) =>
                combineLatest([
                    this._store$.select(ControlDomainSelectors.getIsPenTestInScope),
                    this._store$.select(ControlDomainSelectors.getIsPrivacyInScope),
                    this._store$.select(ControlDomainSelectors.getIsCyberInsuranceInScope),
                ]).pipe(
                    map(([isPenTestInScope, isPrivacyInScope, isCyberInsuranceInScope]) => {
                        // Regenerate scope-related additional questions, being sure to keep any the user
                        // has saved attestations to, so we don't overwrite their changes.
                        const additionalQuestionsToCreate: AdditionalAssessmentQuestionViewModel[] =
                            this.generateDefaultScopeRelatedQuestions(
                                existingAdditionalQuestions,
                                rtpFileArtifacts,
                                isPenTestInScope,
                                isPrivacyInScope,
                                isCyberInsuranceInScope,
                            );

                        return setAssessmentAdditionalQuestions({ additionalQuestions: additionalQuestionsToCreate });
                    }),
                ),
            ),
        ),
    );

    refreshSubProcessorQuestion$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshScopeRelatedAdditionalQuestions),
            withLatestFrom(
                this._store$.select(ArtifactSelectors.getSubprocessorFileArtifact),
                this._store$.select(ArtifactSelectors.getSubprocessorsUrlArtifact),
            ),
            switchMap(([, subprocessorFileArtifact, subprocessorsUrlArtifact]) =>
                combineLatest([this._store$.select(ControlDomainSelectors.getIsPrivacyInScope)]).pipe(
                    filter(([isPrivacyInScope]) => isPrivacyInScope),
                    map(() =>
                        addAdditionalAssessmentQuestion(
                            new SubprocessorsQuestion(
                                !!subprocessorFileArtifact
                                    ? SubprocessorSubmissionType.ARTIFACT
                                    : !!subprocessorsUrlArtifact
                                      ? SubprocessorSubmissionType.LINK
                                      : SubprocessorSubmissionType.MANUAL,
                                subprocessorFileArtifact,
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    createExpiredDocumentationAdditionalQuestions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshSiblingOrExpiredAdditionalQuestions),
            switchMap(() =>
                this._store$.select(getAssessmentRiskRecommendations).pipe(
                    withLatestFrom(
                        this._store$.select(getSortedRtpFileArtifacts),
                        this._store$.select(getExpiredDocumentQuestions),
                    ),
                    switchMap(([assessmentRiskRecommendations, rtpFileArtifacts, expiredDocsAdditionalQuestions]) => {
                        const expiredDocsRiskRecommendation = assessmentRiskRecommendations.find(
                            isExpiredArtifactRiskRecommendation,
                        );

                        if (!expiredDocsRiskRecommendation) {
                            return of(NOOP());
                        }

                        return expiredDocsRiskRecommendation.artifactIds
                            .map((artifactId) =>
                                rtpFileArtifacts.find(
                                    (artifact) =>
                                        artifact.canAskAdditionalQuestionsFor && artifact.artifact.id === artifactId,
                                ),
                            )
                            .filter(
                                (expiredArtifact) =>
                                    !!expiredArtifact &&
                                    !expiredDocsAdditionalQuestions.some(
                                        (rec) =>
                                            rec.id ===
                                            generateExpiredDocumentationQuestionId(expiredArtifact.artifact.id),
                                    ),
                            )
                            .map((expiredArtifact) =>
                                addAdditionalAssessmentQuestion(
                                    new ExpiredDocumentQuestion(
                                        expiredArtifact.artifact.id,
                                        expiredArtifact.artifact.fileName,
                                        expiredArtifact.validation?.auditReportType,
                                    ),
                                ),
                            );
                    }),
                ),
            ),
        ),
    );

    createSiblingDocumentationAdditionalQuestions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshSiblingOrExpiredAdditionalQuestions),
            withLatestFrom(this._store$.select(getSortedRtpFileArtifacts)),
            switchMap(([, rtpFileArtifacts]) =>
                this._store$.select(getAssessmentRiskRecommendations).pipe(
                    withLatestFrom(this._store$.select(getSiblingDocumentationQuestions)),
                    switchMap(([assessmentRiskRecommendations, siblingDocsAdditionalQuestions]) => {
                        const siblingDocsRiskRecommendations = assessmentRiskRecommendations.filter(
                            isCertOnlyArtifactRiskRecommendation,
                        );

                        if (!siblingDocsRiskRecommendations.length) {
                            return of(NOOP());
                        }

                        const siblingDocsAdditionalQuestionIds = siblingDocsAdditionalQuestions.map((q) => q.id);

                        return siblingDocsRiskRecommendations
                            .filter(
                                (assessmentRecommendation) =>
                                    !siblingDocsAdditionalQuestionIds.includes(
                                        generateSiblingDocumentationQuestionId(
                                            assessmentRecommendation.artifactIds[0],
                                            assessmentRecommendation.reportAuditReportType,
                                        ),
                                    ),
                            )
                            .map((assessmentRiskRecommendation) => {
                                const requiringSiblingArtifactId = assessmentRiskRecommendation.artifactIds[0];
                                const requiringSiblingArtifact = rtpFileArtifacts.find(
                                    (artifact) =>
                                        artifact.canAskAdditionalQuestionsFor &&
                                        artifact.artifact.id === requiringSiblingArtifactId,
                                );

                                // Skip if the recommendation is about an artifact that's on the
                                // assessment, but was not uploaded as part of the assessment.
                                return !!requiringSiblingArtifact
                                    ? addAdditionalAssessmentQuestion(
                                          new SiblingDocumentQuestion(
                                              requiringSiblingArtifactId,
                                              requiringSiblingArtifact.artifact.fileName,
                                              requiringSiblingArtifact.validation?.auditReportType,
                                              assessmentRiskRecommendation.reportAuditReportType,
                                          ),
                                      )
                                    : NOOP();
                            });
                    }),
                ),
            ),
        ),
    );

    removeSiblingOrExpiredAdditionalQuestionForRemovedOrIncorrectlyClassifiedArtifact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifactRequestSuccess),
            withLatestFrom(this._store$.select(getAdditionalAssessmentQuestions)),
            map(([{ artifactId }, additionalQuestions]) => {
                const isSiblingOrExpiredAdditionalQuestionForArtifact = (q: AdditionalAssessmentQuestionViewModel) =>
                    (isExpiredDocumentationQuestion(q) || isSiblingDocumentationQuestion(q)) &&
                    q.artifactId === artifactId;
                return additionalQuestions.filter((additionalQuestion) =>
                    isSiblingOrExpiredAdditionalQuestionForArtifact(additionalQuestion),
                );
            }),
            mergeMap((siblingOrExpiredAdditionalQuestionsForRemovedArtifact) =>
                siblingOrExpiredAdditionalQuestionsForRemovedArtifact.map((additionalQuestion) =>
                    removeAdditionalAssessmentQuestion({ additionalQuestion }),
                ),
            ),
        ),
    );

    associateUploadedSubprocessorArtifactWithAdditionalQuestion$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadSubprocessorsFile),
            switchMap(() =>
                this._actions$.pipe(
                    ofType(uploadFilesRequestSuccess),
                    first(),
                    filter(({ successfullyUploadedArtifacts }) => !!successfullyUploadedArtifacts?.length),
                    switchMap(({ successfullyUploadedArtifacts }) =>
                        this._actions$.pipe(
                            ofType(onRtpFileArtifactCreation),
                            withLatestFrom(this._store$.select(getAdditionalAssessmentQuestions)),
                            map(([{ rtpFileArtifacts }, additionalQuestions]) => {
                                const additionalQuestion = additionalQuestions.find(
                                    (q) => q.attestationType === AttestationType.SUBPROCESSORS,
                                );
                                if (!!additionalQuestion) {
                                    const uploadedAsRtpArtifact = rtpFileArtifacts.find(
                                        (a) => a.artifact.id === successfullyUploadedArtifacts[0].id,
                                    );
                                    additionalQuestion.attestationArtifact = uploadedAsRtpArtifact;
                                    return setAdditionalAssessmentQuestion({ additionalQuestion });
                                }
                                return NOOP();
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    associateUploadedArtifactWithAdditionalQuestion$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFiles),
            filter(({ forAdditionalQuestionId }) => !!forAdditionalQuestionId),
            switchMap(({ forAdditionalQuestionId }) =>
                this._actions$.pipe(
                    ofType(uploadFilesRequestSuccess),
                    first(),
                    filter(({ successfullyUploadedArtifacts }) => !!successfullyUploadedArtifacts?.length),
                    switchMap(({ successfullyUploadedArtifacts }) =>
                        this._actions$.pipe(
                            ofType(onRtpFileArtifactCreation),
                            withLatestFrom(this._store$.select(getAdditionalAssessmentQuestions)),
                            map(([{ rtpFileArtifacts }, additionalQuestions]) => {
                                const additionalQuestion = additionalQuestions.find(
                                    (q) => q.id === forAdditionalQuestionId,
                                );
                                if (!!additionalQuestion) {
                                    const uploadedAsRtpArtifact = rtpFileArtifacts.find(
                                        (a) => a.artifact.id === successfullyUploadedArtifacts[0].id,
                                    );
                                    additionalQuestion.attestationArtifact = uploadedAsRtpArtifact;
                                    return setAdditionalAssessmentQuestion({ additionalQuestion });
                                }
                                return NOOP();
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    disassociateRemovedArtifactFromAdditionalQuestion$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifactRequestSuccess),
            withLatestFrom(this._store$.select(getAdditionalAssessmentQuestions)),
            map(([{ artifactId }, additionalQuestions]) => {
                const additionalQuestion = additionalQuestions.find(
                    (rec) => rec.attestationArtifact?.artifact.id === artifactId,
                );
                if (!!additionalQuestion) {
                    additionalQuestion.attestationArtifact = null;
                    return setAdditionalAssessmentQuestion({ additionalQuestion });
                }
                return NOOP();
            }),
        ),
    );

    private generateDefaultScopeRelatedQuestions(
        existingAdditionalQuestions: AdditionalAssessmentQuestionViewModel[],
        rtpFileArtifacts: PublicAssessmentRTPFileArtifact[],
        isPenTestInScope: boolean,
        isPrivacyInScope: boolean,
        isCyberInsuranceInScope: boolean,
    ): AdditionalAssessmentQuestionViewModel[] {
        const additionalQuestionsToCreate: AdditionalAssessmentQuestionViewModel[] = [];

        const sufficientlyClassifiedArtifacts = rtpFileArtifacts.filter(
            (artifact) => artifact.requiredRTPValidationsReceived && artifact.satisfiesRiskDimension,
        );

        // Third party audits (always in scope)
        const thirdPartyAuditQuestion = this.tryCreateAdditionalQuestion(
            ThirdPartyAuditQuestion,
            FileArtifactType.AUDIT_REPORT,
            sufficientlyClassifiedArtifacts,
            existingAdditionalQuestions,
        );
        if (!!thirdPartyAuditQuestion) {
            additionalQuestionsToCreate.push(thirdPartyAuditQuestion);
        }

        // Pen tests
        if (isPenTestInScope) {
            const penTestQuestion = this.tryCreateAdditionalQuestion(
                ThirdPartyPenTestQuestion,
                FileArtifactType.ASSESSMENT,
                sufficientlyClassifiedArtifacts,
                existingAdditionalQuestions,
            );
            if (!!penTestQuestion) {
                additionalQuestionsToCreate.push(penTestQuestion);
            }
        }

        // Privacy
        if (isPrivacyInScope) {
            const privacyQuestion = this.tryCreateAdditionalQuestion(
                PrivacyQuestion,
                FileArtifactType.PRIVACY,
                sufficientlyClassifiedArtifacts,
                existingAdditionalQuestions,
            );
            if (!!privacyQuestion) {
                additionalQuestionsToCreate.push(privacyQuestion);
            }
        }

        // Cyber insurance
        if (isCyberInsuranceInScope) {
            const cyberInsuranceQuestion = this.tryCreateAdditionalQuestion(
                CyberInsuranceQuestion,
                FileArtifactType.CYBER_INSURANCE,
                sufficientlyClassifiedArtifacts,
                existingAdditionalQuestions,
            );
            if (!!cyberInsuranceQuestion) {
                additionalQuestionsToCreate.push(cyberInsuranceQuestion);
            }
        }

        return additionalQuestionsToCreate;
    }

    private tryCreateAdditionalQuestion<T extends AdditionalAssessmentQuestionViewModel>(
        additionalQuestionClass: new () => T,
        fileArtifactType: FileArtifactType,
        sufficientlyClassifiedArtifacts: PublicAssessmentRTPFileArtifact[],
        existingQuestions: AdditionalAssessmentQuestionViewModel[],
    ): AdditionalAssessmentQuestionViewModel | null {
        const artifacts = sufficientlyClassifiedArtifacts.filter(
            (artifact) => artifact.fileArtifactType === fileArtifactType,
        );

        const newQuestion = new additionalQuestionClass();
        const areThereArtifacts = !!artifacts.length;

        if (!areThereArtifacts) {
            const existingQuestionWithAttestations = existingQuestions.find(
                (q) => q.attestationType === newQuestion.attestationType,
            );
            return existingQuestionWithAttestations ?? newQuestion;
        }
        return null;
    }

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
