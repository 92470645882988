<header>
    <h3>Risk Analysis</h3>
    <h5>Last updated {{ relationship.latestRiskAssessment.createdDate | date: 'M/d/y' }}</h5>
</header>
<mat-divider></mat-divider>

@for (navGroup of riskAnalysisNavigationMenu; track navGroup.title; let lastGroup = $last) {
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>{{ navGroup.title }}</mat-panel-title>
            <mat-divider *ngIf="!lastGroup"></mat-divider>
        </mat-expansion-panel-header>

        <div class="docs-component-viewer-nav">
            <div class="docs-component-viewer-nav-content">
                <mat-nav-list>
                    @for (menuItem of navGroup.items; track menuItem.title; let lastItem = $last) {
                        <a
                            (click)="onSelectedItem.emit(menuItem)"
                            mat-list-item
                            [class.active]="selectedNavigationItem?.title === menuItem.title"
                        >
                            <div class="link-grid" [class.disabled]="menuItem.disabled">
                                <mat-icon [ngClass]="menuItem.prefixIconClass">{{ menuItem.prefixIcon }}</mat-icon>
                                <span class="link-title text-truncate">{{ menuItem.title }}</span>
                                <small [ngClass]="menuItem.suffixTextClass">{{
                                    isRiskLevel(menuItem.suffixText)
                                        ? (menuItem.suffixText | riskLevel | async | uppercase)
                                        : menuItem.suffixText
                                }}</small>
                            </div>
                        </a>

                        @if (lastGroup && lastItem) {
                            <ng-container *ngTemplateOutlet="addQuestionnaire"></ng-container>
                        }
                    } @empty {
                        <ng-container *ngTemplateOutlet="addQuestionnaire"></ng-container>
                    }
                </mat-nav-list>
            </div>
        </div>
    </mat-expansion-panel>
    @if (!lastGroup) {
        <mat-divider></mat-divider>
    }
}

<ng-template #addQuestionnaire>
    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <a
            mat-list-item
            *jhiHasNotAuthority="[Roles.Auditor, Roles.Support, Roles.ReadOnly]"
            exportRemovableAction
            [routerLink]="[
                '/',
                { outlets: { popup: 'relationships/' + relationship.id + '/supplemental-questionnaires' } },
            ]"
        >
            <div class="link-grid color-primary add-questionnaire">
                <mat-icon>add</mat-icon>
                <span class="">Add questionnaire</span>
            </div>
        </a>
    </ng-container>
</ng-template>
