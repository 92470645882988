@switch (subStep) {
    @case (AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS) {
        <app-additional-assessment-questions
            data-pw="artifactUploadSubStep"
            [clientName]="clientName"
            [vendorName]="vendorName"
            [additionalQuestions]="additionalQuestions"
            [subprocessorsUrlArtifact]="subprocessorsUrlArtifact"
            [subprocessorDetections]="subprocessorDetections"
            (continued)="continueFromSubstep.emit(AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS)"
            (wentBack)="goBackFromSubstep.emit(AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS)"
            (uploadArtifact)="uploadArtifact.emit($event)"
            (removeFileArtifact)="removeFileArtifact.emit($event)"
            (removeUrlArtifact)="removeUrlArtifact.emit($event)"
            (setPassword)="setPassword.emit($event)"
            (uploadFileFailed)="addingFileFailed.emit($event)"
            (updateQuestion)="updateAdditionalQuestion.emit($event)"
            (uploadSubprocessorsFile)="uploadSubprocessorsFile.emit($event)"
            (updateSubprocessorDetections)="updateSubprocessorDetections.emit($event)"
            (saveSubprocessorsUrl)="saveSubprocessorsUrl.emit($event)"
        />
    }
    @case (AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE) {
        <app-answer-questionnaire
            data-pw="questionnaireSubStep"
            [clientName]="clientName"
            [questionnaire]="questionnaire"
            [forFollowup]="followupRequested"
            [followupControlDomainIds]="followupControlDomainIds"
            [relevantControlDomainIds]="relevantControlDomainIds"
            [activeSupplementalQuestionnaires]="activeSupplementalQuestionnaires"
            (wentBack)="goBackFromSubstep.emit(AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE)"
            (continued)="continueFromSubstep.emit(AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE)"
            (wentToCollectArtifacts)="questionnaireWentBackToCollectArtifacts.emit()"
            (questionnaireUpdated)="questionnaireUpdated.emit($event)"
        />
    }
}
