<div class="modal-body pt-4 pb-0 h-100" data-pw="addControlModal">
    <form id="addControlForm" [formGroup]="addControlFormGroup" (ngSubmit)="submit()">
        <div class="row">
            <div class="col">
                <div class="control-domain-name">
                    {{ artifactControl.control.controlDomainName }}
                </div>
                <div class="control-name">
                    {{ artifactControl.control.name || 'Domain Override' }}
                </div>
            </div>

            <div class="col control-status-container" [ngClass]="{ 'domain-override': isDomainOverride }">
                <div class="form-group row">
                    <label class="col col-form-label control-status-label" for="status">{{
                        artifactControl.control.id ? 'Status' : 'Domain Status'
                    }}</label>
                    <div class="col ps-0">
                        <ngx-select
                            optionValueField="enumValue"
                            optionTextField="name"
                            formControlName="status"
                            name="status"
                            class="control-status-select"
                            [items]="controlStatus$ | async"
                            [noAutoComplete]="true"
                            placeholder="Unvalidated"
                            data-pw="addControlStatusSelect"
                        >
                        </ngx-select>
                    </div>
                </div>
            </div>
        </div>
        <hr />

        <div class="row pt-2">
            <div class="col control-description-and-query-container">
                <legend>Description and Query</legend>
                <p>
                    {{
                        artifactControl.control.description
                            ? artifactControl.control.description
                            : artifactControl.control.controlDomainDescription
                    }}
                </p>
            </div>
        </div>
        <hr />

        <div class="row pt-2" formArrayName="detections">
            <div class="col-12 mb-3 d-flex">
                <legend>Detections</legend>
                <button
                    *jhiHasAnyAuthority="Roles.Auditor"
                    mat-button
                    visoButton="secondary"
                    class="add-detection-button"
                    type="button"
                    (click)="addControlDetection()"
                    [disabled]="isValidationComplete"
                >
                    Add Detection
                </button>
            </div>
            <div class="col-12">
                <div
                    class="row detection"
                    *ngFor="let controlDetection of controlDetections; let i = index"
                    [formGroupName]="i"
                >
                    <div class="col-12 detection-header">
                        <span
                            ><strong>Detection {{ i + 1 }} </strong>
                            <app-chip type="basic" class="ms-2" [disableRipple]="true">
                                <strong
                                    >{{ DetectionSourceTypeDisplayName[controlDetection.value.sourceType] ?? 'Static' }}
                                </strong></app-chip
                            ></span
                        >
                        <div *ngIf="!isValidationComplete" class="d-inline-flex align-items-center">
                            <ngx-select
                                *ngIf="controlValidation"
                                class="control-assigned"
                                placeholder="Move To..."
                                optionValueField="key"
                                optionTextField="name"
                                optGroupLabelField="name"
                                optGroupOptionsField="children"
                                (selectionChanges)="controlAssignedChanged(i, $event)"
                                name="controlAssigned"
                                [items]="controlsToAssign$ | async"
                                data-pw="assignControlSelect"
                            >
                            </ngx-select>
                            <button mat-icon-button class="delete-detection" (click)="deleteControlDetection(i)">
                                <em class="fa fa-trash"></em>
                            </button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="detection-type">Detection Type</label>
                                    <ngx-select
                                        id="detection-type"
                                        optionValueField="enumValue"
                                        optionTextField="name"
                                        formControlName="detectionType"
                                        [items]="detectionTypes$ | async"
                                        data-pw="addControlDetectionTypeSelect"
                                    >
                                    </ngx-select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="controlIdsText">Control IDs</label>
                                    <app-custom-select
                                        id="controlIdsText"
                                        *ngIf="showSuggestedControlIds"
                                        formControlName="controlIdsText"
                                        [options]="suggestedControlIds"
                                        data-pw="controlIdsSelect"
                                    ></app-custom-select>
                                    <input
                                        id="controlIdsText"
                                        *ngIf="!showSuggestedControlIds"
                                        type="text"
                                        class="form-control"
                                        formControlName="controlIdsText"
                                        data-pw="controlIdsText"
                                    />
                                </div>
                            </div>
                            <ng-container *ngIf="showConfidenceScore(i)">
                                <div class="col-1">
                                    <div class="form-group">
                                        <label for="confidence">Confidence</label>
                                        <input
                                            id="confidence"
                                            type="text"
                                            class="form-control"
                                            formControlName="confidence"
                                            maxLength="10"
                                        />
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="viewerPage">Viewer Page</label>
                                    <input
                                        id="viewerPage"
                                        type="text"
                                        class="form-control"
                                        formControlName="viewerPage"
                                        maxLength="55"
                                    />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="sectionName">Section Name</label>
                                    <input
                                        id="sectionName"
                                        type="text"
                                        class="form-control"
                                        formControlName="sectionName"
                                        maxlength="128"
                                    />
                                </div>
                            </div>
                            <div *ngIf="showSubservicerSelectField(i)" class="col-12">
                                <div class="form-group">
                                    <label>Subservicer</label>
                                    <div
                                        class="subservicer-input"
                                        *ngFor="let subservicerFormControl of getSubservicerFormArray(i)"
                                    >
                                        <app-vendor-search
                                            customOptionPrefixText="Use vendor name"
                                            [formControl]="subservicerFormControl"
                                        ></app-vendor-search>
                                    </div>
                                    <div>
                                        <button
                                            mat-button
                                            type="button"
                                            visoButton="secondary"
                                            (click)="addSubservicer(i)"
                                        >
                                            Add Subservicer
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showManagementResponseInput(i)" class="col-12">
                                <div class="form-group">
                                    <label for="managementResponse">Management Response</label>
                                    <textarea
                                        autoGrow
                                        noPastedLines
                                        class="form-control"
                                        type="text"
                                        placeholder="Enter management response"
                                        formControlName="managementResponse"
                                    ></textarea>
                                </div>
                            </div>
                            @if (showSubprocessorInputs(i)) {
                                <div formGroupName="subprocessorGroup">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="subprocessor">Sub-processor</label>
                                            <app-vendor-search formControlName="subprocessorOrg"></app-vendor-search>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="purpose">Purpose</label>
                                            <input
                                                id="purpose"
                                                type="text"
                                                class="form-control"
                                                formControlName="purpose"
                                                maxlength="255"
                                                placeholder="Search for or enter purpose"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="location">Location of processing</label>
                                            <input
                                                id="location"
                                                type="text"
                                                class="form-control"
                                                formControlName="location"
                                                maxlength="255"
                                                placeholder="Enter location of processing"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="notes">Detection</label>
                                    <textarea
                                        autoGrow
                                        noPastedLines
                                        class="form-control"
                                        type="text"
                                        placeholder="Enter detection text"
                                        formControlName="notes"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="auditorComment">Comments</label>
                                    <textarea
                                        autoGrow
                                        noPastedLines
                                        class="form-control"
                                        type="text"
                                        placeholder="Add any comments or notes"
                                        formControlName="auditorComment"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="addControlFormGroup.errors?.atLeastOneDetection">
            <div class="col">
                <p class="form-text text-error">At least one detection is required</p>
            </div>
        </div>
        <hr class="detections-form-divider" />
    </form>
</div>
<div class="modal-footer justify-content-between border-0">
    <button mat-button visoButton="secondary" (click)="cancel()">Cancel</button>
    <div *jhiHasAnyAuthority="Roles.Auditor">
        <button
            *ngIf="!isValidationComplete && !!controlValidation"
            mat-button
            visoButton="secondary"
            color="warn"
            class="me-2"
            type="button"
            (click)="deleteControlValidation()"
            [disabled]="deleteButtonDisabled$ | async"
            data-pw="deleteControlValidationButton"
        >
            {{ 'Delete ' + (isDomainOverride ? 'Override' : 'Validation') }}
        </button>

        <div
            class="tooltip-wrapper"
            placement="top"
            [ngbTooltip]="
                addControlFormGroup.controls.status.invalid
                    ? 'Select a status above'
                    : addControlFormGroup.controls.detections.invalid
                      ? 'Enter Detection'
                      : ''
            "
        >
            <button
                mat-button
                visoButton="primary"
                form="addControlForm"
                type="submit"
                [disabled]="(submitButtonDisabled$ | async) || isValidationComplete"
                data-pw="addControlSubmitButton"
            >
                {{ controlValidation ? 'Update' : 'Validate' }}
            </button>
        </div>
    </div>
</div>
