import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { FileArtifactType } from '@entities/artifact';
import { AnimationState } from '../file-artifact-item-animations.model';

@Component({
    selector: 'app-artifact-file-type-animated-pill',
    templateUrl: './artifact-file-type-animated-pill.component.html',
    styleUrl: './artifact-file-type-animated-pill.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('iconSlideRight', [
            state(AnimationState.PRE_REVEAL, style({ color: '#3f484a' })),
            state(AnimationState.REVEALING, style({ color: '#3f484a', left: 'calc(100% - 20px)' })),
            state(AnimationState.REVEALED, style({ color: '#3f484a', left: 'calc(100% - 20px)' })),
            transition('* => pre-reveal', [
                animate('0.5s 1.0s ease-in', keyframes([style({ color: 'transparent' }), style({ color: '#3f484a' })])),
            ]),
            transition(
                'pre-reveal => revealing',
                animate('0.5s ease-in-out', keyframes([style({ left: 'calc(100% - 20px)' })])),
            ),
        ]),
        trigger('contentReveal', [
            state(AnimationState.REVEALING, style({ clipPath: 'inset(0 0 0 0)' })),
            state(AnimationState.REVEALED, style({ clipPath: 'inset(0 0 0 0)' })),
            transition(
                'pre-reveal => revealing',
                animate('0.5s ease-in-out', keyframes([style({ clipPath: 'inset(0 0 0 0)' })])),
            ),
        ]),
        trigger('pillSizeAndColor', [
            state(AnimationState.PRE_REVEAL, style({ minWidth: 0, maxWidth: '100%' })),
            state(
                AnimationState.REVEALING,
                style({ minWidth: 0, maxWidth: '100%', border: '1px solid transparent', backgroundColor: '#FFF500' }),
            ),
            state(
                AnimationState.REVEALED,
                style({ minWidth: 0, maxWidth: '100%', border: '1px solid transparent', backgroundColor: '#FFF500' }),
            ),
            transition(
                'waiting => pre-reveal',
                animate(
                    '0.5s 1.0s ease-in-out',
                    keyframes([style({ minWidth: 0, maxWidth: '100%', backgroundColor: '#FFF500' })]),
                ),
            ),
        ]),
    ],
})
export class ArtifactFileTypeAnimatedPillComponent implements OnInit, OnChanges {
    @Input({ required: true }) fileArtifactType: FileArtifactType;
    @Input() initialState = AnimationState.WAITING;
    @Input() aiProcessed: boolean = true;

    state: AnimationState;

    artifactLabels: Partial<Record<FileArtifactType, string>> = {
        AUDIT_REPORT: '3rd party audit',
        ASSESSMENT: 'Penetration test',
        PRIVACY: 'Privacy document',
        CYBER_INSURANCE: 'Cyber insurance',
    };

    get fileArtifactTypeLabel(): string {
        return this.fileArtifactType in this.artifactLabels
            ? this.artifactLabels[this.fileArtifactType]
            : 'Other security artifact';
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentFileArtifactType = changes.fileArtifactType.currentValue;
        const previousFileArtifactType = changes.fileArtifactType.previousValue;
        const fileArtifactTypeCanBeRevealed = !previousFileArtifactType && !!currentFileArtifactType;
        if (fileArtifactTypeCanBeRevealed) {
            this.state = AnimationState.PRE_REVEAL;
        }
    }

    ngOnInit(): void {
        this.state = this.initialState;
    }

    moveToNextState(): void {
        switch (this.state) {
            case AnimationState.PRE_REVEAL:
                this.state = AnimationState.REVEALING;
                break;
            case AnimationState.REVEALING:
                this.state = AnimationState.REVEALED;
                break;
        }
    }
}
