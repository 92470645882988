<mat-form-field>
    <input
        matInput
        type="text"
        aria-label="vendor search"
        [placeholder]="placeholder()"
        (blur)="onTouched()"
        [formControl]="searchControl"
        [matAutocomplete]="orgAuto"
    />
    <mat-autocomplete #orgAuto [displayWith]="vendorDisplayFn" [panelWidth]="'300px'">
        @for (vendorOrg of searchResults(); track vendorOrg.id) {
            <mat-option [value]="vendorOrg">
                <div class="d-flex align-items-center">
                    @if (vendorOrg.faviconUrl?.length) {
                        <img
                            class="organization-icon"
                            *ngIf="vendorOrg.faviconUrl"
                            [src]="vendorOrg.faviconUrl"
                            [appImageFallback]="vendorImageFallback"
                            onImageErrorCssClass="no-organization-icon"
                        />
                    } @else if (!vendorOrg.customOrgName) {
                        <span class="organization-icon missing-icon" *ngIf="!vendorOrg.faviconUrl"></span>
                    }
                    <span>{{ vendorOrg.customOrgName ?? vendorOrg.name }}</span>
                </div>
            </mat-option>
        }
    </mat-autocomplete>
</mat-form-field>
