<mat-card>
    <mat-card-header class="mb-3 d-block">
        <div class="d-flex align-items-center">
            <h4 class="mb-0">Context</h4>
            <mat-icon
                class="ms-2 text-primary"
                svgIcon="viso"
                matTooltip="VISO TRUST analyzes vendor data to generate the most accurate outcomes for this relationship. Individual relationship values may vary based on assessment results."
            ></mat-icon>
        </div>
        @if (contextTypes().length === 0 || dataTypes().length === 0) {
            <p class="mt-2">
                Your inherent risk depends on the data and access you share with {{ vendorDetails()?.name }}. Add
                context to improve the accuracy of the predicted risk and predicted scope.
            </p>
        } @else {
            <p class="mt-2">
                Your relationship with {{ vendorDetails()?.name }} involves the following business case and data types.
            </p>
        }
    </mat-card-header>
    <mat-card-content>
        @defer (when !isContextProcessing()) {
            @if (contextTypes().length === 0 || dataTypes().length === 0) {
                <div class="no-context">
                    <img src="content/img/viso_navbar_brand.png" alt="VISO TRUST Logo" />
                    <h3 class="fw-normal mb-0">We were unable to predict context</h3>
                    <p class="mb-0">Add context to get insights about this vendor</p>
                    <button mat-flat-button color="primary" [routerLink]="addContextLink" [replaceUrl]="true">
                        Add context
                    </button>
                </div>
            } @else {
                <div class="row">
                    <div class="col">
                        <h4>
                            {{ relevantRiskDimensions().length }} risk dimension{{
                                relevantRiskDimensions().length === 1 ? ' is ' : 's are '
                            }}
                            in scope during an assessment of {{ vendorDetails()?.name }}.
                        </h4>
                        <div class="d-flex column-gap-2 mb-4">
                            <div *ngFor="let controlDomainType of allRiskDimensions">
                                <app-chip
                                    type="basic-inverted"
                                    [disableRipple]="true"
                                    [disabled]="
                                        isControlDomainTypeDisabled(controlDomainType) ||
                                        isControlDomainTypeOutOfScope(controlDomainType)
                                    "
                                >
                                    <div class="d-flex align-items-center">
                                        <mat-icon
                                            class="me-2"
                                            inline="true"
                                            *ngIf="isControlDomainTypeDisabled(controlDomainType)"
                                            >lock
                                        </mat-icon>
                                        <span>{{ ControlDomainTypeLabels[controlDomainType] }}</span>
                                    </div>
                                </app-chip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <strong>Business case</strong>
                        <p class="text-muted">
                            The business case determines the attack surface profile and the relevant security controls
                            required to reduce the likelihood of data loss.
                        </p>
                        <div *ngFor="let contextType of contextTypes()">
                            <span
                                matTooltip="{{ contextType.description }}"
                                matTooltipPosition="right"
                                class="text-primary hoverable"
                            >
                                {{ contextType.name }}
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <strong>Data types</strong>
                        <p class="text-muted">
                            The overall sensitivity of data types in scope, along with the attack surface, determines
                            the inherent risk of this relationship.
                        </p>
                        <div *ngFor="let dataType of dataTypes()">
                            <span
                                matTooltip="{{ dataType.description }}"
                                matTooltipPosition="right"
                                class="text-primary hoverable"
                            >
                                {{ dataType.name }}
                            </span>
                        </div>
                    </div>
                </div>
            }
        } @placeholder {
            <div class="row">
                <div class="col">
                    <ngx-skeleton-loader appearance="line" [theme]="{ height: '32px', width: '70%' }" />
                    <div class="d-flex column-gap-2 mb-4">
                        <div *ngFor="let controlDomainType of allRiskDimensions">
                            <ngx-skeleton-loader appearance="line" [theme]="{ height: '24px', width: '100px' }" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <ngx-skeleton-loader appearance="line" [theme]="{ height: '24px', width: '30%' }" />
                    <ngx-skeleton-loader appearance="line" [theme]="{ height: '48px', width: '100%' }" />
                    <div class="d-flex flex-column row-gap-2">
                        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px', width: '40%' }" />
                        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px', width: '40%' }" />
                        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px', width: '40%' }" />
                    </div>
                </div>
                <div class="col">
                    <ngx-skeleton-loader appearance="line" [theme]="{ height: '24px', width: '30%' }" />
                    <ngx-skeleton-loader appearance="line" [theme]="{ height: '48px', width: '100%' }" />
                    <div class="d-flex flex-column row-gap-2">
                        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px', width: '40%' }" />
                        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px', width: '40%' }" />
                        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px', width: '40%' }" />
                    </div>
                </div>
            </div>
        }
    </mat-card-content>
</mat-card>
