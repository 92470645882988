import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { AttestationType, AttestationValue } from '@entities/attestation';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import {
    AdditionalAssessmentQuestionViewModel,
    isSubprocessorQuestion,
    PublicAssessmentRTPFileArtifact,
} from '../../../models';
import { FilterFunction } from '@shared/file-upload/interfaces';
import { ControlValidationDetection, UrlArtifact } from '@entities/artifact';

@Component({
    selector: 'app-additional-assessment-questions',
    templateUrl: './app-additional-assessment-questions.component.html',
    styleUrls: ['./app-additional-assessment-questions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalAssessmentQuestionsComponent implements OnInit {
    @Input({ required: true }) additionalQuestions: AdditionalAssessmentQuestionViewModel[];
    @Input({ required: true }) clientName: string;
    @Input({ required: true }) vendorName: string;
    @Input({ required: true }) subprocessorsUrlArtifact: UrlArtifact;
    @Input({ required: true }) subprocessorDetections: ControlValidationDetection[];

    @Output() wentBack = new EventEmitter<void>();
    @Output() continued = new EventEmitter<void>();
    @Output() uploadArtifact = new EventEmitter<{ additionalQuestionId: string; file: File }>();
    @Output() uploadFileFailed = new EventEmitter<FilterFunction>();
    @Output() setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();
    @Output() removeFileArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();
    @Output() removeUrlArtifact = new EventEmitter<UrlArtifact>();
    @Output() updateQuestion = new EventEmitter<AdditionalAssessmentQuestionViewModel>();
    @Output() uploadSubprocessorsFile = new EventEmitter<File>();
    @Output() updateSubprocessorDetections = new EventEmitter<ControlValidationDetection[]>();
    @Output() saveSubprocessorsUrl = new EventEmitter<string>();

    @ViewChild(CdkStepper)
    private _stepper: CdkStepper;

    uploader: FileUploader;
    currentAttestationValueControl: FormControl<AttestationValue>;

    AttestationType = AttestationType;
    isSubprocessorQuestion = isSubprocessorQuestion;

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.currentAttestationValueControl = this._fb.control(null);

        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            this.uploadFileFailed.emit(filter);
        };
    }

    continue(): void {
        if (this._stepper.selectedIndex === this.additionalQuestions.length - 1) {
            this.continued.emit();
        } else {
            this._stepper.next();
        }
    }

    goBack(): void {
        if (this._stepper.selectedIndex === 0) {
            this.wentBack.emit();
        } else {
            this._stepper.previous();
        }
    }
}
