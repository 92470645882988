import { Component, OnDestroy, OnInit } from '@angular/core';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Artifact, AttestationArtifact, ControlValidationDetection } from '@entities/artifact';
import moment from 'moment';
import {
    Attestation,
    AttestationType,
    AttestationTypeLabels,
    AttestationValueLabels,
    ExpiredArtifactAttestationLabels,
    isMissingArtifactAttestation,
    MissingArtifactAttestation,
    SiblingArtifactAttestationLabels,
} from '@entities/attestation';
import { AuditReportTypeCode, AuditReportTypeName } from '@entities/audit-report';

@Component({
    selector: 'app-attestations',
    templateUrl: './attestations.component.html',
    styleUrls: ['./attestations.component.scss'],
})
export class AttestationsComponent implements OnInit, OnDestroy {
    readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    relationshipId: number;
    assessmentId: number;
    relativeCompletedDate: string;
    relationshipName: string;
    relationshipArtifacts: Artifact[] = [];
    attestationsArtifact: AttestationArtifact | null = null;
    subprocessorDetections: ControlValidationDetection[] | null = null;

    AuditReportTypeCode = AuditReportTypeCode;

    private _unsub$ = new Subject<void>();

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.relationshipId = +this.route.snapshot.paramMap.get('relationshipId');
        this.assessmentId = +this.route.snapshot.paramMap.get('assessmentId');

        this.route.data.pipe(takeUntil(this._unsub$)).subscribe((data) => {
            this.attestationsArtifact = data.attestations;
            this.relationshipName = data.relationshipName;
            this.relationshipArtifacts = data.relationshipArtifacts;
            this.subprocessorDetections =
                this.attestationsArtifact?.validation?.auditReportType === AuditReportTypeCode.SUBPROCESSORLIST
                    ? (this.attestationsArtifact.validation.detectedControls?.[0]?.validationDetections ?? [])
                    : null;

            if (this.attestationsArtifact.updatedDate) {
                this.relativeCompletedDate = moment(this.attestationsArtifact.updatedDate).fromNow();
            }
        });
    }

    buildAttestationResponse = (relationshipName: string, attestation: Attestation): string => {
        if (isMissingArtifactAttestation(attestation)) {
            const referencedArtifact = this.relationshipArtifacts.find(
                (a) => a.id === attestation.referencedArtifactId,
            );
            const referencedArtifactType = referencedArtifact?.validation?.auditReportType;
            const referencedArtifactTypeName = !!referencedArtifactType
                ? AuditReportTypeName[referencedArtifactType]
                : null;
            const referencedArtifactFileName = referencedArtifact.fileName;

            if (attestation.attestationType === AttestationType.UPDATED_ARTIFACTS) {
                return ExpiredArtifactAttestationLabels[attestation.attestationValue](
                    relationshipName,
                    referencedArtifactFileName,
                    referencedArtifactTypeName,
                );
            }

            if (attestation.attestationType === AttestationType.SIBLING_DOCS) {
                return SiblingArtifactAttestationLabels[attestation.attestationValue](
                    relationshipName,
                    referencedArtifactFileName,
                    referencedArtifactTypeName,
                );
            }
        }

        return AttestationValueLabels[attestation.attestationValue](
            relationshipName,
            this.getAttestationName(attestation),
        );
    };

    getAttestationName(attestation: Attestation | MissingArtifactAttestation): string {
        return AttestationTypeLabels[attestation.attestationType];
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
