import { createAction, props } from '@ngrx/store';
import { ControlValidationDetection, QuestionnaireArtifact } from '@entities/artifact';
import { AssessmentSubmissionContext, AssessmentSubmissionFormPayload, SetArtifactPasswordPayload } from '../../models';

export enum AssessmentCollectionCommonCommandActions {
    SubmitPasscode = '[Assessment Collection Common Command] Submit Passcode',
    SubmitPasscodeSuccess = '[Assessment Collection Common Command] Submit Passcode Success',
    RedirectToAuthenticate = '[Assessment Collection Common Command] Redirect To Authenticate',
    RedirectToNotInProgressState = '[Assessment Collection Common Command] Redirect To Not In Progress State',
    CancelAssessment = '[Assessment Collection Common Command] Cancel Assessment',
    CancelAssessmentCancel = '[Assessment Collection Common Command] Cancel Assessment Cancel',
    ForwardRequest = '[Assessment Collection Common Command] Forward Request',
    ForwardRequestCancel = '[Assessment Collection Common Command] Forward Request Cancel',
    ExtendExpiration = '[Assessment Collection Common Command] Extend Expiration',
    ExtendAssessmentExpirationCancel = '[Assessment Collection Common Command] Extend Assessment Expiration Cancel',
    DeclineRemediation = '[Assessment Collection Common Command] Decline Remediation',
    DeclineRemediationCancel = '[Assessment Collection Common Command] Decline Remediation Cancel',
    UploadFiles = '[Assessment Collection Common Command] Upload Files',
    UploadFileToReplace = '[Assessment Collection Common Command] Upload Files To Replace',
    LoadRecommendations = '[Assessment Collection Common Command] Load Recommendations',
    SetArtifactPassword = '[Assessment Collection Common Command] Set Artifact Password',
    LoadSecurityControlDomain = '[Assessment Collection Common Command] Load Security Control Domain',
    RemoveArtifact = '[Assessment Collection Common Command] Remove Artifact',
    CreateQuestionnaire = '[Assessment Collection Common Command] Create Questionnaire',
    SaveQuestionnaire = '[Assessment Collection Common Command] Save Questionnaire',
    SubmitAssessment = '[Assessment Collection Common Command] Submit Assessment',
    RefreshScopeRelatedAdditionalQuestions = '[Assessment Collection Common Command] Refresh Scope Related Additional Questions',
    RefreshSiblingOrExpiredAdditionalQuestions = '[Assessment Collection Common Command] Refresh Sibling Or Expired Additional Questions',
    SetSubmissionContext = '[Assessment Collection Common Command] Set Submission Context',
    UpdateSubprocessorDetections = '[Assessment Collection Common Command] Update Subprocessor Detections',
    UploadSubprocessorsFile = '[Assessment Collection Common Command] Upload Subprocessors File',
    SaveSubprocessorsUrl = '[Assessment Collection Common Command] Save Subprocessors Url',
}

export const submitPasscode = createAction(
    AssessmentCollectionCommonCommandActions.SubmitPasscode,
    props<{ passcode: string }>(),
);

export const submitPasscodeSuccess = createAction(AssessmentCollectionCommonCommandActions.SubmitPasscodeSuccess);

export const redirectToAuthenticate = createAction(AssessmentCollectionCommonCommandActions.RedirectToAuthenticate);

export const redirectToNotInProgressState = createAction(
    AssessmentCollectionCommonCommandActions.RedirectToNotInProgressState,
);

export const cancelAssessment = createAction(AssessmentCollectionCommonCommandActions.CancelAssessment);

export const cancelAssessmentCancel = createAction(AssessmentCollectionCommonCommandActions.CancelAssessmentCancel);

export const forwardRequest = createAction(AssessmentCollectionCommonCommandActions.ForwardRequest);

export const forwardRequestCancel = createAction(AssessmentCollectionCommonCommandActions.ForwardRequestCancel);

export const extendExpiration = createAction(AssessmentCollectionCommonCommandActions.ExtendExpiration);

export const extendAssessmentExpirationCancel = createAction(
    AssessmentCollectionCommonCommandActions.ExtendAssessmentExpirationCancel,
);

export const declineRemediation = createAction(AssessmentCollectionCommonCommandActions.DeclineRemediation);

export const declineRemediationCancel = createAction(AssessmentCollectionCommonCommandActions.DeclineRemediationCancel);

export const uploadFiles = createAction(
    AssessmentCollectionCommonCommandActions.UploadFiles,
    props<{ files: File[]; forAdditionalQuestionId?: string }>(),
);

export const uploadFileToReplace = createAction(
    AssessmentCollectionCommonCommandActions.UploadFileToReplace,
    props<{ file: File; artifactIdToReplace: number }>(),
);

export const loadRecommendations = createAction(AssessmentCollectionCommonCommandActions.LoadRecommendations);

export const setArtifactPassword = createAction(
    AssessmentCollectionCommonCommandActions.SetArtifactPassword,
    props<SetArtifactPasswordPayload>(),
);

export const loadSecurityControlDomain = createAction(
    AssessmentCollectionCommonCommandActions.LoadSecurityControlDomain,
);

export const removeArtifact = createAction(
    AssessmentCollectionCommonCommandActions.RemoveArtifact,
    props<{ artifactId: number; fileName: string }>(),
);

export const createQuestionnaire = createAction(AssessmentCollectionCommonCommandActions.CreateQuestionnaire);

export const saveQuestionnaire = createAction(
    AssessmentCollectionCommonCommandActions.SaveQuestionnaire,
    props<{ questionnaire: QuestionnaireArtifact }>(),
);

export const submitAssessment = createAction(
    AssessmentCollectionCommonCommandActions.SubmitAssessment,
    props<{ payload: AssessmentSubmissionFormPayload }>(),
);

export const refreshScopeRelatedAdditionalQuestions = createAction(
    AssessmentCollectionCommonCommandActions.RefreshScopeRelatedAdditionalQuestions,
);

export const refreshSiblingOrExpiredAdditionalQuestions = createAction(
    AssessmentCollectionCommonCommandActions.RefreshSiblingOrExpiredAdditionalQuestions,
);

export const setSubmissionContext = createAction(
    AssessmentCollectionCommonCommandActions.SetSubmissionContext,
    props<{ context: AssessmentSubmissionContext }>(),
);

export const updateSubprocessorDetections = createAction(
    AssessmentCollectionCommonCommandActions.UpdateSubprocessorDetections,
    props<{ subprocessorDetections: ControlValidationDetection[] }>(),
);

export const uploadSubprocessorsFile = createAction(
    AssessmentCollectionCommonCommandActions.UploadSubprocessorsFile,
    props<{ file: File }>(),
);

export const saveSubprocessorsUrl = createAction(
    AssessmentCollectionCommonCommandActions.SaveSubprocessorsUrl,
    props<{ subprocessorsUrl: string }>(),
);
