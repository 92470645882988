import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssessmentCancelledReason, AssessmentStatusLabels, VendorCancelReason } from '@entities/assessment';
import { AUTOMATION_EMAIL } from '@shared/constants/email.constants';

@Component({
    selector: 'app-assessment-cancelled',
    templateUrl: './assessment-cancelled.component.html',
    styleUrls: ['./assessment-cancelled.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentCancelledComponent {
    @Input({ required: true })
    cancelledReason: AssessmentCancelledReason;

    @Input({ required: true })
    clientName: string;

    @Input({ required: true })
    senderEmail: string;

    @Input({ required: true })
    justCancelled: boolean;

    @Input({ required: true })
    remediationJustDeclined: boolean;

    AssessmentStatusLabels = AssessmentStatusLabels;

    get cancelledByClient(): boolean {
        return this.cancelledReason !== VendorCancelReason.NO_LONGER_DO_BUSINESS;
    }

    get senderIsAutomation(): boolean {
        return this.senderEmail === AUTOMATION_EMAIL;
    }
}
