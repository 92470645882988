import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValidationDetection, QuestionnaireArtifact, UrlArtifact } from '@entities/artifact';
import {
    AdditionalAssessmentQuestionViewModel,
    AdditionalInformationSubSteps,
    PublicAssessmentRTPFileArtifact,
} from '../../../models';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-additional-information-step',
    templateUrl: './additional-information-step.component.html',
    styleUrls: ['./additional-information-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInformationStepComponent {
    @Input({ required: true }) subStep: AdditionalInformationSubSteps;
    @Input({ required: true }) additionalQuestions: AdditionalAssessmentQuestionViewModel[];
    @Input({ required: true }) clientName: string;
    @Input({ required: true }) vendorName: string;
    @Input({ required: true }) questionnaire: QuestionnaireArtifact;
    @Input({ required: true }) followupRequested: boolean;
    @Input({ required: true }) followupControlDomainIds: number[];
    @Input({ required: true }) relevantControlDomainIds: number[];
    @Input({ required: true }) activeSupplementalQuestionnaires: Map<string, string>;
    @Input({ required: true }) subprocessorsUrlArtifact: UrlArtifact;
    @Input({ required: true }) subprocessorDetections: ControlValidationDetection[];

    @Output() continueFromSubstep = new EventEmitter<AdditionalInformationSubSteps>();
    @Output() goBackFromSubstep = new EventEmitter<AdditionalInformationSubSteps>();
    @Output() uploadRecommendationsWentBack = new EventEmitter<void>();
    @Output() uploadArtifact = new EventEmitter<{ additionalQuestionId: string; file: File }>();
    @Output() removeFileArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();
    @Output() removeUrlArtifact = new EventEmitter<UrlArtifact>();
    @Output() setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();
    @Output() addingFileFailed = new EventEmitter<FilterFunction>();
    @Output() questionnaireWentBackToCollectArtifacts = new EventEmitter<void>();
    @Output() questionnaireUpdated = new EventEmitter<QuestionnaireArtifact>();
    @Output() updateAdditionalQuestion = new EventEmitter<AdditionalAssessmentQuestionViewModel>();
    @Output() uploadSubprocessorsFile = new EventEmitter<File>();
    @Output() updateSubprocessorDetections = new EventEmitter<ControlValidationDetection[]>();
    @Output() saveSubprocessorsUrl = new EventEmitter<string>();

    AdditionalInformationSubSteps = AdditionalInformationSubSteps;
}
