import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrl: './upload-file.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponent implements OnInit {
    @Input() supportedFileTypes: string[] = [];
    @Input() multiple = true;
    @Input() showSupportedExtensions = true;

    @Output() uploadFiles = new EventEmitter<File[]>();
    @Output() uploadFailed = new EventEmitter<FilterFunction>();
    @Output() invalidFileDropped = new EventEmitter<void>();

    @ViewChild('fileInput') fileInput: ElementRef;

    uploader = new FileUploader({
        url: '',
        maxFileSize: 100000000,
    });

    ngOnInit(): void {
        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            this.uploadFailed.emit(filter);
        };
    }

    public openFilePrompt(): void {
        this.fileInput.nativeElement.click();
    }

    checkAndEmit(files: File[]) {
        if (this.supportedFileTypes.length) {
            const supportedFileTypesWithoutDots = this.supportedFileTypes.map((item) => item.split('.').pop());
            for (const file of files) {
                if (!supportedFileTypesWithoutDots.includes(file?.type?.split('/').pop())) {
                    this.invalidFileDropped.emit();
                }
            }
        }
        this.uploadFiles.emit(files);
    }
}
