export enum RTPEventType {
    RTP_ARTIFACT_AV_SCANNED = 'RTP_ARTIFACT_AV_SCANNED',
    RTP_ARTIFACT_CLASSIFIED = 'RTP_ARTIFACT_CLASSIFIED',
    RTP_PUBLISH_DATE_SET = 'RTP_PUBLISH_DATE_SET',
    RTP_PAGE_DETECTIONS_CLASSIFIED = 'RTP_PAGE_DETECTIONS_CLASSIFIED',
    RTP_ARTIFACT_PAGES_COMPLETED = 'RTP_ARTIFACT_PAGES_COMPLETED',
    RTP_ARTIFACT_ERRORED = 'RTP_ARTIFACT_ERRORED',
    RTP_SUPPQ_DETECTIONS_COMPLETED = 'RTP_SUPPQ_DETECTIONS_COMPLETED',
    RTP_SUPPQ_ALL_DETECTIONS_COMPLETED = 'RTP_SUPPQ_ALL_DETECTIONS_COMPLETED',
    RTP_SUPPQ_ARTIFACT_DETECTIONS_PROCESSED = 'RTP_SUPPQ_ARTIFACT_DETECTIONS_PROCESSED',
    RTP_SUPPQ_ANSWERS_COMPLETED = 'RTP_SUPPQ_ANSWERS_COMPLETED',
}

export enum LongRunningTaskName {
    BULK_START_ASSESSMENTS = 'BULK_START_ASSESSMENTS',
    BULK_CANCEL_ASSESSMENTS = 'BULK_CANCEL_ASSESSMENTS',
    BULK_OFFBOARD_RELATIONSHIPS = 'BULK_OFFBOARD_RELATIONSHIPS',
    BULK_ARCHIVE_RELATIONSHIPS = 'BULK_ARCHIVE_RELATIONSHIPS',
    LLM_PREDICT_CONTEXT = 'LLM_PREDICT_CONTEXT',
}

export enum LongRunningTaskEventType {
    STATUS_CHANGED = 'STATUS_CHANGED',
}

export enum LongRunningTaskStatus {
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

export interface LongRunningTaskEvent {
    clientId: number;
    text: string;
    longRunningTaskName: LongRunningTaskName;
    eventType: LongRunningTaskEventType;
    recordId?: string;
    status: LongRunningTaskStatus;
}

export interface LongRunningTaskProjection {
    id: string;
    name: LongRunningTaskName;
    status: LongRunningTaskStatus;
    startTime: string;
    endTime?: string;
    error?: string;
    orgId: number;
    visoUserId: number;
    recordId?: string;
}
