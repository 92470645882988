import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlValidationDetection, UrlArtifact } from '@entities/artifact';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { debounceTime, map, withLatestFrom } from 'rxjs/operators';
import { SubprocessorSubmissionType } from '../../../../../models/subprocessors';
import { urlValidator } from '@shared/validators/url-validator';
import { PublicAssessmentRTPFileArtifact, SubprocessorsQuestion } from '../../../../../models';
import { FilterFunction } from '@shared/file-upload/interfaces';
import { AttestationValue } from '@entities/attestation';
import { VendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { OrgService } from '@entities/org';
import { Store } from '@ngrx/store';
import { getAssessmentToken, getSecret } from '../../../../../redux/selectors';
import { toObservable } from '@angular/core/rxjs-interop';

interface SubprocessorsFormGroup {
    submissionType: FormControl<SubprocessorSubmissionType>;
    subprocessorUrl: FormControl<string>;
    artifactProvidedCheck: FormControl<boolean>;
}

@Component({
    selector: 'app-subprocessors-question',
    templateUrl: './subprocessors-question.component.html',
    styleUrl: './subprocessors-question.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubprocessorsQuestionComponent implements OnInit, OnDestroy {
    clientName = input.required<string>();
    questionIdx = input.required<number>();
    totalQuestions = input.required<number>();
    subprocessorQuestion = input.required<SubprocessorsQuestion>();
    subprocessorsUrlArtifact = input.required<UrlArtifact>();
    subprocessorDetections = input.required<ControlValidationDetection[]>();

    @Output() goBack = new EventEmitter<void>();
    @Output() continue = new EventEmitter<SubprocessorSubmissionType>();
    @Output() uploadFile = new EventEmitter<File>();
    @Output() uploadFileFailed = new EventEmitter<FilterFunction>();
    @Output() removeFileArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();
    @Output() removeUrlArtifact = new EventEmitter<UrlArtifact>();
    @Output() updateQuestion = new EventEmitter<SubprocessorsQuestion>();
    @Output() updateDetections = new EventEmitter<ControlValidationDetection[]>();
    @Output() saveUrl = new EventEmitter<string>();

    vendorSearchResults$: Observable<VendorSearchResult[]>;
    subprocessorsFormGroup: FormGroup<SubprocessorsFormGroup>;
    SubprocessorInputType = SubprocessorSubmissionType;

    get continueDisabled(): boolean {
        switch (this.subprocessorsFormGroup.controls.submissionType.value) {
            case SubprocessorSubmissionType.MANUAL:
                return !this.subprocessorDetections()?.length;
            case SubprocessorSubmissionType.LINK:
                return !this.subprocessorsUrlArtifact();
            case SubprocessorSubmissionType.ARTIFACT:
                return !this.subprocessorQuestion().attestationArtifact;
            case SubprocessorSubmissionType.DECLINE:
                return false;
        }
    }

    private _fb = inject(FormBuilder);
    private _orgService = inject(OrgService);
    private _store$ = inject(Store);
    private _unsub$ = new Subject<void>();
    private _searchSubject$ = new Subject<string>();

    constructor() {
        toObservable(this.subprocessorsUrlArtifact).subscribe((urlArtifact) =>
            this.subprocessorsFormGroup.controls.subprocessorUrl.setValue(urlArtifact?.url ?? null),
        );
    }

    ngOnInit(): void {
        this.subprocessorsFormGroup = this._fb.group<SubprocessorsFormGroup>({
            submissionType: this._fb.control(this.subprocessorQuestion()?.submissionType),
            subprocessorUrl: this._fb.control(this.subprocessorsUrlArtifact()?.url, [
                urlValidator,
                Validators.required,
            ]),
            artifactProvidedCheck: this._fb.control(false),
        });

        this.subprocessorsFormGroup.valueChanges
            .pipe(takeUntil(this._unsub$), debounceTime(300))
            .subscribe((controls) =>
                this.updateSubprocessorQuestion(controls.submissionType, controls.artifactProvidedCheck),
            );

        this.vendorSearchResults$ = this._searchSubject$.pipe(
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            switchMap(([keyword, token, secret]) =>
                this._orgService.searchByKeywordForAssessment(token, secret, keyword).pipe(
                    map((vendorSearchResults) => {
                        vendorSearchResults.push({
                            id: null,
                            name: keyword,
                            homepage: null,
                            faviconUrl: null,
                            isNewVendor: true,
                            customOrgName: `Create new vendor "${keyword}"`,
                        });
                        return vendorSearchResults;
                    }),
                ),
            ),
        );
    }

    updateSubprocessorQuestion(inputType: SubprocessorSubmissionType, declinedReasonChecked: boolean): void {
        const subprocessorQuestion = this.subprocessorQuestion();
        subprocessorQuestion.submissionType = inputType;
        subprocessorQuestion.attestationValue =
            inputType === SubprocessorSubmissionType.DECLINE
                ? declinedReasonChecked
                    ? AttestationValue.OTHER_ARTIFACT
                    : AttestationValue.WONT_PROVIDE
                : null;
        this.updateQuestion.emit(subprocessorQuestion);
    }

    updateSubprocessorQuestionDetections(updatedDetections: ControlValidationDetection[]): void {
        this.updateDetections.emit(updatedDetections);
    }

    saveSubprocessorsUrl() {
        const url = this.subprocessorsFormGroup.controls.subprocessorUrl.value;
        this.saveUrl.emit(url.startsWith('http') ? url : `https://${url}`);
    }

    searchVendorsByKeyword(keyword: string): void {
        this._searchSubject$.next(keyword);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
