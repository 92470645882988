import { Injectable } from '@angular/core';
import { LongRunningTaskService } from '../long-running-task.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LongRunningTaskActions } from '@shared/long-running-task/redux/long-running-task.actions';

@Injectable()
export class LongRunningTaskEffects {
    getInProgressLongRunningTasksRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LongRunningTaskActions.getInProgressLongRunningTasksRequest),
            mergeMap(() =>
                this._longRunningTaskService.getAllRunningTasks().pipe(
                    map((tasks) => LongRunningTaskActions.getInProgressLongRunningTasksRequestSuccess({ tasks })),
                    catchError(() => of(LongRunningTaskActions.getInProgressLongRunningTasksRequestFailed())),
                ),
            ),
        ),
    );

    getAllTasksForRecordIdRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LongRunningTaskActions.getAllTasksForRecordIdRequest),
            mergeMap(({ recordId }) =>
                this._longRunningTaskService.getAllTasksForRecordId(recordId).pipe(
                    map((tasks) => LongRunningTaskActions.getAllTasksForRecordIdRequestSuccess({ tasks })),
                    catchError(() => of(LongRunningTaskActions.getAllTasksForRecordIdRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _longRunningTaskService: LongRunningTaskService,
    ) {}
}
