<div class="row justify-content-center">
    <div class="col-8">
        <app-assessment-forwarded *ngIf="showForwarded$ | async" />
        <app-assessment-submitted
            *ngIf="showSubmitted$ | async"
            [submittedByEmail]="submittedByEmail$ | async"
            [sentToEmail]="sentToEmail$ | async"
        />
        <app-assessment-completed *ngIf="showCompleted$ | async" />
        <app-assessment-cancelled
            *ngIf="showCancelled$ | async"
            [clientName]="clientName$ | async"
            [senderEmail]="senderEmail$ | async"
            [cancelledReason]="cancelledReason$ | async"
            [justCancelled]="justCancelled$ | async"
            [remediationJustDeclined]="remediationJustDeclined$ | async"
        />
    </div>
</div>
