import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, first, map, Observable, skipWhile, Subject, switchMap, takeWhile, tap } from 'rxjs';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import {
    getPublicAssessmentClientBrandingRequest,
    getPublicAssessmentClientBrandingRequestSuccess,
    submitPasscode,
} from '../../../redux/actions';
import { getExpiredAssessmentError, getInvalidLoginError, getIsAuthenticating } from '../../../redux/selectors';
import { STATIC_SITE_URL, VENDOR_AGREEMENT_URL, VISO_LOGO_URL } from '@shared/constants/url.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { setAssessmentHeaderColor, setAssessmentLogoVisible } from '../../../../../../layout/redux/layout.actions';
import { OrgBranding } from '../../../../../../branding';

enum ErrorTypes {
    INVALID_LOGIN = 'INVALID_LOGIN',
    ASSESSMENT_EXPIRED = 'ASSESSMENT_EXPIRED',
    'required' = 'required',
}

enum ErrorTypesMessages {
    INVALID_LOGIN = 'Sorry that was incorrect. Try again.',
    ASSESSMENT_EXPIRED = 'The assessment has expired',
    'required' = 'This Field is required',
}

function invalidLoginError(
    invalidLoginError$: Observable<boolean>,
    isAuthenticating$: Observable<boolean>,
    cdr: ChangeDetectorRef,
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
        return isAuthenticating$.pipe(
            skipWhile((authenticating) => !authenticating),
            switchMap((authenticating) =>
                invalidLoginError$.pipe(
                    map((loginError) => (loginError ? { [ErrorTypes.INVALID_LOGIN]: true } : null)),
                    takeWhile(() => !authenticating),
                ),
            ),
            tap((error) => !!error && cdr.markForCheck()),
            first(),
        );
    };
}

function expiredAssessmentError(
    expiredAssessmentError$: Observable<boolean>,
    isAuthenticating$: Observable<boolean>,
    cdr: ChangeDetectorRef,
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
        return isAuthenticating$.pipe(
            skipWhile((authenticating) => !authenticating),
            switchMap((authenticating) =>
                expiredAssessmentError$.pipe(
                    map((expired) => (expired ? { [ErrorTypes.ASSESSMENT_EXPIRED]: true } : null)),
                    takeWhile(() => !authenticating),
                ),
            ),
            tap((error) => !!error && cdr.markForCheck()),
            first(),
        );
    };
}

interface AssessmentAuthenticationFormGroup {
    passcode: FormControl<string>;
}

@Component({
    selector: 'app-assessment-collection-authentication',
    templateUrl: './assessment-collection-authentication.component.html',
    styleUrls: ['./assessment-collection-authentication.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentCollectionAuthenticationComponent implements OnInit, OnDestroy {
    assessmentAuthenticationFormGroup: FormGroup<AssessmentAuthenticationFormGroup>;

    branding$ = new BehaviorSubject<OrgBranding>(null);

    readonly VENDOR_AGREEMENT_URL = VENDOR_AGREEMENT_URL;
    readonly STATIC_SITE_URL = STATIC_SITE_URL;
    readonly VISO_LOGO_URL = VISO_LOGO_URL;

    private _unsub$: Subject<void> = new Subject<void>();

    get errors(): ValidationErrors {
        return this.assessmentAuthenticationFormGroup.controls.passcode.errors;
    }

    constructor(
        private _fb: FormBuilder,
        private _store$: Store,
        private _actions$: Actions,
        private _formUtils: FormUtilsService,
        private _cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.assessmentAuthenticationFormGroup = this._fb.group({
            passcode: this._fb.control('', {
                validators: Validators.required,
                asyncValidators: [
                    invalidLoginError(
                        this._store$.select(getInvalidLoginError),
                        this._store$.select(getIsAuthenticating),
                        this._cdr,
                    ),
                    expiredAssessmentError(
                        this._store$.select(getExpiredAssessmentError),
                        this._store$.select(getIsAuthenticating),
                        this._cdr,
                    ),
                ],
            }),
        });

        this._actions$
            .pipe(
                ofType(getPublicAssessmentClientBrandingRequestSuccess),
                tap(({ branding }) => {
                    this._store$.dispatch(setAssessmentLogoVisible({ visible: branding.showLogo }));
                    this._store$.dispatch(setAssessmentHeaderColor({ color: branding.brandingColor }));
                    this.branding$.next(branding);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._store$.dispatch(getPublicAssessmentClientBrandingRequest());
    }

    submitPasscode() {
        const { passcode } = this._formUtils.getCleanTypedFormGroupValue<FormGroup<AssessmentAuthenticationFormGroup>>(
            this.assessmentAuthenticationFormGroup,
        );
        this._store$.dispatch(submitPasscode({ passcode }));
    }

    getErrorMessage: (errorKey: string) => ErrorTypesMessages = (errorKey) => ErrorTypesMessages[errorKey];

    ngOnDestroy() {
        this._unsub$.next();
    }
}
