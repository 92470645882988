<div class="container-top-half" [formGroup]="subprocessorsFormGroup">
    <div class="row">
        <div class="col-xl-3">
            <p>Additional questions ({{ questionIdx() + 1 }} of {{ totalQuestions() }})</p>
            <h1 class="assessment-collection-step-title">
                What critical business do you rely on to service {{ clientName() }}?
            </h1>
            <p>
                Provide a list of sub-processors that are critical to the operation of your company or to your business
                relationship with {{ clientName() }}.
            </p>
        </div>
        <div class="col-auto ps-0 pb-4 d-none d-xl-block">
            <mat-divider class="h-100" [vertical]="true"></mat-divider>
        </div>
        <div class="col flex-nowrap fixed-height">
            <h5 class="fw-bold">Provide a list of your company's sub-processors</h5>
            <mat-radio-group
                class="d-flex flex-column"
                color="primary"
                [formControl]="subprocessorsFormGroup.controls.submissionType"
            >
                @if (subprocessorsFormGroup.controls.submissionType.value; as inputType) {
                    <mat-radio-button [value]="SubprocessorInputType.MANUAL">Manually input</mat-radio-button>
                    <ng-container *ngIf="inputType === SubprocessorInputType.MANUAL" [ngTemplateOutlet]="manual" />
                    <mat-radio-button [value]="SubprocessorInputType.LINK">Link to a public page</mat-radio-button>
                    <ng-container *ngIf="inputType === SubprocessorInputType.LINK" [ngTemplateOutlet]="link" />
                    <mat-radio-button [value]="SubprocessorInputType.ARTIFACT">Provide an artifact</mat-radio-button>
                    <ng-container *ngIf="inputType === SubprocessorInputType.ARTIFACT" [ngTemplateOutlet]="artifact" />
                    <mat-radio-button [value]="SubprocessorInputType.DECLINE">Decline to provide</mat-radio-button>
                    <ng-container *ngIf="inputType === SubprocessorInputType.DECLINE" [ngTemplateOutlet]="decline" />
                }
            </mat-radio-group>

            <!--Templates for the different radio options-->
            <ng-template #manual>
                <div class="manual-edit-container my-2">
                    <app-subprocessor-list
                        [detections]="subprocessorDetections()"
                        [vendorSearchResults]="vendorSearchResults$ | async"
                        (onVendorSearch)="searchVendorsByKeyword($event)"
                        (onModified)="updateSubprocessorQuestionDetections($event)"
                    />
                </div>
            </ng-template>

            <ng-template #link>
                <div class="list-item my-2 d-flex align-items-center flex-nowrap">
                    <mat-form-field class="w-50">
                        <mat-label>URL</mat-label>
                        <input
                            matInput
                            type="text"
                            aria-label="link to a public page"
                            placeholder="www.acme.com/subprocessors"
                            [formControl]="subprocessorsFormGroup.controls.subprocessorUrl"
                            [readonly]="!!subprocessorsUrlArtifact()"
                        />
                        @if (!subprocessorsUrlArtifact()) {
                            <button
                                matSuffix
                                mat-icon-button
                                class="me-2"
                                color="primary"
                                [disabled]="subprocessorsFormGroup.controls.subprocessorUrl.invalid"
                                (click)="saveSubprocessorsUrl()"
                            >
                                <mat-icon>check</mat-icon>
                            </button>
                        } @else {
                            <button
                                matSuffix
                                mat-icon-button
                                class="me-2"
                                [disabled]="subprocessorsFormGroup.controls.subprocessorUrl.invalid"
                                (click)="removeUrlArtifact.emit(subprocessorsUrlArtifact())"
                            >
                                <mat-icon fontSet="material-symbols-outlined">cancel</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </div>
            </ng-template>

            <ng-template #artifact>
                <div class="list-item my-2">
                    @if (!subprocessorQuestion().attestationArtifact) {
                        <div class="upload-container">
                            <app-upload-file
                                #uploader
                                [multiple]="false"
                                [supportedFileTypes]="['.csv', '.xls', '.xlsx', '.xlsm']"
                                (uploadFiles)="uploadFile.emit($event[0])"
                                (uploadFailed)="uploadFileFailed.emit($event)"
                            >
                                <div class="display-2">
                                    <mat-icon [inline]="true" color="primary">upload_file</mat-icon>
                                </div>
                                <strong
                                    >Drag and Drop or
                                    <span class="color-primary" role="button" (click)="uploader.openFilePrompt()"
                                        >Select Files</span
                                    ></strong
                                >
                            </app-upload-file>
                        </div>
                    } @else {
                        <app-file-artifact-item
                            [aiProcessed]="false"
                            [fileArtifactItem]="subprocessorQuestion().attestationArtifact"
                            (remove)="removeFileArtifact.emit(subprocessorQuestion().attestationArtifact)"
                        />
                    }
                </div>
            </ng-template>

            <ng-template #decline>
                <div class="list-item">
                    <mat-checkbox [formControl]="subprocessorsFormGroup.controls.artifactProvidedCheck" color="primary"
                        >I’ve already uploaded an artifact with this information</mat-checkbox
                    >
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="d-flex justify-content-end container-bottom-half">
    <button mat-stroked-button color="primary" class="me-auto" (click)="goBack.emit()">Go back</button>
    <button
        mat-flat-button
        color="primary"
        [disabled]="continueDisabled"
        (click)="continue.emit()"
        data-pw="continueButton"
    >
        Continue
    </button>
</div>
