<div
    class="row main-container"
    *ngIf="{
        clientId: clientId$ | async,
        clientName: clientName$ | async,
        vendorName: vendorName$ | async,
        senderEmail: senderEmail$ | async,
        clientBrandingColor: clientBrandingColor$ | async,
        clientLogoUrl: clientLogoUrl$ | async,
        hasBrandingEnabled: hasBrandingEnabled$ | async,
        useCompactOrientation: useCompactOrientation$ | async,
    } as ctx"
>
    <ng-template #stepperOverview>
        <app-steps-list
            [steps]="stepper?.steps"
            [selectedIndex]="stepper?.selectedIndex"
            [orientation]="ctx.useCompactOrientation ? 'horizontal' : 'vertical'"
        />
    </ng-template>
    <ng-template #logo>
        <div class="d-flex align-items-center">
            <div>
                <img
                    class="client-logo"
                    *ngIf="ctx.clientLogoUrl && ctx.hasBrandingEnabled; else clientName"
                    [src]="ctx.clientLogoUrl"
                    alt="Assessing company logo"
                />
                <ng-template #clientName>
                    <div class="client-name">{{ ctx.clientName }}</div>
                </ng-template>
                <div class="client-subtitle text-muted text-end">Security assessment</div>
            </div>
        </div>
    </ng-template>
    @if (ctx.useCompactOrientation) {
        <div class="d-flex justify-content-between align-items-end mb-4">
            <ng-container [ngTemplateOutlet]="logo"></ng-container>
            @if (stepper.selectedIndex !== StepIds.WELCOME) {
                <ng-container [ngTemplateOutlet]="stepperOverview"></ng-container>
            }
        </div>
    } @else {
        <div class="col-2 stepper-overview-container">
            <div class="row bottom-spacer">
                <ng-container [ngTemplateOutlet]="logo"></ng-container>
            </div>
            <div class="row bottom-spacer">
                <div class="col">
                    <ng-container [ngTemplateOutlet]="stepperOverview"></ng-container>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <mat-divider></mat-divider>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <strong class="d-block">Contact Us</strong>
                    <a [href]="'mailto:' + SUPPORT_EMAIL" class="mat-text-primary">support&#64;visotrust.com</a>
                </div>
            </div>
            <div
                class="row mt-5"
                *ngIf="
                    showPoweredByVisoTrust(
                        ctx.hasBrandingEnabled,
                        !!ctx.clientBrandingColor,
                        !!ctx.clientLogoUrl,
                        ctx.clientId
                    )
                "
            >
                <div class="col text-center">
                    <ng-container [ngTemplateOutlet]="poweredByViso"></ng-container>
                </div>
            </div>
        </div>
    }
    <div class="col">
        <div class="row">
            <div class="col">
                <app-custom-stepper>
                    <cdk-step [id]="StepIds.WELCOME" label="Welcome" editable="true">
                        <app-welcome-step
                            data-pw="welcomeStep"
                            [senderEmail]="ctx.senderEmail"
                            [clientName]="ctx.clientName"
                            [vendorName]="ctx.vendorName"
                            [businessCases]="businessCases$ | async"
                            [isExpirationExtensionAllowed]="isExpirationExtensionAllowed$ | async"
                            [showDeclineRemediation]="isRemediationAssessment$ | async"
                            [riskDimensionsPopoverTemplate]="riskDimensionsPopover"
                            [collectionType]="collectionType$ | async"
                            [isRemediationAssessment]="isRemediationAssessment$ | async"
                            [remediationTargetDate]="remediationTargetDate$ | async"
                            [followupRequested]="isFollowupRequested$ | async"
                            (beginCollection)="continueFromWelcomePage()"
                            (extendExpiration)="extendExpiration()"
                            (forwardRequest)="forwardRequest()"
                            (optOut)="optOut()"
                            (declineRemediation)="declineRemediation()"
                            (navigateToQuestionnaire)="navigateToQuestionnaire()"
                        />
                    </cdk-step>
                    <cdk-step
                        *ngIf="!(isFollowupRequested$ | async)"
                        [id]="StepIds.INITIAL_ARTIFACT_UPLOAD"
                        label="Upload artifacts"
                    >
                        <app-initial-artifact-upload
                            data-pw="initialUploadStep"
                            [uploadedArtifacts]="rtpFileArtifactsForInitialUploadScreen$ | async"
                            [collectionType]="collectionType$ | async"
                            [uploadedStatusesOfInScopeFileTypes]="uploadedStatusesOfInScopeFileTypes$ | async"
                            [additionalQuestions]="additionalAssessmentQuestions$ | async"
                            [expiredArtifactReplacements]="expiredArtifactReplacements$ | async"
                            [previousAssessmentRecommendations]="previousAssessmentRecommendations$ | async"
                            [vendorName]="vendorName$ | async"
                            [disableContinueButton]="disableInitialUploadContinue$ | async"
                            [classificationInProgress]="artifactClassificationInProgress$ | async"
                            (wentBack)="goBackFromInitialUpload()"
                            (continued)="continueFromInitialUpload()"
                            (uploadArtifacts)="uploadArtifacts($event)"
                            (uploadAndReplace)="uploadArtifactToReplace($event.file, $event.artifactIdToReplace)"
                            (setPassword)="setPassword($event)"
                            (removeArtifact)="removePublicAssessmentArtifact($event)"
                            (uploadFailed)="onWhenAddingFileFailed($event)"
                        />
                    </cdk-step>
                    <cdk-step
                        [id]="StepIds.ADDITIONAL_INFORMATION"
                        [label]="additionalInformationStepLabel$ | async"
                        editable="true"
                    >
                        <app-additional-information-step
                            data-pw="additionalInformationStep"
                            [subStep]="currentCollectArtifactsSubStep$ | async"
                            [additionalQuestions]="additionalAssessmentQuestions$ | async"
                            [clientName]="ctx.clientName"
                            [vendorName]="ctx.vendorName"
                            [questionnaire]="questionnaire$ | async"
                            [followupRequested]="isFollowupRequested$ | async"
                            [followupControlDomainIds]="followupControlDomainIds$ | async"
                            [relevantControlDomainIds]="relevantControlDomainIds$ | async"
                            [activeSupplementalQuestionnaires]="activeSupplementalQuestionnaires$ | async"
                            [subprocessorsUrlArtifact]="subprocessorsUrlArtifact$ | async"
                            [subprocessorDetections]="subprocessorDetections$ | async"
                            (continueFromSubstep)="continueFromSubStep($event)"
                            (goBackFromSubstep)="goBackFromSubStep($event)"
                            (addingFileFailed)="onWhenAddingFileFailed($event)"
                            (uploadArtifact)="
                                uploadAttestationArtifactForAdditionalQuestion($event.additionalQuestionId, $event.file)
                            "
                            (questionnaireUpdated)="saveQuestionnaire($event)"
                            (questionnaireWentBackToCollectArtifacts)="navigateToCollectArtifacts()"
                            (removeFileArtifact)="removePublicAssessmentArtifact($event)"
                            (removeUrlArtifact)="removeUrlArtifact($event)"
                            (setPassword)="setPassword($event)"
                            (updateAdditionalQuestion)="updateAdditionalQuestion($event)"
                            (uploadSubprocessorsFile)="uploadSubprocessorsFile($event)"
                            (updateSubprocessorDetections)="updateSubprocessorDetections($event)"
                            (saveSubprocessorsUrl)="saveSubprocessorsUrl($event)"
                        />
                    </cdk-step>
                    <cdk-step [id]="StepIds.SUBMIT" label="Submit" editable="true">
                        <app-submission-step
                            data-pw="submissionStep"
                            [submitted]="isSubmitted$ | async"
                            [clientName]="ctx.clientName"
                            (wentBack)="submissionWentBack()"
                            (submit)="submissionSubmit($event)"
                        />
                    </cdk-step>
                </app-custom-stepper>
            </div>
        </div>
    </div>
    @if (
        ctx.useCompactOrientation &&
        showPoweredByVisoTrust(ctx.hasBrandingEnabled, !!ctx.clientBrandingColor, !!ctx.clientLogoUrl, ctx.clientId)
    ) {
        <div class="text-center mt-3">
            <ng-container [ngTemplateOutlet]="poweredByViso"></ng-container>
        </div>
    }
</div>

<ng-template #riskDimensionsPopover>
    <mat-card>
        <mat-card-content>
            Our risk dimensions span security, privacy, business continuity, insurance, and artificial intelligence risk
            dimensions.
        </mat-card-content>
    </mat-card>
</ng-template>
<ng-template #poweredByViso>
    Powered by<br />
    <a [externalLink]="STATIC_SITE_URL" target="_blank">
        <img class="mx-2" [ngSrc]="VISO_LOGO_URL" width="150" height="30" alt="VISO TRUST logo" />
    </a>
</ng-template>
