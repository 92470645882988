import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    input,
    Output,
    signal,
    ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ControlValidationDetection, ControlValidationDetectionSubservicer, SubservicerType } from '@entities/artifact';
import { VendorComponentsModule } from '@shared/vendor-components/vendor-components.module';
import { MatVendorSearchComponent } from '@shared/vendor-components/mat-vendor-search/mat-vendor-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { VendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { CallbackPipeModule } from '@shared/pipes/callback-pipe';
import { MatDivider } from '@angular/material/divider';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { filter, take } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';

interface SubprocessorInputFormGroup {
    detectionId: FormControl<number>;
    vendorOrg: FormControl<string | VendorSearchResult>;
    purpose: FormControl<string>;
    location: FormControl<string>;
    editing: FormControl<boolean>;
    new: FormControl<boolean>;
}

const sortByCreatedDate = (detections: ControlValidationDetection[]): ControlValidationDetection[] =>
    detections.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());

@Component({
    selector: 'app-subprocessor-list',
    templateUrl: './subprocessor-list.component.html',
    styleUrl: './subprocessor-list.component.scss',
    imports: [
        CommonModule,
        MatTableModule,
        VendorComponentsModule,
        MatVendorSearchComponent,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        CallbackPipeModule,
        MatDivider,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubprocessorListComponent {
    detections = input.required({
        transform: (cvd: ControlValidationDetection[]) => sortByCreatedDate(cvd),
    });
    readonly = input<boolean>(false);
    vendorSearchResults = input<VendorSearchResult[]>([]);

    @Output() onModified = new EventEmitter<ControlValidationDetection[]>();
    @Output() onVendorSearch = new EventEmitter<string>();

    @ViewChild(MatTable) table: MatTable<SubprocessorInputFormGroup>;

    displayedColumns = ['company', 'purpose', 'location'];
    dataSource = new MatTableDataSource<FormGroup<SubprocessorInputFormGroup>>();
    showCancelEditButton = false;

    // Tracking which row we're searching vendors for, so we don't
    // show search results across rows, since we're using the same search results input for each row.
    currentIndexSearchedFor = signal<number>(0);

    private _initialFormState: Partial<ControlValidationDetectionSubservicer[]>;
    private _fb = inject(FormBuilder);
    private _confirmDialogService = inject(MatConfirmDialogService);
    private _lastIndexSearchedFor = this.currentIndexSearchedFor();

    get currentlyEditingRow(): boolean {
        return this.dataSource.data.some((row) => row.controls.editing.value);
    }

    constructor() {
        toObservable(this.detections).subscribe((detections) => {
            if (this.dataSource.data.length) {
                this._updateExistingFormGroups(this.detections());
            } else {
                this.dataSource.data = this._createNewFormGroups(detections);
            }
            if (!this.readonly()) {
                if (!this.displayedColumns.includes('actions')) {
                    this.displayedColumns.push('actions');
                }
                if (!this.dataSource.data.length) {
                    this.addEmptyRow();
                }
            }
            this.showCancelEditButton = this.dataSource.data.some((group) => !group.controls.new.value);
            this._initialFormState = this.dataSource.data.map((d) => d.value);
        });

        toObservable(this.vendorSearchResults).subscribe(() =>
            this.currentIndexSearchedFor.set(this._lastIndexSearchedFor),
        );
    }

    toggleEditMode(row: FormGroup<SubprocessorInputFormGroup>): void {
        row.controls.editing.setValue(true);
    }

    cancelEditMode(rowIdx: number): void {
        if (this.dataSource.data[rowIdx].controls.new.value) {
            this.deleteRow(rowIdx);
        } else {
            this.dataSource.data[rowIdx].reset(this._initialFormState[rowIdx]);
        }
    }

    saveRow(row: FormGroup<SubprocessorInputFormGroup>): void {
        row.controls.editing.setValue(false);
        row.controls.new.setValue(false);
        this.showCancelEditButton = true;
        this._saveAllSubprocessors();
    }

    deleteRow(rowIdx: number): void {
        const row = this.dataSource.data[rowIdx];
        const orgName = (row.controls.vendorOrg.value as VendorSearchResult)?.name;

        const _deleteRow = () => {
            this.dataSource.data.splice(rowIdx, 1);
            this.table.renderRows();
        };

        if (row.controls.detectionId.value && orgName?.length) {
            this._confirmDialogService
                .confirm({
                    title: 'Delete sub-processor',
                    message: `Are you sure you want to delete <strong>${orgName}</strong> from your list of sub-processors?`,
                    confirmLabel: 'Yes, delete sub-processor',
                    confirmButtonColor: 'warn',
                })
                .pipe(filter(Boolean), take(1))
                .subscribe(() => {
                    _deleteRow();
                    this._saveAllSubprocessors();
                });
        } else {
            _deleteRow();
        }
    }

    getVendorNameOrObject(subprocessorNameOrObject: VendorSearchResult | string | null): string {
        return typeof subprocessorNameOrObject === 'string' ? subprocessorNameOrObject : subprocessorNameOrObject?.name;
    }

    addEmptyRow(): void {
        this.dataSource.data.push(
            this._fb.group({
                detectionId: this._fb.control(null),
                vendorOrg: this._fb.control(null, Validators.required),
                purpose: this._fb.control(null),
                location: this._fb.control(null),
                editing: this._fb.control(true),
                new: this._fb.control(true),
            }),
        );
        this.table.renderRows();
    }

    trackByFn(index: number) {
        return index;
    }

    searchVendorForIndex(index: any, keyword: string) {
        this._lastIndexSearchedFor = index;
        this.onVendorSearch.emit(keyword);
    }

    private _saveAllSubprocessors(): void {
        const detections: ControlValidationDetection[] = [];
        for (const row of this.dataSource.data.filter((row) => !row.controls.editing.value)) {
            const { detectionId, vendorOrg, purpose, location } = row.value;
            const subprocessorOrg = vendorOrg as VendorSearchResult;
            const subprocessor = {
                subservicerId: subprocessorOrg?.id,
                subservicerName: subprocessorOrg?.name,
                subservicerHomepage: subprocessorOrg?.homepage,
                subservicerFaviconUrl: subprocessorOrg?.faviconUrl,
                subservicerType: SubservicerType.SUBPROCESSOR,
                purpose: purpose,
                location: location,
            };
            let detection = this.detections().find((d) => d.id === detectionId);
            if (detection) {
                detection.subservicers[0] = subprocessor;
            } else {
                detection = {
                    id: detectionId,
                    subservicers: [subprocessor],
                };
            }
            detections.push(detection);
        }

        this.onModified.emit(detections);
    }

    private _updateExistingFormGroups(detections: ControlValidationDetection[]): void {
        detections.forEach((detection) => {
            const formGroup =
                this.dataSource.data.find((group) => group.controls.detectionId.value === detection.id) ??
                this.dataSource.data.find((group) => !group.controls.detectionId.value);
            if (formGroup) {
                formGroup.controls.detectionId.setValue(detection.id);
                formGroup.controls.vendorOrg.setValue({
                    id: detection.subservicers[0]?.subservicerId,
                    name: detection.subservicers[0]?.subservicerName,
                    homepage: detection.subservicers[0]?.subservicerHomepage,
                    faviconUrl: detection.subservicers[0]?.subservicerFaviconUrl,
                } as VendorSearchResult);
                formGroup.controls.purpose.setValue(detection.subservicers[0]?.purpose);
                formGroup.controls.location.setValue(detection.subservicers[0]?.location);
            }
        });
    }

    private _createNewFormGroups(detections: ControlValidationDetection[]): FormGroup<SubprocessorInputFormGroup>[] {
        return detections.map((detection) => {
            const subprocessor = detection.subservicers[0];
            return this._fb.group<SubprocessorInputFormGroup>({
                detectionId: this._fb.control(detection.id),
                vendorOrg: this._fb.control(
                    {
                        id: subprocessor?.subservicerId,
                        name: subprocessor?.subservicerName,
                        homepage: subprocessor?.subservicerHomepage,
                        faviconUrl: subprocessor?.subservicerFaviconUrl,
                    } as VendorSearchResult,
                    Validators.required,
                ),
                purpose: this._fb.control(subprocessor?.purpose),
                location: this._fb.control(subprocessor?.location),
                editing: this._fb.control(false),
                new: this._fb.control(false),
            });
        });
    }
}
