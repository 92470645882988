import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CallbackPipeModule } from 'src/main/webapp/app/shared/pipes/callback-pipe';
import { ReplacePipeModule } from '@shared/pipes/replace-pipe/replace-pipe.module';
import { VisoButtonModule } from '@shared/viso-button/viso-button.module';
import { MatConfirmDialogModule } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.module';
import { FileUploadModule } from '@shared/file-upload';
import { EnumToArrayPipeModule } from '@shared/pipes/enum-to-array-pipe/enum-to-array-pipe.module';
import { ExternalLinkModule } from '@shared/external-link';
import { AssessmentComponentsModule } from '@shared/assessment-components/assessment-components.module';
import { RadioButtonContainerTriggerModule } from '@shared/directives/radio-button-container-trigger/radio-button-container-trigger.module';
import { VisoPopoverModule } from '@shared/viso-popover/viso-popover.module';
import {
    AdditionalAssessmentQuestionsComponent,
    AdditionalInformationStepComponent,
    AnswerQuestionnaireComponent,
    ArtifactExamplesComponent,
    ArtifactListComponent,
    AssessmentCancelledComponent,
    AssessmentCollectionAuthenticationComponent,
    AssessmentCollectionComponent,
    AssessmentCompletedComponent,
    AssessmentForwardedComponent,
    AssessmentNoLongerInProgressComponent,
    AssessmentSubmittedComponent,
    ConfirmCancellationDialogComponent,
    ConfirmDeclineRemediationDialogComponent,
    FileArtifactItemComponent,
    ForwardAssessmentDialogComponent,
    StepsListComponent,
    SubmissionStepComponent,
    WelcomeStepComponent,
} from './components';
import { assessmentCollectionStateKey } from './redux/assessment-collection.state';
import { assessmentCollectionReducer } from './redux/assessment-collection.reducer';
import { effects } from './redux/effects';
import { ToggleDisableOnActionsModule } from '@shared/toggle-disable-on-actions/toggle-disable-on-actions.module';
import { InitialArtifactUploadComponent } from './components/flow/initial-artifact-upload/initial-artifact-upload.component';
import { LottieComponent } from 'ngx-lottie';
import { ReplaceFileArtifactItemComponent } from './components/flow/replace-file-artifact-item/replace-file-artifact-item.component';
import { SafeHtmlPipeModule } from '@shared/pipes/safe-html-pipe/safe-html-pipe.module';
import { SupplementalQuestionnaireStepComponent } from './components/flow/supplemental-questionnaire-step/supplemental-questionnaire-step.component';
import { ArtifactAuditTypeAnimatedLabelComponent } from './components/flow/file-artifact-item-animations/artifact-audit-type-animated-label/artifact-audit-type-animated-label.component';
import { ArtifactFileTypeAnimatedPillComponent } from './components/flow/file-artifact-item-animations/artifact-file-type-animated-pill/artifact-file-type-animated-pill.component';
import { CustomStepperModule } from '@shared/components/custom-stepper/custom-stepper.module';
import { SubprocessorsQuestionComponent } from './components/flow/artifact-upload-recommendations/additional-question-components/subprocessors-question/subprocessors-question.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SubprocessorListComponent } from '@shared/subprocessor/subprocessor-list/subprocessor-list.component';
import { MissingArtifactQuestionComponent } from './components/flow/artifact-upload-recommendations/additional-question-components/missing-artifact-question/missing-artifact-question.component';
import { UploadFileComponent } from './components/flow/upload-file-prompt/upload-file.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FullPageFileDropComponent } from '@shared/file-upload/components/full-page-file-drop/full-page-file-drop.component';
import { FileUploadHelperService } from './services/file-upload-helper.service';

@NgModule({
    declarations: [
        AdditionalInformationStepComponent,
        ArtifactExamplesComponent,
        ArtifactListComponent,
        AdditionalAssessmentQuestionsComponent,
        AssessmentCancelledComponent,
        AssessmentCollectionComponent,
        AssessmentCollectionAuthenticationComponent,
        AssessmentCompletedComponent,
        AssessmentForwardedComponent,
        AssessmentNoLongerInProgressComponent,
        AssessmentSubmittedComponent,
        ConfirmCancellationDialogComponent,
        ConfirmDeclineRemediationDialogComponent,
        FileArtifactItemComponent,
        ReplaceFileArtifactItemComponent,
        ForwardAssessmentDialogComponent,
        StepsListComponent,
        SubmissionStepComponent,
        WelcomeStepComponent,
        InitialArtifactUploadComponent,
        ArtifactAuditTypeAnimatedLabelComponent,
        ArtifactFileTypeAnimatedPillComponent,
        SupplementalQuestionnaireStepComponent,
        AnswerQuestionnaireComponent,
        SubprocessorsQuestionComponent,
        MissingArtifactQuestionComponent,
        UploadFileComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatCardModule,
        MatIconModule,
        MatExpansionModule,
        MatDividerModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatSelectModule,
        CdkStepperModule,
        CallbackPipeModule,
        ReplacePipeModule,
        VisoButtonModule,
        MatConfirmDialogModule,
        FileUploadModule,
        EnumToArrayPipeModule,
        ExternalLinkModule,
        AssessmentComponentsModule,
        RadioButtonContainerTriggerModule,
        VisoPopoverModule,
        StoreModule.forFeature(assessmentCollectionStateKey, assessmentCollectionReducer),
        EffectsModule.forFeature(effects),
        ToggleDisableOnActionsModule,
        LottieComponent,
        SafeHtmlPipeModule,
        NgOptimizedImage,
        CustomStepperModule,
        MatTableModule,
        MatPaginatorModule,
        SubprocessorListComponent,
        MatCheckbox,
        FullPageFileDropComponent,
    ],
    providers: [FileUploadHelperService],
    exports: [AssessmentCollectionComponent, AssessmentCollectionAuthenticationComponent],
})
export class AssessmentCollectionCommonModule {}
