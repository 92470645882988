import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AdditionalInformationSubSteps, AssessmentSubmissionContext, StepIds } from '../../models';
import { setSubmissionContext } from '../actions';
import { getAreThereAdditionalQuestions, getIsFollowupRequested } from '../selectors';
import { StepNavigationActions } from '../actions/step-navigation';
import { Injectable } from '@angular/core';

@Injectable()
export class StepNavigationEffects {
    continueFromWelcomePage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.continueFromWelcomePage),
            withLatestFrom(this._store$.select(getIsFollowupRequested)),
            map(([, isFollowupRequested]) =>
                isFollowupRequested
                    ? StepNavigationActions.navigateToQuestionnaireForFollowup()
                    : StepNavigationActions.navigateToCollectArtifacts(),
            ),
        ),
    );

    navigateToQuestionnaireForFollowup$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.navigateToQuestionnaireForFollowup),
            mergeMap(() => [
                StepNavigationActions.setCurrentStep(StepIds.ADDITIONAL_INFORMATION),
                setSubmissionContext({ context: AssessmentSubmissionContext.FOLLOWUP_QUESTIONNAIRE }),
                StepNavigationActions.setCurrentCollectArtifactsSubStep(
                    AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE,
                ),
            ]),
        ),
    );

    navigateToCollectArtifacts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.navigateToCollectArtifacts),
            mergeMap(() => [
                setSubmissionContext({ context: AssessmentSubmissionContext.FILE_ARTIFACTS }),
                StepNavigationActions.setCurrentStep(StepIds.INITIAL_ARTIFACT_UPLOAD),
            ]),
        ),
    );

    continueFromInitialUpload$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.continueFromInitialUpload),
            withLatestFrom(this._store$.select(getAreThereAdditionalQuestions)),
            mergeMap(([, areThereRecommendations]) => [
                StepNavigationActions.setCurrentStep(
                    areThereRecommendations ? StepIds.ADDITIONAL_INFORMATION : StepIds.SUBMIT,
                ),
                StepNavigationActions.setCurrentCollectArtifactsSubStep(
                    AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS,
                ),
            ]),
        ),
    );

    goBackFromInitialUpload$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.goBackFromInitialUpload),
            map(() => StepNavigationActions.setCurrentStep(StepIds.WELCOME)),
        ),
    );

    goBackFromSubmission$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.goBackFromSubmission),
            withLatestFrom(this._store$.select(getAreThereAdditionalQuestions)),
            map(([, areThereRecommendations]) =>
                StepNavigationActions.setCurrentStep(
                    areThereRecommendations ? StepIds.ADDITIONAL_INFORMATION : StepIds.INITIAL_ARTIFACT_UPLOAD,
                ),
            ),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
