import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { ImageFallbackModule } from '../image-fallback/image-fallback.module';
import { TagsModule } from '../tags/tags.module';
import { VendorCardComponent } from './vendor-card/vendor-card.component';
import { ExternalLinkModule } from '../external-link';
import { CallbackPipeModule } from '../pipes/callback-pipe';
import { VendorSearchComponent } from './vendor-search/vendor-search.component';
import { AsVendorSearchResultPipe } from './as-vendor-search-result.pipe';
import { VendorTileComponent } from './vendor-tile/vendor-tile.component';
import { RouterModule } from '@angular/router';
import { VisoButtonModule } from '../viso-button/viso-button.module';
import { DisableAddRelationshipOnRulesModule } from '../disable-add-relationship-on-rules/disable-add-relationship-on-rules.module';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { ChipModule } from '../chip';
import { TimeAgoPipeModule } from '@shared/pipes/timeago-pipe/timeago-pipe.module';
import { VendorDetailsFormComponent } from './vendor-details/vendor-details-form/vendor-details-form.component';
import { EnumToArrayPipeModule } from '@shared/pipes/enum-to-array-pipe/enum-to-array-pipe.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { AuthDirectivesModule } from '../auth-directives';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BadgeModule } from '../badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { VendorRiskAdvisoryDialogComponent } from './vendor-risk-advisory-dialog/vendor-risk-advisory-dialog.component';
import { AssessmentComponentsModule } from '../assessment-components/assessment-components.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToggleDisableOnActionsModule } from '../toggle-disable-on-actions/toggle-disable-on-actions.module';
import { EffectsModule } from '@ngrx/effects';
import { VendorRiskAdvisoryEffects } from './vendor-risk-advisory-dialog/redux/effects/vendor-risk-advisory.effects';
import { VisoEditorModule } from '../components/viso-editor/viso-editor.module';
import { VendorRiskAdvisoryTableComponent } from './vendor-risk-advisory-table/vendor-risk-advisory-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SafeHtmlPipeModule } from '@shared/pipes/safe-html-pipe/safe-html-pipe.module';
import { MatTooltip } from '@angular/material/tooltip';
import { TruncateModule } from '@shared/truncate/truncate.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { VendorLeadershipComponent } from './vendor-details/components/vendor-leadership/vendor-leadership.component';
import { VendorRiskEventsComponent } from './vendor-details/components/vendor-risk-events/vendor-risk-events.component';
import { VendorArtifactsComponent } from './vendor-details/components/vendor-artifacts/vendor-artifacts.component';
import { VendorContextComponent } from './vendor-details/components/vendor-context/vendor-context.component';
import { VendorOverviewComponent } from './vendor-details/components/vendor-overview/vendor-overview.component';
import { DisclaimerModule } from '@shared/disclaimer';
import { VendorRiskDetailsComponent } from './vendor-details/components/vendor-overview/vendor-predicted-risk-details/vendor-risk-details.component';
import { VendorComplianceComponent } from './vendor-details/components/vendor-compliance/vendor-compliance.component';
import { VendorLinksComponent } from './vendor-details/components/vendor-links/vendor-links.component';
import { VendorRiskDomainsComponent } from './vendor-details/components/vendor-risk-domains/vendor-risk-domains.component';
import { ReplacePipeModule } from '@shared/pipes/replace-pipe/replace-pipe.module';
import { RiskUtilsModule } from '@shared/risk-utils/risk-utils.module';
import { AssuranceLevelMeterModule } from '@shared/assurance-level-meter';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
    declarations: [
        VendorSearchComponent,
        VendorCardComponent,
        AsVendorSearchResultPipe,
        VendorTileComponent,
        VendorDetailsComponent,
        VendorDetailsFormComponent,
        VendorRiskAdvisoryDialogComponent,
        VendorRiskAdvisoryTableComponent,
        VendorLeadershipComponent,
        VendorRiskEventsComponent,
        VendorArtifactsComponent,
        VendorContextComponent,
        VendorOverviewComponent,
        VendorRiskDetailsComponent,
        VendorComplianceComponent,
        VendorLinksComponent,
        VendorRiskDomainsComponent,
    ],
    imports: [
        EffectsModule.forFeature([VendorRiskAdvisoryEffects]),
        CommonModule,
        ReactiveFormsModule,
        NgxSelectModule,
        ImageFallbackModule,
        TagsModule,
        ExternalLinkModule,
        CallbackPipeModule,
        RouterModule,
        VisoButtonModule,
        DisableAddRelationshipOnRulesModule,
        MatCardModule,
        MatListModule,
        ChipModule,
        TimeAgoPipeModule,
        EnumToArrayPipeModule,
        MatSlideToggleModule,
        MatInputModule,
        AuthDirectivesModule,
        MatOptionModule,
        MatSelectModule,
        BadgeModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatDatepickerModule,
        AssessmentComponentsModule,
        MatDialogModule,
        MatCheckboxModule,
        ToggleDisableOnActionsModule,
        VisoEditorModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        SafeHtmlPipeModule,
        MatTooltip,
        TruncateModule,
        MatSidenavModule,
        DisclaimerModule,
        ReplacePipeModule,
        RiskUtilsModule,
        AssuranceLevelMeterModule,
        NgxSkeletonLoaderModule,
    ],
    exports: [
        VendorSearchComponent,
        VendorCardComponent,
        VendorTileComponent,
        VendorDetailsComponent,
        VendorDetailsFormComponent,
        VendorRiskAdvisoryDialogComponent,
    ],
})
export class VendorComponentsModule {}
