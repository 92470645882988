@if (viewType === ArtifactPageLinkViewType.locationLink) {
    @switch (viewerPage | callback: getPageType) {
        @case ('range') {
            <a class="viso-link" (click)="openArtifactPage()"> p{{ viewerPage.initialPage }} </a>
            -
            <ng-container
                [ngTemplateOutlet]="linkRef"
                [ngTemplateOutletContext]="{ label: viewerPage.finalPage }"
            ></ng-container>
        }
        @case ('page') {
            <ng-container
                [ngTemplateOutlet]="linkRef"
                [ngTemplateOutletContext]="{ label: 'p' + viewerPage }"
            ></ng-container>
        }
        @case ('text') {
            <span>{{ viewerPage }}</span>
        }
    }
} @else {
    <span [tooltip]="artifactTooltip">
        @if (
            detection.artifactType === ArtifactType.FILE_ARTIFACT ||
            detection.artifactType === ArtifactType.URL_ARTIFACT
        ) {
            <ng-container
                [ngTemplateOutlet]="linkRef"
                [ngTemplateOutletContext]="{ label: detection.artifactName }"
            ></ng-container>
        } @else if (detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
            <a
                exportRemovableAction
                target="blank"
                [routerLink]="['/questionnaire', detection.artifactId]"
                [replaceUrl]="false"
            >
                Open in new tab
                <mat-icon class="open-in-new-icon">open_in_new</mat-icon>
            </a>
        } @else if (
            detection.artifactType === ArtifactType.ATTESTATION_ARTIFACT &&
            detection.detectionType === ControlValidationDetectionType.SUBPROCESSOR
        ) {
            <a exportRemovableAction target="blank" [routerLink]="['./attestations', detection.artifactId]">
                Open in new tab
                <mat-icon class="open-in-new-icon">open_in_new</mat-icon>
            </a>
        }
    </span>
}

<ng-template #linkRef let-label="label">
    <a class="viso-link" (click)="openArtifactPage()">
        {{ label }}
        <mat-icon class="open-in-new-icon">open_in_new</mat-icon>
    </a>
</ng-template>
