<mat-card appearance="outlined" *ngIf="justCancelled || remediationJustDeclined; else cancelled">
    <mat-card-content class="text-center" *ngIf="justCancelled">
        <p class="lead">You have successfully opted out of the assessment with {{ clientName }}.</p>
        <p>You may close the page.</p>
    </mat-card-content>
    <mat-card-content class="text-center" *ngIf="remediationJustDeclined">
        <p class="lead">You have successfully declined the remediation request from {{ clientName }}.</p>
        <p>You may close the page.</p>
    </mat-card-content>
</mat-card>
<ng-template #cancelled>
    <mat-card appearance="outlined">
        <mat-card-content class="text-center">
            <p>
                Thank you for responding to our collection request, but this review has been marked as
                {{ AssessmentStatusLabels.CANCELLED }}
                <ng-container *ngIf="cancelledByClient"> by {{ clientName }} </ng-container>
                and your participation is no longer needed.
            </p>

            <p class="text-footer pt-1">
                If you have any questions, reach out to the primary contact at {{ clientName }},
                <a *ngIf="!senderIsAutomation" class="text-nowrap" href="mailto:{{ senderEmail }}"
                    >{{ senderEmail }},</a
                >
                or email us at <a class="text-nowrap" href="mailto:support@visotrust.com">support&#64;visotrust.com</a>.
            </p>
        </mat-card-content>
    </mat-card>
</ng-template>
