<mat-accordion [displayMode]="'flat'" [multi]="true">
    <mat-expansion-panel
        *ngFor="let controlDomainAssessment of controlDomainAssessments; trackBy: trackByControlDomainId"
        (closed)="triggerAccordionToggled(false, controlDomainAssessment)"
        (opened)="triggerAccordionToggled(true, controlDomainAssessment)"
        [expanded]="isAccordionOpened(controlDomainAssessment.controlDomainId)"
        togglePosition="before"
        class="mat-elevation-z0"
    >
        <mat-expansion-panel-header [expandedHeight]="'auto'" class="expansion-panel-header">
            <mat-panel-title
                class="p-2 ps-0 expansion-panel-title"
                [ngClass]="{
                    'out-of-scope': isOutOfScope,
                    'control-name-truncate': !isAccordionOpened(controlDomainAssessment.controlDomainId),
                }"
            >
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <span>
                            {{ controlDomainAssessment.controlName }}
                            <strong *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]" class="control-coverage-label">
                                {{ controlDomainAssessment.coverage * 100 }}%
                            </strong>
                        </span>
                        <mat-panel-description *ngIf="!isDisabled">
                            <div class="d-flex gap-3 control-badges">
                                @if (controlDomainAssessment.exceptionsCount > 0) {
                                    <span class="badge exceptions-badge">Exception</span>
                                }
                                @if (controlDomainAssessment.subservicerCount > 0) {
                                    <span class="badge subservicer-badge">Subservicer</span>
                                }
                                @if (controlDomainAssessment.subprocessorCount > 0) {
                                    <span class="badge subprocessor-badge">Sub-processor</span>
                                }
                                @if (controlDomainAssessment.sharedResponsibility) {
                                    <span class="badge shared-responsibility-badge">Shared Responsibility</span>
                                }
                                @if (controlDomainAssessment.cuecCount > 0) {
                                    <span class="badge cuec-badge">CUEC</span>
                                }
                            </div>
                        </mat-panel-description>
                    </div>

                    <div class="d-flex align-items-center gap-2">
                        @if (controlDomainAssessment.atLeastOneDetectionIsTested) {
                            <mat-icon fontSet="material-symbols-outlined" class="test-icon" matTooltip="Tested"
                                >science
                            </mat-icon>
                        }
                        <div
                            class="flex-shrink-1 flex-grow-0 d-flex align-items-center"
                            *ngIf="
                                !!controlDomainAssessment.assuranceLevel &&
                                groupControlValidationStatus !== SecurityControlDomainGroupStatus.NOT_APPLICABLE &&
                                groupControlValidationStatus !== SecurityControlDomainGroupStatus.UNVALIDATED &&
                                groupControlValidationStatus !== SecurityControlDomainGroupStatus.OUT_OF_SCOPE &&
                                controlDomainAssessment.detectionsTotalCount > 0
                            "
                        >
                            <app-assurance-level-meter
                                [hideLabel]="true"
                                [assuranceLevel]="controlDomainAssessment.assuranceLevel"
                            ></app-assurance-level-meter>
                        </div>
                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <section class="expansion-panel-content" matExpansionPanelContent>
            <p class="ms-2 me-4 mb-3 domain-description" [ngClass]="isOutOfScope ? 'out-of-scope' : ''">
                {{ controlDomainAssessment.answer || controlDomainAssessment.longDescription }}
            </p>

            <ul class="ps-2 pe-3" *ngIf="!isDisabled">
                @for (individualControl of controlDomainAssessment.individualControls; track trackByControlId) {
                    <p class="control-name">
                        {{ individualControl.controlName }}
                        ({{ individualControl.detections.length }})
                    </p>
                    @if (individualControl.controlId === subprocessorControlId) {
                        <mat-card [appearance]="'outlined'" class="mb-3 subprocessors-list-card">
                            <mat-card-header>
                                <h5>Sub-processor list</h5>
                            </mat-card-header>
                            <mat-card-content>
                                <app-subprocessor-list [readonly]="true" [detections]="individualControl.detections" />
                            </mat-card-content>
                        </mat-card>
                    }
                    @if (individualControl.controlId !== subprocessorControlId || subprocessorDetectionsVisible) {
                        @for (detection of individualControl.detections; track trackById) {
                            <ng-container
                                *ngTemplateOutlet="
                                    controlDetection;
                                    context: {
                                        detection: detection | asCommonDetection,
                                        individualControlName: detection.controlName,
                                        domainControlName: controlDomainAssessment.controlName,
                                    }
                                "
                            ></ng-container>
                        }

                        <p *ngIf="controlDomainAssessment.detections.length" class="control-name">
                            {{ !controlDomainAssessment.forSuppQ ? 'Domain validation - ' : '' }}
                            {{ controlDomainAssessment.controlName }} ({{ controlDomainAssessment.detections.length }})
                        </p>
                        @for (detection of controlDomainAssessment.detections; track trackById) {
                            <ng-container
                                *ngTemplateOutlet="
                                    controlDetection;
                                    context: {
                                        detection: detection | asCommonDetection,
                                        individualControlName:
                                            (!controlDomainAssessment.forSuppQ ? 'Domain Level - ' : '') +
                                            controlDomainAssessment.controlName,
                                        domainControlName: controlDomainAssessment.controlName,
                                    }
                                "
                            ></ng-container>
                        }
                    }

                    @if (individualControl.controlId === subprocessorControlId) {
                        <button
                            mat-button
                            color="primary"
                            class="subprocessors-button"
                            (click)="subprocessorDetectionsVisible = !subprocessorDetectionsVisible"
                        >
                            <mat-icon>
                                {{ subprocessorDetectionsVisible ? 'visibility_off' : 'visibility' }}
                            </mat-icon>
                            {{ subprocessorDetectionsVisible ? 'Hide' : 'Show' }} sub-processor detections
                        </button>
                    }
                }
            </ul>
        </section>
    </mat-expansion-panel>
</mat-accordion>

<ng-template
    #controlDetection
    let-detection="detection"
    let-individualControlName="individualControlName"
    let-domainControlName="domainControlName"
>
    <li class="list-group-item px-0 pt-0" *ngIf="isDetectionAllowed(detection)">
        <app-detection-card
            [detection]="detection"
            [individualControlName]="individualControlName"
            [frameworkType]="frameworkType"
            (click)="onDetectionClick(detection, individualControlName, frameworkType, domainControlName)"
            [selected]="(getDetectionDetailsDrawerData$ | async)?.detection?.id === detection?.id"
        />
    </li>
</ng-template>
