import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import {
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../routes/request/models/vendor-details.model';
import { DataType } from '@entities/data-type';
import { ContextType } from '@entities/context-type';
import { ControlDomainType, ControlDomainTypeLabels } from '@entities/control-domain';
import {
    LongRunningTaskName,
    LongRunningTaskProjection,
    LongRunningTaskStatus,
} from '@entities/long-running-tasks/long-running-task.model';

@Component({
    selector: 'app-vendor-context',
    templateUrl: './vendor-context.component.html',
    styleUrl: './vendor-context.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorContextComponent {
    vendorDetails = input.required<VendorDetailsResponse>();
    vendorDetailsStats = input.required<VendorDetailsStatsResponse>();
    contextTypes = input.required<ContextType[]>();
    dataTypes = input.required<DataType[]>();
    disabledControlDomainTypes = input.required<ControlDomainType[]>();
    inScopeControlDomainTypes = input.required<ControlDomainType[]>();
    relationshipId = input<number>();
    relationshipLongRunningTasks = input<LongRunningTaskProjection[]>();

    ControlDomainTypeLabels = ControlDomainTypeLabels;

    isContextProcessing: Signal<boolean>;

    relevantRiskDimensions: Signal<ControlDomainType[]>;
    allRiskDimensions = Object.keys(ControlDomainType)
        .filter(
            (controlDomainType) =>
                controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE &&
                controlDomainType !== ControlDomainType.SUBSERVICERS,
        )
        .map((controlDomainType) => ControlDomainType[controlDomainType]);

    get addContextLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId()}/context`,
                },
            },
        ];
    }

    constructor() {
        this.relevantRiskDimensions = computed(() =>
            this.inScopeControlDomainTypes()?.filter(
                (controlDomainType) =>
                    controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE &&
                    controlDomainType !== ControlDomainType.SUBSERVICERS,
            ),
        );

        this.isContextProcessing = computed(
            () =>
                this.relationshipLongRunningTasks()?.some(
                    (task) =>
                        task.name === LongRunningTaskName.LLM_PREDICT_CONTEXT &&
                        task.status === LongRunningTaskStatus.RUNNING,
                ) ||
                (!this.relationshipLongRunningTasks()?.some(
                    (task) => task.name === LongRunningTaskName.LLM_PREDICT_CONTEXT,
                ) &&
                    !this.vendorDetailsStats()?.canPredictRiskUsingExistingInfo),
        );
    }

    isControlDomainTypeDisabled(controlDomainType: ControlDomainType): boolean {
        return this.disabledControlDomainTypes()?.includes(controlDomainType);
    }

    isControlDomainTypeOutOfScope(controlDomainType: ControlDomainType) {
        return !this.inScopeControlDomainTypes()?.includes(controlDomainType);
    }
}
