import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject, combineLatest, first, Observable, of, Subject } from 'rxjs';
import {
    delay,
    filter,
    map,
    mergeMap,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { ChartDataset } from 'chart.js';
import { Relationship } from '@entities/relationship';
import { RiskAssessment } from '@entities/risk-assessment';
import { Artifact, ArtifactType, QuestionnaireArtifact } from '@entities/artifact';
import { Attachment } from '@entities/attachment';
import { VisoUser, VisoUserGroups, VisoUserRole } from '@entities/viso-user';
import { AuditReport, AuditReportService, AuditReportTypeCode } from '@entities/audit-report';
import { ControlDomain, ControlDomainType, ControlDomainTypeLabels } from '@entities/control-domain';
import { RelationshipComment } from '@entities/relationship-comment';
import {
    AssuranceLevels,
    ControlDomainFileArtifact,
    ControlDomainQuestionnaireArtifact,
    RelationshipControlDomainAssessments,
} from '@entities/relationship/models/security-control-domain';
import { Tag } from '@entities/tag';
import { DataType } from '@entities/data-type';
import { RiskUtilsService } from '@shared/utils/risk-utils.service';
import { AssessmentRecertificationUtilsService } from '@shared/utils/assessment-recertification-utils.service';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import { ColorUtilsService } from '@shared/utils/color-utils.service';
import { FileDownloadActions } from '@shared/file-download/redux/actions';
import { DateUtilsService } from '@shared/utils/date-utils.service';
import { ObjectsToQuillMentionsConverterService } from '@shared/components/quill-wrapper/services/objects-to-quill-mentions-converter.service';
import { FastMention, QuillMentionListItem } from '@shared/components/quill-wrapper/quill.model';
import { QuillMentionParseHelperService } from '@shared/components/quill-wrapper/services/quill-mention-parse-helper.service';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { defaultRLPSearchParam, RouterUtilsService, RouterUtilsServiceRoute } from '@shared/utils/router-utils.service';
import { ClientProfileService } from '../../admin';
import {
    ClientProfile,
    DataTypeConfig,
    OrgAssessmentSummarySections,
} from '../../admin/client-profile/client-profile.model';
import { AssessmentStatus } from '@entities/assessment';
import {
    getProfileAssessmentSummarySections,
    getProfileDataTypes,
    getUserAccount,
    getUserAuthority,
    getUserIsTrial,
    getUserProfile,
    getVendorTiers,
} from '../session/redux/session.selectors';
import { getControls, getOrgControls } from '../controls/redux/controls.selectors';
import { getControlsByOrgIdRequest, getControlsByOrgIdRequestSuccess } from '../controls/redux/controls.actions';
import { getContactsRequest } from '../contact-management/redux/contact-management.actions';
import { PrimaryVendorContact } from '../primary-vendor-contact';
import {
    getRelationshipSubscribersRequest,
    getRelationshipSubscribersRequestSuccess,
    getRequestRiskAssessmentsRequest,
    getRequestRiskAssessmentsRequestSuccess,
    linkTagRequest,
    openEditFollowupMethodDialog,
    openReviewFollowupDialog,
    openReviewRiskDialog,
    unlinkTagRequest,
    unsubscribeContactRequest,
} from './redux/request.actions';
import {
    ArtifactActions,
    deleteRequestArtifactRequest,
    downloadRelationshipArtifactsAsZip,
    getRelationshipArtifactSupersessionRequest,
    getRequestArtifactsRequest,
    getRequestArtifactsRequestSuccess,
    openArtifactFile,
} from './redux/actions/artifacts.actions';
import {
    AttachmentActions,
    deleteRequestAttachmentRequest,
    downloadAttachment,
    downloadRelationshipAttachmentsAsZip,
    getRequestAttachmentRequest,
    getRequestAttachmentRequestSuccess,
} from './redux/actions/attachments.actions';
import {
    createRequestCommentRequest,
    deleteRequestCommentRequest,
    getRequestCommentsRequest,
    getRequestCommentsRequestSuccess,
} from './redux/actions/comments.actions';
import {
    getRequestMentionableUsersRequest,
    getRequestMentionableUsersRequestSuccess,
} from './redux/actions/mentionable-users.actions';
import {
    getRelationshipRequestSuccess,
    getRequestTags,
    getRequestTagsSuccess,
} from './redux/actions/relationship.actions';
import { offboardRelationshipRequest, openOnboardModal } from './redux/actions/onboard.actions';
import { markRequestAsArchived } from './redux/actions/mark.actions';
import { getRelationshipBusinessOwnerRequestSuccess } from './redux/actions/business-owner.actions';
import {
    getLastIiqrResponseByRelationshipId,
    getRelationshipArtifactSupersession,
    getRelationshipLongRunningTasks,
    getRelationshipPrimaryVendorContact,
    getRelationshipSuppQReprocessingState,
    getRelationshipVendorTier,
    getSupplementalQuestionnaireConfig,
} from './redux/request.selectors';
import {
    getRelationshipLatestCompletedAssessmentDate,
    getRelationshipPopulatedLatestAssessment,
    getRelationshipPopulatedPastAssessments,
} from './redux/selectors/assessment.selectors';
import {
    getRelationshipSecurityControlDomainRequest,
    getRelationshipSecurityControlDomainRequestSuccess,
} from './redux/actions/security-control-domains.actions';
import { GroupedControlDomains, MainTabs, RiskAnalysisTabs } from './models';
import {
    cancelRequestLatestAssessment,
    getRequestAssessmentsRequest,
    getRequestLatestAssessmentRequest,
    openStartAssessmentModal,
    proceedWithAvailableDataRequest,
    startAssessmentRequestSuccess,
} from './redux/actions/assessments.actions';
import { getChartColors } from './graph-data-constants';
import { askQuestionRequest } from './redux/actions/question-answering.actions';
import { InteractiveIqrResponse } from './rdp-ai-question-answering/rdp-ai-question-answering.model';
import { PopulatedAssessment } from './models/populated-assessment';
import {
    RTPEvent,
    RTPEventType,
    RTPPageDetectionsClassified,
    RTPSupplementalQuestionnaireAnswersCompleted,
    RTPSupplementalQuestionnaireArtifactDetectionsProcessed,
} from '@entities/rtp';
import {
    onRelationshipRtpEvent,
    onRelationshipRtpEventCancelled,
    onRelationshipRtpEventSuccess,
} from './redux/actions/rtp.actions';
import {
    getRelationshipAssessmentSummaryConfigRequest,
    getRelationshipAssessmentSummaryConfigRequestSuccess,
} from './redux/actions/assessment-summary-config.actions';
import { openCreateRiskAdvisoryDialog } from './redux/actions/risk-advisory.actions';
import { RiskTolerance, RiskToleranceLabels } from '@entities/risk-tolerance';
import { getBusinessUnitsRequest } from '../business-unit-management/redux/business-unit-management.actions';
import { getOrgDomainsRequest } from './redux/actions/organization.actions';
import {
    getOrgSupplementalQuestionnaireConfigRequest,
    getRelationshipSupplementalQuestionnaireConfigRequest,
    getRelationshipSupplementalQuestionnaireReprocessingStateRequest,
} from './redux/actions/supplemental-questionnaire.actions';
import { ActiveStatus, SupplementalQuestionnaireConfig } from '@entities/org';
import { VendorTier } from '@entities/vendor-tier';
import { MatDrawer } from '@angular/material/sidenav';
import * as FromArtifactIntelligenceSelectors from '@shared/artifact-intelligence/redux/artifact-intelligence.selectors';
import { ArtifactIntelligenceActions } from '@shared/artifact-intelligence/redux/artifact-intelligence.actions';
import { MediaWatcherService } from '@shared/services/media-watcher.service';
import { startSpotlightTour } from '../../layout/redux/layout.actions';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';
import { updateUserProfileRequest } from '../session/redux/session.actions';
import { SuppQReprocessingState } from '@entities/supplemental-questionnaire';
import { onRelationshipLongRunningTaskEvent } from './redux/actions/long-running-task.actions';
import { LongRunningTaskProjection } from '@entities/long-running-tasks/long-running-task.model';
import { LongRunningTaskActions } from '@shared/long-running-task/redux/long-running-task.actions';

export interface TabDefinition {
    id: string;
    label: string;
    disabled: boolean;
    bodyClass?: string;
    labelClass?: string;
    disabledToolTip?: string;
}

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('matDrawer', { static: false }) matDrawer: MatDrawer;

    request$ = new BehaviorSubject<Relationship>(null);

    currentAccount$: Observable<VisoUser>;
    userIsTrial$: Observable<boolean>;
    previousNavigatedUrl: string;
    previousNavigatedUrlQueryParams: Params;
    isPreviousViewFiltered: boolean;

    relationshipSubscribers$ = new BehaviorSubject<VisoUser[]>([]);
    primaryVendorContact$ = new BehaviorSubject<PrimaryVendorContact>(null);
    riskAssessments$ = new BehaviorSubject<RiskAssessment[]>([]);
    riskAssessmentsDatasets$: Observable<ChartDataset[]>;
    attachments$ = new BehaviorSubject<Attachment[]>(null);
    hasAttachments$: Observable<boolean>;
    controls$: Observable<ControlDomain[]>;
    groupedControls$: Observable<GroupedControlDomains[]>;
    supplementalQuestionnaireConfiguration$: Observable<SupplementalQuestionnaireConfig[]>;
    auditReports$: Observable<AuditReport[]>;
    latestRiskAssessment$ = new BehaviorSubject<RiskAssessment>(null);
    latestNonTransitionalRiskAssessment$ = new BehaviorSubject<RiskAssessment>(null);
    senderClientProfile$: Observable<ClientProfile>;

    latestAssessmentStatus$ = new BehaviorSubject<AssessmentStatus>(null);
    latestAssessmentFollowUpQuestionnaireCreatedDate$: Observable<Date>;
    missingInformationWasRequested$: Observable<boolean>;
    comments$ = new BehaviorSubject<RelationshipComment[]>(null);
    mentions$ = new BehaviorSubject<QuillMentionListItem[]>(null);
    fastMentions$ = new BehaviorSubject<FastMention[]>(null);
    businessOwner$ = new BehaviorSubject<VisoUser>(null);
    securityControlDomain$ = new BehaviorSubject<RelationshipControlDomainAssessments>(null);
    dataTypes$ = new BehaviorSubject<DataType[]>([]);
    dataTypesProfileConfig$ = new BehaviorSubject<DataTypeConfig[]>([]);
    isUpForRecertification$ = new BehaviorSubject<boolean>(false);
    isRecertificationOverdue$ = new BehaviorSubject<boolean>(false);
    isAssessmentInProgress$ = new BehaviorSubject<boolean>(false);
    recertificationDueDaysLeftCount$ = new BehaviorSubject<number>(0);
    showPrintArea$ = new BehaviorSubject<boolean>(false);
    latestAssessment$: Observable<PopulatedAssessment>;
    pastAssessments$: Observable<PopulatedAssessment[]>;
    isOnlyLegacyAssessmentPresent$: Observable<boolean>;
    latestCompletedAssessmentDate$: Observable<Date>;
    disabledControlDomainTypes$: Observable<ControlDomainType[]>;
    inScopeControlDomainTypes$: Observable<ControlDomainType[]>;
    isLatestAssessmentNonDocumentsOnly$: Observable<boolean>;
    hasPrivacyModuleEnabled$: Observable<boolean>;
    hasRDPAIQAEnabled$: Observable<boolean>;
    hasSupplementalQuestionnairesEnabled$: Observable<boolean>;
    hasOrgUserOnboardEnabled$: Observable<boolean>;
    hasLicenseManagementEnabled$: Observable<boolean>;
    hasArtifactsWithValidations$: Observable<boolean>;
    relationshipTags$ = new BehaviorSubject<Tag[]>([]);
    iiqrResponse$: Observable<InteractiveIqrResponse>;
    isCurrentUserAuditor: boolean;
    isCurrentUserSupport: boolean;
    artifactSupersession$: Observable<Map<number, number>>;
    dateOfNextArtifactExpiration$ = new BehaviorSubject<Date>(null);
    assessmentSummarySectionConfig$ = new BehaviorSubject<OrgAssessmentSummarySections[]>([]);
    allVendorTiers$: Observable<VendorTier[]>;
    currentVendorTier$: Observable<VendorTier>;
    filteredArtifacts$: Observable<Artifact[]>;

    tabDefinitions$: Observable<TabDefinition[]>;
    private readonly AI_QA_TAB_INDEX: number = 3;

    isRiskTimelineListView: boolean = false;
    selectedAttachments = new Map<Attachment, boolean>();
    anyAttachmentSelected: boolean = false;

    tabContentTrigger$ = new BehaviorSubject<MainTabs>(null);

    getIsDetectionDetailsDrawerOpen$ = this._store$.select(
        FromArtifactIntelligenceSelectors.getIsDetectionDetailsDrawerOpen,
    );
    getDetectionDetailsDrawerData$ = this._store$.select(
        FromArtifactIntelligenceSelectors.getDetectionDetailsDrawerData,
    );
    suppQReprocessingState$: Observable<Map<string, SuppQReprocessingState>>;
    relationshipLongRunningTasks$: Observable<LongRunningTaskProjection[]>;

    readonly Roles = VisoUserRole;
    readonly AssuranceLevels = AssuranceLevels;
    readonly FeatureFlags = FeatureFlags;
    readonly ArtifactActions = ArtifactActions;
    readonly AttachmentActions = AttachmentActions;
    readonly FileDownloadActions = FileDownloadActions;
    readonly ControlDomainType = ControlDomainType;

    @ViewChild('mainTabset')
    private _mainTabset: MatTabGroup;

    MainTabs = MainTabs;

    @HostListener('window:afterprint')
    onafterprint() {
        this.showPrintArea$.next(false);
    }

    onCloseDrawer() {
        this._store$.dispatch(ArtifactIntelligenceActions.closeDetectionDetailsDrawer());
    }

    artifactRtpEvents$ = new BehaviorSubject<Map<number, RTPEvent>>(new Map());

    drawerMode: 'side' | 'over';

    private _artifacts$ = new BehaviorSubject<Artifact[]>([]);
    private _unsub$ = new Subject<void>();
    private _requestId: number;
    private _getOrgIdRequestTrigger$ = new BehaviorSubject<number>(null);
    private _mainTabsetDefaultTab: MainTabs;

    constructor(
        private _auditReportService: AuditReportService,
        private _route: ActivatedRoute,
        private _dateUtilsService: DateUtilsService,
        private _store$: Store,
        private _actions$: Actions,
        private _objectsToQuillMentionsConverterService: ObjectsToQuillMentionsConverterService,
        private _quillMentionParseHelperService: QuillMentionParseHelperService,
        private _routerUtilsService: RouterUtilsService,
        private _clientProfileService: ClientProfileService,
        private _riskUtilsService: RiskUtilsService,
        private _colorUtilsService: ColorUtilsService,
        private _recertificationUtils: AssessmentRecertificationUtilsService,
        private _assessmentUtils: AssessmentUtilsService,
        private _cdr: ChangeDetectorRef,
        private _viewportScroller: ViewportScroller,
        private _featureFlagsService: FeatureFlagService,
        private _mediaWatcherService: MediaWatcherService,
    ) {}

    get request(): Relationship {
        return this.request$.value;
    }

    get addArtifactRouterLink(): any[] {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.request?.id}/add-artifact`,
                },
            },
        ];
    }

    get uploadAttachmentsRouterLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.request.id}/add-attachment`,
                },
            },
        ];
    }

    get doesRelationshipHaveContext(): boolean {
        return this.request?.contextTypes?.length > 0 && this.request?.dataTypes?.length > 0;
    }

    ngOnInit() {
        this.suppQReprocessingState$ = this._store$.select(getRelationshipSuppQReprocessingState);
        this.relationshipLongRunningTasks$ = this._store$.select(getRelationshipLongRunningTasks);
        this.hasSupplementalQuestionnairesEnabled$ = this._featureFlagsService.flagsLoaded.pipe(
            map((flagset) => flagset[FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES]),
        );

        this._store$
            .pipe(select(getUserAuthority(VisoUserRole.Auditor)), takeUntil(this._unsub$))
            .subscribe((isCurrentUserAuditor) => {
                this.isCurrentUserAuditor = isCurrentUserAuditor;
            });
        this._store$
            .pipe(select(getUserAuthority(VisoUserRole.Support)), takeUntil(this._unsub$))
            .subscribe((isCurrentUserSupport) => {
                this.isCurrentUserSupport = isCurrentUserSupport;
            });

        this._actions$
            .pipe(
                ofType(getRelationshipAssessmentSummaryConfigRequestSuccess),
                withLatestFrom(this._store$.pipe(select(getProfileAssessmentSummarySections))),
                takeUntil(this._unsub$),
            )
            .subscribe(([{ assessmentSummarySections }, clientProfileSummaryConfig]) => {
                let summaryConfig: OrgAssessmentSummarySections[];
                if (assessmentSummarySections?.length > 0) {
                    summaryConfig = assessmentSummarySections;
                } else {
                    summaryConfig = clientProfileSummaryConfig;
                }
                this.assessmentSummarySectionConfig$.next(summaryConfig);
            });

        this._route.data
            .pipe(withLatestFrom(this._route.paramMap, this._route.queryParamMap), takeUntil(this._unsub$))
            .subscribe(([routeData, paramMap, queryParamMap]) => {
                this._requestId = +paramMap.get('id');
                this.request$.next(routeData.relationship);

                this._mainTabsetDefaultTab = MainTabs.RELATIONSHIP;
                const assessmentTabSelected = queryParamMap.get('assessmentTabSelected');
                if (assessmentTabSelected) {
                    this._mainTabsetDefaultTab = MainTabs.ASSESSMENT;
                }

                if (this.request?.latestRiskAssessment?.predicted) {
                    this._mainTabsetDefaultTab = MainTabs.DETAILS;
                }

                this.loadAll();
                if (!!this._mainTabset) {
                    [
                        this.securityControlDomain$,
                        this.comments$,
                        this.mentions$,
                        this.fastMentions$,
                        this.attachments$,
                    ].forEach((subject) => subject.next(null));
                    this.tabContentTrigger$.next(this.tabContentTrigger$.value);
                }

                this.subscribeToRtpEvents();
                this.subscribeToLongRunningTaskEvents();
            });

        this._actions$
            .pipe(
                ofType(onRelationshipRtpEventSuccess),
                withLatestFrom(this.artifactRtpEvents$),
                map(([{ relationshipRtpEvent }, currentMap]) => {
                    const updatedMap = new Map(currentMap);
                    updatedMap.set(relationshipRtpEvent.artifactId, relationshipRtpEvent);

                    return updatedMap;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((updatedMap) => this.artifactRtpEvents$.next(updatedMap));

        this._artifacts$
            .pipe(
                map((artifacts) =>
                    artifacts?.map((artifact) => {
                        if (artifact?.type === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
                            const questionnaireArtifact: QuestionnaireArtifact = artifact;
                            questionnaireArtifact.artifactTypeName = 'Questionnaire';
                            questionnaireArtifact.fileName = `Questionnaire Artifact ${this._dateUtilsService.formatDate(
                                questionnaireArtifact.createdDate,
                                'yyyy-MM-dd hh:mm a',
                            )}`;
                            return questionnaireArtifact;
                        }
                        return artifact;
                    }),
                ),
                takeUntil(this._unsub$),
            )
            .subscribe((artifacts) => this._artifacts$.next(artifacts));

        this._actions$
            .pipe(
                ofType(onRelationshipRtpEventSuccess),
                map(({ relationshipRtpEvent }) => {
                    switch (relationshipRtpEvent.eventType) {
                        case RTPEventType.RTP_ARTIFACT_PAGES_COMPLETED:
                            this.processRTPPageDetectionsEvents(relationshipRtpEvent);
                            this.loadRelationshipSupplementalQuestionnaireReprocessingStates();
                            break;
                        case RTPEventType.RTP_SUPPQ_ALL_DETECTIONS_COMPLETED:
                            const suppQArtifactProcessedEvent =
                                relationshipRtpEvent as RTPSupplementalQuestionnaireArtifactDetectionsProcessed;
                            this.securityControlDomain$.next(suppQArtifactProcessedEvent.securityControlDomain);
                            break;
                        case RTPEventType.RTP_SUPPQ_ANSWERS_COMPLETED:
                            const suppQAllAnswersCompleted =
                                relationshipRtpEvent as RTPSupplementalQuestionnaireAnswersCompleted;
                            this._artifacts$.next(suppQAllAnswersCompleted.securityControlDomain.artifacts);
                            this.securityControlDomain$.next(suppQAllAnswersCompleted.securityControlDomain);
                            this.loadRelationshipSupplementalQuestionnaireReprocessingStates();
                            break;
                        default:
                            break;
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.currentAccount$ = this._store$.pipe(select(getUserAccount));
        this.userIsTrial$ = this._store$.pipe(select(getUserIsTrial));

        this._actions$
            .pipe(
                ofType(startAssessmentRequestSuccess, proceedWithAvailableDataRequest),
                switchMap(() => this.tabDefinitions$),
                tap(() => {
                    this.navigateToTab(MainTabs.ASSESSMENT);
                    this._viewportScroller.scrollToAnchor('mainTabset');
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getRequestTagsSuccess),
                tap(({ tags }) => this.relationshipTags$.next(tags.sort((a, b) => a.name.localeCompare(b.name)))),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.artifactSupersession$ = this._store$.pipe(select(getRelationshipArtifactSupersession));

        this._actions$
            .pipe(
                ofType(getRequestRiskAssessmentsRequestSuccess),
                map(({ riskAssessments }) => riskAssessments.slice().sort((a, b) => a.createdDate - b.createdDate)),
                tap((riskAssessments) => this.riskAssessments$.next(riskAssessments)),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.riskAssessmentsDatasets$ = this.riskAssessments$.pipe(
            map((riskAssessments) => {
                const colors = getChartColors(this._colorUtilsService, this._riskUtilsService);
                return {
                    riskAssessments,
                    defaultValues: [
                        {
                            label: 'Inherent Risk Score',
                            data: [],
                            ...colors[0],
                            fill: true,
                            tension: 0.3,
                            order: 2,
                        },
                        {
                            label: 'Residual Risk Score',
                            data: [],
                            ...colors[1],
                            fill: true,
                            tension: 0.3,
                            order: 1,
                        },
                    ],
                };
            }),
            map(({ riskAssessments, defaultValues }) =>
                riskAssessments.reduce(([inherentRiskSeries, residualRiskSeries], riskAssessment) => {
                    const date = this._dateUtilsService.convertDateTimeFromServer(riskAssessment.createdDate);
                    const inherentRiskItem = {
                        x: date,
                        y: Math.round(riskAssessment.inherentRiskScore * 10000) / 100,
                    };
                    const residualRiskItem = {
                        x: date,
                        y: Math.round(riskAssessment.score * 10000) / 100,
                    };

                    inherentRiskSeries.data.push(inherentRiskItem);
                    residualRiskSeries.data.push(residualRiskItem);

                    return [inherentRiskSeries, residualRiskSeries];
                }, defaultValues),
            ),
        );

        this._actions$
            .pipe(
                ofType(getRelationshipSubscribersRequestSuccess),
                tap(({ subscribers }) => this.relationshipSubscribers$.next(subscribers)),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._store$
            .pipe(
                select(getRelationshipPrimaryVendorContact),
                tap((primaryVendorContact) => this.primaryVendorContact$.next(primaryVendorContact)),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.latestAssessment$ = this._store$.pipe(select(getRelationshipPopulatedLatestAssessment));

        this.pastAssessments$ = this._store$.pipe(select(getRelationshipPopulatedPastAssessments));

        this.latestCompletedAssessmentDate$ = this._store$.pipe(select(getRelationshipLatestCompletedAssessmentDate));

        this.auditReports$ = this._auditReportService.query().pipe(
            map(({ body }) => body),
            map((auditReports) =>
                auditReports.map((auditReport) => ({
                    ...auditReport,
                    auditReportFriendlyName: this._auditReportService.getAuditTypeFriendlyName(auditReport.auditType),
                })),
            ),
        );

        this.supplementalQuestionnaireConfiguration$ = this._store$
            .select(getSupplementalQuestionnaireConfig)
            .pipe(map((config) => config.questionnaires));

        const orgControls$ = new BehaviorSubject<ControlDomain[]>([]);

        this._store$
            .pipe(
                select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support])),
                mergeMap((isAuditorOrSupport) =>
                    isAuditorOrSupport
                        ? this._actions$.pipe(
                              ofType(getControlsByOrgIdRequestSuccess),
                              map(({ controls }) => controls),
                          )
                        : this._store$.pipe(select(getOrgControls)),
                ),
                takeUntil(this._unsub$),
            )
            .subscribe((controls) => orgControls$.next(controls));

        const fullControls$ = this._store$.pipe(select(getControls));

        this.controls$ = combineLatest([
            orgControls$,
            fullControls$,
            this.latestRiskAssessment$.pipe(
                filter((value) => !!value),
                map((latestRiskAssessment) => latestRiskAssessment.controlDomainAssessments),
            ),
            this.securityControlDomain$,
        ]).pipe(
            map(([orgControls, fullControls, controlDomainAssessments, securityControlDomain]) =>
                fullControls.map((fullControl) => ({
                    ...fullControl,
                    relevant: !!controlDomainAssessments.find(
                        (controlDomainAssessment) => fullControl.id === controlDomainAssessment.controlDomainId,
                    )?.relevant,
                    disabled: !orgControls.find((orgControl) => fullControl.id === orgControl.id),
                    coverage: controlDomainAssessments.find(
                        (controlAssessment) => fullControl.id === controlAssessment.controlDomainId,
                    )?.coverage,
                    hasDetections: securityControlDomain?.artifacts?.some(
                        (a: ControlDomainFileArtifact | ControlDomainQuestionnaireArtifact) =>
                            a.validation?.detectedControls?.some(
                                (cv) => cv.controlDomainId === fullControl.id && !!cv.validationDetections.length,
                            ),
                    ),
                })),
            ),
        );

        this.groupedControls$ = combineLatest([this.controls$, this.supplementalQuestionnaireConfiguration$]).pipe(
            map(([controlDomains, combinedSupplementalQuestionnaireConfig]) => {
                const groups: GroupedControlDomains[] = [
                    {
                        controlDomainType: ControlDomainType.SECURITY,
                        controlDomainTypeLabel: null,
                        controlDomains: [],
                        featureFlagEnabled$: of(true),
                        tabDomId: RiskAnalysisTabs.SECURITY,
                        tooltipDomId: `${RiskAnalysisTabs.SECURITY}-tooltip`,
                        hideNoControlsInScopeMessage: true,
                        isStatusRiskBased: true,
                    },
                    {
                        controlDomainType: ControlDomainType.PRIVACY,
                        controlDomainTypeLabel: null,
                        controlDomains: [],
                        featureFlagEnabled$: this.hasPrivacyModuleEnabled$,
                        tabDomId: RiskAnalysisTabs.PRIVACY,
                        tooltipDomId: `${RiskAnalysisTabs.PRIVACY}-tooltip`,
                        isStatusCompliantBased: true,
                    },
                    {
                        controlDomainType: ControlDomainType.RESILIENCE,
                        controlDomainTypeLabel: null,
                        controlDomains: [],
                        featureFlagEnabled$: of(true),
                        tabDomId: RiskAnalysisTabs.RESILIENCE,
                        tooltipDomId: `${RiskAnalysisTabs.RESILIENCE}-tooltip`,
                        isStatusCompliantBased: true,
                    },
                    {
                        controlDomainType: ControlDomainType.ARTIFICIAL_INTELLIGENCE,
                        controlDomainTypeLabel: null,
                        controlDomains: [],
                        featureFlagEnabled$: of(true),
                        tabDomId: RiskAnalysisTabs.ARTIFICIAL_INTELLIGENCE,
                        tooltipDomId: `${RiskAnalysisTabs.ARTIFICIAL_INTELLIGENCE}-tooltip`,
                        isStatusCompliantBased: true,
                    },
                    {
                        controlDomainType: ControlDomainType.PRODUCT_SECURITY,
                        controlDomainTypeLabel: '',
                        controlDomains: [],
                        featureFlagEnabled$: of(true),
                        tabDomId: RiskAnalysisTabs.PRODUCT_SECURITY,
                        tooltipDomId: `${RiskAnalysisTabs.PRODUCT_SECURITY}-tooltip`,
                        isStatusCompliantBased: true,
                    },
                    {
                        controlDomainType: ControlDomainType.CYBER_INSURANCE,
                        controlDomainTypeLabel: null,
                        controlDomains: [],
                        featureFlagEnabled$: of(true),
                        tabDomId: RiskAnalysisTabs.CYBER_INSURANCE,
                        tooltipDomId: `${RiskAnalysisTabs.CYBER_INSURANCE}-tooltip`,
                        isStatusInformationBased: true,
                    },
                    {
                        controlDomainType: ControlDomainType.SERVICE_LOCATIONS,
                        controlDomainTypeLabel: null,
                        controlDomains: [],
                        featureFlagEnabled$: of(true),
                        tabDomId: RiskAnalysisTabs.SERVICE_LOCATIONS,
                        tooltipDomId: `${RiskAnalysisTabs.SERVICE_LOCATIONS}-tooltip`,
                        isStatusInformationBased: true,
                    },
                ]
                    .map((group) => ({
                        ...group,
                        controlDomainTypeLabel: ControlDomainTypeLabels[group.controlDomainType],
                        controlDomains: controlDomains.filter(
                            (controlDomain) => controlDomain.controlDomainType === group.controlDomainType,
                        ),
                    }))
                    .map((group) => ({
                        ...group,
                        allControlsOutOfScope: group.controlDomains.every((control) => !control.relevant),
                        allControlsDisabled: group.controlDomains.every((control) => control.disabled),
                    }));

                combinedSupplementalQuestionnaireConfig
                    ?.filter(
                        (suppQ) =>
                            (suppQ.enabled && suppQ.activeStatus === ActiveStatus.ACTIVE) ||
                            (suppQ.activeStatus === ActiveStatus.DELETED &&
                                !!controlDomains.filter(
                                    (controlDomain) =>
                                        suppQ.questions.map((q) => q.id).includes(controlDomain.id) &&
                                        controlDomain.hasDetections,
                                )?.length),
                    )
                    .forEach((suppQ) => {
                        groups.push({
                            allControlsDisabled: false,
                            allControlsOutOfScope: false,
                            controlDomainType: ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE,
                            controlDomainTypeLabel: suppQ.questionnaireName,
                            controlDomains: suppQ.questions.map((question) =>
                                // Map from suppQ and not from control domains so we preserve the suppQ ordering.
                                controlDomains.find((cd) => cd.id === question.id),
                            ),
                            featureFlagEnabled$: this.hasSupplementalQuestionnairesEnabled$,
                            tabDomId: suppQ.questionnaireName,
                            tooltipDomId: `${suppQ.questionnaireName}-tooltip`,
                            hideNoControlsInScopeMessage: true,
                            supplementalQuestionnaireId: suppQ.id,
                        });
                    });
                return groups;
            }),
        );

        this._getOrgIdRequestTrigger$
            .pipe(
                filter((value) => !!value),
                withLatestFrom(
                    this._store$.pipe(select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support]))),
                ),
                filter(([orgId, isAuditorOrSupport]) => isAuditorOrSupport),
                map(([orgId, isAuditorOrSupport]) => orgId),
                take(1),
            )
            .subscribe((orgId) => this._store$.dispatch(getControlsByOrgIdRequest({ orgId })));

        this.latestAssessmentFollowUpQuestionnaireCreatedDate$ = this.latestAssessment$.pipe(
            filter(
                (latestAssessment) =>
                    [AssessmentStatus.REVIEW_STARTED, AssessmentStatus.COLLECTING_INFORMATION].includes(
                        latestAssessment?.status,
                    ) && latestAssessment.hasFollowupQuestionnaire,
            ),
            map((latestAssessment) => {
                const latestFollowupQuestionnaireArtifact = latestAssessment.artifacts?.find((artifact) => {
                    if (artifact.type === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
                        let questionnaireArtifact: QuestionnaireArtifact = artifact;
                        return questionnaireArtifact.followup;
                    }
                });

                return latestFollowupQuestionnaireArtifact?.createdDate;
            }),
        );

        this.missingInformationWasRequested$ = this.latestAssessment$.pipe(
            map(
                (latestAssessment) =>
                    latestAssessment?.status === AssessmentStatus.COLLECTING_INFORMATION &&
                    latestAssessment.hasFollowupQuestionnaire,
            ),
        );

        this.isLatestAssessmentNonDocumentsOnly$ = this.latestAssessment$.pipe(
            map((latestAssessment) => !latestAssessment?.documentsOnly),
        );

        this.hasPrivacyModuleEnabled$ = this._featureFlagsService.hasFeatureFlagEnabled(FeatureFlags.PRIVACY_MODULE);
        this.hasRDPAIQAEnabled$ = this._featureFlagsService.hasFeatureFlagEnabled(FeatureFlags.RDP_AI_QA);
        this.hasOrgUserOnboardEnabled$ = this._featureFlagsService.hasFeatureFlagEnabled(
            FeatureFlags.ORG_USER_CAN_ONBOARD,
        );

        this.iiqrResponse$ = this.request$.pipe(
            switchMap((relationship) => this._store$.select(getLastIiqrResponseByRelationshipId(relationship.id))),
        );

        this.hasAttachments$ = this.attachments$.pipe(map((attachments) => !!attachments?.length));

        this._actions$
            .pipe(
                ofType(getRequestArtifactsRequestSuccess),
                map(({ artifacts }) => artifacts),
                takeUntil(this._unsub$),
            )
            .subscribe((artifacts) => this._artifacts$.next(artifacts));

        this.hasArtifactsWithValidations$ = this._artifacts$.pipe(
            map((artifacts) => artifacts?.some((artifact) => !!artifact.validation?.detectedControls?.length)),
        );

        this.senderClientProfile$ = combineLatest([
            this._getOrgIdRequestTrigger$.pipe(filter((orgId) => !!orgId)),
            this._store$.pipe(select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support]))),
        ]).pipe(
            mergeMap(([orgId, isAuditorOrSupport]) =>
                isAuditorOrSupport ? this._clientProfileService.getForOrg(orgId) : of(null),
            ),
            shareReplay(1),
        );

        combineLatest([this.senderClientProfile$, this.dataTypes$, this._store$.pipe(select(getProfileDataTypes))])
            .pipe(
                map(([profile, dataTypes, profileDataTypes]) => {
                    if (profile) {
                        return profile.dataTypes.filter((dt) => dataTypes.find((d) => d.id === dt.dataType?.id));
                    } else {
                        return profileDataTypes?.filter((dt) => dataTypes.find((d) => d.id === dt.dataType?.id));
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((dataTypes) =>
                this.dataTypesProfileConfig$.next(
                    dataTypes.sort((a, b) => this._riskUtilsService.sortBySensitivityThenName(a.dataType, b.dataType)),
                ),
            );

        const mentionableUsers$ = this._actions$.pipe(
            ofType(getRequestMentionableUsersRequestSuccess),
            map(({ mentionableUsers }) => mentionableUsers),
            shareReplay(1),
        );

        combineLatest([
            mentionableUsers$,
            this._actions$.pipe(
                ofType(getRequestCommentsRequestSuccess),
                map(({ comments }) =>
                    comments.sort(
                        (a, b) =>
                            this._dateUtilsService.convertDateTimeFromServer(b.createdDate).getTime() -
                            this._dateUtilsService.convertDateTimeFromServer(a.createdDate).getTime(),
                    ),
                ),
            ),
        ])
            .pipe(
                map(([users, comments]) =>
                    comments.map((comment) => ({
                        ...comment,
                        safeHtml: this._quillMentionParseHelperService.convertVisoMentions(comment.text, users),
                    })),
                ),
                takeUntil(this._unsub$),
            )
            .subscribe((comments) => this.comments$.next(comments));

        combineLatest([
            this._actions$.pipe(
                ofType(getRequestMentionableUsersRequestSuccess),
                map(({ mentionableUsers }) => mentionableUsers),
                map((mentionableUsers) => [
                    ...this._objectsToQuillMentionsConverterService.convertUserGroups(),
                    ...this._objectsToQuillMentionsConverterService.convertUsers(mentionableUsers),
                ]),
            ),
            this.primaryVendorContact$,
        ])
            .pipe(
                map(([mentionableUsers, primaryContact]) => {
                    let mentions = mentionableUsers;
                    let fastMentions =
                        this._objectsToQuillMentionsConverterService.getDefaultFastMentions() as FastMention[];
                    if (!primaryContact) {
                        mentions = mentions.filter(
                            (mention) => mention.id !== `G${VisoUserGroups.THIRD_PARTY_PRIMARY}`,
                        );
                        fastMentions = fastMentions.filter(
                            (fastMention) => fastMention.mention.id !== `G${VisoUserGroups.THIRD_PARTY_PRIMARY}`,
                        );
                    }
                    return { mentions, fastMentions };
                }),
                takeUntil(this._unsub$),
            )
            .subscribe(({ mentions, fastMentions }) => {
                this.mentions$.next(mentions as QuillMentionListItem[]);
                this.fastMentions$.next(fastMentions);
            });

        this._actions$
            .pipe(
                ofType(getRelationshipBusinessOwnerRequestSuccess),
                map(({ businessOwner }) => businessOwner),
                takeUntil(this._unsub$),
            )
            .subscribe((businessOwner) => this.businessOwner$.next(businessOwner));

        this._actions$
            .pipe(
                ofType(getRequestAttachmentRequestSuccess),
                map(({ attachments }) => attachments),
                takeUntil(this._unsub$),
            )
            .subscribe((attachments) => this.attachments$.next(attachments));

        this._actions$
            .pipe(
                ofType(getRelationshipSecurityControlDomainRequestSuccess),
                map(({ securityControlDomain }) => securityControlDomain),
                takeUntil(this._unsub$),
            )
            .subscribe((securityControlDomain) => this.securityControlDomain$.next(securityControlDomain));

        this._actions$
            .pipe(
                ofType(getRelationshipRequestSuccess),
                tap(({ relationship }) => {
                    if (relationship.startDate) {
                        relationship.startDate = this._dateUtilsService.formatDate(
                            relationship.startDate,
                            'yyyy-MM-dd',
                        );
                    }

                    this.request$.next(relationship);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.request$
            .pipe(
                tap((request) => {
                    this.dataTypes$.next(request?.dataTypes || []);
                    this.latestRiskAssessment$.next(request?.latestRiskAssessment);
                    this.latestNonTransitionalRiskAssessment$.next(request?.latestNonTransitionalRiskAssessment);
                    this.businessOwner$.next(request?.businessOwner);
                    this.latestAssessmentStatus$.next(request?.latestAssessmentStatus);
                    this._getOrgIdRequestTrigger$.next(request?.clientId);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.request$
            .pipe(
                tap((request) => {
                    const { recertificationDate, status, latestAssessmentStatus } = request ?? {};
                    this.isUpForRecertification$.next(
                        this._recertificationUtils.isRelationshipUpForRecertification(recertificationDate, status),
                    );

                    this.isRecertificationOverdue$.next(
                        this._recertificationUtils.isRelationshipAssessmentExpired(recertificationDate),
                    );

                    this.isAssessmentInProgress$.next(
                        this._assessmentUtils.isAssessmentInProgress(latestAssessmentStatus),
                    );

                    this.recertificationDueDaysLeftCount$.next(
                        Math.ceil(
                            this._dateUtilsService.numDaysBetween(
                                this._dateUtilsService.convertDateTimeFromServer(request?.recertificationDate) ||
                                    new Date(),
                                new Date(),
                            ) || 0,
                        ),
                    );
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.isOnlyLegacyAssessmentPresent$ = combineLatest([this.riskAssessments$, this.latestAssessment$]).pipe(
            map(
                ([riskAssessments, latestAssessment]) =>
                    !!riskAssessments?.length && riskAssessments.every((ra) => ra.legacy) && !latestAssessment,
            ),
        );

        this.tabContentTrigger$
            .pipe(
                map((tabId) => {
                    switch (tabId) {
                        case MainTabs.RISK_ANALYSIS:
                            return () => {
                                if (!this.securityControlDomain$.value) {
                                    this.loadSecurityControlDomain();
                                }
                            };
                        case MainTabs.COMMENTS:
                            if (!this.comments$.value || !this.mentions$.value || !this.fastMentions$.value) {
                                return () => {
                                    this.loadComments();
                                    this.loadMentionableUsers();
                                };
                            }
                            return;
                        case MainTabs.ATTACHMENTS:
                            return () => {
                                if (!this.attachments$.value) {
                                    this.loadAttachments();
                                }
                            };
                        default:
                            return;
                    }
                }),
                filter((fn) => !!fn),
                takeUntil(this._unsub$),
            )
            .subscribe((fn) => fn());

        this.setPreviousBreadcrumb(this._routerUtilsService.getPreviousRoute());

        combineLatest([this._artifacts$, this.artifactSupersession$])
            .pipe(
                map(([artifacts, artifactSupersession]) =>
                    artifacts
                        .filter(
                            (a) =>
                                !Array.from(artifactSupersession.values()).find((id) => id === a.id) &&
                                !!a.validation?.expirationDate,
                        )
                        .sort((a, b) => a.validation.expirationDate.getTime() - b.validation.expirationDate.getTime()),
                ),
                map((artifactsSortedByExpiration) =>
                    artifactsSortedByExpiration.length
                        ? artifactsSortedByExpiration[0].validation.expirationDate
                        : null,
                ),
                takeUntil(this._unsub$),
            )
            .subscribe((dateOfNextArtifactExpiration) =>
                this.dateOfNextArtifactExpiration$.next(dateOfNextArtifactExpiration),
            );

        this.disabledControlDomainTypes$ = this.groupedControls$.pipe(
            switchMap((groups) =>
                combineLatest(
                    groups.map((group) =>
                        group.featureFlagEnabled$.pipe(
                            map((featureFlagEnabled) => ({
                                ...group,
                                isDisabled: !featureFlagEnabled || group.allControlsDisabled,
                            })),
                        ),
                    ),
                ),
            ),
            map((groups) => groups.filter((group) => group.isDisabled).map((group) => group.controlDomainType)),
        );

        this.inScopeControlDomainTypes$ = this.groupedControls$.pipe(
            map((groups) =>
                groups.filter((group) => !group.allControlsOutOfScope).map((group) => group.controlDomainType),
            ),
        );

        this.allVendorTiers$ = this._store$.select(getVendorTiers);
        this.currentVendorTier$ = this._store$.select(getRelationshipVendorTier);

        this._mediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsub$)).subscribe(({ matchingAliases }) => {
            // Set the drawerMode if the given breakpoint is active
            if (matchingAliases.includes('xxl')) {
                this.drawerMode = 'side';
            } else {
                this.drawerMode = 'over';
            }

            this._cdr.markForCheck();
        });

        this.tabDefinitions$ = this.latestRiskAssessment$.pipe(
            map((assessment) => assessment?.predicted ?? false),
            switchMap((isPredicted) => this.createTabDefinitions(isPredicted)),
        );

        this.filteredArtifacts$ = this._artifacts$.pipe(
            withLatestFrom(this._store$.select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support]))),
            map(([artifacts, isAuditorOrSupport]) =>
                isAuditorOrSupport
                    ? artifacts
                    : artifacts.filter(
                          (a) =>
                              a.type !== ArtifactType.ATTESTATION_ARTIFACT ||
                              a.validation?.auditReportType === AuditReportTypeCode.SUBPROCESSORLIST,
                      ),
            ),
        );
    }

    ngAfterViewInit(): void {
        this.navigateToTab(this._mainTabsetDefaultTab);

        this._mainTabset.selectedTabChange
            .pipe(
                startWith(this._mainTabset._tabs.get(this._mainTabset.selectedIndex)),
                map((tabEventOrTab) =>
                    tabEventOrTab instanceof MatTabChangeEvent ? tabEventOrTab.tab : tabEventOrTab,
                ),
                map((tab) => this.getMatTabId(tab)),
                takeUntil(this._unsub$),
            )
            .subscribe((tabId) => this.tabContentTrigger$.next(tabId));

        this._mainTabset.selectedTabChange
            .pipe(
                filter((tabEvent) =>
                    [
                        MainTabs.RISK_ANALYSIS,
                        MainTabs.ASSESSMENT,
                        MainTabs.ATTACHMENTS,
                        MainTabs.RISK_TIMELINE,
                    ].includes(this.getMatTabId(tabEvent.tab)),
                ),
                delay(10),
                takeUntil(this._unsub$),
            )
            .subscribe(() => window.dispatchEvent(new Event('resize')));

        this._cdr.detectChanges();

        combineLatest([this.latestRiskAssessment$, this._store$.select(getUserProfile)])
            .pipe(
                first(),
                filter(([assessment, user]) => assessment?.predicted && user.showInstantAnalysisRDPWalkthrough),
            )
            .subscribe(([_, user]) => {
                this._store$.dispatch(startSpotlightTour({ tourType: SpotlightTourType.INSTANT_ANALYSIS_RDP }));
                this._store$.dispatch(
                    updateUserProfileRequest({
                        userProfile: { ...user, showInstantAnalysisRDPWalkthrough: false },
                    }),
                );
            });
    }

    processRTPPageDetectionsEvents(relationshipRtpEvent: RTPEvent) {
        const artifactPageDetectionsEvent = relationshipRtpEvent as RTPPageDetectionsClassified;
        let latestRiskCopy = Object.assign({}, this.latestRiskAssessment$.value);
        latestRiskCopy.risk = artifactPageDetectionsEvent.residualRisk;
        this.latestRiskAssessment$.next(latestRiskCopy);

        this.request.latestRiskAssessment.risk = artifactPageDetectionsEvent.residualRisk;
        if (this.request.latestNonTransitionalRiskAssessment?.id === this.request.latestRiskAssessment?.id) {
            this.request.latestNonTransitionalRiskAssessment.risk = artifactPageDetectionsEvent.residualRisk;
            let latestNonTransitionalRiskCopy = Object.assign({}, this.latestNonTransitionalRiskAssessment$.value);
            latestNonTransitionalRiskCopy.risk = artifactPageDetectionsEvent.residualRisk;
            this.latestNonTransitionalRiskAssessment$.next(latestNonTransitionalRiskCopy);
        }

        this.securityControlDomain$.next(artifactPageDetectionsEvent.securityControlDomain);
    }

    subscribeToRtpEvents() {
        this._store$.dispatch(onRelationshipRtpEvent({ relationshipId: this._requestId }));
    }

    subscribeToLongRunningTaskEvents() {
        this._store$.dispatch(onRelationshipLongRunningTaskEvent({ relationshipId: this._requestId }));
    }

    downloadArtifacts() {
        const artifactIds = this._artifacts$.value.map((artifact) => artifact.id);
        this._store$.dispatch(downloadRelationshipArtifactsAsZip({ requestId: this.request?.id, artifactIds }));
    }

    downloadSelectedAttachments() {
        let attachmentIds = Array.from(this.selectedAttachments)
            .filter(([_, value]) => value)
            .map(([key]) => key.id);

        this._store$.dispatch(downloadRelationshipAttachmentsAsZip({ requestId: this.request?.id, attachmentIds }));
    }

    loadAll(): void {
        this.loadAssessments();
        this.loadArtifacts();
        this.loadRiskAssessments();
        this.loadRelationshipSubscribers();
        this.loadLatestAssessment();
        this.loadTags();
        this.loadArtifactSupersession();
        this.loadBusinessUnits();
        this.loadClientContacts();
        this.loadClientOrgDomains();
        this.loadRelationshipSupplementalQuestionnaireConfig();
        this.loadClientSupplementalQuestionnaireConfig();
        this.loadRelationshipSupplementalQuestionnaireReprocessingStates();

        if (!this.isCurrentUserSupport && !this.isCurrentUserAuditor) {
            this.loadAssessmentSummaryConfig();
        } else {
            this._store$
                .pipe(select(getProfileAssessmentSummarySections), takeUntil(this._unsub$))
                .subscribe((orgAssessmentSummaryConfig) => {
                    this.assessmentSummarySectionConfig$.next(orgAssessmentSummaryConfig);
                });
        }

        this._store$.dispatch(
            LongRunningTaskActions.getAllTasksForRecordIdRequest({ recordId: this._requestId.toString() }),
        );
    }

    getRiskTolerance(clientProfile: ClientProfile) {
        const riskToleranceLabel = RiskToleranceLabels[clientProfile?.riskTolerance || RiskTolerance.MINIMAL];
        return riskToleranceLabel.toLowerCase();
    }

    archiveRequest(): void {
        const requestId = this.request.id;
        this._store$.dispatch(markRequestAsArchived({ requestId }));
    }

    markAsNotOnboarded(): void {
        const requestId = this.request.id;
        this._store$.dispatch(offboardRelationshipRequest({ relationshipId: requestId }));
    }

    openOnboardModal(): void {
        const risk = this.request.latestRiskAssessment.legacy
            ? this.request.latestRiskAssessment.riskScores.SECURITY.residualRiskLevel
            : this.request.latestRiskAssessment.risk;
        this._store$.dispatch(
            openOnboardModal({
                relationship: this.request,
                vendorName: this.request.vendorName,
                risk,
                riskIsTransitional: this.request.latestRiskAssessment.transitional,
                dateOfNextArtifactExpiration: this.dateOfNextArtifactExpiration$.value,
            }),
        );
    }

    loadRelationshipSubscribers(): void {
        this._store$.dispatch(getRelationshipSubscribersRequest({ requestId: this._requestId }));
    }

    loadRiskAssessments(): void {
        this._store$.dispatch(getRequestRiskAssessmentsRequest({ requestId: this._requestId }));
    }

    loadAssessments(): void {
        this._store$.dispatch(getRequestAssessmentsRequest({ requestId: this._requestId }));
    }

    loadLatestAssessment(): void {
        this._store$.dispatch(getRequestLatestAssessmentRequest({ requestId: this._requestId }));
    }

    loadComments(): void {
        this._store$.dispatch(getRequestCommentsRequest({ requestId: this._requestId }));
    }

    loadMentionableUsers(): void {
        this._store$.dispatch(getRequestMentionableUsersRequest({ requestId: this._requestId }));
    }

    loadArtifacts(): void {
        this._store$.dispatch(getRequestArtifactsRequest({ requestId: this._requestId }));
    }

    loadAttachments(): void {
        this._store$.dispatch(getRequestAttachmentRequest({ requestId: this._requestId }));
    }

    loadSecurityControlDomain(): void {
        this._store$.dispatch(getRelationshipSecurityControlDomainRequest({ requestId: this._requestId }));
    }

    loadTags(): void {
        this._store$.dispatch(getRequestTags({ requestId: this._requestId }));
    }

    loadArtifactSupersession(): void {
        this._store$.dispatch(getRelationshipArtifactSupersessionRequest({ relationshipId: this._requestId }));
    }

    loadAssessmentSummaryConfig() {
        this._store$.dispatch(getRelationshipAssessmentSummaryConfigRequest({ relationshipId: this._requestId }));
    }

    loadRelationshipSupplementalQuestionnaireConfig() {
        this._store$.dispatch(
            getRelationshipSupplementalQuestionnaireConfigRequest({ relationshipId: this._requestId }),
        );
    }

    loadClientSupplementalQuestionnaireConfig() {
        this._store$.dispatch(getOrgSupplementalQuestionnaireConfigRequest({ clientId: this.request.clientId }));
    }

    loadRelationshipSupplementalQuestionnaireReprocessingStates() {
        this._store$.dispatch(
            getRelationshipSupplementalQuestionnaireReprocessingStateRequest({ relationshipId: this.request.id }),
        );
    }

    loadBusinessUnits(): void {
        if (this.isCurrentUserAuditor || this.isCurrentUserSupport) {
            this._store$.dispatch(getBusinessUnitsRequest({ req: null, clientId: this.request.clientId }));
        } else {
            this._store$.dispatch(getBusinessUnitsRequest(null));
        }
    }

    loadClientContacts(): void {
        if (this.isCurrentUserAuditor || this.isCurrentUserSupport) {
            this._store$.dispatch(getContactsRequest({ req: null, clientId: this.request.clientId }));
        } else {
            this._store$.dispatch(getContactsRequest(null));
        }
    }

    loadClientOrgDomains(): void {
        this._store$.dispatch(getOrgDomainsRequest({ orgId: this.request.clientId }));
    }

    createComment(commentMessage: string): void {
        const requestId = this.request.id;
        this._store$.dispatch(createRequestCommentRequest({ requestId, commentMessage }));
    }

    deleteComment(commentId: number): void {
        const requestId = this.request.id;
        this._store$.dispatch(deleteRequestCommentRequest({ requestId, commentId }));
    }

    startAssessment(): void {
        const [request, primaryContact] = [this.request, this.primaryVendorContact$.value];
        this._store$.dispatch(openStartAssessmentModal({ request, primaryContact, recertification: false }));
    }

    cancelAssessment(): void {
        const requestId = this._requestId;
        this._store$.dispatch(cancelRequestLatestAssessment({ requestId }));
    }

    confirmRecertifyAssessment(): void {
        const [request, primaryContact] = [this.request, this.primaryVendorContact$.value];
        this._store$.dispatch(openStartAssessmentModal({ request, primaryContact, recertification: true }));
    }

    toggleTimelineView(): void {
        this.isRiskTimelineListView = !this.isRiskTimelineListView;
    }

    unsubscribeSubscriber(contactId: number): void {
        const requestId = this.request.id;
        this._store$.dispatch(unsubscribeContactRequest({ contactId, requestId }));
    }

    exportRelationshipAsPDF(): void {
        let obs = of(null);
        const lazyLoadedItems: Observable<any>[] = [];

        if (!this.comments$.value) {
            lazyLoadedItems.push(
                of(true).pipe(
                    tap(() => {
                        this.loadComments();
                        this.loadMentionableUsers();
                    }),
                    mergeMap(() =>
                        this.comments$.pipe(
                            filter((value) => !!value),
                            take(1),
                        ),
                    ),
                ),
            );
        }

        if (!this.securityControlDomain$.value) {
            lazyLoadedItems.push(
                of(true).pipe(
                    tap(() => this.loadSecurityControlDomain()),
                    mergeMap(() =>
                        this.securityControlDomain$.pipe(
                            filter((value) => !!value),
                            take(1),
                        ),
                    ),
                ),
            );
        }

        if (!this.attachments$.value) {
            lazyLoadedItems.push(
                of(true).pipe(
                    tap(() => this.loadAttachments()),
                    mergeMap(() =>
                        this.attachments$.pipe(
                            filter((value) => !!value),
                            take(1),
                        ),
                    ),
                ),
            );
        }

        obs.pipe(
            tap(() => {
                this.showPrintArea$.next(true);
            }),
            mergeMap(() => (!!lazyLoadedItems.length && combineLatest(lazyLoadedItems)) || of(null)),
            delay(500),
            tap(() => window.print()),
            take(1),
        ).subscribe();
    }

    deleteArtifact(artifact: Artifact): void {
        const [requestId, artifactId] = [this.request?.id, artifact.id];
        this._store$.dispatch(deleteRequestArtifactRequest({ requestId, artifactId }));
    }

    askQuestion(question: string): void {
        this._store$.dispatch(askQuestionRequest({ relationshipId: this.request.id, question }));
    }

    goToArtifacts(): void {
        this.navigateToTab(MainTabs.ARTIFACTS);
    }

    ngOnDestroy() {
        this._store$.dispatch(onRelationshipRtpEventCancelled());
        this._unsub$.next();
    }

    private getMatTabId(matTab: MatTab): MainTabs {
        return matTab._implicitContent.elementRef.nativeElement.parentNode.id;
    }

    private getMainTabsetTabIndex(tabId: MainTabs): number {
        return this._mainTabset._tabs.toArray().findIndex((tab) => this.getMatTabId(tab) === tabId);
    }

    private navigateToTab(tabId: MainTabs): void {
        this._mainTabset.selectedIndex = this.getMainTabsetTabIndex(tabId);
    }

    private setPreviousBreadcrumb(previousRoute: RouterUtilsServiceRoute): void {
        this.previousNavigatedUrl = previousRoute?.url || '';
        this.previousNavigatedUrlQueryParams = previousRoute?.queryParams;
        this.isPreviousViewFiltered =
            previousRoute?.hasQueryParams &&
            !(
                previousRoute?.url.includes('/relationships') &&
                previousRoute?.queryParams?.search === defaultRLPSearchParam &&
                !previousRoute?.queryParams?.sort
            );
    }

    linkTagToRelationship(tag: Tag) {
        this._store$.dispatch(linkTagRequest({ tagId: tag.id, requestId: this._requestId }));
    }

    unlinkTagToRelationship(tag: Tag) {
        this._store$.dispatch(unlinkTagRequest({ tagId: tag.id, requestId: this._requestId }));
    }

    openArtifact(payload: { artifactId: number; artifactName: string }): void {
        this._store$.dispatch(openArtifactFile(payload));
    }

    onAttachmentSelected(selectedAttachments: Map<Attachment, boolean>) {
        this.selectedAttachments = selectedAttachments;

        var values = Array.from(this.selectedAttachments).map(([_, value]) => value);

        if (values.every((val) => !val)) {
            this.anyAttachmentSelected = false;
        } else {
            this.anyAttachmentSelected = true;
        }
    }

    deleteAttachment(attachment: Attachment): void {
        const [requestId, attachmentId] = [this.request?.id, attachment.id];
        this._store$.dispatch(deleteRequestAttachmentRequest({ requestId, attachmentId }));
    }

    downloadAttachment(attachment: Attachment): void {
        const [requestId, attachmentId] = [this.request?.id, attachment.id];
        this._store$.dispatch(downloadAttachment({ requestId, attachmentId }));
    }

    trackByControlGroup(index: number, groupedControlDomains: GroupedControlDomains) {
        return groupedControlDomains.controlDomainType;
    }

    openCreateRiskAdvisory() {
        const { vendorId, vendorName } = this.request;
        this._store$.dispatch(
            openCreateRiskAdvisoryDialog({
                orgId: vendorId,
                name: vendorName,
            }),
        );
    }

    openReviewRiskDialog(): void {
        this._store$.dispatch(openReviewRiskDialog({ riskReviewNote: null }));
    }

    openReviewFollowupDialog(): void {
        this._store$.dispatch(openReviewFollowupDialog());
    }

    openEditFollowupDialog(): void {
        this._store$.dispatch(openEditFollowupMethodDialog());
    }

    private createTabDefinitions(isPredicted: boolean): Observable<TabDefinition[]> {
        return this.hasRDPAIQAEnabled$.pipe(
            map((hasRDPAIQAEnabled) => {
                const tabs = this.getBaseTabDefinitions(isPredicted);

                if (isPredicted) {
                    const detailsTabIndex = tabs.findIndex((tab) => tab.id === MainTabs.DETAILS);
                    if (detailsTabIndex !== -1) {
                        const [detailsTab] = tabs.splice(detailsTabIndex, 1);
                        tabs.unshift(detailsTab);
                    }
                }

                const aiQaInsertPosition = isPredicted ? this.AI_QA_TAB_INDEX + 1 : this.AI_QA_TAB_INDEX;
                tabs.splice(aiQaInsertPosition, 0, {
                    id: MainTabs.AI_QA,
                    label: 'AI Q&A',
                    disabled: !hasRDPAIQAEnabled || isPredicted,
                    disabledToolTip:
                        !hasRDPAIQAEnabled && !isPredicted ? 'Contact support@visotrust.com to enable AI Q&A.' : null,
                });

                return tabs;
            }),
        );
    }

    private getBaseTabDefinitions(isPredicted = false): TabDefinition[] {
        return [
            { id: MainTabs.RELATIONSHIP, label: 'Relationship', disabled: false, bodyClass: '' },
            { id: MainTabs.ASSESSMENT, label: 'Assessments', disabled: isPredicted, bodyClass: '' },
            { id: MainTabs.RISK_ANALYSIS, label: 'Risk Analysis', disabled: isPredicted, bodyClass: '' },
            {
                id: MainTabs.ARTIFACTS,
                label: 'Artifacts',
                disabled: isPredicted,
                bodyClass: '',
                labelClass: 'artifacts-tab-header',
            },
            { id: MainTabs.RISK_TIMELINE, label: 'Risk Timeline', disabled: isPredicted, bodyClass: '' },
            { id: MainTabs.ATTACHMENTS, label: 'Attachments', disabled: isPredicted, bodyClass: '' },
            {
                id: MainTabs.DETAILS,
                label: isPredicted ? 'Instant Analysis' : 'Details',
                disabled: false,
                bodyClass: '',
            },
            { id: MainTabs.COMMENTS, label: 'Comments', disabled: isPredicted, bodyClass: '' },
        ];
    }

    trackById(index: number, tab: any): string {
        return tab.id;
    }
}
