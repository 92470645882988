import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    saveSubprocessorsUrl,
    saveSubprocessorsUrlRequest,
    updateSubprocessorDetections,
    saveSubprocessorDetectionsRequest,
} from '../actions';
import { map, withLatestFrom } from 'rxjs';
import { getAssessmentToken, getSecret } from '../selectors';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class SubprocessorEffects {
    private _actions$ = inject(Actions);
    private _store$ = inject(Store);

    updateSubprocessorDetections$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateSubprocessorDetections),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ subprocessorDetections }, assessmentToken, secret]) =>
                saveSubprocessorDetectionsRequest({ assessmentToken, secret, subprocessorDetections }),
            ),
        ),
    );

    saveSubprocessorsUrl$ = createEffect(() =>
        this._actions$.pipe(
            ofType(saveSubprocessorsUrl),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ subprocessorsUrl }, assessmentToken, secret]) =>
                saveSubprocessorsUrlRequest({ assessmentToken, secret, url: subprocessorsUrl }),
            ),
        ),
    );
}
