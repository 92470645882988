<div class="container-top-half">
    <!-- Preload the animation so it's ready and shows immeditely upon submission. -->
    <!-- If we put the animation within ng-template below then it won't load until ng-template is rendered. -->
    <div class="row justify-content-center" [ngClass]="{ 'd-none': !submitted }">
        <div class="col-auto">
            <ng-lottie
                width="150px"
                [options]="submittedAnimationOptions"
                (animationCreated)="animationCreated($event)"
            ></ng-lottie>
        </div>
    </div>

    @if (!submitted) {
        <div class="row">
            <div class="col-4">
                <div class="row">
                    <div class="col">
                        <h1 class="assessment-collection-step-title">Review the statement before final submission.</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p>
                            By submitting this information, you certify that you are authorized by your company and
                            qualified to provide information regarding your company's security program. All information
                            provided is true and accurate to the best of your knowledge.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto ps-0">
                <mat-divider class="h-100" [vertical]="true"></mat-divider>
            </div>
            <div class="col mb-5">
                <div class="text-bold">Sign and submit</div>
                <form [formGroup]="assessmentSubmissionFormGroup" class="mt-4 w-100 d-inline-block">
                    <mat-form-field class="mb-5 w-100 dense-3">
                        <mat-label>First name</mat-label>
                        <input type="text" matInput formControlName="firstName" data-pw="submitterFirstNameInput" />
                    </mat-form-field>
                    <mat-form-field class="mb-5 w-100 dense-3">
                        <mat-label>Last name</mat-label>
                        <input type="text" matInput formControlName="lastName" data-pw="submitterLastNameInput" />
                    </mat-form-field>
                    <mat-form-field class="mb-5 w-100 dense-3">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" data-pw="submitterEmailInput" />
                    </mat-form-field>
                </form>
            </div>
        </div>
    } @else {
        <div class="row justify-content-center">
            <div class="col-xl-8">
                <div class="row">
                    <div class="col">
                        <h1 class="submission-header">Your assessment has been submitted!</h1>
                        <p class="text-bold">Here's what to expect next:</p>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <div class="d-flex flex-nowrap">
                            <div class="me-3">
                                <mat-icon fontSet="material-symbols-outlined"> description </mat-icon>
                            </div>
                            <p class="m-0 pt-1">
                                We'll complete a detailed analysis of the provided information using our framework,
                                which covers comprehensive risk dimensions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-nowrap">
                            <div class="me-3">
                                <mat-icon fontSet="material-symbols-outlined"> inventory </mat-icon>
                            </div>
                            <p class="m-0 pt-1">
                                If there are any gaps in control coverage, we'll ask for additional artifacts or
                                responses.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
</div>

@if (!submitted) {
    <div class="d-flex justify-content-end container-bottom-half">
        <button mat-stroked-button color="primary" class="me-auto" (click)="wentBack.emit()">Go back</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="assessmentSubmissionFormGroup.invalid"
            (click)="emitSubmit()"
            toggleDisableOnActions
            data-pw="submitAssessmentButton"
        >
            Submit
        </button>
    </div>
} @else {
    <div class="container-bottom-half no-divider"></div>
}
