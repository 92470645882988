import { FileArtifactType } from '@entities/artifact';
import { AttestationType, AttestationValue } from '@entities/attestation';
import { AuditReportTypeCode, AuditReportTypeName } from '@entities/audit-report';
import { PublicAssessmentRTPFileArtifact } from './public-assessment-rtp-file-artifact';
import { expectedArtifactLabels } from './labels';
import { SubprocessorSubmissionType } from './subprocessors';

export function generateExpiredDocumentationQuestionId(artifactId: number): string {
    return `${AttestationType.UPDATED_ARTIFACTS}-${artifactId}`;
}

export function generateSiblingDocumentationQuestionId(
    artifactId: number,
    expectedSiblingAuditReportTypeCode: AuditReportTypeCode,
): string {
    return `${AttestationType.SIBLING_DOCS}-${artifactId}-${expectedSiblingAuditReportTypeCode}`;
}

export function formatThirdPartyProvidedAuditReportType(
    additionalQuestion: AdditionalAssessmentQuestionViewModel,
    fileName: string,
): string {
    if (isExpiredDocumentationQuestion(additionalQuestion)) {
        const suffix = additionalQuestion.expiredAuditType !== AuditReportTypeCode.PCIDSSAOCQSA ? ` - ${fileName}` : '';
        return `${AuditReportTypeName[additionalQuestion.expiredAuditType]}${suffix}`;
    }
    if (isSiblingDocumentationQuestion(additionalQuestion)) {
        return `${AuditReportTypeName[additionalQuestion.requiredSiblingAuditType]} - ${fileName}`;
    }
    return additionalQuestion.artifactThirdPartyProvidedAuditType;
}

export interface AdditionalAssessmentQuestionViewModel {
    id: string;
    title: string;
    subtitle?: string;
    order: number;
    expectedArtifactsLabels: ReadonlyArray<string>;
    attestationType: AttestationType;
    attestationValue: AttestationValue;
    attestationArtifact: PublicAssessmentRTPFileArtifact;
    fileArtifactType: FileArtifactType;
    artifactThirdPartyProvidedAuditType: string;
}

export class ThirdPartyAuditQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.THIRD_PARTY_AUDITS;
    title = 'Do you have any third party audits?';
    subtitle = 'Third party audits provide a high level of confidence that you have controls in place.';
    fileArtifactType = FileArtifactType.AUDIT_REPORT;
    artifactThirdPartyProvidedAuditType = null;
    id = this.attestationType;
    expectedArtifactsLabels = expectedArtifactLabels.AUDIT_REPORT;
    order = 0;

    attestationArtifact: PublicAssessmentRTPFileArtifact;
    attestationValue: AttestationValue;
}

export class ThirdPartyPenTestQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.PEN_TESTS;
    title = 'Do you have any third party penetration tests?';
    fileArtifactType = FileArtifactType.ASSESSMENT;
    artifactThirdPartyProvidedAuditType = AuditReportTypeName[AuditReportTypeCode.PENTEST];
    id = this.attestationType;
    expectedArtifactsLabels = expectedArtifactLabels.ASSESSMENT;
    order = 1;

    attestationArtifact = null;
    attestationValue = null;
}

export class PrivacyQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.PRIVACY_DOCS;
    title = 'Do you have any privacy documentation?';
    fileArtifactType = FileArtifactType.PRIVACY;
    artifactThirdPartyProvidedAuditType = null;
    id = this.attestationType;
    expectedArtifactsLabels = expectedArtifactLabels.PRIVACY;
    order = 2;

    attestationArtifact = null;
    attestationValue = null;
}

export class CyberInsuranceQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.CYBERSECURITY_INSURANCE;
    title = 'Do you have cybersecurity insurance?';
    fileArtifactType = FileArtifactType.CYBER_INSURANCE;
    artifactThirdPartyProvidedAuditType = AuditReportTypeName[AuditReportTypeCode.CYBERINSURANCEPOLICY];
    id = this.attestationType;
    expectedArtifactsLabels = expectedArtifactLabels.CYBER_INSURANCE;
    order = 3;

    attestationArtifact = null;
    attestationValue = null;
}

export class ExpiredDocumentQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.UPDATED_ARTIFACTS;
    title: string;
    subtitle: string;
    fileArtifactType = FileArtifactType.AUDIT_REPORT;
    artifactThirdPartyProvidedAuditType = null;
    id = generateExpiredDocumentationQuestionId(this.artifactId);
    expectedArtifactsLabels = [];
    order = 4;

    attestationArtifact = null;
    attestationValue = null;
    auditType = this.expiredAuditType;

    constructor(
        public artifactId: number,
        public artifactName: string,
        public expiredAuditType: AuditReportTypeCode,
    ) {
        this.title = `The ${AuditReportTypeName[this.expiredAuditType]} you uploaded is expired. Do you have a more recent one?`;
        this.subtitle = `You uploaded <strong>${artifactName}</strong>`;
    }
}

export class SiblingDocumentQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.SIBLING_DOCS;
    title: string;
    subtitle: string;
    fileArtifactType = FileArtifactType.AUDIT_REPORT;
    artifactThirdPartyProvidedAuditType = null;
    id = generateSiblingDocumentationQuestionId(this.artifactId, this.requiredSiblingAuditType);
    expectedArtifactsLabels = [];
    order = 5;

    attestationArtifact = null;
    attestationValue = null;
    auditType = this.uploadedAuditType;

    constructor(
        public artifactId: number,
        public artifactName: string,
        public uploadedAuditType: AuditReportTypeCode,
        public requiredSiblingAuditType: AuditReportTypeCode,
    ) {
        this.title = `You\'ve uploaded ${AuditReportTypeName[this.uploadedAuditType]}. Do you have the ${AuditReportTypeName[this.requiredSiblingAuditType]}?`;
        this.subtitle = `We require both to validate control domains.<br/><br/>You uploaded <strong>${artifactName}</strong>`;
    }
}

export class SubprocessorsQuestion implements AdditionalAssessmentQuestionViewModel {
    attestationType = AttestationType.SUBPROCESSORS;
    id = this.attestationType;
    title = null;
    subtitle = null;
    fileArtifactType = FileArtifactType.PRIVACY;
    artifactThirdPartyProvidedAuditType = null;
    expectedArtifactsLabels = [];
    attestationValue: AttestationValue;
    order = 6;

    constructor(
        public submissionType: SubprocessorSubmissionType,
        public attestationArtifact: PublicAssessmentRTPFileArtifact,
    ) {}
}

export function isThirdPartyAuditQuestion(
    value: AdditionalAssessmentQuestionViewModel,
): value is ThirdPartyAuditQuestion {
    return value.attestationType === AttestationType.THIRD_PARTY_AUDITS;
}

export function isExpiredDocumentationQuestion(
    value: AdditionalAssessmentQuestionViewModel,
): value is ExpiredDocumentQuestion {
    return value.attestationType === AttestationType.UPDATED_ARTIFACTS;
}

export function isSiblingDocumentationQuestion(
    value: AdditionalAssessmentQuestionViewModel,
): value is SiblingDocumentQuestion {
    return value.attestationType === AttestationType.SIBLING_DOCS;
}

export function isThirdPartyPenTestQuestion(
    value: AdditionalAssessmentQuestionViewModel,
): value is ThirdPartyPenTestQuestion {
    return value.attestationType === AttestationType.PEN_TESTS;
}

export function isPrivacyQuestion(value: AdditionalAssessmentQuestionViewModel): value is PrivacyQuestion {
    return value.attestationType === AttestationType.PRIVACY_DOCS;
}

export function isCyberInsuranceQuestion(
    value: AdditionalAssessmentQuestionViewModel,
): value is CyberInsuranceQuestion {
    return value.attestationType === AttestationType.CYBERSECURITY_INSURANCE;
}

export function isSubprocessorQuestion(value: AdditionalAssessmentQuestionViewModel): value is SubprocessorsQuestion {
    return value.attestationType === AttestationType.SUBPROCESSORS;
}
