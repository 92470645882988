import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FilterFunction } from '@shared/file-upload/interfaces';
import {
    AdditionalAssessmentQuestionViewModel,
    isExpiredDocumentationQuestion,
    isSiblingDocumentationQuestion,
    PublicAssessmentRTPFileArtifact,
} from '../../../../../models';
import { FormControl } from '@angular/forms';
import { AttestationValue } from '@entities/attestation';
import { FileArtifactType, FileArtifactTypeLabels } from '@entities/artifact';
import { AuditReportTypeName } from '@entities/audit-report';

@Component({
    selector: 'app-missing-artifact-question',
    templateUrl: './missing-artifact-question.component.html',
    styleUrl: './missing-artifact-question.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissingArtifactQuestionComponent implements OnChanges {
    @Input({ required: true }) additionalQuestion: AdditionalAssessmentQuestionViewModel;
    @Input({ required: true }) vendorName: string;
    @Input({ required: true }) questionIdx: number;
    @Input({ required: true }) totalQuestions: number;

    @Output() goBack = new EventEmitter<void>();
    @Output() continue = new EventEmitter<void>();
    @Output() uploadArtifact = new EventEmitter<{ additionalQuestionId: string; file: File }>();
    @Output() uploadFileFailed = new EventEmitter<FilterFunction>();
    @Output() setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();
    @Output() removeArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();
    @Output() updateQuestion = new EventEmitter<AdditionalAssessmentQuestionViewModel>();

    currentAttestationValueControl = new FormControl<AttestationValue>(null);

    artifactLabels: Partial<Record<FileArtifactType, string>> = {
        AUDIT_REPORT: 'third party audit',
        ASSESSMENT: 'penetration test',
        PRIVACY: 'privacy document',
        CYBER_INSURANCE: 'cyber insurance document',
    };

    AttestationValue = AttestationValue;

    ngOnChanges(changes: SimpleChanges): void {
        const additionalQuestion = changes.additionalQuestion?.currentValue as AdditionalAssessmentQuestionViewModel;
        if (!!additionalQuestion) {
            if (!!additionalQuestion.attestationArtifact) {
                this.currentAttestationValueControl.setValue(null);
                this.currentAttestationValueControl.disable();
            } else {
                this.currentAttestationValueControl.enable();
            }
        }
    }

    emitUpload(additionalQuestionId: string, file: File): void {
        this.uploadArtifact.emit({ additionalQuestionId: additionalQuestionId, file });
    }

    classificationIsIncorrect(artifact: PublicAssessmentRTPFileArtifact): boolean {
        return !!artifact && (artifact.fileArtifactTypeOverridden || artifact.auditReportTypeOverridden);
    }

    getIncorrectClassificationLabel(recommendation: AdditionalAssessmentQuestionViewModel): string {
        if (isExpiredDocumentationQuestion(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.expiredAuditType];
            return (
                `This artifact was not classified as ${auditTypeName}. ` +
                'Attest that you do not have this artifact or continue if the provided artifact is correct. ' +
                "If you believe this to be in error, don't worry. All assessments go through a human audit."
            );
        }
        if (isSiblingDocumentationQuestion(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.requiredSiblingAuditType];
            return (
                `This artifact was not classified as ${auditTypeName}. ` +
                'Attest that you do not have this artifact or continue if the provided artifact is correct. ' +
                "If you believe this to be in error, don't worry. All assessments go through a human audit."
            );
        } else {
            const documentLabel = this.artifactLabels[recommendation.fileArtifactType];
            const categoryLabel = FileArtifactTypeLabels[recommendation.fileArtifactType].toLowerCase();
            return (
                `This artifact was not classified as a ${documentLabel}. ` +
                `Attest that you do not have an artifact for ${categoryLabel} or continue if you believe this substantiates ${categoryLabel}. ` +
                "If you believe this to be in error, don't worry. All assessments go through a human audit."
            );
        }
    }

    getAttestationOptions(
        recommendation: AdditionalAssessmentQuestionViewModel,
    ): { label: string; value: AttestationValue }[] {
        const result = [];
        const fileTypeLabel = FileArtifactTypeLabels[recommendation.fileArtifactType].toLowerCase();
        if (isExpiredDocumentationQuestion(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.expiredAuditType];
            result.push(
                {
                    label: `${this.vendorName} does not have a more recent ${auditTypeName}.`,
                    value: AttestationValue.DONT_HAVE,
                },
                {
                    label: `${this.vendorName} will not provide a more recent ${auditTypeName}.`,
                    value: AttestationValue.WONT_PROVIDE,
                },
            );
        } else if (isSiblingDocumentationQuestion(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.requiredSiblingAuditType];
            result.push(
                {
                    label: `${this.vendorName} does not have ${auditTypeName}.`,
                    value: AttestationValue.DONT_HAVE,
                },
                {
                    label: `${this.vendorName} will not provide ${auditTypeName}.`,
                    value: AttestationValue.WONT_PROVIDE,
                },
            );
        } else {
            result.push(
                {
                    label: `${this.vendorName} does not have artifacts for ${fileTypeLabel}.`,
                    value: AttestationValue.DONT_HAVE,
                },
                {
                    label: `${this.vendorName} will not provide artifacts for ${fileTypeLabel}.`,
                    value: AttestationValue.WONT_PROVIDE,
                },
                {
                    label: `Another artifact substantiates ${fileTypeLabel}.`,
                    value: AttestationValue.OTHER_ARTIFACT,
                },
            );
        }
        return result;
    }

    backDisabled(recommendation: AdditionalAssessmentQuestionViewModel): boolean {
        return (
            !!recommendation.attestationArtifact && recommendation.attestationArtifact.waitingOnRequiredRTPValidations
        );
    }

    continueDisabled(recommendation: AdditionalAssessmentQuestionViewModel): boolean {
        return (
            !this.currentAttestationValueControl.value &&
            (!recommendation.attestationArtifact || recommendation.attestationArtifact.waitingOnRequiredRTPValidations)
        );
    }

    continueFn(): void {
        this.processTransitionToNewStep();
        this.continue.emit();
    }

    goBackFn(): void {
        this.processTransitionToNewStep();
        this.goBack.emit();
    }

    private processTransitionToNewStep(): void {
        this.additionalQuestion.attestationValue = this.currentAttestationValueControl.value;
        this.updateQuestion.emit(this.additionalQuestion);
    }
}
