import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ArtifactFilePageLink, ArtifactType, ControlValidationDetectionType } from '@entities/artifact';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';

import { Detection } from '../../../models/detection.model';
import { AssuranceLevels } from '@entities/relationship/models';
import { AuditReportTypeName } from '@entities/audit-report';
import { FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';
import { ArtifactPageLinkViewType } from '../artifact-page-link/artifact-page-link.component';

@Component({
    selector: 'app-detection-card',
    templateUrl: './detection-card.component.html',
    styleUrls: ['./detection-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'cursor-pointer' },
})
export class DetectionCardComponent {
    readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    @Input() detection: Detection;

    @Input() individualControlName: string;

    @Input() frameworkType: FrameworkMappingType;

    @Input() selected: boolean;

    AssuranceLevels = AssuranceLevels;
    ArtifactType = ArtifactType;
    AuditReportType = AuditReportTypeName;
    DetectionType = ControlValidationDetectionType;
    ArtifactPageLinkViewType = ArtifactPageLinkViewType;
    DetectionTypeCardLabels: Partial<Record<ControlValidationDetectionType, string>> = {
        [ControlValidationDetectionType.NORMAL]: 'General',
        [ControlValidationDetectionType.EXCEPTION]: 'Exception',
        [ControlValidationDetectionType.SUBSERVICE]: 'Subservicer',
        [ControlValidationDetectionType.SUBPROCESSOR]: 'Sub-processor',
        [ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL]: 'Shared Responsibility',
        [ControlValidationDetectionType.CUEC]: 'CUEC',
    };

    getPages(pageList: string): ArtifactFilePageLink[] {
        if (!pageList) {
            return null;
        }
        const pageNumbers = pageList.replace(/, +/g, ',').split(',');

        return pageNumbers.map((page) => {
            if (isNaN(Number(page)) && page.includes('-')) {
                const pageRange = page.split('-');
                return {
                    initialPage: +pageRange[0],
                    finalPage: +pageRange[1],
                };
            } else if (typeof page === 'number') {
                return page;
            } else {
                return page;
            }
        });
    }
}
