<mat-drawer-container class="drawer-container">
    <mat-drawer
        class="drawer"
        [opened]="getIsDetectionDetailsDrawerOpen$ | async"
        [mode]="drawerMode"
        [disableClose]="true"
        position="end"
        #matDrawer
    >
        @if (getIsDetectionDetailsDrawerOpen$ | async) {
            <app-detection-details
                *ngIf="getDetectionDetailsDrawerData$ | async as detectionDetails"
                [detection]="detectionDetails.detection"
                [controlName]="detectionDetails.controlName"
                [domainControlName]="detectionDetails.domainControlName"
                [frameworkType]="detectionDetails.frameworkType"
                (close)="onCloseDrawer()"
            />
        }
    </mat-drawer>

    <mat-drawer-content class="drawer-content">
        <header class="request-header">
            <div *ngIf="!request" class="spinner-container">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="sk-spinner sk-spinner-pulse"></div>
                </div>
            </div>

            <app-header
                class="d-print-none"
                [hidden]="!request"
                [request]="request"
                [latestAssessmentStatus]="latestAssessmentStatus$ | async"
                [isPreviousViewFiltered]="isPreviousViewFiltered"
                [previousNavigatedUrlQueryParams]="previousNavigatedUrlQueryParams"
                [onPrint]="showPrintArea$ | async"
                [isUpForRecertification]="isUpForRecertification$ | async"
                [currentUser]="currentAccount$ | async"
                [businessOwner]="businessOwner$ | async"
                (onboard)="openOnboardModal()"
                (archive)="archiveRequest()"
                (markAsNotOnboarded)="markAsNotOnboarded()"
                (startAssessment)="startAssessment()"
                (confirmRecertifyAssessment)="confirmRecertifyAssessment()"
                (downloadPDF)="exportRelationshipAsPDF()"
                (openCreateRiskAdvisory)="openCreateRiskAdvisory()"
                [hasOrgUserOnboardEnabled]="hasOrgUserOnboardEnabled$ | async"
                [hasLicenseManagementEnabled]="hasLicenseManagementEnabled$ | async"
            ></app-header>
            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner$ | async">
                <app-in-line-callout
                    *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                    [relationship]="request"
                    (startAssessment)="startAssessment()"
                    class="mx-2"
                />
            </ng-container>
            @if (!(latestRiskAssessment$ | async)?.predicted) {
                <app-stat-boxes
                    class="d-print-none"
                    [hidden]="!(request$ | async)"
                    [relationship]="request$ | async"
                    [latestAssessment]="latestAssessment$ | async"
                    [latestRiskAssessment]="latestRiskAssessment$ | async"
                    [latestNonTransitionalRiskAssessment]="latestNonTransitionalRiskAssessment$ | async"
                    [isUpForRecertification]="isUpForRecertification$ | async"
                    [isRecertificationOverdue]="isRecertificationOverdue$ | async"
                    [isAssessmentInProgress]="isAssessmentInProgress$ | async"
                    [recertificationDueDaysLeft]="recertificationDueDaysLeftCount$ | async"
                    (editRiskReview)="openReviewRiskDialog()"
                />
            }

            <ng-container *ngIf="isCurrentUserAuditor || isCurrentUserSupport">
                <div class="row mt-0 ms-4">
                    <div class="col">
                        <p class="text-light">
                            {{ request.clientName }} has a
                            {{ getRiskTolerance(senderClientProfile$ | async) }}
                            risk tolerance.
                        </p>
                    </div>
                </div>
            </ng-container>
        </header>

        <div [hidden]="!request" class="d-print-none">
            <ng-container
                *ngIf="{
                    latestRiskAssessment: latestRiskAssessment$ | async,
                    selectedTab: tabContentTrigger$ | async,
                    isRdpAiQAEnabled: hasRDPAIQAEnabled$ | async,
                } as tabGroupCtx"
            >
                <mat-tab-group
                    [ngClass]="{ 'details-tab-active': tabGroupCtx.selectedTab === MainTabs.DETAILS }"
                    id="mainTabset"
                    #mainTabset
                    mat-stretch-tabs="false"
                    class="mb-3 viso-tabset"
                    animationDuration="0"
                    dynamicHeight
                >
                    <ng-container *ngFor="let tab of tabDefinitions$ | async; trackBy: trackById">
                        <mat-tab
                            [id]="tab.id"
                            [disabled]="tab.disabled"
                            [labelClass]="tab.labelClass"
                            [bodyClass]="tab.bodyClass"
                        >
                            <ng-template mat-tab-label>
                                @if (tab.disabled) {
                                    <mat-icon class="me-2" fontSet="material-symbols-outlined">lock</mat-icon>
                                }
                                <span [matTooltip]="tab.disabledToolTip">{{ tab.label }}</span>
                            </ng-template>
                            <ng-container [ngSwitch]="tab.id">
                                <ng-container *ngSwitchCase="MainTabs.RELATIONSHIP">
                                    <app-relationship-tab
                                        [combinedSupplementalQuestionnaireConfig]="
                                            supplementalQuestionnaireConfiguration$ | async
                                        "
                                        [hasSupplementalQuestionnairesEnabled]="
                                            hasSupplementalQuestionnairesEnabled$ | async
                                        "
                                        [relationship]="request"
                                        [dataTypesConfig]="dataTypesProfileConfig$ | async"
                                        [businessOwner]="businessOwner$ | async"
                                        [primaryVendorContact]="primaryVendorContact$ | async"
                                        [latestCompletedAssessmentDate]="latestCompletedAssessmentDate$ | async"
                                        [tags]="relationshipTags$ | async"
                                        [subscribers]="relationshipSubscribers$ | async"
                                        [currentUser]="currentAccount$ | async"
                                        [dateOfNextArtifactExpiration]="dateOfNextArtifactExpiration$ | async"
                                        [vendorTiers]="allVendorTiers$ | async"
                                        [currentVendorTier]="currentVendorTier$ | async"
                                        (unlinkTag)="unlinkTagToRelationship($event)"
                                        (linkTag)="linkTagToRelationship($event)"
                                        (unsubscribeSubscriber)="unsubscribeSubscriber($event)"
                                    />
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.ASSESSMENT">
                                    <app-assessment-list
                                        [assessmentSummarySectionConfig]="assessmentSummarySectionConfig$ | async"
                                        [relationship]="request"
                                        [artifacts]="filteredArtifacts$ | async"
                                        [latestAssessment]="latestAssessment$ | async"
                                        [orgUserCanOnboardAcceptRisk]="hasOrgUserOnboardEnabled$ | async"
                                        [assessments]="pastAssessments$ | async"
                                        [businessOwner]="businessOwner$ | async"
                                        [isOnlyLegacyAssessmentPresent]="isOnlyLegacyAssessmentPresent$ | async"
                                        [isUpForRecertification]="isUpForRecertification$ | async"
                                        [isRecertificationOverdue]="isRecertificationOverdue$ | async"
                                        (startAssessment)="startAssessment()"
                                        (confirmRecertifyAssessment)="confirmRecertifyAssessment()"
                                        (cancelAssessment)="cancelAssessment()"
                                        (editRiskReview)="openReviewRiskDialog()"
                                        (reviewFollowup)="openReviewFollowupDialog()"
                                        (editFollowup)="openEditFollowupDialog()"
                                    />
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.RISK_ANALYSIS">
                                    <app-risk-analysis
                                        [supplementalQuestionnaireReprocessingState]="suppQReprocessingState$ | async"
                                        [groupedControls]="groupedControls$ | async"
                                        [artifactSupersession]="artifactSupersession$ | async"
                                        [relationshipControlDomainAssessments]="securityControlDomain$ | async"
                                        [request]="request"
                                        [latestAssessmentStatus]="latestAssessmentStatus$ | async"
                                        [latestAssessmentFollowUpQuestionnaireCreatedDate]="
                                            latestAssessmentFollowUpQuestionnaireCreatedDate$ | async
                                        "
                                        [missingInformationWasRequested]="missingInformationWasRequested$ | async"
                                        [isLatestAssessmentNonDocumentsOnly]="
                                            isLatestAssessmentNonDocumentsOnly$ | async
                                        "
                                        [businessOwner]="businessOwner$ | async"
                                    />
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.ARTIFACTS">
                                    <ng-container *ngIf="filteredArtifacts$ | async as artifacts">
                                        <div class="row mt-3">
                                            <div class="col d-flex align-items-center">
                                                <h3 class="lead mb-0">Artifacts ({{ artifacts.length }})</h3>
                                            </div>
                                            <div class="col text-end d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    class="me-2 shrink centered"
                                                    mat-icon-button
                                                    *jhiHasNotAuthority="Roles.ReadOnly"
                                                    toggleDisableOnActions
                                                    [disablingActions]="[
                                                        ArtifactActions.DownloadRelationshipArtifactsAsZip,
                                                    ]"
                                                    [enablingActions]="[
                                                        FileDownloadActions.SaveFileFromResBlob,
                                                        ArtifactActions.DownloadRelationshipArtifactsAsZipFailed,
                                                    ]"
                                                    [disabled]="!artifacts.length"
                                                    (click)="downloadArtifacts()"
                                                >
                                                    <mat-icon color="primary">download</mat-icon>
                                                </button>
                                                <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner$ | async">
                                                    <button
                                                        mat-stroked-button
                                                        color="primary"
                                                        *jhiHasNotAuthority="[Roles.Admin, Roles.ReadOnly]"
                                                        [routerLink]="addArtifactRouterLink"
                                                        [replaceUrl]="true"
                                                        class="me-2 allow-pointer-events"
                                                        [disabled]="
                                                            isCurrentUserSupport || !doesRelationshipHaveContext
                                                        "
                                                        [disableTooltip]="doesRelationshipHaveContext"
                                                        ngbTooltip="Add Context to this Relationship before uploading artifacts"
                                                        data-pw="addArtifact"
                                                    >
                                                        <mat-icon color="primary">add</mat-icon>
                                                        {{
                                                            !(isAssessmentInProgress$ | async)
                                                                ? 'Add artifact'
                                                                : 'Add artifact to assessment'
                                                        }}
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                        @if (isAssessmentInProgress$ | async) {
                                            <div class="text-muted subtitle">
                                                This relationship has an assessment in progress. Uploaded artifacts are
                                                evaluated as part of the assessment.
                                            </div>
                                        } @else {
                                            <div class="text-muted subtitle">There are no assessments in progress.</div>
                                        }
                                        <div class="row mb-4">
                                            <div class="col">
                                                <app-artifacts-list
                                                    *ngIf="artifacts.length; else noArtifacts"
                                                    [artifacts]="artifacts"
                                                    [currentAccount]="currentAccount$ | async"
                                                    [messages]="{ emptyMessage: '' }"
                                                    [request]="request"
                                                    [artifactSupersession]="artifactSupersession$ | async"
                                                    [artifactRtpEventMap]="artifactRtpEvents$ | async"
                                                    (deleteArtifact)="deleteArtifact($event)"
                                                    (openArtifact)="openArtifact($event)"
                                                />
                                                <ng-template #noArtifacts>
                                                    <div class="text-center">
                                                        <p>We have nothing to show you yet</p>
                                                        <ng-container
                                                            *hideIfOrgUserIsNotBusinessOwner="businessOwner$ | async"
                                                        >
                                                            <button
                                                                mat-button
                                                                visoButton="secondary"
                                                                color="primary"
                                                                *jhiHasNotAuthority="[Roles.Admin, Roles.ReadOnly]"
                                                                class="allow-pointer-events"
                                                                [routerLink]="addArtifactRouterLink"
                                                                [replaceUrl]="true"
                                                                [disabled]="
                                                                    isCurrentUserSupport || !doesRelationshipHaveContext
                                                                "
                                                                [disableTooltip]="doesRelationshipHaveContext"
                                                                ngbTooltip="Add Context to this Relationship before uploading artifacts"
                                                                data-pw="addArtifact"
                                                            >
                                                                Add a file to this relationship
                                                            </button>
                                                        </ng-container>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.AI_QA">
                                    <app-rdp-ai-question-answering
                                        [iiqrResponse]="iiqrResponse$ | async"
                                        [hasArtifactsWithValidations]="hasArtifactsWithValidations$ | async"
                                        [vendorName]="request.vendorName"
                                        (askQuestion)="askQuestion($event)"
                                        (goToArtifactsTab)="goToArtifacts()"
                                    />
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.RISK_TIMELINE">
                                    <ng-container *ngIf="!isRiskTimelineListView; else riskTimelineList">
                                        <app-risk-timeline
                                            [riskAssessments]="riskAssessments$ | async"
                                            [riskAssessmentsDatasets]="riskAssessmentsDatasets$ | async"
                                            (toggleTimelineView)="toggleTimelineView()"
                                        />
                                    </ng-container>
                                    <ng-template #riskTimelineList>
                                        <app-risk-timeline-list
                                            [riskAssessments]="riskAssessments$ | async"
                                            (toggleTimelineView)="toggleTimelineView()"
                                        />
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.ATTACHMENTS">
                                    <div class="card attachments-container mt-3">
                                        <div
                                            class="card-header d-flex flex-row justify-content-between align-items-center border-bottom"
                                        >
                                            <div>
                                                <p class="card-title">Relationship Attachments</p>
                                                <p class="m-0">
                                                    Add files you want to associate to this relationship. Attachments
                                                    will not be assessed.
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                mat-button
                                                visoButton="primary"
                                                [routerLink]="uploadAttachmentsRouterLink"
                                                *jhiHasNotAuthority="Roles.ReadOnly"
                                            >
                                                Add Attachments
                                            </button>
                                        </div>
                                        <div class="ms-auto me-3 pt-3" *jhiHasNotAuthority="Roles.ReadOnly">
                                            <button
                                                *ngIf="hasAttachments$ | async"
                                                type="button"
                                                class="shrink centered"
                                                mat-icon-button
                                                [disabled]="!anyAttachmentSelected"
                                                toggleDisableOnActions
                                                [disablingActions]="[
                                                    AttachmentActions.DownloadRelationshipAttachmentsAsZip,
                                                ]"
                                                [enablingActions]="[
                                                    FileDownloadActions.SaveFileFromResBlob,
                                                    AttachmentActions.DownloadRelationshipAttachmentsAsZipFailed,
                                                ]"
                                                (click)="downloadSelectedAttachments()"
                                            >
                                                <mat-icon>download</mat-icon>
                                            </button>
                                        </div>
                                        <app-attachments
                                            [attachments]="attachments$ | async"
                                            (onAttachmentSelected)="onAttachmentSelected($event)"
                                            (deleteAttachment)="deleteAttachment($event)"
                                            (downloadAttachment)="downloadAttachment($event)"
                                        />
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.DETAILS">
                                    <app-vendor-details-tab
                                        [relationshipLongRunningTasks]="relationshipLongRunningTasks$ | async"
                                        [inScopeControlDomainTypes]="inScopeControlDomainTypes$ | async"
                                        [disabledControlDomainTypes]="disabledControlDomainTypes$ | async"
                                        [contextTypes]="request.contextTypes"
                                        [dataTypes]="request.dataTypes"
                                        [vendorOrgId]="request.vendorId"
                                        [relationshipId]="request.id"
                                        [latestRiskAssessment]="latestRiskAssessment$ | async"
                                        [isTabSelected]="tabGroupCtx.selectedTab === MainTabs.DETAILS"
                                    ></app-vendor-details-tab>
                                </ng-container>
                                <ng-container *ngSwitchCase="MainTabs.COMMENTS">
                                    <app-comment-handler
                                        [comments]="comments$ | async"
                                        [currentAccount]="currentAccount$ | async"
                                        [mentions]="mentions$ | async"
                                        [fastMentions]="fastMentions$ | async"
                                        (createComment)="createComment($event)"
                                        (deleteComment)="deleteComment($event)"
                                    />
                                </ng-container>
                            </ng-container>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </ng-container>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<app-relationship-detail-page-export
    *ngIf="showPrintArea$ | async"
    pdfExportable
    actionRemover
    class="d-none d-print-block"
    [hasSupplementalQuestionnairesEnabled]="hasSupplementalQuestionnairesEnabled$ | async"
    [request]="request"
    [relationshipSubscribers]="relationshipSubscribers$ | async"
    [primaryVendorContact]="primaryVendorContact$ | async"
    [businessOwner]="businessOwner$ | async"
    [latestRiskAssessment]="latestRiskAssessment$ | async"
    [latestNonTransitionalRiskAssessment]="latestNonTransitionalRiskAssessment$ | async"
    [latestCompletedAssessmentDate]="latestCompletedAssessmentDate$ | async"
    [artifacts]="filteredArtifacts$ | async"
    [groupedControlDomains]="groupedControls$ | async"
    [riskAssessments]="riskAssessments$ | async"
    [riskAssessmentsDatasets]="riskAssessmentsDatasets$ | async"
    [securityControlDomain]="securityControlDomain$ | async"
    [latestAssessment]="latestAssessment$ | async"
    [latestAssessmentStatus]="latestAssessmentStatus$ | async"
    [comments]="comments$ | async"
    [currentAccount]="currentAccount$ | async"
    [userIsTrial]="userIsTrial$ | async"
    [dataTypesProfileConfig]="dataTypesProfileConfig$ | async"
    [latestAssessmentFollowUpQuestionnaireCreatedDate]="latestAssessmentFollowUpQuestionnaireCreatedDate$ | async"
    [missingInformationWasRequested]="missingInformationWasRequested$ | async"
    [pastAssessments]="pastAssessments$ | async"
    [isOnlyLegacyAssessmentPresent]="isOnlyLegacyAssessmentPresent$ | async"
    [isUpForRecertification]="isUpForRecertification$ | async"
    [isRecertificationOverdue]="isRecertificationOverdue$ | async"
    [isAssessmentInProgress]="isAssessmentInProgress$ | async"
    [recertificationDueDaysLeftCount]="recertificationDueDaysLeftCount$ | async"
    [isLatestAssessmentNonDocumentsOnly]="isLatestAssessmentNonDocumentsOnly$ | async"
    [tags]="relationshipTags$ | async"
    [attachments]="attachments$ | async"
    [artifactSupersession]="artifactSupersession$ | async"
    [assessmentSummarySectionConfig]="assessmentSummarySectionConfig$ | async"
    [combinedSupplementalQuestionnaireConfig]="supplementalQuestionnaireConfiguration$ | async"
    [currentVendorTier]="currentVendorTier$ | async"
/>
