import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { VendorSearchResult } from '../models/vendor-search-result';
import { MatFormField, MatInput } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { IMAGE_FALLBACK_BASE64 } from '@shared/image-fallback/image-fallback.constants';
import { ImageFallbackModule } from '@shared/image-fallback/image-fallback.module';

/**
 * Vendor search component that will eventually replace our existing ngb vendor search.
 * Note: Currently does not support custom vendors. Custom names won't be written to the form control.
 **/
@Component({
    selector: 'mat-vendor-search',
    templateUrl: './mat-vendor-search.component.html',
    styleUrl: './mat-vendor-search.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MatVendorSearchComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        CommonModule,
        MatInput,
        MatFormField,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        ImageFallbackModule,
    ],
})
export class MatVendorSearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
    placeholder = input<string>();
    searchResults = input<VendorSearchResult[]>([]);

    @Output() onSearch = new EventEmitter<string>();

    searchControl = new FormControl<string | VendorSearchResult>(null);
    readonly vendorImageFallback: string = IMAGE_FALLBACK_BASE64;

    private _unsub$ = new Subject<void>();

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(300),
                filter(this.searchTermIsStringWithLength),
                distinctUntilChanged(),
                takeUntil(this._unsub$),
            )
            .subscribe((keyword) => this.onSearch.emit(keyword));

        this.searchControl.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((value) =>
            // If the value is a string and not a selection from the dropdown, clear it.
            this._onChanged(typeof value === 'string' ? null : value),
        );
    }

    private searchTermIsStringWithLength(val: string | VendorSearchResult): val is string {
        return !!val && typeof val === 'string' && val.length > 0;
    }

    onTouched: Function = () => {};

    registerOnChange(fn: any): void {
        this._onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: VendorSearchResult): void {
        this.searchControl.setValue(obj);
    }

    private _onChanged: Function = (value: VendorSearchResult) => {};

    setDisabledState(isDisabled: boolean) {
        isDisabled ? this.searchControl.disable() : this.searchControl.enable();
    }

    vendorDisplayFn = (searchResult: VendorSearchResult): string => searchResult?.name;

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
