import { OrgSize, OrgUrlType } from '@entities/org';

export enum ComplianceStandard {
    C5 = 'C5',
    CCPA = 'CCPA',
    CSA_LEVEL2 = 'CSA_LEVEL2',
    CSA_STAR = 'CSA_STAR',
    FEDRAMP = 'FEDRAMP',
    GDPR = 'GDPR',
    GLBA = 'GLBA',
    HIPAA = 'HIPAA',
    HITRUST = 'HITRUST',
    ISO27001 = 'ISO27001',
    ISO27017 = 'ISO27017',
    ISO27018 = 'ISO27018',
    ISO27701 = 'ISO27701',
    PCIDSS = 'PCIDSS',
    PRIVACY_SHIELD = 'PRIVACY_SHIELD',
    SOC1 = 'SOC1',
    SOC2 = 'SOC2',
    SOC3 = 'SOC3',
    TISAXREPORTLEVEL3 = 'TISAXREPORTLEVEL3',
}

export enum ComplianceStandardLabels {
    C5 = 'C5',
    CCPA = 'CCPA',
    CSA_LEVEL2 = 'CSA Level 2',
    CSA_STAR = 'CSA Star',
    FEDRAMP = 'FEDRAMP',
    GDPR = 'GDPR',
    GLBA = 'GLBA',
    HIPAA = 'HIPAA',
    HITRUST = 'HITRUST',
    ISO27001 = 'ISO 27001',
    ISO27017 = 'ISO 27017',
    ISO27018 = 'ISO 27018',
    ISO27701 = 'ISO 27701',
    PCIDSS = 'PCI DSS',
    PRIVACY_SHIELD = 'Privacy Shield',
    SOC1 = 'SOC 1',
    SOC2 = 'SOC 2',
    SOC3 = 'SOC 3',
    TISAXREPORTLEVEL3 = 'TISAX Level 3',
}

export interface LeadershipContactView {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    linkedinUrl: string;
}

export interface SuggestedRelationshipContext {
    contextTypeIds: number[];
    dataTypeIds: number[];
    count: number[];
}

export interface VendorDetailsResponse {
    id: number;
    legalName: string;
    name: string;
    faviconUrl: string;
    updatedDate: Date;
    description: string;
    isDerank: boolean;
    industry: string;
    homepage: string;
    countryCode: string;
    region: string;
    city: string;
    address: string;
    postalCode: string;
    stateCode: string;
    urls: Record<OrgUrlType, string>;
    orgSize: OrgSize;
    stockTicker: string;
    foundedDate: Date;
    publiclyTraded: boolean;
    complianceStandards: ComplianceStandard[];
    leadership: LeadershipContactView[];
    domains: string[];
    trustCenter?: OrgTrustCenter;
    managedById: number;
}

export interface OrgTrustCenter {
    id: string;
    url: string;
    type: TrustCenterType;
}

export interface VendorDetailsUrl {
    name: string;
    url: string;
    type: OrgUrlType;
}

export interface UpdateVendorDetailsRequest {
    id: number;
    urls: Record<OrgUrlType, string>;
    complianceStandards: ComplianceStandard[];
    leadership: LeadershipContactView[];
    trustCenter?: { url: string; type: TrustCenterType };
}

export interface AdminUpdateVendorDetailsRequest extends UpdateVendorDetailsRequest {
    legalName: string;
    name: string;
    homepage: string;
    description: string;
    isDerank: boolean;
    industry: string;
    orgSize: OrgSize;
    countryCode: string;
    region: string;
    city: string;
    address: string;
    postalCode: string;
    stateCode: string;
    stockTicker: string;
    foundedDate: Date;
}

export enum TrustCenterType {
    CONVEYOR = 'CONVEYOR',
    DRATA = 'DRATA',
    SAFEBASE = 'SAFEBASE',
    VANTA = 'VANTA',
    WHISTIC = 'WHISTIC',
    OTHER = 'OTHER',
}

export interface VendorDetailsStatsResponse {
    lastAssessedDate: Date;
    averageResponseTime: number;
    assessmentsCompleted: number;
    canPredictRiskUsingExistingInfo: boolean;
    validatedRiskDomainCount: number;
    complianceStandardCount: number;
    highAssuranceArtifactCount: number;
    doesPredictedContextHaveHighSensitivityData: number;
    lastRiskAdvisoryPublishedDate: Date;
    materialRiskAdvisoriesCount: number;
}
