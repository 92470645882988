import { createAction, props } from '@ngrx/store';
import { AdditionalAssessmentQuestionViewModel } from '../../models';

export enum AssessmentCollectionCommonAdditionalAssessmentQuestionAction {
    AddAdditionalAssessmentQuestion = '[Assessment Collection Common Additional Assessment Question] Add Additional Assessment Question',
    SetAdditionalAssessmentQuestion = '[Assessment Collection Common Additional Assessment Question] Set Additional Assessment Question',
    SetAdditionalAssessmentQuestions = '[Assessment Collection Common Additional Assessment Question] Set Additional Assessment Questions',
    RemoveAdditionalAssessmentQuestion = '[Assessment Collection Common Additional Assessment Question] Remove Additional Assessment Question',
    OnWhenAddingFileFailed = '[Assessment Collection Common Additional Assessment Question] On When Adding File Failed',
}

export const addAdditionalAssessmentQuestion = createAction(
    AssessmentCollectionCommonAdditionalAssessmentQuestionAction.AddAdditionalAssessmentQuestion,
    (additionalQuestion: AdditionalAssessmentQuestionViewModel) => ({ additionalQuestion }),
);

export const setAssessmentAdditionalQuestions = createAction(
    AssessmentCollectionCommonAdditionalAssessmentQuestionAction.SetAdditionalAssessmentQuestions,
    props<{ additionalQuestions: AdditionalAssessmentQuestionViewModel[] }>(),
);

export const setAdditionalAssessmentQuestion = createAction(
    AssessmentCollectionCommonAdditionalAssessmentQuestionAction.SetAdditionalAssessmentQuestion,
    props<{ additionalQuestion: AdditionalAssessmentQuestionViewModel }>(),
);

export const removeAdditionalAssessmentQuestion = createAction(
    AssessmentCollectionCommonAdditionalAssessmentQuestionAction.RemoveAdditionalAssessmentQuestion,
    props<{ additionalQuestion: AdditionalAssessmentQuestionViewModel }>(),
);

export const onWhenAddingFileFailed = createAction(
    AssessmentCollectionCommonAdditionalAssessmentQuestionAction.OnWhenAddingFileFailed,
    props<{ message: string }>(),
);
