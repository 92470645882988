import { createAction, props } from '@ngrx/store';
import { ApolloError } from '@apollo/client';
import { LongRunningTaskEvent } from '@entities/long-running-tasks';

export enum LongRunningTaskActions {
    OnRelationshipLongRunningTaskEventCancelled = '[Request] On Relationship Long Running Task Event Cancelled',
    OnRelationshipLongRunningTaskEvent = '[Request] On Relationship Long Running Task Event',
    OnRelationshipLongRunningTaskEventSuccess = '[Request] On Relationship Long Running Task Event Success',
    OnRelationshipLongRunningTaskEventFailed = '[Request] On Relationship Long Running Task Event Failed',
}

export const onRelationshipLongRunningTaskEventCancelled = createAction(
    LongRunningTaskActions.OnRelationshipLongRunningTaskEventCancelled,
);

export const onRelationshipLongRunningTaskEvent = createAction(
    LongRunningTaskActions.OnRelationshipLongRunningTaskEvent,
    props<{
        relationshipId: number;
    }>(),
);

export const onRelationshipLongRunningTaskEventSuccess = createAction(
    LongRunningTaskActions.OnRelationshipLongRunningTaskEventSuccess,
    props<{ relationshipLongRunningTaskEvent: LongRunningTaskEvent }>(),
);

export const onRelationshipLongRunningTaskEventFailed = createAction(
    LongRunningTaskActions.OnRelationshipLongRunningTaskEventFailed,
    props<{ error: ApolloError }>(),
);
