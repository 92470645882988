import { PublicAssessment } from '@entities/assessment';
import { AuditReportTypeCode, PublicAuditReport } from '@entities/audit-report';
import { AssessmentRecommendation } from '@entities/recommendation';
import { RTPEvent, RTPEventType } from '@entities/rtp';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { selectRouteData, selectRouteParam } from '@shared/redux/router/router.selectors';
import {
    AdditionalAssessmentQuestionViewModel,
    AssessmentSubmissionContext,
    CollectionTypes,
    PublicAssessmentRTPFileArtifact,
    RoutingData,
} from '../../models';
import { assessmentCollectionTokenParamName } from '../../route-constants';
import { AppState, AssessmentCollectionState, assessmentCollectionStateKey } from '../assessment-collection.state';

export const getAssessmentCollectionState: MemoizedSelector<AppState, AssessmentCollectionState> =
    createFeatureSelector<AssessmentCollectionState>(assessmentCollectionStateKey);

export const getAssessmentToken = createSelector(
    selectRouteParam(assessmentCollectionTokenParamName),
    (assessmentToken) => assessmentToken!,
);

export const getPublicAssessment: MemoizedSelector<AppState, PublicAssessment> = createSelector(
    getAssessmentCollectionState,
    (state) => state.publicAssessment,
);

export const getAssessmentExpiration: MemoizedSelector<AppState, Date> = createSelector(
    getPublicAssessment,
    (assessment) => assessment?.expireDate,
);

export const getAssessmentCollectionType: MemoizedSelector<AppState, CollectionTypes> = createSelector<
    AppState,
    Partial<RoutingData>,
    CollectionTypes
>(selectRouteData, (routeData) => routeData?.collectionType!);

export const getSecret: MemoizedSelector<AppState, string> = createSelector(
    getAssessmentCollectionState,
    (state) => state.passcode,
);

export const getAssessmentRiskRecommendations: MemoizedSelector<AppState, AssessmentRecommendation[]> = createSelector(
    getAssessmentCollectionState,
    (state) => state.recommendations,
);

export const getAuditReports: MemoizedSelector<AppState, PublicAuditReport[]> = createSelector(
    getAssessmentCollectionState,
    (state) => state.auditReports,
);

export const getSortedRtpFileArtifacts: MemoizedSelector<AppState, PublicAssessmentRTPFileArtifact[]> = createSelector(
    getAssessmentCollectionState,
    (state) =>
        state.rtpFileArtifacts.sort((a, b) => Date.parse(a.artifact.createdDate) - Date.parse(b.artifact.createdDate)),
);

export const getSortedRtpFileArtifactsForInitialUploadScreen: MemoizedSelector<
    AppState,
    PublicAssessmentRTPFileArtifact[]
> = createSelector(getSortedRtpFileArtifacts, (rtpFileArtifacts) =>
    rtpFileArtifacts.filter(
        (a) =>
            // Hide from initial upload page. For now, just sub-processors. These will be shown elsewhere in the flow.
            a.artifact.validation?.auditReportType !== AuditReportTypeCode.SUBPROCESSORLIST,
    ),
);

export const getRtpEventsMap: MemoizedSelector<AppState, Map<number, Map<RTPEventType, RTPEvent>>> = createSelector(
    getAssessmentCollectionState,
    (state) => state.rtpEventsMap,
);

export const getAdditionalAssessmentQuestions: MemoizedSelector<AppState, AdditionalAssessmentQuestionViewModel[]> =
    createSelector(getAssessmentCollectionState, (state) =>
        (state.additionalQuestions ?? []).sort((a, b) => a.order - b.order),
    );

export const getIsSubmitted: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentCollectionState,
    (state) => state.submitted,
);

export const getIsFollowupRequested: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentCollectionState,
    (state) => state.followupRequested,
);

export const getSubmissionContext: MemoizedSelector<AppState, AssessmentSubmissionContext> = createSelector(
    getAssessmentCollectionState,
    (state) => state.submissionContext,
);

export const getExpiredArtifactIdsToReplace: MemoizedSelector<AppState, Map<number, number>> = createSelector(
    getAssessmentCollectionState,
    (state) => new Map(Object.entries(state.expiredArtifactIdsToReplace).map(([k, v]) => [+k, v])),
);

export const getActiveSupplementalQuestionnaires: MemoizedSelector<AppState, Map<string, string>> = createSelector(
    getAssessmentCollectionState,
    (state) =>
        new Map(Object.entries(state.publicAssessment?.supplementalQuestionnaires ?? []).map(([k, v]) => [k, v])),
);
