<div class="container-top-half">
    <div class="row">
        <div class="col-3 col-xl-4 fixed-height">
            <p>Additional questions ({{ questionIdx + 1 }} of {{ totalQuestions }})</p>
            <h1 class="assessment-collection-step-title mb-3">{{ additionalQuestion.title }}</h1>
            @if (!!additionalQuestion.subtitle?.length) {
                <p class="mb-3" [innerHtml]="additionalQuestion.subtitle | safeHtml"></p>
            }
            @if (!!additionalQuestion.expectedArtifactsLabels?.length) {
                <div class="mb-3">
                    <p class="text-bold">What artifacts are we looking for?</p>
                    <ul class="ps-4">
                        @for (expectedArtifact of additionalQuestion.expectedArtifactsLabels; track expectedArtifact) {
                            <li>{{ expectedArtifact }}</li>
                        }
                    </ul>
                </div>
            }
        </div>

        <div class="col-auto ps-0 pb-4">
            <mat-divider class="h-100" [vertical]="true"></mat-divider>
        </div>

        <div class="col p-3 fixed-height">
            <div class="row pb-5">
                <div class="col">
                    <div class="mb-3">
                        <strong> Upload an artifact... </strong>
                    </div>
                    @if (!!additionalQuestion.attestationArtifact) {
                        <app-artifact-list
                            [rtpFileArtifacts]="[additionalQuestion.attestationArtifact]"
                            (setPassword)="setPassword.emit($event)"
                            (removeArtifact)="removeArtifact.emit($event)"
                        />
                    } @else {
                        <app-upload-file
                            [multiple]="false"
                            (uploadFiles)="emitUpload(additionalQuestion.id, $event[0])"
                            (uploadFailed)="uploadFileFailed.emit($event)"
                        />
                    }
                    @if (classificationIsIncorrect(additionalQuestion.attestationArtifact)) {
                        <p class="text-error text-bold">
                            {{ getIncorrectClassificationLabel(additionalQuestion) }}
                        </p>
                    }
                </div>
            </div>
            <div class="row pb-4">
                <div class="col">
                    <strong [ngClass]="{ 'text-muted': !!additionalQuestion.attestationArtifact }">
                        ...or attest to one of the following:
                    </strong>
                    <mat-radio-group
                        [formControl]="currentAttestationValueControl"
                        [value]="additionalQuestion.attestationValue"
                        class="d-flex flex-column"
                        color="primary"
                        data-pw="attestationRadioButtonGroup"
                    >
                        @for (
                            attestationOption of getAttestationOptions(additionalQuestion);
                            track attestationOption.value
                        ) {
                            <mat-radio-button
                                class="mt-2"
                                [value]="attestationOption.value"
                                data-pw="dontHaveAttestationOption"
                            >
                                {{ attestationOption.label }}
                            </mat-radio-button>
                        }
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-end container-bottom-half">
    <button
        mat-stroked-button
        color="primary"
        class="me-auto"
        [disabled]="backDisabled(additionalQuestion)"
        (click)="goBackFn()"
    >
        Go back
    </button>
    <button
        mat-flat-button
        color="primary"
        [disabled]="continueDisabled(additionalQuestion)"
        (click)="continueFn()"
        data-pw="continueButton"
    >
        Continue
    </button>
</div>
