import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { ApolloError } from '@apollo/client';
import { of } from 'rxjs';
import { RelationshipService } from '../../../../entities/relationship';
import {
    onRelationshipLongRunningTaskEvent,
    onRelationshipLongRunningTaskEventCancelled,
    onRelationshipLongRunningTaskEventFailed,
    onRelationshipLongRunningTaskEventSuccess,
} from '../actions/long-running-task.actions';

@Injectable()
export class LongRunningTaskEffects {
    onRelationshipLongRunningTaskEvent = createEffect(() =>
        this._actions$.pipe(
            ofType(onRelationshipLongRunningTaskEvent),
            switchMap(({ relationshipId }) =>
                this._relationshipService.onRelationshipLongRunningTaskEvent(relationshipId).pipe(
                    map((response) =>
                        onRelationshipLongRunningTaskEventSuccess({
                            relationshipLongRunningTaskEvent: response,
                        }),
                    ),
                    takeUntil(this._actions$.pipe(ofType(onRelationshipLongRunningTaskEventCancelled))),
                    catchError((error: ApolloError) => of(onRelationshipLongRunningTaskEventFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _relationshipService: RelationshipService,
    ) {}
}
