import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { Actions, ofType } from '@ngrx/effects';
import {
    addDomainToVendor,
    deleteVendorDetailsTabRiskAdvisory,
    deleteVendorDetailsTabRiskAdvisoryRequestSuccess,
    getVendorDetailsRequest,
    getVendorDetailsStatsRequest,
    getVendorDetailsTabRiskAdvisoriesRequest,
    refreshVendorDetailsVendorDetailsTabRequest,
    refreshVendorDetailsVendorDetailsTabRequestFailed,
    refreshVendorDetailsVendorDetailsTabRequestSuccess,
    updateVendorDetailsRequest,
    updateVendorDetailsRequestFailed,
    updateVendorDetailsRequestSuccess,
} from '../redux/actions/vendor-details-tab.actions';
import { VisoUserRole } from '@entities/viso-user';
import {
    AdminUpdateVendorDetailsRequest,
    UpdateVendorDetailsRequest,
    VendorDetailsResponse,
} from '../models/vendor-details.model';
import { getUserAuthority } from '../../session/redux/session.selectors';
import {
    createVendorRiskAdvisoryRequestSuccess,
    updateVendorRiskAdvisoryRequestSuccess,
} from '@shared/vendor-components/vendor-risk-advisory-dialog/redux/actions/vendor-risk-advisory.actions';
import { NetworkExposureRiskAdvisory, RiskAdvisory } from '@entities/risk-advisory';
import { SortDirection } from '@angular/material/sort';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { ContextType } from '@entities/context-type';
import { DataType } from '@entities/data-type';
import { ControlDomainType } from '@entities/control-domain';
import { RelationshipVendorDetailsStatsResponse } from '@entities/relationship';
import { RiskAssessment } from '@entities/risk-assessment';
import {
    getRelationshipVendorDetails,
    getRelationshipVendorDetailsRiskAdvisories,
    getRelationshipVendorDetailsRiskAdvisoryTotalCount,
    getRelationshipVendorDetailsStats,
} from '../redux/request.selectors';
import { LongRunningTaskProjection } from '@entities/long-running-tasks/long-running-task.model';

@Component({
    selector: 'app-vendor-details-tab',
    templateUrl: './vendor-details-tab.component.html',
    styleUrls: ['./vendor-details-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorDetailsTabComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    vendorOrgId: number;

    @Input({ required: true })
    relationshipId: number;

    @Input({ required: true })
    latestRiskAssessment: RiskAssessment;

    @Input({ required: true })
    contextTypes: ContextType[];

    @Input({ required: true })
    dataTypes: DataType[];

    @Input({ required: true })
    disabledControlDomainTypes: ControlDomainType[];

    @Input({ required: true })
    inScopeControlDomainTypes: ControlDomainType[];

    @Input({ required: true })
    relationshipLongRunningTasks: LongRunningTaskProjection[];

    @Input({ required: true })
    set isTabSelected(value: boolean) {
        this._isTabSelected$.next(value);
    }

    get isTabSelected() {
        return this._isTabSelected$.value;
    }

    Roles = VisoUserRole;

    isCurrentUserAdmin$: Observable<boolean>;
    isCurrentUserSupportAuditAdmin$: Observable<boolean>;
    vendorDetails$: Observable<VendorDetailsResponse>;
    vendorDetailsStats$: Observable<RelationshipVendorDetailsStatsResponse>;
    riskAdvisories$: Observable<NetworkExposureRiskAdvisory[]>;
    totalRiskAdvisoryCount$: Observable<number>;

    isEditingDirectoryRecord: boolean = false;

    riskAdvisorySortByColumnName: string = 'publishedDate';
    riskAdvisorySortDirection: SortDirection = 'desc';
    riskAdvisoryCurrentPage: number = 0;
    riskAdvisoryItemsPerPage: number = 10;
    showRefreshVendorDetailsButton: boolean = false;
    refreshingVendorDetails: boolean = false;

    private _isTabSelected$ = new BehaviorSubject<boolean>(false);
    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.vendorDetails$ = this._store$.select(getRelationshipVendorDetails);
        this.vendorDetailsStats$ = this._store$.select(getRelationshipVendorDetailsStats);
        this.riskAdvisories$ = this._store$.select(getRelationshipVendorDetailsRiskAdvisories);
        this.totalRiskAdvisoryCount$ = this._store$.select(getRelationshipVendorDetailsRiskAdvisoryTotalCount);
        this.isCurrentUserAdmin$ = this._store$.select(getUserAuthority(VisoUserRole.Admin));
        this.isCurrentUserSupportAuditAdmin$ = this._store$.select(
            getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support, VisoUserRole.Admin]),
        );

        this.loadFeatureFlags();

        this._isTabSelected$
            .pipe(
                filter((isTabSelected) => isTabSelected),
                take(1),
            )
            .subscribe(() => {
                this.loadVendorDetails();
                this.loadRiskAdvisories();
            });

        this._actions$
            .pipe(
                ofType(
                    refreshVendorDetailsVendorDetailsTabRequestSuccess,
                    refreshVendorDetailsVendorDetailsTabRequestFailed,
                ),
                tap(() => {
                    this.refreshingVendorDetails = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(refreshVendorDetailsVendorDetailsTabRequest),
                tap(() => {
                    this.refreshingVendorDetails = true;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(
                    updateVendorDetailsRequestSuccess,
                    updateVendorDetailsRequestFailed,
                    refreshVendorDetailsVendorDetailsTabRequestSuccess,
                ),
                tap(() => {
                    this.loadVendorDetails();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(
                    createVendorRiskAdvisoryRequestSuccess,
                    updateVendorRiskAdvisoryRequestSuccess,
                    deleteVendorDetailsTabRiskAdvisoryRequestSuccess,
                ),
                tap(() => {
                    this.loadRiskAdvisories();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    toggleEdit() {
        this.isEditingDirectoryRecord = !this.isEditingDirectoryRecord;
    }

    addNewDomain(domain: string, vendorDetails: VendorDetailsResponse) {
        this._store$.dispatch(
            addDomainToVendor({
                vendorDetails,
                newDomain: domain,
            }),
        );
    }

    updateVendorDetails(
        request: AdminUpdateVendorDetailsRequest | UpdateVendorDetailsRequest,
        isCurrentUserAdmin: boolean,
    ) {
        this._store$.dispatch(
            updateVendorDetailsRequest({
                updateRequest: request,
                isCurrentUserAdmin,
            }),
        );
    }

    onRiskAdvisorySortChange(event: { column: string; direction: SortDirection }) {
        this.riskAdvisorySortByColumnName = event.column;
        this.riskAdvisorySortDirection = event.direction;
        this.loadRiskAdvisories();
    }

    onRiskAdvisoryPageChange(event: { page: number; pageSize: number }) {
        this.riskAdvisoryCurrentPage = event.page;
        this.riskAdvisoryItemsPerPage = event.pageSize;
        this.loadRiskAdvisories();
    }

    refreshVendorDetails(vendorDetails: VendorDetailsResponse) {
        this._store$.dispatch(
            refreshVendorDetailsVendorDetailsTabRequest({
                vendorId: vendorDetails.id,
                relationshipId: this.relationshipId,
            }),
        );
    }

    deleteRiskAdvisory(event: { riskAdvisory: RiskAdvisory }) {
        this._store$.dispatch(deleteVendorDetailsTabRiskAdvisory(event));
    }

    private sortRiskAdvisories(): string[] {
        const result = [this.riskAdvisorySortByColumnName + ',' + this.riskAdvisorySortDirection];
        if (this.riskAdvisorySortByColumnName !== 'id') {
            result.push('id');
        }
        return result;
    }

    private loadVendorDetails() {
        this._store$.dispatch(getVendorDetailsRequest({ orgId: this.vendorOrgId }));
        this._store$.dispatch(getVendorDetailsStatsRequest({ relationshipId: this.relationshipId }));
    }

    private loadRiskAdvisories() {
        this._store$.dispatch(
            getVendorDetailsTabRiskAdvisoriesRequest({
                params: {
                    page: this.riskAdvisoryCurrentPage,
                    size: this.riskAdvisoryItemsPerPage,
                    sort: this.sortRiskAdvisories(),
                },
                vendorId: this.vendorOrgId,
            }),
        );
    }

    private loadFeatureFlags(): void {
        this._featureFlagService.flagsLoaded.pipe(takeUntil(this._unsub$)).subscribe((flags) => {
            this.showRefreshVendorDetailsButton = flags[FeatureFlags.ZOOMINFO_ORG_ENRICH] ?? false;
        });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
