import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { VisoUserService } from '@entities/viso-user';
import { EventNotification } from '@entities/notification';
import {
    bulkArchiveRelationshipsRequest,
    bulkArchiveRelationshipsRequestAccepted,
    bulkArchiveRelationshipsRequestFailed,
    bulkArchiveRelationshipsRequestSuccess,
    assignRelationshipsToTierRequestSuccess,
    bulkCancelAssessmentsRequest,
    bulkCancelAssessmentsRequestAccepted,
    bulkCancelAssessmentsRequestFailed,
    bulkCancelAssessmentsRequestSuccess,
    bulkDisableArtifactUpdatesRequest,
    bulkDisableArtifactUpdatesRequestSuccess,
    bulkEnableArtifactUpdatesRequest,
    bulkEnableArtifactUpdatesRequestSuccess,
    getAllRelationshipIdsQueryFailed,
    getAllRelationshipIdsQueryRequest,
    getAllRelationshipIdsQuerySuccess,
    getRelationshipCountsRequest,
    getRelationshipCountsRequestFailed,
    getRelationshipCountsRequestSuccess,
    getRiskNetworkGraphRequest,
    getRiskNetworkGraphRequestFailed,
    getRiskNetworkGraphRequestSuccess,
    getVendorByIdRequest,
    getVendorByIdRequestFailed,
    getVendorByIdRequestSuccess,
    bulkOffboardRelationshipsRequest,
    bulkOffboardRelationshipsRequestAccepted,
    bulkOffboardRelationshipsRequestFailed,
    bulkOffboardRelationshipsRequestSuccess,
    orgClientsQueryRequest,
    orgClientsQuerySuccess,
    queryRelationshipsForCsvRequest,
    queryRelationshipsForCsvRequestFailed,
    queryRelationshipsForCsvRequestSuccess,
    relationshipsQueryFailed,
    relationshipsQueryRequest,
    relationshipsQuerySuccess,
    restoreRelationshipRequest,
    restoreRelationshipRequestFailed,
    restoreRelationshipRequestSuccess,
    bulkStartAssessmentsRequest,
    bulkStartAssessmentsRequestAccepted,
    bulkStartAssessmentsRequestFailed,
    bulkStartAssessmentsRequestSuccess,
    visoUsersQueryRequest,
    visoUsersQuerySuccess,
    bulkDisableArtifactUpdatesRequestFailed,
    bulkEnableArtifactUpdatesRequestFailed,
} from '../relationships.actions';
import { EMPTY, of } from 'rxjs';
import { catchError, distinctUntilChanged, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { VendorRelationshipsService } from '../../relationships.service';
import { OrgService } from '@entities/org';
import { RelationshipService } from '@entities/relationship';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { NgbModalWrapperService } from '../../../../shared';
import { WelcomeMessageComponent } from '../../welcome-message/welcome-message.component';
import { AssessmentWelcomeMessageComponent } from '../../assessment-welcome-message/assessment-welcome-message.component';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { HttpStatusCode } from '@angular/common/http';
import { getNewNotificationsSubscriptionSuccess } from 'src/main/webapp/app/layout/redux/layout.actions';
import { EventNotificationType } from '@entities/notification';
import { LongRunningTaskActions } from '@shared/long-running-task/redux/long-running-task.actions';

@Injectable()
export class RelationshipsEffects {
    /**
     * Relationships Query Request Effect
     * Triggers when `relationshipsQueryRequest` gets dispatched
     * Executes `RelationshipsService.query()` to retrieve the results
     */
    relationshipsQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(relationshipsQueryRequest),
            switchMap((action) =>
                this._vendorRelationshipService.query(action.params).pipe(
                    map((response) =>
                        relationshipsQuerySuccess({
                            results: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError(() => of(relationshipsQueryFailed())),
                ),
            ),
        ),
    );

    /**
     * Convert to Csv Request Effect
     * Triggers when `convertToCsvRequest` gets dispatched
     * Executes `RelationshipsService.query()` to retrieve results
     */
    convertToCsvRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(queryRelationshipsForCsvRequest),
            switchMap((action) =>
                this._vendorRelationshipService.query(action.params).pipe(
                    map((response) => queryRelationshipsForCsvRequestSuccess({ results: response.body })),
                    catchError(() => of(queryRelationshipsForCsvRequestFailed())),
                ),
            ),
        ),
    );

    /**
     * Viso Users Query Request Effect
     * Triggers when `visoUsersQueryRequest` gets dispatched
     * Executes `VisoUserService.query()` to retrieve the results
     */
    visoUsersQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(visoUsersQueryRequest),
            switchMap(() =>
                this._visoUserService.findAllForOrg().pipe(
                    map((response) => visoUsersQuerySuccess({ results: response.body })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    /**
     * Org Clients Query Request Effect
     * Triggers when `orgClientsQueryRequest` gets dispatched
     * Executes `_orgService.clients()` to retrieve the results
     */
    orgClientsQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(orgClientsQueryRequest),
            switchMap(() =>
                this._orgService.clients().pipe(
                    map((response) => orgClientsQuerySuccess({ results: response.body })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    bulkStartAssessmentsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkStartAssessmentsRequest),
            map(({ ids, assessmentsInProgress, countToEagerlyProcess }) => ({
                relationshipIds: ids,
                showInProgressConfirmation: ids.length > countToEagerlyProcess ? false : assessmentsInProgress,
                countToEagerlyProcess,
            })),
            exhaustMap(({ relationshipIds, showInProgressConfirmation, countToEagerlyProcess }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: `Start assessments for ${relationshipIds.length} relationship(s)`,
                        message:
                            'Please note that depending on the number of selected relationship(s), assessment completion times may vary.',
                        confirmLabel: 'Start assessments',
                    })
                    .pipe(
                        filter(Boolean),
                        switchMap(() => {
                            return showInProgressConfirmation
                                ? this._matConfirmDialogService.confirm({
                                      title: 'Assessments in progress',
                                      message:
                                          'One or more assessments are already in progress. Do you want to cancel them and start new ones?',
                                      confirmLabel: 'Start assessments',
                                  })
                                : of(true);
                        }),
                        filter(Boolean),
                        switchMap(() =>
                            this._relationshipService.bulkStartAssessments(relationshipIds, countToEagerlyProcess).pipe(
                                map((response) =>
                                    response.status === HttpStatusCode.Accepted
                                        ? bulkStartAssessmentsRequestAccepted({
                                              ids: relationshipIds,
                                              response: response.body,
                                          })
                                        : bulkStartAssessmentsRequestSuccess({
                                              ids: relationshipIds,
                                              response: response.body,
                                          }),
                                ),
                                catchError(() => of(bulkStartAssessmentsRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    sendAssessmentsForRelationshipsSuccessOrAccepted$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkStartAssessmentsRequestSuccess, bulkStartAssessmentsRequestAccepted),
                map((action) => {
                    const response = action.response;

                    let message = response.started?.length
                        ? `${response.started?.length} assessment(s) successfully started.`
                        : '';

                    if (response.totalFailureCount > 0) {
                        message.length ? (message += '<br>') : '';
                        message += `${response.totalFailureCount} assessment(s) could not be started:`;
                    }

                    if (response.licenseLimitReached?.length) {
                        message += `<br> - ${response.licenseLimitReached.length} exceeded available assessments.`;
                    }

                    if (response.missing3PContact?.length) {
                        message += `<br> - ${response.missing3PContact.length} missing third party.`;
                    }

                    if (response.noContextTypes?.length) {
                        message += `<br> - ${response.noContextTypes.length} missing business case.`;
                    }

                    if (response.noDataTypes?.length) {
                        message += `<br> - ${response.noDataTypes.length} missing data types.`;
                    }

                    if (action.type === bulkStartAssessmentsRequestAccepted.type) {
                        message +=
                            '<br><br>We will continue processing the remaining assessments in the background. This may take a while, but you will receive a notification when the process has been completed.';
                    }

                    return message;
                }),
                tap((message) => this._snackbarService.success(message, { config: { duration: 10000 } })),
            ),
        { dispatch: false },
    );

    bulkCancelAssessmentsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkCancelAssessmentsRequest),
            exhaustMap(({ ids, showConfirmationModal, countToEagerlyProcess }) => {
                let obs = of(true);
                if (showConfirmationModal) {
                    obs = this._matConfirmDialogService
                        .confirm({
                            title: `Cancel assessments for ${ids.length} relationship(s)`,
                            message: 'Are you sure you want to cancel assessments for the selected relationship(s)?',
                            confirmLabel: 'Cancel assessments',
                        })
                        .pipe(filter((result) => result));
                }
                return obs.pipe(
                    switchMap(() =>
                        this._relationshipService.bulkCancelAssessments(ids, countToEagerlyProcess).pipe(
                            map((response) =>
                                response.status === HttpStatusCode.Accepted
                                    ? bulkCancelAssessmentsRequestAccepted({
                                          ids,
                                          response: response.body,
                                      })
                                    : bulkCancelAssessmentsRequestSuccess({
                                          ids,
                                          response: response.body,
                                      }),
                            ),
                            catchError(() => of(bulkCancelAssessmentsRequestFailed())),
                        ),
                    ),
                );
            }),
        ),
    );

    cancelAssessmentsForRelationshipsRequestSuccessOrAccepted$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkCancelAssessmentsRequestSuccess, bulkCancelAssessmentsRequestAccepted),
                map((action) => {
                    const response = action.response;

                    let message = response.cancelled?.length
                        ? `${response.cancelled?.length} assessment(s) successfully cancelled.`
                        : '';

                    if (response.totalFailureCount > 0) {
                        message.length ? (message += '<br>') : '';
                        message += `${response.totalFailureCount} assessment(s) could not be cancelled.`;
                    }

                    if (action.type === bulkCancelAssessmentsRequestAccepted.type) {
                        message +=
                            '<br><br>We will continue processing the remaining assessments in the background. This may take a while, but you will receive a notification when the process has been completed.';
                    }

                    return message;
                }),
                tap((message) => {
                    this._snackbarService.success(message, { config: { duration: 10000 } });
                }),
            ),
        { dispatch: false },
    );

    bulkDisableArtifactUpdatesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkDisableArtifactUpdatesRequest),
            exhaustMap(({ ids }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: `Disable artifact updates for ${ids.length} relationship(s)`,
                        message: 'Are you sure you want to disable artifact updates for the selected relationship(s)?',
                        confirmLabel: 'Disable artifact updates',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._relationshipService.bulkDisableArtifactUpdates(ids).pipe(
                                map((response) =>
                                    bulkDisableArtifactUpdatesRequestSuccess({
                                        ids,
                                        response,
                                    }),
                                ),
                                catchError(() => of(bulkDisableArtifactUpdatesRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    bulkDisableArtifactUpdatesRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkDisableArtifactUpdatesRequestSuccess),
                map(({ response }) => {
                    let message = response.updated?.length
                        ? `Artifact updates disabled for ${response.updated?.length} relationship(s).`
                        : '';
                    if (response.totalFailureCount > 0) {
                        message.length ? (message += '<br>') : '';
                        message += `Artifact updates could not disabled for ${response.totalFailureCount} relationship(s).`;
                    }
                    return message;
                }),
                tap((message) => {
                    this._snackbarService.success(message);
                }),
            ),
        { dispatch: false },
    );

    bulkEnableArtifactUpdatesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkEnableArtifactUpdatesRequest),
            exhaustMap(({ ids }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: `Enable artifact updates for ${ids.length} relationship(s)`,
                        message: 'Are you sure you want to enable artifact updates for the selected relationship(s)?',
                        confirmLabel: 'Enable artifact updates',
                    })
                    .pipe(filter((result) => result))
                    .pipe(
                        switchMap(() =>
                            this._relationshipService.bulkEnableArtifactUpdates(ids).pipe(
                                map((response) =>
                                    bulkEnableArtifactUpdatesRequestSuccess({
                                        ids,
                                        response,
                                    }),
                                ),
                                catchError(() => of(bulkEnableArtifactUpdatesRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    enableArtifactUpdatesForRelationshipsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkEnableArtifactUpdatesRequestSuccess),
                map(({ response }) => {
                    let message = response.updated?.length
                        ? `Artifact updates enabled for ${response.updated?.length} relationship(s).`
                        : '';
                    if (response.totalFailureCount > 0) {
                        message.length ? (message += '<br>') : '';
                        message += `Artifact updates could not enabled for ${response.totalFailureCount} relationship(s).`;
                    }
                    return message;
                }),
                tap((message) => {
                    this._snackbarService.success(message);
                }),
            ),
        { dispatch: false },
    );

    openWelcomeMessageModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter((route) => !!route && route.routeConfig.path === 'new-user'),
                tap(() => {
                    this._modalService.open<WelcomeMessageComponent>(
                        WelcomeMessageComponent,
                        {},
                        {
                            backdrop: 'static',
                            size: 'md',
                            keyboard: false,
                        },
                    );
                }),
            ),
        { dispatch: false },
    );

    openInitialAssessmentMessageModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter((route) => !!route && route.routeConfig.path === 'first-assessment-created'),
                tap(() => {
                    this._modalService.open<AssessmentWelcomeMessageComponent>(
                        AssessmentWelcomeMessageComponent,
                        {},
                        { size: 'md' },
                    );
                }),
            ),
        { dispatch: false },
    );

    getRiskNetworkGraphRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRiskNetworkGraphRequest),
            switchMap(({ searchString, networkExposureOrgId }) =>
                this._vendorRelationshipService.queryRiskNetworkGraph(searchString, networkExposureOrgId).pipe(
                    map((riskNetworkGraphNodes) => getRiskNetworkGraphRequestSuccess({ riskNetworkGraphNodes })),
                    catchError(() => of(getRiskNetworkGraphRequestFailed())),
                ),
            ),
        ),
    );

    getVendorByIdRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getVendorByIdRequest),
            switchMap(({ vendorId }) =>
                this._orgService.findVendorById(vendorId).pipe(
                    map((vendor) => getVendorByIdRequestSuccess({ vendor })),
                    catchError(() => of(getVendorByIdRequestFailed())),
                ),
            ),
        ),
    );

    getRelationshipCounts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipCountsRequest),
            switchMap(() =>
                this._vendorRelationshipService.getRelationshipCounts().pipe(
                    map((relationshipCounts) => getRelationshipCountsRequestSuccess({ relationshipCounts })),
                    catchError(() => of(getRelationshipCountsRequestFailed())),
                ),
            ),
        ),
    );

    refreshRelationshipCounts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                assignRelationshipsToTierRequestSuccess,
                bulkArchiveRelationshipsRequestSuccess,
                bulkArchiveRelationshipsRequestAccepted,
                restoreRelationshipRequestSuccess,
            ),
            map(() => getRelationshipCountsRequest()),
        ),
    );

    bulkArchiveRelationshipsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkArchiveRelationshipsRequest),
            switchMap(({ relationshipIds, countToEagerlyProcess }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: `Archive ${relationshipIds.length} relationship(s)`,
                        message: 'Are you sure you want to archive the selected relationship(s)?',
                        confirmLabel: 'Archive',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._relationshipService.bulkArchive(relationshipIds, countToEagerlyProcess).pipe(
                                map((response) =>
                                    response.status === HttpStatusCode.Accepted
                                        ? bulkArchiveRelationshipsRequestAccepted({ response: response.body })
                                        : bulkArchiveRelationshipsRequestSuccess({ response: response.body }),
                                ),
                                catchError(() => of(bulkArchiveRelationshipsRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    bulkArchiveRelationshipsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkArchiveRelationshipsRequestSuccess, bulkArchiveRelationshipsRequestAccepted),
                map((action) => {
                    const response = action.response;

                    let message = response.updated?.length
                        ? `${response.updated?.length} relationships(s) successfully archived.`
                        : '';

                    if (response.totalFailureCount > 0) {
                        message.length ? (message += '<br>') : '';
                        message += `${response.totalFailureCount} relationship(s) could not be archived.`;
                    }

                    if (action.type === bulkArchiveRelationshipsRequestAccepted.type) {
                        message +=
                            '<br><br>We will continue processing the remaining relationships in the background. This may take a while, but you will receive a notification when the process has been completed.';
                    }

                    return message;
                }),
                tap((message) => {
                    this._snackbarService.success(message, { config: { duration: 10000 } });
                }),
            ),
        { dispatch: false },
    );

    bulkArchiveRelationshipsRequestFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkArchiveRelationshipsRequestFailed),
                tap(() => this._snackbarService.error('Archiving relationships failed')),
            ),
        { dispatch: false },
    );

    restoreRelationshipRequest$ = createEffect(
        () => () =>
            this._actions$.pipe(
                ofType(restoreRelationshipRequest),
                exhaustMap(({ relationshipId }) =>
                    this._relationshipService.offboard(relationshipId).pipe(
                        tap((response) =>
                            this._snackbarService.success(
                                `Relationship with ${response.vendorName} successfully restored.`,
                            ),
                        ),
                        map(() => restoreRelationshipRequestSuccess()),
                        catchError(() => of(restoreRelationshipRequestFailed())),
                    ),
                ),
            ),
    );

    offboardRelationshipsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkOffboardRelationshipsRequest),
            exhaustMap(({ relationshipIds, countToEagerlyProcess }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: `Offboard ${relationshipIds.length} relationship(s)`,
                        message: 'Are you sure you want to offboard the selected relationship(s)?',
                        confirmLabel: 'Offboard',
                    })
                    .pipe(
                        filter(Boolean),
                        switchMap(() =>
                            this._relationshipService.bulkOffboard(relationshipIds, countToEagerlyProcess).pipe(
                                map((response) =>
                                    response.status === HttpStatusCode.Accepted
                                        ? bulkOffboardRelationshipsRequestAccepted({ response: response.body })
                                        : bulkOffboardRelationshipsRequestSuccess({ response: response.body }),
                                ),
                                catchError(() => of(bulkOffboardRelationshipsRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    offboardRelationshipsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkOffboardRelationshipsRequestSuccess, bulkOffboardRelationshipsRequestAccepted),
                map((action) => {
                    const response = action.response;

                    let message = response.updated?.length
                        ? `${response.updated?.length} relationships(s) successfully marked not as onboarded.`
                        : '';

                    if (response.totalFailureCount > 0) {
                        message.length ? (message += '<br>') : '';
                        message += `${response.totalFailureCount} relationship(s) could not be marked as not onboarded.`;
                    }

                    if (action.type === bulkOffboardRelationshipsRequestAccepted.type) {
                        message +=
                            '<br><br>We will continue processing the remaining relationships in the background. This may take a while, but you will receive a notification when the process has been completed.';
                    }

                    return message;
                }),
                tap((message) => {
                    this._snackbarService.success(message, { config: { duration: 10000 } });
                }),
            ),
        { dispatch: false },
    );

    offboardRelationshipsRequestFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(bulkOffboardRelationshipsRequestFailed),
                tap(() => this._snackbarService.error('Failed to offboard the selected relationship(s).')),
            ),
        { dispatch: false },
    );

    getAllRelationshipIdsQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getAllRelationshipIdsQueryRequest),
            exhaustMap(({ search }) =>
                this._vendorRelationshipService.getAllRelationshipIds(search).pipe(
                    map((relationshipIds) => getAllRelationshipIdsQuerySuccess({ relationshipIds })),
                    catchError(() => of(getAllRelationshipIdsQueryFailed())),
                ),
            ),
        ),
    );

    getInProgressLongRunningTasksOnBulkRequestAccepted$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                bulkStartAssessmentsRequestAccepted,
                bulkCancelAssessmentsRequestAccepted,
                bulkArchiveRelationshipsRequestAccepted,
                bulkOffboardRelationshipsRequestAccepted,
            ),
            map(() => LongRunningTaskActions.getInProgressLongRunningTasksRequest()),
        ),
    );

    getInProgressLongRunningTasksOnBulkNotification$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getNewNotificationsSubscriptionSuccess),
            map(({ newNotification }) => (newNotification as EventNotification).eventNotificationType),
            filter(
                (eventNotificationType) =>
                    !!eventNotificationType &&
                    [
                        EventNotificationType.BULK_RELATIONSHIP_ASSESSMENTS_CREATED,
                        EventNotificationType.BULK_RELATIONSHIP_ASSESSMENTS_CANCELLED,
                        EventNotificationType.BULK_RELATIONSHIP_MARKED_NOT_ONBOARDED,
                        EventNotificationType.BULK_RELATIONSHIP_ARCHIVED,
                    ].includes(eventNotificationType),
            ),
            map(() => LongRunningTaskActions.getInProgressLongRunningTasksRequest()),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _vendorRelationshipService: VendorRelationshipsService,
        private _visoUserService: VisoUserService,
        private _snackbarService: SnackbarService,
        private _orgService: OrgService,
        private _relationshipService: RelationshipService,
        private _modalService: NgbModalWrapperService,
        private _matConfirmDialogService: MatConfirmDialogService,
    ) {}
}
