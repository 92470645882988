<div class="row justify-content-center" *ngIf="{ branding: (branding$ | async) } as b">
    <div
        class="col-auto"
        *ngIf="{ name: b.branding?.orgName, logo: b.branding?.logoUrl, showLogo: b.branding?.showLogo } as ctx"
    >
        <mat-card appearance="outlined" class="py-4">
            <mat-card-header class="d-block text-center">
                <img class="branded-logo mb-4" [src]="ctx.logo" *ngIf="ctx.logo; else name" />
                <ng-template #name>
                    <p class="assessment-collection-step-title">Welcome to VISO TRUST</p>
                </ng-template>
            </mat-card-header>
            <mat-card-content>
                <form
                    [formGroup]="assessmentAuthenticationFormGroup"
                    (submit)="submitPasscode()"
                    class="d-flex flex-column align-items-center"
                >
                    <p class="mb-4">
                        @if (ctx.logo) {
                            {{ ctx.name }} is using VISO TRUST to conduct a security assessment.<br />
                            Please enter the passcode to begin the assessment.
                        } @else {
                            We are working to review your security posture with minimal effort on your end.
                        }
                    </p>
                    <div class="d-flex w-50 mb-4">
                        <mat-form-field class="dense-3 flex-grow-1 me-3">
                            <mat-label>Passcode</mat-label>
                            <input
                                matInput
                                type="password"
                                formControlName="passcode"
                                data-pw="passcodeInput"
                                autofocus
                            />
                            <mat-error *ngFor="let error of errors | keyvalue" class="text-center">
                                {{ error.key | callback: getErrorMessage }}
                            </mat-error>
                        </mat-form-field>
                        <button
                            class="flex-grow-0 mt-1"
                            type="submit"
                            mat-flat-button
                            color="primary"
                            data-pw="submitPasscodeButton"
                        >
                            Submit
                        </button>
                    </div>
                </form>
                <div
                    class="powered-by pt-xl-4 mb-5 d-flex justify-content-center align-items-center"
                    *ngIf="!ctx.showLogo"
                >
                    Powered by
                    <a [externalLink]="STATIC_SITE_URL" target="_blank">
                        <img class="mx-2" [ngSrc]="VISO_LOGO_URL" width="150" height="30" alt="VISO TRUST logo" />
                    </a>
                </div>
                <p class="text-muted mx-auto w-75 text-center">
                    By submitting your data to VISO TRUST or engaging with our platform, you affirm that you've reviewed
                    and consent to abide by
                    <a [externalLink]="VENDOR_AGREEMENT_URL" class="mat-text-primary" target="_blank"
                        >the VISO TRUST Agreement</a
                    >.
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
