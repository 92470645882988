import { FileArtifactType } from '@entities/artifact';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import {
    AttestationsGroups,
    ExpiredDocumentQuestion,
    isCyberInsuranceQuestion,
    isExpiredDocumentationQuestion,
    isPrivacyQuestion,
    isSiblingDocumentationQuestion,
    isThirdPartyAuditQuestion,
    isThirdPartyPenTestQuestion,
    SiblingDocumentQuestion,
    SubprocessorsQuestion,
} from '../../models';
import { AppState } from '../assessment-collection.state';
import { getAdditionalAssessmentQuestions } from './main.selectors';
import * as ControlDomainSelectors from './control-domain.selectors';
import { AttestationType } from '@entities/attestation';

export const getAreThereAdditionalQuestions: MemoizedSelector<AppState, boolean> = createSelector(
    getAdditionalAssessmentQuestions,
    (additionalQuestions) => !!additionalQuestions?.length,
);

export const getExpiredDocumentQuestions: MemoizedSelector<AppState, ExpiredDocumentQuestion[]> = createSelector(
    getAdditionalAssessmentQuestions,
    (additionalQuestions) => additionalQuestions.filter(isExpiredDocumentationQuestion),
);

export const getSiblingDocumentationQuestions: MemoizedSelector<AppState, SiblingDocumentQuestion[]> = createSelector(
    getAdditionalAssessmentQuestions,
    (additionalQuestions) => additionalQuestions.filter(isSiblingDocumentationQuestion),
);

export const getAttestationGroupsFromRecommendations: MemoizedSelector<AppState, AttestationsGroups> = createSelector(
    getAdditionalAssessmentQuestions,
    (recommendations) => {
        const attestationGroups = recommendations
            .filter((rec) => !!rec.attestationValue)
            .reduce(
                (groups, recommendation) => {
                    if (isSiblingDocumentationQuestion(recommendation)) {
                        groups.siblingArtifactAttestations[recommendation.artifactId] = recommendation.attestationValue;
                    } else if (isExpiredDocumentationQuestion(recommendation)) {
                        groups.expiredArtifactAttestations[recommendation.artifactId] = recommendation.attestationValue;
                    } else {
                        groups.attestations[recommendation.attestationType] = recommendation.attestationValue;
                    }
                    return groups;
                },
                {
                    attestations: {},
                    siblingArtifactAttestations: {},
                    expiredArtifactAttestations: {},
                } as AttestationsGroups,
            );

        return attestationGroups;
    },
);

export const getUploadedStatusesOfInScopeFileTypes: MemoizedSelector<
    AppState,
    Partial<Record<FileArtifactType, boolean>>
> = createSelector(
    getAdditionalAssessmentQuestions,
    ControlDomainSelectors.getIsPrivacyInScope,
    ControlDomainSelectors.getIsPenTestInScope,
    ControlDomainSelectors.getIsCyberInsuranceInScope,
    (additionalQuestions, privacyInScope, penTestInScope, cyberInsuranceInScope) => {
        const result: Partial<Record<FileArtifactType, boolean>> = {
            AUDIT_REPORT: true, // audit report always in scope.
        };

        if (privacyInScope) {
            result.PRIVACY = true;
        }
        if (penTestInScope) {
            result.ASSESSMENT = true;
        }
        if (cyberInsuranceInScope) {
            result.CYBER_INSURANCE = true;
        }

        // Area is satisfied if no additional question exists OR the additional question has an artifact uploaded for it.
        for (const additionalQuestion of additionalQuestions) {
            if (isThirdPartyAuditQuestion(additionalQuestion)) {
                result.AUDIT_REPORT = !!additionalQuestion.attestationArtifact;
            } else if (isPrivacyQuestion(additionalQuestion)) {
                result.PRIVACY = !!additionalQuestion.attestationArtifact;
            } else if (isThirdPartyPenTestQuestion(additionalQuestion)) {
                result.ASSESSMENT = !!additionalQuestion.attestationArtifact;
            } else if (isCyberInsuranceQuestion(additionalQuestion)) {
                result.CYBER_INSURANCE = !!additionalQuestion.attestationArtifact;
            }
        }

        return result;
    },
);

export const getSubprocessorsQuestion = createSelector(getAdditionalAssessmentQuestions, (additionalQuestions) =>
    additionalQuestions.find((q): q is SubprocessorsQuestion => q.attestationType === AttestationType.SUBPROCESSORS),
);
