import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../assessment-collection.state';
import { getAssessmentCollectionState, getPublicAssessment } from './main.selectors';
import { ControlDomainType } from '@entities/control-domain';

export const getFollowupControlDomainIds: MemoizedSelector<AppState, number[]> = createSelector(
    getAssessmentCollectionState,
    (state) => state.followupControlDomainIds,
);

export const getRelevantControlDomainIds: MemoizedSelector<AppState, number[]> = createSelector(
    getAssessmentCollectionState,
    (state) => state.inScopeControlDomains.map((cd) => cd.id),
);

export const getIsPenTestInScope: MemoizedSelector<AppState, boolean> = createSelector(
    getPublicAssessment,
    (publicAssessment) =>
        Object.values(publicAssessment?.inScopeControlDomains || {}).some(
            (controlDomainName) => controlDomainName === 'Penetration Testing',
        ),
);

export const getIsPrivacyInScope: MemoizedSelector<AppState, boolean> = createSelector(
    getPublicAssessment,
    (publicAssessment) =>
        publicAssessment?.inScopeControlDomainTypes.some(
            (controlDomainType) => controlDomainType === ControlDomainType.PRIVACY,
        ),
);

export const getIsCyberInsuranceInScope: MemoizedSelector<AppState, boolean> = createSelector(
    getPublicAssessment,
    (publicAssessment) =>
        publicAssessment?.inScopeControlDomainTypes.some(
            (controlDomainType) => controlDomainType === ControlDomainType.CYBER_INSURANCE,
        ),
);
