<table class="bg-transparent" mat-table [dataSource]="dataSource" [trackBy]="trackByFn">
    <ng-container matColumnDef="company">
        <th class="fw-bold" mat-header-cell *matHeaderCellDef>Company</th>
        <td mat-cell *matCellDef="let row; let rowIdx = index">
            @if (row.controls.editing.value) {
                <mat-vendor-search
                    [formControl]="row.controls.vendorOrg"
                    [searchResults]="rowIdx === currentIndexSearchedFor() ? vendorSearchResults() : []"
                    (onSearch)="searchVendorForIndex(rowIdx, $event)"
                    placeholder="Company"
                    aria-label="Sub-processor company name"
                />
            } @else {
                <span>{{ row.controls.vendorOrg.value | callback: getVendorNameOrObject }}</span>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="purpose">
        <th class="fw-bold" mat-header-cell *matHeaderCellDef>Purpose</th>
        <td mat-cell *matCellDef="let row">
            @if (row.controls.editing.value) {
                <mat-form-field class="my-2 w-100">
                    <input
                        matInput
                        type="text"
                        aria-label="Sub-processor purpose"
                        placeholder="Purpose"
                        [formControl]="row.controls.purpose"
                    />
                </mat-form-field>
            } @else {
                <span>{{ row.controls.purpose.value }}</span>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="location">
        <th class="fw-bold" mat-header-cell *matHeaderCellDef>Location of processing</th>
        <td mat-cell *matCellDef="let row">
            @if (row.controls.editing.value) {
                <mat-form-field class="my-2 w-100">
                    <input
                        matInput
                        type="text"
                        aria-label="Sub-processor location of processing"
                        placeholder="Location"
                        [formControl]="row.controls.location"
                    />
                </mat-form-field>
            } @else {
                <span>{{ row.controls.location.value }}</span>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let row; let rowIdx = index">
            @if (row.controls.editing.value) {
                <button
                    mat-icon-button
                    color="primary"
                    aria-label="save"
                    (click)="saveRow(row)"
                    [disabled]="!row.valid"
                >
                    <mat-icon>check</mat-icon>
                </button>
                @if (showCancelEditButton) {
                    <button mat-icon-button aria-label="save" (click)="cancelEditMode(rowIdx)">
                        <mat-icon>close</mat-icon>
                    </button>
                }
            } @else {
                <button mat-icon-button aria-label="edit" [matMenuTriggerFor]="menu" [disabled]="currentlyEditingRow">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item type="button" (click)="toggleEditMode(row)">Edit</button>
                    <button mat-menu-item type="button" color="warning" (click)="deleteRow(rowIdx)">Delete</button>
                </mat-menu>
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <mat-divider />
</table>

<div class="d-flex w-100 justify-content-between align-items-center">
    @if (!readonly()) {
        <button class="my-2" mat-button color="primary" (click)="addEmptyRow()" [disabled]="currentlyEditingRow">
            <mat-icon>add</mat-icon>
            Add another company
        </button>
    }
</div>
