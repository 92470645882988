import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import {
    ArtifactFilePageLink,
    ArtifactType,
    ControlValidationDetectionType,
    PageRangeLink,
} from '../../../../../entities/artifact';
import { Store } from '@ngrx/store';
import { openArtifactFile } from '../../../../../routes/request/redux/actions/artifacts.actions';
import { Detection } from '@shared/artifact-intelligence/models/detection.model';
import moment from 'moment';
import { AuditReportTypeCode } from '@entities/audit-report';

export enum ArtifactPageLinkViewType {
    locationLink,
    artifactLink,
}

@Component({
    selector: 'app-artifact-page-link',
    templateUrl: './artifact-page-link.component.html',
    styleUrls: ['./artifact-page-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactPageLinkComponent {
    @Input() detection: Detection;
    @Input() viewType: ArtifactPageLinkViewType;

    /* Only for locationLink ViewType */
    @Input() viewerPage?: ArtifactFilePageLink | any;
    @Input() isLast?: boolean;

    ArtifactPageLinkViewType = ArtifactPageLinkViewType;
    ArtifactType = ArtifactType;
    ControlValidationDetectionType = ControlValidationDetectionType;

    private _store$: Store = inject(Store);
    private isoAuditReportTypes: AuditReportTypeCode[] = [
        AuditReportTypeCode.ISOIEC2700127002,
        AuditReportTypeCode.ISO27001CERTONLY,
    ];

    openArtifactPage(): void {
        const artifact = {
            artifactId: this.detection.artifactId,
            artifactName: this.detection.artifactName,
            selectedDetectionId: this.detection.id,
            viewerPage: this.viewerPage,
        };
        this._store$.dispatch(openArtifactFile(artifact));
    }

    getPageType(page: ArtifactFilePageLink) {
        if (page && (page as PageRangeLink).initialPage) {
            return 'range';
        }
        if (!isNaN(page as number)) {
            return 'page';
        }
        return 'text';
    }

    get artifactTooltip(): string {
        const { artifactExpirationDate, artifactValidationExpired, artifactValidationAuditReportType } = this.detection;
        if (!artifactExpirationDate) {
            return 'Does not expire';
        }
        if (artifactValidationExpired) {
            if (this.isoAuditReportTypes.includes(artifactValidationAuditReportType as AuditReportTypeCode)) {
                return 'This artifact is subject to an annual surveillance audit so this date may be different than the ISO Recertification date';
            } else {
                return 'Expired on ' + moment(artifactExpirationDate).format('MMM DD, YYYY');
            }
        } else {
            return 'Expiring on ' + moment(artifactExpirationDate).format('MMM DD, YYYY');
        }
    }
}
