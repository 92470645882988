<app-custom-stepper *ngIf="!!additionalQuestions.length">
    @for (additionalQuestion of additionalQuestions; track additionalQuestion.id; let i = $index) {
        <cdk-step [id]="i">
            <!-- Any other special additional information steps go here. -->
            @if (isSubprocessorQuestion(additionalQuestion)) {
                <app-subprocessors-question
                    [clientName]="clientName"
                    [subprocessorQuestion]="additionalQuestion"
                    [subprocessorsUrlArtifact]="subprocessorsUrlArtifact"
                    [subprocessorDetections]="subprocessorDetections"
                    [questionIdx]="i"
                    [totalQuestions]="additionalQuestions.length"
                    (goBack)="goBack()"
                    (continue)="continue()"
                    (uploadFile)="uploadSubprocessorsFile.emit($event)"
                    (uploadFileFailed)="uploadFileFailed.emit($event)"
                    (removeFileArtifact)="removeFileArtifact.emit($event)"
                    (removeUrlArtifact)="removeUrlArtifact.emit($event)"
                    (updateQuestion)="updateQuestion.emit($event)"
                    (updateDetections)="updateSubprocessorDetections.emit($event)"
                    (saveUrl)="saveSubprocessorsUrl.emit($event)"
                />
            } @else {
                <app-missing-artifact-question
                    [additionalQuestion]="additionalQuestion"
                    [vendorName]="vendorName"
                    [questionIdx]="i"
                    [totalQuestions]="additionalQuestions.length"
                    (uploadArtifact)="uploadArtifact.emit($event)"
                    (uploadFileFailed)="uploadFileFailed.emit($event)"
                    (setPassword)="setPassword.emit($event)"
                    (removeArtifact)="removeFileArtifact.emit($event)"
                    (updateQuestion)="updateQuestion.emit($event)"
                    (goBack)="goBack()"
                    (continue)="continue()"
                />
            }
        </cdk-step>
    }
</app-custom-stepper>
