import { createSelector, MemoizedSelector } from '@ngrx/store';
import {
    FileArtifact,
    isFileArtifact,
    isQuestionnaireArtifact,
    isUrlArtifact,
    QuestionnaireArtifact,
    UrlArtifact,
} from '@entities/artifact';
import { AppState } from '../assessment-collection.state';
import {
    getExpiredArtifactIdsToReplace,
    getPublicAssessment,
    getSortedRtpFileArtifacts,
    getSortedRtpFileArtifactsForInitialUploadScreen,
} from './main.selectors';
import { getAssessmentCreatedDate } from './assessment-data.selectors';
import { ExpiredArtifactReplacement, PublicAssessmentRTPFileArtifact } from '../../models';
import { AuditReportTypeCode } from '@entities/audit-report';

export const getFileArtifacts: MemoizedSelector<AppState, FileArtifact[]> = createSelector(
    getPublicAssessment,
    (publicAssessment) => (publicAssessment?.artifacts || []).filter(isFileArtifact),
);

export const getUrlArtifacts: MemoizedSelector<AppState, FileArtifact[]> = createSelector(
    getPublicAssessment,
    (publicAssessment) => (publicAssessment?.artifacts || []).filter(isUrlArtifact),
);

export const getExpiredArtifacts: MemoizedSelector<AppState, FileArtifact[]> = createSelector(
    getFileArtifacts,
    (artifacts) => artifacts?.filter((a) => a.validation?.expired || a.validation?.expiringSoon),
);

export const getExpiredArtifactsUploadedBeforeAssessmentCreation: MemoizedSelector<AppState, FileArtifact[]> =
    createSelector(getExpiredArtifacts, getAssessmentCreatedDate, (expiredArtifacts, assessmentCreatedDate) =>
        expiredArtifacts.filter((artifact) => artifact.validation?.createdDate <= assessmentCreatedDate),
    );

export const getArtifactsUploadedAfterAssessmentCreation: MemoizedSelector<AppState, FileArtifact[]> = createSelector(
    getFileArtifacts,
    getAssessmentCreatedDate,
    (fileArtifacts, assessmentCreatedDate) =>
        fileArtifacts.filter((artifact) => new Date(artifact.createdDate) > assessmentCreatedDate),
);

export const getExpiredArtifactReplacements: MemoizedSelector<AppState, ExpiredArtifactReplacement[]> = createSelector(
    getExpiredArtifactsUploadedBeforeAssessmentCreation,
    getSortedRtpFileArtifactsForInitialUploadScreen,
    getExpiredArtifactIdsToReplace,
    (existingExpiredArtifacts, newlyUploadedArtifacts, expiredArtifactIdsToReplace) =>
        Array.from(expiredArtifactIdsToReplace.entries()).map(([expiredId, replacementId]) => ({
            expiredFileArtifact: existingExpiredArtifacts.find((a) => a.id === expiredId),
            replacedByRtpFileArtifact: !!replacementId
                ? newlyUploadedArtifacts.find((rtp) => rtp.artifact.id === replacementId)
                : null,
        })),
);

export const getQuestionnaireArtifact: MemoizedSelector<AppState, QuestionnaireArtifact> = createSelector(
    getPublicAssessment,
    (publicAssessment) => (publicAssessment?.artifacts || []).find(isQuestionnaireArtifact),
);

export const areThereUploadedArtifacts: MemoizedSelector<AppState, boolean> = createSelector(
    getArtifactsUploadedAfterAssessmentCreation,
    (artifacts) => !!artifacts?.length,
);

export const getSubprocessorFileArtifact: MemoizedSelector<AppState, PublicAssessmentRTPFileArtifact> = createSelector(
    getSortedRtpFileArtifacts,
    (artifacts) =>
        artifacts.find((artifact) => artifact.validation?.auditReportType === AuditReportTypeCode.SUBPROCESSORLIST),
);

export const getSubprocessorsUrlArtifact: MemoizedSelector<AppState, UrlArtifact> = createSelector(
    getUrlArtifacts,
    (artifacts) =>
        artifacts.find((artifact) => artifact.validation?.auditReportType === AuditReportTypeCode.SUBPROCESSORLIST),
);
