import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import {
    createQuestionnaireRequestSuccess,
    extendAssessmentExpirationRequestSuccess,
    getPublicAssessmentRequest,
    loadRecommendations,
    onRTPArtifactClassifiedEvent,
    onRTPArtifactPublishDateSetEvent,
    removeArtifactRequestFailed,
    removeArtifactRequestSuccess,
    saveSubprocessorsUrlRequestSuccess,
    setPasswordForArtifactRequestSuccess,
    saveSubprocessorDetectionsRequestSuccess,
    uploadFilesRequestFailed,
    uploadFilesRequestSuccess,
} from '../actions';
import { getAssessmentToken, getSecret } from '../selectors';

@Injectable()
export class RefreshTriggersEffects {
    refreshPublicAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                uploadFilesRequestSuccess,
                uploadFilesRequestFailed,
                setPasswordForArtifactRequestSuccess,
                createQuestionnaireRequestSuccess,
                removeArtifactRequestSuccess,
                removeArtifactRequestFailed,
                extendAssessmentExpirationRequestSuccess,
                saveSubprocessorsUrlRequestSuccess,
                saveSubprocessorDetectionsRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => getPublicAssessmentRequest({ assessmentToken, secret })),
        ),
    );

    refreshRecommendationsOnArtifactActions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFilesRequestSuccess, removeArtifactRequestSuccess),
            map(() => loadRecommendations()),
        ),
    );

    refreshRecommendationsOnRtpEvents$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onRTPArtifactClassifiedEvent, onRTPArtifactPublishDateSetEvent),
            map(() => loadRecommendations()),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
