import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LongRunningTaskName, LongRunningTaskProjection } from '@entities/long-running-tasks';

@Injectable({ providedIn: 'root' })
export class LongRunningTaskService {
    private resourceUrl = 'api/tasks';

    constructor(private _http: HttpClient) {}

    getAllRunningTasks(): Observable<LongRunningTaskName[]> {
        return this._http.get<LongRunningTaskName[]>(`${this.resourceUrl}/running/org`);
    }

    getAllTasksForRecordId(recordId: string): Observable<LongRunningTaskProjection[]> {
        return this._http.get<LongRunningTaskProjection[]>(`${this.resourceUrl}/record/${recordId}`);
    }
}
