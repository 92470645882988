<ng-template #rtpErrorPopover>
    <div class="classification-warning-popover">
        <strong>Classification for the provided artifact failed.</strong>
        <br /><br />
        <div>
            Provide another artifact or continue if you believe this artifact is correct. Please note that all artifacts
            are manually verified by a human auditor.
        </div>
    </div>
</ng-template>
<div class="d-flex flex-nowrap">
    <mat-card class="w-100" [ngClass]="{ 'artifact-warning': rtpUnableToClassify || needsPassword }">
        <mat-card-content>
            <div class="row fixed-height">
                <div
                    class="col d-flex align-items-center"
                    [ngClass]="{
                        'justify-content-between': !rtpUnableToClassify,
                        'justify-content-end': rtpUnableToClassify,
                    }"
                >
                    @if (!rtpUnableToClassify) {
                        <app-artifact-audit-type-animated-label
                            [auditReportTypeCode]="fileArtifactItem.auditReportTypeCode"
                            [expectedClassification]="auditTypeToReplace"
                            [initialState]="initialAnimationState"
                        />
                    }
                    <app-artifact-file-type-animated-pill
                        [aiProcessed]="aiProcessed"
                        [fileArtifactType]="fileArtifactItem.fileArtifactType"
                        [initialState]="initialAnimationState"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="filename text-muted d-flex align-items-center">
                        {{ replacesAnotherArtifact ? 'New artifact: ' + artifactName : artifactName }}
                        @if (rtpUnableToClassify) {
                            <mat-icon
                                class="text-error ms-1"
                                [inline]="true"
                                [appPopover]="rtpErrorPopover"
                                popoverTheme="dark"
                            >
                                info
                            </mat-icon>
                        }
                    </div>
                </div>
            </div>
            @if (rtpUnableToClassify) {
                <strong class="text-error">Unable to classify</strong>
            } @else if (needsPassword) {
                <div class="row mt-4">
                    <div class="col pe-0">
                        <div class="d-flex align-items-center">
                            <mat-form-field class="dense-3 flex-grow-1">
                                <mat-label>Document password</mat-label>
                                <input
                                    [type]="hidePassword ? 'password' : 'text'"
                                    [formControl]="documentPasswordFormControl"
                                    matInput
                                />
                            </mat-form-field>
                            <button
                                mat-icon-button
                                matSuffix
                                (click)="hidePassword = !hidePassword"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hidePassword"
                            >
                                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                matSuffix
                                (click)="emitSetPassword()"
                                [disabled]="!passwordFormControlHasValue"
                                [attr.aria-label]="'Save password'"
                            >
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <strong class="text-error">Missing/incorrect password</strong>
                    </div>
                </div>
            }
        </mat-card-content>
    </mat-card>
    <button class="ms-2" mat-icon-button (click)="remove.emit()" [disabled]="artifactIsPlaceholder">
        <mat-icon>close</mat-icon>
    </button>
</div>
