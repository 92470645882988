import { AdditionalQuestionEffects } from './additional-question.effects';
import { SubStepNavigationEffects } from './sub-step-navigation.effects';
import { CommandsEffects } from './commands.effects';
import { EventsEffects } from './events.effects';
import { FeedbackEffects } from './feedback.effects';
import { RedirectsEffects } from './redirects.effects';
import { RefreshTriggersEffects } from './refresh-triggers.effects';
import { RequestEffects } from './requests.effects';
import { RTPEffects } from './rtp.effects';
import { StepNavigationEffects } from './step-navigation.effects';
import { QuestionnaireEffects } from './questionnaire.effects';
import { SnackbarEffects } from './snackbar.effects';
import { SubprocessorEffects } from './subprocessor.effects';

export const effects = [
    AdditionalQuestionEffects,
    SubStepNavigationEffects,
    CommandsEffects,
    EventsEffects,
    FeedbackEffects,
    RedirectsEffects,
    RefreshTriggersEffects,
    RequestEffects,
    RTPEffects,
    StepNavigationEffects,
    QuestionnaireEffects,
    SnackbarEffects,
    SubprocessorEffects,
];
