import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FileArtifact, FileArtifactType } from '@entities/artifact';
import { ExpiredArtifactReplacement, PublicAssessmentRTPFileArtifact } from '../../../models';
import { BehaviorSubject } from 'rxjs';
import { AuditReportTypeCode } from '@entities/audit-report';

@Component({
    selector: 'app-artifact-list',
    templateUrl: './artifact-list.component.html',
    styleUrls: ['./artifact-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactListComponent implements OnChanges {
    @Input({ required: true }) rtpFileArtifacts: PublicAssessmentRTPFileArtifact[];
    @Input() expiredArtifactReplacements: ExpiredArtifactReplacement[] = [];

    @Output() uploadAndReplace = new EventEmitter<{ file: File; artifactIdToReplace: number }>();
    @Output() uploadAndReplaceFailed = new EventEmitter<any>();
    @Output() setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();
    @Output() removeArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();

    FileArtifactType = FileArtifactType;
    uploadedRtpFileArtifacts$ = new BehaviorSubject<PublicAssessmentRTPFileArtifact[]>(null);

    ngOnChanges(): void {
        this.uploadedRtpFileArtifacts$.next(this.getArtifactsNotUsedAsReplacements(this.rtpFileArtifacts));
    }

    getArtifactsNotUsedAsReplacements(
        uploadedRtpFileArtifacts: PublicAssessmentRTPFileArtifact[],
    ): PublicAssessmentRTPFileArtifact[] {
        // If an artifact is meant to be replaced, we want to display that separately in an "upload and replace" list item.
        // If it was uploaded and not explicitly replacing another artifact, we can just display it at the bottom of the list as a normal file artifact.
        // This method returns those artifacts.
        const idsOfArtifactsUploadedAsReplacements = this.expiredArtifactReplacements
            .filter((replacement) => !!replacement.replacedByRtpFileArtifact)
            .map((replacement) => replacement.replacedByRtpFileArtifact.artifact.id);
        return uploadedRtpFileArtifacts.filter((a) => !idsOfArtifactsUploadedAsReplacements.includes(a.artifact.id));
    }

    emitSetPassword(password: string, artifact: PublicAssessmentRTPFileArtifact): void {
        this.setPassword.emit({ artifact, password });
    }

    emitArtifactRemoved(fileArtifactItem: PublicAssessmentRTPFileArtifact): void {
        this.removeArtifact.emit(fileArtifactItem);
    }

    getArtifactAuditReportType(artifact: FileArtifact): AuditReportTypeCode {
        return !!artifact.validation ? artifact.validation.auditReportType : null;
    }
}
