import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    input,
    OnDestroy,
    Output,
    Signal,
    ViewChild,
} from '@angular/core';
import { QuestionnaireArtifact } from '@entities/artifact';
import { QuestionnaireAnswer, QuestionnaireAnswerType, QuestionnaireAnswerTypeLabels } from '@entities/assessment';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { CdkStepper } from '@angular/cdk/stepper';
import { ControlDomainType, ControlDomainTypeLabels } from '@entities/control-domain';

type QuestionnairePage = {
    pageTitle?: string;
    questionsFormArray: FormArray<FormGroup<QuestionnaireAnswerFormControls>>;
};

type QuestionnaireAnswerFormControls = {
    answerType: FormControl<QuestionnaireAnswerType>;
    answer: FormControl<string>;

    // Display/tracking only
    answerId: FormControl<string>;
    question: FormControl<string>;
    controlDomainName: FormControl<string>;
    controlDomainType: FormControl<ControlDomainType>;
    controlDomainDescription: FormControl<string>;
};

@Component({
    selector: 'app-answer-questionnaire',
    templateUrl: './answer-questionnaire.component.html',
    styleUrl: './answer-questionnaire.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerQuestionnaireComponent implements OnDestroy {
    clientName = input.required<string>();
    questionnaire = input.required<QuestionnaireArtifact>();
    followupControlDomainIds = input.required<number[]>();
    relevantControlDomainIds = input.required<number[]>();
    forFollowup = input.required<boolean>();
    activeSupplementalQuestionnaires = input.required<Map<string, string>>();

    @Output()
    questionnaireUpdated = new EventEmitter<QuestionnaireArtifact>();

    @Output()
    continued = new EventEmitter<void>();

    @Output()
    wentBack = new EventEmitter<void>();

    @Output()
    wentToCollectArtifacts = new EventEmitter<void>();

    questionnairePages: Signal<QuestionnairePage[]>;

    ControlDomainTypeLabels = ControlDomainTypeLabels;
    QuestionnaireAnswerType = QuestionnaireAnswerType;
    QuestionnaireAnswerTypeLabels = QuestionnaireAnswerTypeLabels;

    @ViewChild(CdkStepper)
    private _stepper: CdkStepper;

    private _unsub$ = new Subject<void>();

    private get currentPageForm(): FormArray<FormGroup<QuestionnaireAnswerFormControls>> {
        return this.questionnairePages()[this._stepper.selectedIndex].questionsFormArray;
    }

    constructor(private _fb: FormBuilder) {
        this.questionnairePages = computed(() =>
            !!this.questionnaire()
                ? this.setupQuestionnairePages()
                : [
                      {
                          pageTitle: null,
                          questionsFormArray: this._fb.array<FormGroup<QuestionnaireAnswerFormControls>>([]),
                      },
                  ],
        );
    }

    attemptToContinue(): void {
        const currentForm = this.currentPageForm;
        if (currentForm.invalid) {
            this.scrollToFirstInvalidQuestion(currentForm);
        } else if (this._stepper.selectedIndex === this.questionnairePages().length - 1) {
            this.continued.emit();
        } else {
            this._stepper.next();
        }
    }

    goBack(): void {
        if (this._stepper.selectedIndex === 0) {
            this.wentBack.emit();
        } else {
            this._stepper.previous();
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private setupQuestionnairePages(): QuestionnairePage[] {
        const questionnairePages: QuestionnairePage[] = [];

        const relevantAnswers = this.questionnaire().answers.filter((a) => this.isQuestionRelevantOrAnswerBlank(a));

        // First, push any standard questions.
        const standardQuestionnairePage: QuestionnairePage = {
            questionsFormArray: this.createFormForQuestionsPage(
                relevantAnswers.filter((a) => a.controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE),
            ),
        };

        // Next, create any supplemental questionnaire pages, if applicable.
        // Note that we filter by active questions, since it's possible that either the feature could be disabled
        // (and thus supp q's don't come back active here) or the Client could themselves mark individual supp q's disabled for this Relationship
        // while the assessment is active.
        const supplementalQuestionnairePages: QuestionnairePage[] = [];

        const activeSupplementalQuestions = this.questionnaire().answers.filter(
            (q) =>
                q.controlDomainType === ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE &&
                this.activeSupplementalQuestionnaires().has(q.supplementalQuestionnaireId),
        );

        if (!!activeSupplementalQuestions.length) {
            const questionAnswersGroupedBySuppQID = activeSupplementalQuestions.reduce((acc, answer) => {
                acc.has(answer.supplementalQuestionnaireId)
                    ? acc.get(answer.supplementalQuestionnaireId).push(answer)
                    : acc.set(answer.supplementalQuestionnaireId, [answer]);
                return acc;
            }, new Map<string, QuestionnaireAnswer[]>());

            // Convert the groups into Questionnaire Pages.
            for (const questionnaireId of questionAnswersGroupedBySuppQID.keys()) {
                supplementalQuestionnairePages.push({
                    pageTitle: this.activeSupplementalQuestionnaires().get(questionnaireId),
                    questionsFormArray: this.createFormForQuestionsPage(
                        questionAnswersGroupedBySuppQID.get(questionnaireId),
                    ),
                });
            }
        }

        questionnairePages.push(standardQuestionnairePage, ...supplementalQuestionnairePages);

        standardQuestionnairePage.pageTitle = this.forFollowup()
            ? 'Standard questions'
            : `We have ${questionnairePages.flatMap((page) => page.questionsFormArray.controls).length} questions about your company's practices.`;

        return questionnairePages;
    }

    private createFormForQuestionsPage(
        answers: QuestionnaireAnswer[],
    ): FormArray<FormGroup<QuestionnaireAnswerFormControls>> {
        const formArray = this._fb.array(
            answers.map((answer) =>
                this._fb.group<QuestionnaireAnswerFormControls>({
                    answerType: this._fb.control(answer.answerType, Validators.required),
                    answer:
                        answer.answerType === QuestionnaireAnswerType.DONT_DO
                            ? this._fb.control(answer.answer)
                            : this._fb.control(answer.answer, Validators.required),

                    // Display/tracking only
                    answerId: this._fb.control(answer.id),
                    question: this._fb.control(answer.question),
                    controlDomainName: this._fb.control(answer.controlDomainName),
                    controlDomainType: this._fb.control(answer.controlDomainType),
                    controlDomainDescription: this._fb.control(answer.controlDomainDescription),
                }),
            ),
            { updateOn: 'change' },
        );

        formArray.controls.forEach((formGroup) =>
            formGroup.valueChanges
                .pipe(takeUntil(this._unsub$))
                .subscribe((value) => this.updateFormGroupValidatorsForSelectedAnswerType(value.answerType, formGroup)),
        );

        formArray.valueChanges
            .pipe(debounceTime(500), takeUntil(this._unsub$))
            .subscribe(() => this.updateQuestionnaireWithCurrentFormValues());

        return formArray;
    }

    private updateQuestionnaireWithCurrentFormValues() {
        for (const answerFormGroup of this.questionnairePages().flatMap((page) => page.questionsFormArray.controls)) {
            const answerToUpdate = this.questionnaire().answers.find(
                (a) => a.id === answerFormGroup.controls.answerId.value,
            );
            answerToUpdate.answerType = answerFormGroup.controls.answerType.value;
            answerToUpdate.answer = answerFormGroup.controls.answer.value;
        }
        this.questionnaireUpdated.emit(this.questionnaire());
    }

    private updateFormGroupValidatorsForSelectedAnswerType(
        selectedAnswerType: QuestionnaireAnswerType,
        formGroup: FormGroup<QuestionnaireAnswerFormControls>,
    ): void {
        if (selectedAnswerType === QuestionnaireAnswerType.DONT_DO) {
            formGroup.controls.answer.removeValidators(Validators.required);
        } else {
            formGroup.controls.answer.addValidators(Validators.required);
        }
        formGroup.controls.answer.updateValueAndValidity({ emitEvent: false });
    }

    private scrollToFirstInvalidQuestion(currentForm: FormArray<FormGroup<QuestionnaireAnswerFormControls>>): void {
        currentForm.markAllAsTouched();
        document.querySelector('.ng-invalid').scrollIntoView({ behavior: 'smooth' });
    }

    private isQuestionRelevantOrAnswerBlank(questionnaireAnswer: QuestionnaireAnswer): boolean {
        const isRelevantQuestion = this.relevantControlDomainIds().includes(questionnaireAnswer.controlDomainId);
        if (this.forFollowup()) {
            const isRelevantFollowupQuestion = this.followupControlDomainIds().includes(
                questionnaireAnswer.controlDomainId,
            );
            return isRelevantQuestion && isRelevantFollowupQuestion;
        }
        return isRelevantQuestion;
    }

    protected readonly ControlDomainType = ControlDomainType;
}
