import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LocalStorageService, NgxWebstorageModule, SessionStorageService } from 'ngx-webstorage';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from '@shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { VisoAdminModule } from './admin/admin.module';
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { PaginationConfig } from './blocks/config/uib-pagination.config';
import { ThemesService } from './core/themes/themes.service';
import { Router } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { appReducer } from '@shared/redux/reducer';
import { effects } from '@shared/redux/effects';
import { GraphQLModule } from './graphql.module';
import { GlobalErrorHandler } from './core/utils/global-error-handler';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { BehaviorSubject } from 'rxjs';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { PdfArtifactViewerModule } from './routes/pdf-artifact-viewer/pdf-artifact-viewer.module';
import { LogRocketModule } from '@shared/log-rocket/log-rocket.module';
import { SnackbarModule } from '@shared/components/snackbar/snackbar.module';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ResizableDrawerModule } from '@shared/directives/resizable-drawer/resizable-drawer.module';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
        CoreModule,
        LayoutModule,
        SharedModule,
        VisoAdminModule,
        SnackbarModule,
        RoutesModule,
        StoreModule.forRoot(
            { app: appReducer, router: routerReducer },
            {
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                },
            },
        ),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        EffectsModule.forRoot(effects),
        LogRocketModule,
        GraphQLModule,
        PdfArtifactViewerModule,
        MatSidenavModule,
        ResizableDrawerModule,
        GuidedTourModule,
    ],
    providers: [
        PaginationConfig,
        ThemesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [LocalStorageService, SessionStorageService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [Injector, Router],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [SnackbarService],
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        [{ provide: BREADCRUMB_CONTAINER_TOKEN, useValue: new BehaviorSubject(null) }],
        provideLottieOptions({
            player: () => import('lottie-web/build/player/lottie_svg'), // Importing only the svg renderer to reduce bundle size.
        }),
        provideCacheableAnimationLoader(),
        GuidedTourService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
