import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { QuestionFormGroup, SupplementalQuestionnaireFormGroup } from '../supplemental-questionnaire-dialog.component';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';
import { requiredFalseValidator } from '@shared/validators/required-false-validator';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    SupplementalQuestionnaireResponse,
    SupplementalQuestionnaireWithQuestions,
} from '@entities/supplemental-questionnaire';

@Component({
    selector: 'app-supplemental-questionnaire-edit',
    templateUrl: './supplemental-questionnaire-edit.component.html',
    styleUrl: './supplemental-questionnaire-edit.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplementalQuestionnaireEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input({ required: true })
    set importedQuestionnaireQuestions(value: string[]) {
        this.importedQuestionnaireQuestions$.next(value);
    }

    @Input({ required: true })
    createSupplementalQuestionnaireFormGroup: FormGroup<SupplementalQuestionnaireFormGroup>;

    @Input({ required: true })
    isEditing: boolean;

    @Input({ required: true })
    isCurrentUserOrgAdmin: boolean;

    @Input({ required: true })
    questionnaireToEdit: SupplementalQuestionnaireWithQuestions;

    @Input({ required: true })
    maxSupplementalQuestionnaireQuestions: number;

    @Input({ required: true })
    supplementalQuestionnaires: SupplementalQuestionnaireResponse[];

    displayedColumns = ['question', 'actions'];
    dataSource = new MatTableDataSource<FormGroup<QuestionFormGroup>>();

    importedQuestionnaireQuestions$ = new BehaviorSubject<string[]>([]);
    columnDefinitions: string[] = [];

    itemsPerPage = 10;

    availableQuestionCount: number;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    private _unsub$ = new Subject<void>();

    get cannotAddNewQuestion(): boolean {
        const firstQuestionFormGroup =
            this.createSupplementalQuestionnaireFormGroup.controls.questions.controls[0]?.controls;
        return !firstQuestionFormGroup?.question.value || !this._hasMoreQuestionsAvailable;
    }

    get questionsLeft(): number {
        return Math.max(0, this.availableQuestionCount - this.dataSource.data?.length);
    }

    private get _hasMoreQuestionsAvailable(): boolean {
        return this.dataSource.data?.length < this.availableQuestionCount;
    }

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.columnDefinitions = !this.isCurrentUserOrgAdmin
            ? this.displayedColumns.filter((col) => col !== 'actions')
            : this.displayedColumns;

        if (!!this.questionnaireToEdit) {
            this.createSupplementalQuestionnaireFormGroup.controls.id.setValue(this.questionnaireToEdit.id);
            this.createSupplementalQuestionnaireFormGroup.controls.questionnaireName.setValue(
                this.questionnaireToEdit.questionnaireName,
            );
            this.questionnaireToEdit.questions.forEach((question) =>
                this.addNewQuestion(question.question, { id: question.id, append: true }),
            );
            this.createSupplementalQuestionnaireFormGroup.controls.enabled.setValue(this.questionnaireToEdit.enabled);
        } else if (this.createSupplementalQuestionnaireFormGroup.controls.questions.length === 0) {
            this.addNewQuestion();
        }

        this.importedQuestionnaireQuestions$.pipe(takeUntil(this._unsub$)).subscribe((importedQuestions) => {
            if (importedQuestions?.length > 0) {
                this.createSupplementalQuestionnaireFormGroup.controls.questions.removeAt(-1);
                importedQuestions.forEach((question) => this.addNewQuestion(question, { append: true }));
            }
        });

        let otherQuestionCount = 0;
        this.supplementalQuestionnaires?.forEach((questionnaire) => {
            if (questionnaire.id != this.questionnaireToEdit?.id) {
                otherQuestionCount += questionnaire.questionCount;
            }
        });
        this.availableQuestionCount = this.maxSupplementalQuestionnaireQuestions - otherQuestionCount;
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = this.createSupplementalQuestionnaireFormGroup.controls.questions.controls;
    }

    toggleEditMode(row: FormGroup<QuestionFormGroup>) {
        if (row.controls.question.valid) {
            row.controls.editing.setValue(!row.controls.editing.value);
        }
    }

    addNewQuestion(questionText?: string, opts?: { id?: number; append?: boolean }) {
        const allQuestionsBeingEdited =
            this.createSupplementalQuestionnaireFormGroup.controls.questions.controls.filter(
                (ctrl) => ctrl.controls?.editing.value,
            );

        if (allQuestionsBeingEdited.length > 0) {
            allQuestionsBeingEdited.forEach((editingCtrl) => editingCtrl.controls.editing.setValue(false));
        }

        const question = this._fb.group<QuestionFormGroup>({
            id: this._fb.control<number>(opts?.id ?? null),
            question: this._fb.control<string>(questionText ?? '', {
                validators: [Validators.required, noWhitespaceValidator],
                nonNullable: true,
            }),
            editing: this._fb.control<boolean>(!questionText, requiredFalseValidator),
        });
        opts?.append
            ? this.createSupplementalQuestionnaireFormGroup.controls.questions.push(question)
            : this.createSupplementalQuestionnaireFormGroup.controls.questions.insert(0, question);
        if (!opts?.append) {
            document.querySelector('#top-row')?.scrollIntoView({ behavior: 'smooth' });
        }
        this.dataSource.data = this.createSupplementalQuestionnaireFormGroup.controls.questions.controls;
    }

    removeQuestion(row: FormGroup<QuestionFormGroup>) {
        const questionControls = this.createSupplementalQuestionnaireFormGroup.controls.questions;
        if (questionControls.length === 1) {
            row.setValue({
                id: row.controls.id.value,
                question: '',
                editing: true,
            });
        } else {
            this.createSupplementalQuestionnaireFormGroup.controls.questions.removeAt(
                questionControls.controls.findIndex((formGroup) => formGroup === row),
            );
        }

        this.dataSource.data = this.createSupplementalQuestionnaireFormGroup.controls.questions.controls;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
