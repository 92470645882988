import { ChangeDetectionStrategy, Component, computed, input, output, Signal } from '@angular/core';
import { VisoUserRole } from '@entities/viso-user';
import {
    ComplianceStandard,
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../routes/request/models/vendor-details.model';
import { OrgUrlType } from '@entities/org';
import { IMAGE_FALLBACK_BASE64 } from '@shared/image-fallback/image-fallback.constants';
import { RiskAssessment } from '@entities/risk-assessment';
import { LongRunningTaskName, LongRunningTaskProjection, LongRunningTaskStatus } from '@entities/long-running-tasks';

@Component({
    selector: 'app-vendor-overview',
    templateUrl: './vendor-overview.component.html',
    styleUrl: './vendor-overview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorOverviewComponent {
    vendorDetails = input.required<VendorDetailsResponse>();
    vendorDetailsStats = input.required<VendorDetailsStatsResponse>();
    isRelationshipDetails = input.required<boolean>();
    latestRiskAssessment = input<RiskAssessment>();
    relationshipLongRunningTasks = input<LongRunningTaskProjection[]>();

    onAddRelationshipClicked = output<void>();

    isContextProcessing: Signal<boolean>;

    readonly Roles = VisoUserRole;
    readonly ComplianceStandard = ComplianceStandard;
    readonly OrgUrlType = OrgUrlType;
    readonly vendorImageFallback = IMAGE_FALLBACK_BASE64;

    constructor() {
        this.isContextProcessing = computed(
            () =>
                (this.relationshipLongRunningTasks()?.some(
                    (task) =>
                        task.name === LongRunningTaskName.LLM_PREDICT_CONTEXT &&
                        task.status === LongRunningTaskStatus.RUNNING,
                ) ||
                    (!this.relationshipLongRunningTasks()?.some(
                        (task) => task.name === LongRunningTaskName.LLM_PREDICT_CONTEXT,
                    ) &&
                        !this.vendorDetailsStats()?.canPredictRiskUsingExistingInfo)) &&
                this.isRelationshipDetails(),
        );
    }

    get vendorName(): string {
        return this.vendorDetails()?.name;
    }

    get vendorFaviconUrl(): string {
        return this.vendorDetails()?.faviconUrl;
    }

    get homepage(): string {
        return this.vendorDetails()?.homepage;
    }

    get industry(): string {
        return this.vendorDetails()?.industry;
    }

    get description(): string {
        return this.vendorDetails()?.description;
    }

    get foundedDate(): Date {
        return this.vendorDetails()?.foundedDate;
    }

    get complianceStandards(): ComplianceStandard[] {
        return this.vendorDetails()?.complianceStandards?.sort((a, b) => {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        });
    }
}
