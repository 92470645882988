import { Artifact, ArtifactType, FileArtifact, QuestionnaireArtifact, UrlArtifact } from './artifact.model';

export function isArtifactExpiringSoon(artifact: Artifact): boolean {
    if (!artifact.validation || !artifact.validation.expirationDate) {
        return false;
    }

    const today = new Date();
    const diffTime = artifact.validation.expirationDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 && diffDays <= 30;
}

export function isArtifactExpired(artifact: Artifact): boolean {
    if (!artifact.validation || !artifact.validation.expirationDate) {
        return false;
    }

    const today = new Date();
    const diffTime = artifact.validation.expirationDate.getTime() - today.getTime();
    return diffTime <= 0;
}

export function isFileArtifact(value: Artifact): value is FileArtifact {
    return value.type === ArtifactType.FILE_ARTIFACT;
}

export function isUrlArtifact(value: Artifact): value is UrlArtifact {
    return value.type === ArtifactType.URL_ARTIFACT;
}

export function isQuestionnaireArtifact(value: Artifact): value is QuestionnaireArtifact {
    return value.type === ArtifactType.QUESTIONNAIRE_ARTIFACT;
}
