import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getRelationshipRequest, getRequestTags, updateRelationshipSuccess } from '../actions/relationship.actions';
import { offboardRelationshipRequestSuccess, onboardRelationshipSuccess } from '../actions/onboard.actions';
import {
    assignRelationshipBusinessOwnerRequestSuccess,
    getRelationshipBusinessOwnerRequest,
} from '../actions/business-owner.actions';
import {
    cancelRequestLatestAssessmentRequestSuccess,
    getRequestAssessmentsRequest,
    getRequestLatestAssessmentRequest,
    proceedWithAvailableDataRequestSuccess,
    startAssessmentRequestSuccess,
} from '../actions/assessments.actions';
import {
    acceptRiskRequestSuccess,
    addPrimaryVendorContactRequestSuccess,
    assignRelationshipToTierRequestSuccess,
    cancelRemediationRequestSuccess,
    completeLatestAssessmentRequestSuccess,
    getRelationshipSubscribersRequest,
    getRequestPrimaryVendorContactRequest,
    getRequestPrimaryVendorContactRequestSuccess,
    getRequestRiskAssessmentsRequest,
    linkTagRequestSuccess,
    removePrimaryVendorContactRequestSuccess,
    revokeRiskAcceptanceRequestSuccess,
    sendLatestAssessmentFollowupRequestSuccess,
    startRemediationRequestSuccess,
    subscribeContactRequestSuccess,
    unlinkTagRequestSuccess,
    unsubscribeContactRequestSuccess,
    updateFollowupTypeRequestSuccess,
} from '../request.actions';
import { updateLifecycleManagementSuccess } from '../actions/lifecycle-management.actions';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getRelationshipClientId, getRelationshipId } from '../request.selectors';
import { Store } from '@ngrx/store';
import {
    deleteRequestArtifactRequestSuccess,
    getRequestArtifactsRequest,
    uploadRequestArtifactRequestSuccess,
} from '../actions/artifacts.actions';
import {
    deleteRequestAttachmentRequestSuccess,
    getRequestAttachmentRequest,
    uploadRequestAttachmentRequestSuccess,
} from '../actions/attachments.actions';
import { Injectable } from '@angular/core';
import {
    deleteRelationshipSupplementalQuestionnaireConfigRequestSuccess,
    getOrgSupplementalQuestionnaireConfigRequest,
    getRelationshipSupplementalQuestionnaireConfigRequest,
    getRelationshipSupplementalQuestionnaireReprocessingStateRequest,
    updateRelationshipSupplementalQuestionnaireConfigRequestSuccess,
} from '../actions/supplemental-questionnaire.actions';
import {
    createRequestCommentRequestSuccess,
    deleteRequestCommentRequestSuccess,
    getRequestCommentsRequest,
} from '../actions/comments.actions';
import { getRelationshipSecurityControlDomainRequest } from '../actions/security-control-domains.actions';
import {
    reprocessSupplementalQuestionnaireRequestFailed,
    reprocessSupplementalQuestionnaireRequestSuccess,
} from '../../../assessment-details/assessment-details-common/redux/assessment-details.actions';
import { onRelationshipLongRunningTaskEventSuccess } from '../actions/long-running-task.actions';
import { LongRunningTaskActions } from '@shared/long-running-task/redux/long-running-task.actions';

@Injectable()
export class RefreshTriggersEffects {
    private _artifactUpdateActions = [
        uploadRequestArtifactRequestSuccess,
        deleteRequestArtifactRequestSuccess,
        startAssessmentRequestSuccess,
        cancelRequestLatestAssessmentRequestSuccess,
        getRequestPrimaryVendorContactRequestSuccess,
        cancelRemediationRequestSuccess,
        updateRelationshipSupplementalQuestionnaireConfigRequestSuccess,
        deleteRelationshipSupplementalQuestionnaireConfigRequestSuccess,
    ];

    refreshSuppQProcessingStates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                reprocessSupplementalQuestionnaireRequestSuccess,
                reprocessSupplementalQuestionnaireRequestFailed,
                uploadRequestArtifactRequestSuccess,
                deleteRequestArtifactRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) =>
                getRelationshipSupplementalQuestionnaireReprocessingStateRequest({ relationshipId }),
            ),
        ),
    );

    refreshRelationship$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                ...this._artifactUpdateActions,
                updateRelationshipSuccess,
                onboardRelationshipSuccess,
                offboardRelationshipRequestSuccess,
                assignRelationshipBusinessOwnerRequestSuccess,
                startAssessmentRequestSuccess,
                cancelRequestLatestAssessmentRequestSuccess,
                linkTagRequestSuccess,
                unlinkTagRequestSuccess,
                updateLifecycleManagementSuccess,
                proceedWithAvailableDataRequestSuccess,
                acceptRiskRequestSuccess,
                revokeRiskAcceptanceRequestSuccess,
                completeLatestAssessmentRequestSuccess,
                assignRelationshipToTierRequestSuccess,
                onRelationshipLongRunningTaskEventSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRelationshipRequest({ requestId: relationshipId })),
        ),
    );

    refreshLongRunningTaskStates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onRelationshipLongRunningTaskEventSuccess),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) =>
                LongRunningTaskActions.getAllTasksForRecordIdRequest({ recordId: relationshipId.toString() }),
            ),
        ),
    );

    refreshArtifacts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(...this._artifactUpdateActions),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestArtifactsRequest({ requestId: relationshipId })),
        ),
    );

    refreshAttachments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteRequestAttachmentRequestSuccess, uploadRequestAttachmentRequestSuccess),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestAttachmentRequest({ requestId: relationshipId })),
        ),
    );

    loadRiskAssessments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                ...this._artifactUpdateActions,
                updateRelationshipSuccess,
                onboardRelationshipSuccess,
                offboardRelationshipRequestSuccess,
                assignRelationshipBusinessOwnerRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestRiskAssessmentsRequest({ requestId: relationshipId })),
        ),
    );

    loadLatestAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                uploadRequestArtifactRequestSuccess,
                startAssessmentRequestSuccess,
                cancelRequestLatestAssessmentRequestSuccess,
                updateRelationshipSuccess,
                removePrimaryVendorContactRequestSuccess,
                offboardRelationshipRequestSuccess,
                proceedWithAvailableDataRequestSuccess,
                startRemediationRequestSuccess,
                cancelRemediationRequestSuccess,
                completeLatestAssessmentRequestSuccess,
                sendLatestAssessmentFollowupRequestSuccess,
                updateFollowupTypeRequestSuccess,
                addPrimaryVendorContactRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestLatestAssessmentRequest({ requestId: relationshipId })),
        ),
    );

    loadAssessments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                updateRelationshipSuccess,
                addPrimaryVendorContactRequestSuccess,
                removePrimaryVendorContactRequestSuccess,
                cancelRequestLatestAssessmentRequestSuccess,
                startAssessmentRequestSuccess,
                offboardRelationshipRequestSuccess,
                startRemediationRequestSuccess,
                cancelRemediationRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestAssessmentsRequest({ requestId: relationshipId })),
        ),
    );

    loadSupplementalQuestionnaireConfig$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                deleteRelationshipSupplementalQuestionnaireConfigRequestSuccess,
                updateRelationshipSupplementalQuestionnaireConfigRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId), this._store$.select(getRelationshipClientId)),
            mergeMap(([, relationshipId, clientId]) => [
                getRelationshipSupplementalQuestionnaireConfigRequest({ relationshipId }),
                getOrgSupplementalQuestionnaireConfigRequest({ clientId }),
            ]),
        ),
    );

    loadComments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                createRequestCommentRequestSuccess,
                deleteRequestCommentRequestSuccess,
                startAssessmentRequestSuccess,
                onboardRelationshipSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestCommentsRequest({ requestId: relationshipId })),
        ),
    );

    loadSubscribers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                subscribeContactRequestSuccess,
                unsubscribeContactRequestSuccess,
                updateRelationshipSuccess,
                assignRelationshipBusinessOwnerRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRelationshipSubscribersRequest({ requestId: relationshipId })),
        ),
    );

    loadBusinessOwner$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRelationshipSuccess, assignRelationshipBusinessOwnerRequestSuccess),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRelationshipBusinessOwnerRequest({ requestId: relationshipId })),
        ),
    );

    loadPrimaryContact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                startAssessmentRequestSuccess,
                updateRelationshipSuccess,
                addPrimaryVendorContactRequestSuccess,
                removePrimaryVendorContactRequestSuccess,
            ),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestPrimaryVendorContactRequest({ requestId: relationshipId })),
        ),
    );

    loadSecurityControlDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRelationshipSuccess, ...this._artifactUpdateActions),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRelationshipSecurityControlDomainRequest({ requestId: relationshipId })),
        ),
    );

    loadTags$ = createEffect(() =>
        this._actions$.pipe(
            ofType(linkTagRequestSuccess, unlinkTagRequestSuccess),
            withLatestFrom(this._store$.select(getRelationshipId)),
            map(([, relationshipId]) => getRequestTags({ requestId: relationshipId })),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
    ) {}
}
