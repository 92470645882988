import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AuditReportTypeCode, AuditReportTypeName } from '@entities/audit-report';
import { AnimationState } from '../file-artifact-item-animations.model';

@Component({
    selector: 'app-artifact-audit-type-animated-label',
    templateUrl: './artifact-audit-type-animated-label.component.html',
    styleUrls: ['./artifact-audit-type-animated-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('iconSlideRight', [
            state(AnimationState.PRE_REVEAL, style({ color: '#3f484a' })),
            state(AnimationState.REVEALING, style({ left: '100%' })),
            state(AnimationState.REVEALED, style({ color: 'transparent' })),
            transition('* => pre-reveal', [animate('0.3s ease-in', keyframes([style({ color: '#3f484a' })]))]),
            transition('pre-reveal => revealing', [
                animate(
                    '0.5s ease-in-out',
                    keyframes([style({ color: '#3f484a', left: '0' }), style({ color: '#3f484a', left: '100%' })]),
                ),
            ]),
            transition('revealing => revealed', [
                animate('0.2s ease-in', keyframes([style({ color: '#3f484a' }), style({ color: 'transparent' })])),
            ]),
        ]),
        trigger('contentReveal', [
            state(AnimationState.REVEALING, style({ clipPath: 'inset(0 0 0 0)' })),
            state(AnimationState.REVEALED, style({ clipPath: 'inset(0 0 0 0)' })),
            transition(
                'pre-reveal => revealing',
                animate('0.5s ease-in-out', keyframes([style({ clipPath: 'inset(0 0 0 0)' })])),
            ),
        ]),
    ],
})
export class ArtifactAuditTypeAnimatedLabelComponent implements OnInit, OnChanges {
    @Input({ required: true }) auditReportTypeCode: AuditReportTypeCode;
    @Input() initialState = AnimationState.WAITING;
    @Input() expectedClassification: AuditReportTypeCode;

    state: AnimationState;
    AuditReportTypeName = AuditReportTypeName;

    get auditReportTypeLabel(): string {
        return !this.auditReportTypeCode || this.auditReportTypeCode === AuditReportTypeCode.NONE
            ? null
            : AuditReportTypeName[this.auditReportTypeCode];
    }

    get displayIncorrectClassificationPopover(): boolean {
        return !!this.expectedClassification && this.expectedClassification !== this.auditReportTypeCode;
    }

    ngOnInit(): void {
        this.state = this.initialState;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentAuditType = changes.auditReportTypeCode.currentValue;
        const previousAuditType = changes.auditReportTypeCode.previousValue;
        const auditTypeCanBeRevealed =
            (!previousAuditType || previousAuditType === AuditReportTypeCode.NONE) &&
            currentAuditType !== AuditReportTypeCode.NONE;
        if (auditTypeCanBeRevealed) {
            this.state = AnimationState.PRE_REVEAL;
        }
    }

    moveToNextState(): void {
        switch (this.state) {
            case AnimationState.PRE_REVEAL:
                this.state = AnimationState.REVEALING;
                break;
            case AnimationState.REVEALING:
                this.state = AnimationState.REVEALED;
                break;
        }
    }
}
