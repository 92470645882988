import { AssuranceLevels } from '../relationship/models/security-control-domain';
import { BaseEntity } from '../../shared';
import { FileArtifactType } from '@entities/artifact';

// Enum and Friendly Name
export enum AuditReportTypeName {
    QUESTIONNAIRE = 'Questionnaire',
    ISOIEC2700127002 = 'ISO 27001 Statement of Applicability',
    SOC2TYPE2 = 'AICPA SOC 2 Type 2 Report',
    SOC1TYPE2 = 'AICPA SOC 1 Type 2 Report',
    SOC2TYPE1 = 'AICPA SOC 2 Type 1 Report',
    SOC1TYPE1 = 'AICPA SOC 1 Type 1 Report',
    CSASTARL2A = 'CSA STAR Level 2 Attestation Report',
    CSASTARL2C = 'CSA STAR Level 2 Certification Report',
    FEDRAMPH = 'FedRAMP Certification & Security Package - High',
    FEDRAMPM = 'FedRAMP Certification & Security Package - Moderate',
    FEDRAMPL = 'FedRAMP Certification & Security Package - Low',
    FEDRAMPLSAAS = 'FedRAMP Certification & Security Package - LI-SaaS',
    HITRUSTC = 'HITRUST CSF Certification Report',
    HITRUSTV = 'HITRUST CSF Validation Report',
    PENTEST = 'Third Party Penetration Test Report',
    APPASSESS = 'Third Party Application Security Assessment Report',
    SECPOL = 'Security Policies/Standards',
    CSASTARSA = 'CSA STAR Self-Assessment',
    SIG = 'Shared Assessments SIG/SIGLite',
    HITRUSTSELF = 'HITRUST Self-Assessment',
    VSASELF = 'VSA Self-Assessment',
    OTHERSELF = 'Other Self-Assessment',
    SECOVER = 'Security Overview Document',
    OTHER = 'Other Relevant Artifact',
    NONE = 'Immaterial Artifact',
    ISO27001CERTONLY = 'ISO 27001 Certificate Only',
    SOC3ORSVCDESCONLY = 'SOC 1/2/3 Description Only',
    MISC3PREPORT = 'Miscellaneous Third Party Audit',
    C5 = 'BSI C5 Report',
    BRIDGE = 'Bridge Letter',
    SURVEILLANCE = 'Surveillance Audit',
    SUPERSEDED = 'Superseded',
    PENTESTISSUES = 'Third Party Penetration Test Report (Unresolved Findings)',
    APPASSESSISSUES = 'Third Party Application Security Assessment Report (Unresolved Findings)',
    UKCESAQ = 'CyberEssential Self Assessment Questionnaire',
    UKCECERTONLY = 'CyberEssential Basic Certificate',
    UKCEPLUSREPORT = 'CyberEssential Plus Certification Report',
    UKCEPLUSCERTONLY = 'CyberEssential Plus Certificate',
    PRIVACYPOLICY = 'Privacy Policy',
    CLIENTTHIRDPARTYCONTRACT = 'Client-Third Party Contractual Agreement',
    CYBERINSURANCEPOLICY = 'Cyber Insurance Policy',
    WHITEPAPER = 'Security/Trust Whitepaper',
    PUBLICBREACHNOTICE = 'Public Breach Notice',
    STATUSPAGE = 'Status Page',
    TERMSOFSERVICE = 'Terms of Service',
    BUGBOUNTY = 'Bug Bounty Program',
    ESGPOL = 'Environmental, Social, and Governance (ESG) Policy',
    USAGEPOL = 'Usage Policy',
    OTHERURL = 'Other Relevant Link',
    TRUSTSECPAGE = 'Security or Trust Center',
    PRIVACYDPA = 'Data Processing Agreement (DPA)',
    PRIVACYDSA = 'Data Sharing Agreement',
    ISO27701PIMS = 'ISO 27701 (PIMS) Statement of Applicability',
    ISO27701CERTONLY = 'ISO 27701 (PIMS) Certificate Only',
    DATAPROTECTIONPOL = 'Data Protection Policy',
    DATARETENTIONPOL = 'Data Retention Policy',
    DATABREACHRESPONSEPROC = 'Data Breach Response and Notification Procedure',
    DATAPROTECTIONIMPACTASSESSMENT = 'Data Protection Impact Assessment (DPIA)',
    BUSINESSASSOCAGRMT = 'Business Associate Agreement (BAA)',
    GXPQ = 'GxP Questionnaire',
    SBOM = 'Software Bill of Materials (SBOM)',
    GUAC = 'Graph for Understanding Artifact Composition (GUAC)',
    VDAISASELF = 'VDA ISA Questionnaire (TISAX Self - Assessment)',
    TISAXREPORTLEVEL2 = 'TISAX Assessment Level 2 Report',
    TISAXREPORTLEVEL3 = 'TISAX Assessment Level 3 Report',
    SECFORM8K = 'Form 8-K',
    HITRUSTR2 = 'HITRUST Risk-based (r2) Validated Assessment Report',
    HITRUSTI1 = 'HITRUST Implemented (i1) Validated Assessment Report',
    HITRUSTE1 = 'HITRUST Essentials (e1) Validated Assessment Report',
    HITRUSTINTERIMLETTER = 'HITRUST Interim Assessment Letter',
    HITRUSTCERTr2 = 'HITRUST r2 certification letter',
    HITRUSTCERTe1 = 'HITRUST CSF Essentials (e1) certification',
    HITRUSTCERTi1 = 'HITRUST CSF Implemented (i1) certification',
    PCIDSSROC = 'PCI DSS Cert and Report of Compliance',
    PCIDSSSELF = 'PCI DSS Self Assessment',
    PCIDSSAOCQSA = 'PCI DSS Attestation of Compliance (On-Site)',
    PCIDSSAOCSELF = 'PCI DSS Attestation of Compliance (Self)',
    PCIDSSV4MATRIX = 'PCI DSS V4 Responsibility Matrix',
    PCIDSSV4AOC = 'PCIDSS V4 AOC',
    ISO27017CERTONLY = 'ISO 27017 Certificate only',
    ISOIEC2701727002 = 'ISO 27017 Statement of Applicability',
    ISO27018CERTONLY = 'ISO 27018 Certificate only',
    ISOIEC2701827002 = 'ISO 27018 Statement of Applicability',
    ISO22301CERTONLY = 'ISO 22301 Certificate only',
    ISO22301BCP = 'ISO 22301 Business Continuity Plan (BCP)',
    ISO22301BCPTESTREPORT = 'ISO 22301 BCP Test Report',
    DIAGRAM = 'Diagram - Arch, Network & Data Flow',
    ISO21434CERTONLY = 'ISO 21434 Certificate only',
    SUBPROCESSORLIST = 'Sub-Processor List',

    UNVALIDATED = '-', // FE Only, not in DB
}

export enum AuditReportTypeCode {
    QUESTIONNAIRE = 'QUESTIONNAIRE',
    ISOIEC2700127002 = 'ISOIEC2700127002',
    SOC2TYPE2 = 'SOC2TYPE2',
    SOC1TYPE2 = 'SOC1TYPE2',
    SOC2TYPE1 = 'SOC2TYPE1',
    SOC1TYPE1 = 'SOC1TYPE1',
    CSASTARL2A = 'CSASTARL2A',
    CSASTARL2C = 'CSASTARL2C',
    FEDRAMPH = 'FEDRAMPH',
    FEDRAMPM = 'FEDRAMPM',
    FEDRAMPL = 'FEDRAMPL',
    FEDRAMPLSAAS = 'FEDRAMPLSAAS',
    HITRUSTC = 'HITRUSTC',
    HITRUSTV = 'HITRUSTV',
    PENTEST = 'PENTEST',
    APPASSESS = 'APPASSESS',
    SECPOL = 'SECPOL',
    CSASTARSA = 'CSASTARSA',
    SIG = 'SIG',
    HITRUSTSELF = 'HITRUSTSELF',
    VSASELF = 'VSASELF',
    OTHERSELF = 'OTHERSELF',
    SECOVER = 'SECOVER',
    OTHER = 'OTHER',
    NONE = 'NONE',
    ISO27001CERTONLY = 'ISO27001CERTONLY',
    SOC3ORSVCDESCONLY = 'SOC3ORSVCDESCONLY',
    MISC3PREPORT = 'MISC3PREPORT',
    C5 = 'C5',
    BRIDGE = 'BRIDGE',
    SURVEILLANCE = 'SURVEILLANCE',
    SUPERSEDED = 'SUPERSEDED',
    PENTESTISSUES = 'PENTESTISSUES',
    APPASSESSISSUES = 'APPASSESSISSUES',
    UKCESAQ = 'UKCESAQ',
    UKCECERTONLY = 'UKCECERTONLY',
    UKCEPLUSREPORT = 'UKCEPLUSREPORT',
    UKCEPLUSCERTONLY = 'UKCEPLUSCERTONLY',
    PRIVACYPOLICY = 'PRIVACYPOLICY',
    CLIENTTHIRDPARTYCONTRACT = 'CLIENTTHIRDPARTYCONTRACT',
    CYBERINSURANCEPOLICY = 'CYBERINSURANCEPOLICY',
    WHITEPAPER = 'WHITEPAPER',
    PUBLICBREACHNOTICE = 'PUBLICBREACHNOTICE',
    STATUSPAGE = 'STATUSPAGE',
    TERMSOFSERVICE = 'TERMSOFSERVICE',
    BUGBOUNTY = 'BUGBOUNTY',
    ESGPOL = 'ESGPOL',
    USAGEPOL = 'USAGEPOL',
    OTHERURL = 'OTHERURL',
    TRUSTSECPAGE = 'TRUSTSECPAGE',
    PRIVACYDPA = 'PRIVACYDPA',
    PRIVACYDSA = 'PRIVACYDSA',
    ISO27701PIMS = 'ISO27701PIMS',
    ISO27701CERTONLY = 'ISO27701CERTONLY',
    DATAPROTECTIONPOL = 'DATAPROTECTIONPOL',
    DATARETENTIONPOL = 'DATARETENTIONPOL',
    DATABREACHRESPONSEPROC = 'DATABREACHRESPONSEPROC',
    DATAPROTECTIONIMPACTASSESSMENT = 'DATAPROTECTIONIMPACTASSESSMENT',
    BUSINESSASSOCAGRMT = 'BUSINESSASSOCAGRMT',
    VDAISASELF = 'VDAISASELF',
    TISAXREPORTLEVEL2 = 'TISAXREPORTLEVEL2',
    TISAXREPORTLEVEL3 = 'TISAXREPORTLEVEL3',
    UNVALIDATED = 'UNVALIDATED',
    SECFORM8K = 'SECFORM8K',
    HITRUSTR2 = 'HITRUSTR2',
    HITRUSTI1 = 'HITRUSTI1',
    HITRUSTE1 = 'HITRUSTE1',
    HITRUSTINTERIMLETTER = 'HITRUSTINTERIMLETTER',
    HITRUSTCERTr2 = 'HITRUSTCERTr2',
    HITRUSTCERTe1 = 'HITRUSTCERTe1',
    HITRUSTCERTi1 = 'HITRUSTCERTi1',
    PCIDSSROC = 'PCIDSSROC',
    PCIDSSSELF = 'PCIDSSSELF',
    PCIDSSAOCQSA = 'PCIDSSAOCQSA',
    PCIDSSAOCSELF = 'PCIDSSAOCSELF',
    PCIDSSV4MATRIX = 'PCIDSSV4MATRIX',
    PCIDSSV4AOC = 'PCIDSSV4AOC',
    GXPQ = 'GXPQ',
    SBOM = 'SBOM',
    GUAC = 'GUAC',
    ISO27017CERTONLY = 'ISO27017CERTONLY',
    ISOIEC2701727002 = 'ISOIEC2701727002',
    ISO27018CERTONLY = 'ISO27018CERTONLY',
    ISOIEC2701827002 = 'ISOIEC2701827002',
    ISO22301CERTONLY = 'ISO22301CERTONLY',
    ISO22301BCP = 'ISO22301BCP',
    ISO22301BCPTESTREPORT = 'ISO22301BCPTESTREPORT',
    DIAGRAM = 'DIAGRAM',
    ISO21434CERTONLY = 'ISO21434CERTONLY',
    SUBPROCESSORLIST = 'SUBPROCESSORLIST',
}

export const auditReportPublishedDateExplanation: Partial<Record<AuditReportTypeCode, string>> = {
    [AuditReportTypeCode.ISOIEC2700127002]:
        'If the SOA published date is before ISO certificate issue date, then the ISO certificate issue date ' +
        'is taken as the published date. If the SOA published date is after the ISO certificate issue date then the SOA ' +
        'public date is taken as the published date.',
    [AuditReportTypeCode.SOC2TYPE2]: 'Published date is taken as the audit period end date.',
    [AuditReportTypeCode.SOC1TYPE2]: 'Published date is taken as the audit period end date.',
    [AuditReportTypeCode.SOC2TYPE1]: 'Published date is taken as the audit period end date.',
    [AuditReportTypeCode.SOC1TYPE1]: 'Published date is taken as the audit period end date.',
    [AuditReportTypeCode.CSASTARL2A]: 'Published date is taken as the attestation period "from date".',
    [AuditReportTypeCode.CSASTARL2C]: 'Published date is taken as the certification date.',
    [AuditReportTypeCode.FEDRAMPH]: 'Published date is taken as the date of report.',
    [AuditReportTypeCode.FEDRAMPM]: 'Published date is taken as the date of report.',
    [AuditReportTypeCode.FEDRAMPL]: 'Published date is taken as the date of report.',
    [AuditReportTypeCode.FEDRAMPLSAAS]: 'Published date is taken as the date of report.',
    [AuditReportTypeCode.HITRUSTC]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTV]: 'Published date is taken as the date of report.',
    [AuditReportTypeCode.PENTEST]:
        'In cases where there is more than a month between the report publish date and the testing completed date ' +
        '(usually in cases where the report was issued after completing remediation) the date that the testing was completed ' +
        'will be taken as the publish date. If there is less than a month between these dates, the report date will be used. ' +
        'If only a month is mentioned, the middle of the month is chosen as the published date.',
    [AuditReportTypeCode.APPASSESS]:
        'In cases where there is more than a month between the report publish date and the security assessment ' +
        'end date, then the security assessment end date will be taken as the publish date. If there is less than a month ' +
        'between these dates, the report date will be used. If only a month is mentioned when the security assessment end ' +
        'date is completed, the middle of the month is chosen as the published date.',
    [AuditReportTypeCode.ISO27001CERTONLY]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.C5]: 'Published date is taken as the audit period end date.',
    [AuditReportTypeCode.SURVEILLANCE]: 'Published date is taken as the audit period end date.',
    [AuditReportTypeCode.PENTESTISSUES]:
        'In cases where there is more than a month between the report publish date and the testing completed ' +
        'date (usually in cases where the report was issued after completing remediation) the date that the testing was ' +
        'completed will be taken as the publish date. If there is less than a month between these dates, the report date ' +
        'will be used. If only a month is mentioned, the middle of the month is chosen as the published date.',
    [AuditReportTypeCode.APPASSESSISSUES]:
        'In cases where there is more than a month between the report publish date and the security assessment ' +
        'end date, then the security assessment end date will be taken as the publish date. If there is less than a month ' +
        'between these dates, the report date will be used. If only a month is mentioned when the security assessment end ' +
        'date is completed, the middle of the month is chosen as the published date.',
    [AuditReportTypeCode.UKCESAQ]: 'Published date is taken as the certification date.',
    [AuditReportTypeCode.UKCECERTONLY]: 'Published date is taken as the certification date.',
    [AuditReportTypeCode.UKCEPLUSREPORT]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.UKCEPLUSCERTONLY]: 'Published date is taken as the certification date.',
    [AuditReportTypeCode.CYBERINSURANCEPOLICY]: 'Published date is taken as the date of the Insurance policy.',
    [AuditReportTypeCode.ISO27701PIMS]:
        'If the SOA published date is before ISO certificate issue date, then the ISO certificate issue date is ' +
        'taken as the published date. If the SOA published date is after the ISO certificate issue date then the SOA public ' +
        'date is taken as the published date.',
    [AuditReportTypeCode.ISO27701CERTONLY]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.TISAXREPORTLEVEL2]: 'Published date is taken as the certification date.',
    [AuditReportTypeCode.TISAXREPORTLEVEL3]: 'Published date is taken as the certification date.',
    [AuditReportTypeCode.HITRUSTR2]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTI1]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTE1]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTINTERIMLETTER]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTCERTr2]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTCERTe1]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.HITRUSTCERTi1]: 'Published date is taken as the date of the report.',
    [AuditReportTypeCode.PCIDSSROC]: 'Published date is taken as the QSA signed date.',
    [AuditReportTypeCode.PCIDSSAOCQSA]: 'Published date is taken as the QSA signed date.',
    [AuditReportTypeCode.PCIDSSV4MATRIX]:
        'Published date is taken as the QSA Signed date from PCI DSS Attestation of Compliance (On-Site).',
    [AuditReportTypeCode.PCIDSSV4AOC]: 'Published date is taken as the QSA signed date.',
    [AuditReportTypeCode.ISO27017CERTONLY]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.ISOIEC2701727002]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.ISO27018CERTONLY]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.ISOIEC2701827002]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.ISO22301CERTONLY]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.ISO22301BCP]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
    [AuditReportTypeCode.ISO21434CERTONLY]:
        'Published date is taken as the latter of the certificate issue date or re-issue date.',
};

export enum AuditReportGroup {
    ISO = 'ISO',
    SOC1 = 'SOC1',
    SOC2 = 'SOC2',
    CSTAR = 'CSTAR',
    HITRUST = 'HITRUST',
    UKCE = 'UKCE',
    UKCEBASE = 'UKCEBASE',
    PRIVACY = 'PRIVACY',
    PRIVACYDPA = 'PRIVACYDPA',
    PRIVACYDSA = 'PRIVACYDSA',
    TISAX = 'TISAX',
    PENTEST = 'PENTEST',
    CYBERINSURANCEPOLICY = 'CYBERINSURANCEPOLICY',
}

export class AuditReport implements BaseEntity {
    constructor(
        public id?: number,
        public auditType?: AuditReportTypeCode,
        public auditReportGroup?: AuditReportGroup,
        public assurance?: number,
        public description?: string,
        public canExpire?: boolean,
        public expirationPeriod?: number,
        public thirdPartyAudit?: boolean,
        public comprehensiveAudit?: boolean,
        public auditReportFriendlyName?: string,
        public assuranceLevel?: AssuranceLevels,
        public auditCriteria?: string[],
    ) {}
}

export interface PublicAuditReport {
    auditReportId: number;
    auditReport: AuditReportTypeCode;
    auditReportLongName: string;
    comprehensiveAuditReport: boolean;
}

export const AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES: Record<AuditReportTypeCode, Partial<FileArtifactType>> = {
    // AUDIT_REPORT (Third Party Audit)
    ISOIEC2700127002: FileArtifactType.AUDIT_REPORT,
    SOC2TYPE2: FileArtifactType.AUDIT_REPORT,
    SOC1TYPE2: FileArtifactType.AUDIT_REPORT,
    SOC2TYPE1: FileArtifactType.AUDIT_REPORT,
    SOC1TYPE1: FileArtifactType.AUDIT_REPORT,
    CSASTARL2A: FileArtifactType.AUDIT_REPORT,
    CSASTARL2C: FileArtifactType.AUDIT_REPORT,
    FEDRAMPH: FileArtifactType.AUDIT_REPORT,
    FEDRAMPM: FileArtifactType.AUDIT_REPORT,
    FEDRAMPL: FileArtifactType.AUDIT_REPORT,
    FEDRAMPLSAAS: FileArtifactType.AUDIT_REPORT,
    ISO27001CERTONLY: FileArtifactType.AUDIT_REPORT,
    C5: FileArtifactType.AUDIT_REPORT,
    BRIDGE: FileArtifactType.AUDIT_REPORT,
    SURVEILLANCE: FileArtifactType.AUDIT_REPORT,
    UKCEPLUSREPORT: FileArtifactType.AUDIT_REPORT,
    HITRUSTC: FileArtifactType.AUDIT_REPORT,
    HITRUSTV: FileArtifactType.AUDIT_REPORT,
    HITRUSTR2: FileArtifactType.AUDIT_REPORT,
    HITRUSTI1: FileArtifactType.AUDIT_REPORT,
    PCIDSSROC: FileArtifactType.AUDIT_REPORT,
    PCIDSSAOCQSA: FileArtifactType.AUDIT_REPORT,
    PCIDSSV4MATRIX: FileArtifactType.AUDIT_REPORT,
    PCIDSSV4AOC: FileArtifactType.AUDIT_REPORT,
    ISO27017CERTONLY: FileArtifactType.AUDIT_REPORT,
    ISOIEC2701727002: FileArtifactType.AUDIT_REPORT,
    ISO27018CERTONLY: FileArtifactType.AUDIT_REPORT,
    ISOIEC2701827002: FileArtifactType.AUDIT_REPORT,
    ISO22301CERTONLY: FileArtifactType.AUDIT_REPORT,
    ISO22301BCP: FileArtifactType.AUDIT_REPORT,
    ISO22301BCPTESTREPORT: FileArtifactType.AUDIT_REPORT,
    DIAGRAM: FileArtifactType.AUDIT_REPORT,
    ISO21434CERTONLY: FileArtifactType.AUDIT_REPORT,

    // ASSESSMENT (Pen tests)
    PENTEST: FileArtifactType.ASSESSMENT,
    APPASSESS: FileArtifactType.ASSESSMENT,
    PENTESTISSUES: FileArtifactType.ASSESSMENT,
    APPASSESSISSUES: FileArtifactType.ASSESSMENT,
    VDAISASELF: FileArtifactType.ASSESSMENT,
    TISAXREPORTLEVEL2: FileArtifactType.ASSESSMENT,
    TISAXREPORTLEVEL3: FileArtifactType.ASSESSMENT,

    // PRIVACY
    PRIVACYDPA: FileArtifactType.PRIVACY,
    PRIVACYDSA: FileArtifactType.PRIVACY,
    ISO27701PIMS: FileArtifactType.PRIVACY,
    ISO27701CERTONLY: FileArtifactType.PRIVACY,
    DATAPROTECTIONPOL: FileArtifactType.PRIVACY,
    DATARETENTIONPOL: FileArtifactType.PRIVACY,
    DATABREACHRESPONSEPROC: FileArtifactType.PRIVACY,
    DATAPROTECTIONIMPACTASSESSMENT: FileArtifactType.PRIVACY,
    BUSINESSASSOCAGRMT: FileArtifactType.PRIVACY,
    SUBPROCESSORLIST: FileArtifactType.PRIVACY,

    // ALTERNATIVE
    SECPOL: FileArtifactType.ALTERNATIVE,
    CSASTARSA: FileArtifactType.ALTERNATIVE,
    SIG: FileArtifactType.ALTERNATIVE,
    VSASELF: FileArtifactType.ALTERNATIVE,
    OTHERSELF: FileArtifactType.ALTERNATIVE,
    SECOVER: FileArtifactType.ALTERNATIVE,
    OTHER: FileArtifactType.ALTERNATIVE,
    SOC3ORSVCDESCONLY: FileArtifactType.ALTERNATIVE,
    MISC3PREPORT: FileArtifactType.ALTERNATIVE,
    UKCESAQ: FileArtifactType.ALTERNATIVE,
    UKCECERTONLY: FileArtifactType.ALTERNATIVE,
    UKCEPLUSCERTONLY: FileArtifactType.ALTERNATIVE,
    PRIVACYPOLICY: FileArtifactType.ALTERNATIVE,
    CLIENTTHIRDPARTYCONTRACT: FileArtifactType.ALTERNATIVE,
    CYBERINSURANCEPOLICY: FileArtifactType.ALTERNATIVE,
    WHITEPAPER: FileArtifactType.ALTERNATIVE,
    PUBLICBREACHNOTICE: FileArtifactType.ALTERNATIVE,
    STATUSPAGE: FileArtifactType.ALTERNATIVE,
    TERMSOFSERVICE: FileArtifactType.ALTERNATIVE,
    BUGBOUNTY: FileArtifactType.ALTERNATIVE,
    ESGPOL: FileArtifactType.ALTERNATIVE,
    USAGEPOL: FileArtifactType.ALTERNATIVE,
    OTHERURL: FileArtifactType.ALTERNATIVE,
    TRUSTSECPAGE: FileArtifactType.ALTERNATIVE,
    GXPQ: FileArtifactType.ALTERNATIVE,
    SBOM: FileArtifactType.ALTERNATIVE,
    GUAC: FileArtifactType.ALTERNATIVE,
    SECFORM8K: FileArtifactType.ALTERNATIVE,
    HITRUSTSELF: FileArtifactType.ALTERNATIVE,
    HITRUSTE1: FileArtifactType.ALTERNATIVE,
    HITRUSTINTERIMLETTER: FileArtifactType.ALTERNATIVE,
    HITRUSTCERTr2: FileArtifactType.ALTERNATIVE,
    HITRUSTCERTe1: FileArtifactType.ALTERNATIVE,
    HITRUSTCERTi1: FileArtifactType.ALTERNATIVE,
    PCIDSSSELF: FileArtifactType.ALTERNATIVE,
    PCIDSSAOCSELF: FileArtifactType.ALTERNATIVE,

    // Null
    QUESTIONNAIRE: null,
    NONE: null,
    SUPERSEDED: null,
    UNVALIDATED: null,
};
