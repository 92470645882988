@if (uploadedRtpFileArtifacts$ | async; as uploadedRtpFileArtifacts) {
    @for (replacement of expiredArtifactReplacements; track replacement.expiredFileArtifact.fileName) {
        <div class="row mb-4">
            <div class="col">
                @if (!!replacement.replacedByRtpFileArtifact) {
                    <app-file-artifact-item
                        class="mb-2"
                        [fileArtifactItem]="replacement.replacedByRtpFileArtifact"
                        [auditTypeToReplace]="getArtifactAuditReportType(replacement.expiredFileArtifact)"
                        (setPassword)="emitSetPassword($event, replacement.replacedByRtpFileArtifact)"
                        (remove)="emitArtifactRemoved(replacement.replacedByRtpFileArtifact)"
                    />
                } @else {
                    <app-replace-file-artifact-item
                        [artifactToReplace]="replacement.expiredFileArtifact"
                        (uploadReplacement)="uploadAndReplace.emit($event)"
                        (onWhenAddingFileFailed)="uploadAndReplaceFailed.emit($event)"
                    />
                }
            </div>
        </div>
    }

    @for (rtpFileArtifact of uploadedRtpFileArtifacts; track rtpFileArtifact.artifact.fileName) {
        <app-file-artifact-item
            [class.mb-4]="!$last"
            [fileArtifactItem]="rtpFileArtifact"
            (setPassword)="emitSetPassword($event, rtpFileArtifact)"
            (remove)="emitArtifactRemoved(rtpFileArtifact)"
        />
    }
}
