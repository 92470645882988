import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RelationshipsEditColumnsComponent } from './edit-columns/edit-columns.component';
import { RelationshipsAdditionalFiltersPanelComponent } from './additional-filters-panel/additional-filters-panel.component';
import { RecertificationType, RequestStatus } from '@entities/relationship';
import { BehaviorSubject, combineLatest, EMPTY, first, Observable, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    skip,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { RelationshipAssessmentPhases, RelationshipCounts, VendorRelationship } from './models/relationship.model';
import { AdditionalFilters, ColumnNames, RelationshipProps } from './relationships.constants';
import { select, Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import {
    bulkArchiveRelationshipsRequest,
    bulkArchiveRelationshipsRequestAccepted,
    bulkArchiveRelationshipsRequestSuccess,
    assignRelationshipsToTierRequest,
    assignRelationshipsToTierRequestSuccess,
    bulkCancelAssessmentsRequest,
    bulkCancelAssessmentsRequestAccepted,
    bulkCancelAssessmentsRequestSuccess,
    clearAllFilters,
    clearAllUnpaginatedRelationshipIds,
    clearVendorTierStats,
    bulkDisableArtifactUpdatesRequest,
    bulkDisableArtifactUpdatesRequestSuccess,
    bulkEnableArtifactUpdatesRequest,
    bulkEnableArtifactUpdatesRequestSuccess,
    getAllRelationshipIdsQueryRequest,
    getRelationshipCountsRequest,
    getRiskNetworkGraphRequest,
    getRiskNetworkGraphRequestSuccess,
    getRiskThresholdsForVendorTierStats,
    getVendorTierStatsRequest,
    bulkOffboardRelationshipsRequest,
    bulkOffboardRelationshipsRequestAccepted,
    bulkOffboardRelationshipsRequestSuccess,
    openAssignRelationshipsToTierDialog,
    openManageTiersDialog,
    openVendorTierDescriptionDialog,
    queryRelationshipsForCsvRequest,
    queryRelationshipsForCsvRequestFailed,
    queryRelationshipsForCsvRequestSuccess,
    relationshipsQueryFailed,
    relationshipsQueryRequest,
    relationshipsQuerySuccess,
    restoreRelationshipRequest,
    restoreRelationshipRequestSuccess,
    bulkStartAssessmentsRequest,
    bulkStartAssessmentsRequestAccepted,
    bulkStartAssessmentsRequestSuccess,
    setAdditionalFiltersState,
    setSelectedTierId,
    updateVendorTiersCountRequestSuccess,
} from './redux/relationships.actions';
import { ConvertToCsvService } from '@shared/services/convert-to-csv.service';
import {
    getRelationshipCounts,
    getRelationshipsColumnGroups,
    getUnpaginatedIdsForCurrentFilter,
    getVendorTierStats,
    getIsGraphExpanded,
    getEditingTierDescription,
    getSelectedTierId,
} from './redux/relationships.selectors';
import { RelationshipColumn, RelationshipColumnGroup } from './models/relationship-column.model';
import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { LicenseType, VisoUser, VisoUserRole } from '@entities/viso-user';
import { DateUtilsService } from '@shared/utils/date-utils.service';
import { ArrayUtilsService } from '@shared/utils/array-utils.service';
import { AssessmentStatus } from '@entities/assessment';
import {
    getProfileRiskTolerance,
    getUserAccount,
    getUserAuthority,
    getUserProfile,
    getVendorTiers,
} from '../session/redux/session.selectors';
import { Risk } from '@entities/risk-assessment';
import { RiskUtilsService } from '@shared/utils/risk-utils.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { FormControl, NgModel } from '@angular/forms';
import { bulkLinkTagsRequest, bulkLinkTagsRequestSuccess } from '../request/redux/request.actions';
import { MetabasePredeterminedDatesRanges } from '../metabase-dashboard/models';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { addRelationshipRoute } from '../add-relationship/add-relationship.constants';
import { RiskNetworkGraphNode } from './models/risk-network-graph-node';
import { Tag } from '@entities/tag';
import { getTagsRequestSuccess } from '../tag/redux/tag.actions';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import {
    getRiskToleranceThresholds,
    RiskThresholdData,
} from '../risk-model-settings/redux/risk-model-settings.selectors';
import { RiskTolerance } from '@entities/risk-tolerance';
import { VendorTier, VendorTierStats } from '@entities/vendor-tier';
import { MatTabGroup } from '@angular/material/tabs';
import { longRunningTaskFeature } from '@shared/long-running-task/redux/long-running-task.reducer';
import { startSpotlightTour } from '../../layout/redux/layout.actions';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';
import { updateUserProfileRequest } from '../session/redux/session.actions';
import { LongRunningTaskActions } from '@shared/long-running-task/redux/long-running-task.actions';

enum ListOrGraphOptions {
    List,
    Graph,
}

interface TableSortItem {
    prop: string;
    dir: string;
}

@Component({
    selector: 'app-relationships',
    templateUrl: './relationships.component.html',
    styleUrls: ['./relationships.component.scss'],
})
export class RelationshipsComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @ViewChild('relationshipsTable') relationshipsTable: any;
    @ViewChild('additionalFiltersPanel') additionalFiltersPanel: RelationshipsAdditionalFiltersPanelComponent;
    @ViewChild('keywordInputFilter') keywordInputFilter: ElementRef;
    @ViewChild('listGraphTabGroup') listGraphTabGroup: MatTabGroup;

    Roles = VisoUserRole;
    LicenseType = LicenseType;
    RequestStatus = RequestStatus;
    Risk = Risk;
    AssessmentStatus = AssessmentStatus;
    SelectionType = SelectionType;

    relationships: VendorRelationship[];
    itemsPerPage: number;
    page: number;
    predicate: any;
    reverse: any;
    totalCurrentItems: number;
    totalUnarchivedItems: number;
    totalFilteredRelationships: number;
    totalGraphItems: number;
    filters: any;
    relationshipsLoaded: boolean;
    relationshipsLoading: boolean;
    relationshipArchivedStatus = RequestStatus.ARCHIVED;
    columns: RelationshipColumn[] = [];
    lastColumnsInGroup: RelationshipColumn[] = [];
    ListOrGraphOptions = ListOrGraphOptions;
    listOrGraphRadioFormControl = new FormControl<ListOrGraphOptions>(ListOrGraphOptions.List);
    FeatureFlags = FeatureFlags;

    currentAccount$: Observable<VisoUser>;
    keywordFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    keywordFilterApplied: boolean;
    tableSort$: BehaviorSubject<TableSortItem[]> = new BehaviorSubject<TableSortItem[]>([
        { prop: 'updatedDate', dir: 'desc' },
    ]);
    riskNetworkGraphNodes$ = new BehaviorSubject<RiskNetworkGraphNode[]>([]);
    drStatusFilter: string[] = [];
    assessmentPhaseFilter: (AssessmentStatus | string)[] = [];
    riskLevelFilter: string[] = [];
    tierFilter: string[] = [];
    inherentRiskLevelFilter: string[] = [];
    additionalFiltersApplied: boolean;
    isCurrentUserAuditor: boolean;
    doesCurrentUserHaveAvailableAssessments: boolean;
    doesCurrentUserHaveAvailableRelationships: boolean;
    isNthPartiesFilterApplied: boolean;
    columnGroups: RelationshipColumnGroup[];
    rowLabel: number;
    networkExposureOrgId: number;
    rowOptions = [{ value: 20 }, { value: 40 }, { value: 60 }];
    addRelationshipRoute = addRelationshipRoute;
    relationshipCounts$: Observable<RelationshipCounts>;
    vendorTiersVM$: Observable<{
        allVendorTiers: VendorTier[];
        vendorTierStats: VendorTierStats;
        riskTolerance: RiskTolerance;
        riskToleranceThresholds: Map<RiskTolerance, Map<Risk, RiskThresholdData>>;
        description: string;
    }>;
    unpaginatedRelationshipIdsForCurrentFilter$ = this._store$.select(getUnpaginatedIdsForCurrentFilter);
    bulkRelationshipActionInProgress$ = this._store$.select(longRunningTaskFeature.longRunningTaskInProgressForOrg);

    private _filterParamsFromUrl: string;
    private _tierIdFromUrl: string;
    private _drStatusFilterAllValues = ['NOT_ONBOARDED', 'ONBOARDED', 'ARCHIVED'];
    private _drStatusFilterInitialValue = ['NOT_ONBOARDED', 'ONBOARDED'];
    private _assessmentPhaseInitialValue = [
        '',
        AssessmentStatus.STARTED,
        AssessmentStatus.COLLECTING_INFORMATION,
        AssessmentStatus.REVIEW_STARTED,
        AssessmentStatus.AUDIT_COMPLETED,
        AssessmentStatus.COMPLETED,
    ];
    private _assessmentPhaseFullValues = [
        '',
        AssessmentStatus.STARTED,
        AssessmentStatus.COLLECTING_INFORMATION,
        AssessmentStatus.REVIEW_STARTED,
        AssessmentStatus.AUDIT_COMPLETED,
        AssessmentStatus.COMPLETED,
    ];
    private _riskLevelFilterInitialValue = ['NO_CONTEXT', 'LOW', 'MEDIUM', 'HIGH', 'EXTREME'];
    private _tierFilterInitialValue = [];

    private _noContextRiskLabel = 'NO_CONTEXT';
    private _notAssessedAssessmentPhase = RelationshipAssessmentPhases.NOT_ASSESSED;
    private _creatorFirstNameColumn = 'creatorFirstName';
    private _creatorLastNameColumn = 'creatorLastName';
    private _tagsColumn = 'tags';
    private _tagsNameField = 'tags.name';
    private _residualRiskColumn = 'residualRisk';
    private _recommendationCountColumn = 'recommendationCount';
    private _artifactUpdateColumn = 'artifactUpdateType';

    private _unsub$: Subject<void> = new Subject<void>();
    editingTierDescription$: Observable<boolean>;

    customMessages = {
        emptyMessage: 'Please modify the filter to display data in the table',
    };

    selectedTagNamesControl = new FormControl<Tag[]>([]);
    selectedTierId$: Observable<number | null>;
    tagsColumnWidth: number = null;
    userNavigatedToGraphOrListView: ListOrGraphOptions = ListOrGraphOptions.List;
    isGraphExpanded$: Observable<boolean>;

    selectedRelationshipsOnCurrentPage$: Observable<VendorRelationship[]>;
    selectedRelationshipIds$ = new BehaviorSubject<number[]>([]);

    constructor(
        private _convertToCsvService: ConvertToCsvService,
        private _dateUtilsService: DateUtilsService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modalService: NgbModal,
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _arrayUtils: ArrayUtilsService,
        private _riskUtils: RiskUtilsService,
        private _assessmentUtils: AssessmentUtilsService,
    ) {
        this.relationships = [];
        this.itemsPerPage = 20;
        this.page = 0;
        this.rowLabel = 20;
    }

    get filtersIncludeArchived(): boolean {
        return this.filters.drStatus.values.includes(RequestStatus.ARCHIVED);
    }

    get displaySelectAllRelationshipsButton$(): Observable<boolean> {
        return this.unpaginatedRelationshipIdsForCurrentFilter$.pipe(
            map(
                (unpaginatedRelationshipIdsForCurrentFilter) =>
                    // If we've selected everything on the page but not the rest of the pages.
                    this.unarchivedRelationshipsOnPage?.length === this.selectedRelationshipIds$.value.length &&
                    this.selectedRelationshipIds$.value.length < unpaginatedRelationshipIdsForCurrentFilter.length,
            ),
        );
    }

    get areStatusFiltersApplied() {
        return !this._arrayUtils.isFirstArrayEqualsToSecondArray(this._drStatusFilterInitialValue, this.drStatusFilter);
    }

    get areAssessmentPhaseFiltersApplied() {
        return !this._arrayUtils.isFirstArrayIncludedInSecondArray(
            this._assessmentPhaseInitialValue,
            this.assessmentPhaseFilter,
        );
    }

    get areRiskLevelFiltersApplied() {
        return !this._arrayUtils.isFirstArrayIncludedInSecondArray(
            this._riskLevelFilterInitialValue,
            this.riskLevelFilter,
        );
    }

    get areTierFiltersApplied() {
        return !this._arrayUtils.isFirstArrayIncludedInSecondArray(this._tierFilterInitialValue, this.tierFilter);
    }

    get areTagFiltersApplied() {
        return this.selectedTagNamesControl.value.length > 0;
    }

    get areNonTieringFiltersApplied(): boolean {
        return (
            this.areStatusFiltersApplied ||
            this.areAssessmentPhaseFiltersApplied ||
            this.areRiskLevelFiltersApplied ||
            this.keywordFilterApplied ||
            this.areTagFiltersApplied ||
            this.additionalFiltersApplied
        );
    }

    get unarchivedRelationshipsOnPage() {
        return this.relationships?.filter((relationship) => relationship.drStatus !== RequestStatus.ARCHIVED);
    }

    get bulkActionInProgressMessage$(): Observable<string> {
        return this.bulkRelationshipActionInProgress$.pipe(
            map((running) => (running ? 'There is currently another bulk-action running.' : null)),
        );
    }

    ngOnInit(): void {
        this.selectedRelationshipsOnCurrentPage$ = this.selectedRelationshipIds$.pipe(
            map((ids) => this.unarchivedRelationshipsOnPage.filter((r) => ids.includes(r.id))),
        );

        this._store$
            .pipe(select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support])), takeUntil(this._unsub$))
            .subscribe((isCurrentUserAuditor) => {
                this.isCurrentUserAuditor = isCurrentUserAuditor;
            });

        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub$)).subscribe((currentUser) => {
            this.doesCurrentUserHaveAvailableAssessments =
                currentUser?.clientLicense?.maxRelationshipsAssessed == -1
                    ? true
                    : currentUser?.clientLicense?.maxRelationshipsAssessed -
                          currentUser?.clientLicense?.relationshipsAssessedCount >
                      0;

            this.doesCurrentUserHaveAvailableRelationships =
                currentUser?.clientLicense?.maxRelationshipsCreated == -1
                    ? true
                    : currentUser?.clientLicense?.maxRelationshipsCreated -
                          currentUser?.clientLicense?.relationshipsCreatedCount >
                      0;

            this.editingTierDescription$ = this._store$.select(getEditingTierDescription);
        });

        this.isGraphExpanded$ = this._store$.select(getIsGraphExpanded).pipe(takeUntil(this._unsub$));

        this.currentAccount$ = this._store$.pipe(select(getUserAccount));

        this.tableSort$
            .pipe(
                map((sortData) => sortData && sortData[0]),
                takeUntil(this._unsub$),
            )
            .subscribe((sortItem) => {
                this.predicate = sortItem.prop;
                this.reverse = sortItem.dir !== 'desc';
            });

        // If we've switched tabs and filters have since changed, refresh relationships.
        this.listOrGraphRadioFormControl.valueChanges
            .pipe(
                takeUntil(this._unsub$),
                map(() => this.filter(this.filters)),
                distinctUntilChanged(),
                skip(1), // Relationships will be loaded elsewhere. Skip first execution.
            )
            .subscribe(() => this.loadRelationships());

        // If we've switched tabs for the first time, load relationships.
        this.listOrGraphRadioFormControl.valueChanges
            .pipe(
                takeUntil(this._unsub$),
                withLatestFrom(this.riskNetworkGraphNodes$),
                filter(
                    ([listViewType, riskNetworkNodes]) =>
                        (listViewType === ListOrGraphOptions.Graph && !riskNetworkNodes.length) ||
                        (listViewType === ListOrGraphOptions.List && !this.relationships.length),
                ),
            )
            .subscribe(() => this.loadRelationships());

        this._route.queryParams.pipe(take(1)).subscribe(({ search, sort, page, view, networkExposureOrgId, tier }) => {
            this.networkExposureOrgId = networkExposureOrgId;
            this._filterParamsFromUrl = search;
            this._tierIdFromUrl = tier;
            const sortPredicateFromUrl: string = !!sort ? (Array.isArray(sort) ? sort[0] : sort) : undefined;

            if (sortPredicateFromUrl) {
                const [prop, dir] = sortPredicateFromUrl.split(',');
                this.tableSort$.next([{ prop, dir }]);
            }

            this.page = +page || this.page;

            const vendorTiers$ = this._store$.select(getVendorTiers).pipe(map((tiers) => tiers ?? []));
            vendorTiers$.pipe(takeUntil(this._unsub$)).subscribe((tiers) => {
                this._tierFilterInitialValue = [...tiers.map((tier) => String(tier.id)), 'null'];
            });

            if (!!this._filterParamsFromUrl) {
                if (!this.relationshipsLoaded) {
                    this.toggleAllTiers();
                    this.setUrlFilters(this._filterParamsFromUrl);
                }
            } else {
                this.clearAllFilters();
            }

            this.userNavigatedToGraphOrListView = view === 'graph' ? ListOrGraphOptions.Graph : ListOrGraphOptions.List;
            this.listOrGraphRadioFormControl.setValue(this.userNavigatedToGraphOrListView);
            if (tier) {
                if (tier === 'allRelationships') {
                    this.setSelectedTierId(null);
                } else {
                    this.setSelectedTierId(+this._tierIdFromUrl);
                }
            } else {
                this.setSelectedTierId(null);
            }

            // After all is loaded, then set up vendor tiering.
            this.setupVendorTiering();
        });

        this.selectedTagNamesControl.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((selectedTagNames) => {
            this.applyFilter(
                RelationshipProps.tags,
                true,
                selectedTagNames.map((tag) => tag.name),
            );
        });

        this._actions$
            .pipe(
                ofType(
                    bulkStartAssessmentsRequestSuccess,
                    bulkStartAssessmentsRequestAccepted,
                    bulkCancelAssessmentsRequestSuccess,
                    bulkCancelAssessmentsRequestAccepted,
                    bulkLinkTagsRequestSuccess,
                    bulkDisableArtifactUpdatesRequestSuccess,
                    bulkEnableArtifactUpdatesRequestSuccess,
                    bulkArchiveRelationshipsRequestSuccess,
                    bulkArchiveRelationshipsRequestAccepted,
                    bulkOffboardRelationshipsRequestSuccess,
                    bulkOffboardRelationshipsRequestAccepted,
                    restoreRelationshipRequestSuccess,
                ),
                tap(() => {
                    this.selectedRelationshipIds$.next([]);
                    this.loadRelationships();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(ofType(relationshipsQuerySuccess), withLatestFrom(this.selectedTierId$), takeUntil(this._unsub$))
            .subscribe(([{ results, headers }, selectedTierId]) => {
                this.onSuccess(results, headers, selectedTierId);
            });

        this._actions$
            .pipe(ofType(relationshipsQueryFailed, queryRelationshipsForCsvRequestFailed), takeUntil(this._unsub$))
            .subscribe(() => (this.relationshipsLoading = false));

        this._store$
            .pipe(select(getRelationshipsColumnGroups))
            .pipe(
                map((columnsGroupsState) => {
                    this.initializeColumnsState(columnsGroupsState);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(queryRelationshipsForCsvRequestSuccess),
                withLatestFrom(this._store$.select(getVendorTiers)),
                takeUntil(this._unsub$),
            )
            .subscribe(([{ results }, vendorTiers]) => this.onConvertToCsvSuccess(results, vendorTiers));

        this.listOrGraphRadioFormControl.valueChanges
            .pipe(
                startWith(this.listOrGraphRadioFormControl.value),
                takeUntil(this._unsub$),
                filter(() => !!this.listGraphTabGroup),
            )
            .subscribe((option) => {
                this.listGraphTabGroup.selectedIndex = option === ListOrGraphOptions.List ? 0 : 1;
            });

        this._actions$
            .pipe(
                ofType(getRiskNetworkGraphRequestSuccess),
                map(({ riskNetworkGraphNodes }) => riskNetworkGraphNodes),
                tap((nodes) => {
                    this.relationshipsLoading = false;
                    this.totalGraphItems = nodes.length;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((nodes) => this.riskNetworkGraphNodes$.next(nodes));
        this.relationshipCounts$ = this._store$.select(getRelationshipCounts);
        this._store$.dispatch(LongRunningTaskActions.getInProgressLongRunningTasksRequest());

        this._store$
            .select(getUserProfile)
            .pipe(filter(Boolean), first())
            .subscribe((user) => {
                if (user.showInstantAnalysisRLPWalkthrough) {
                    this._store$.dispatch(startSpotlightTour({ tourType: SpotlightTourType.INSTANT_ANALYSIS_RLP }));
                    this._store$.dispatch(
                        updateUserProfileRequest({
                            userProfile: { ...user, showInstantAnalysisRLPWalkthrough: false },
                        }),
                    );
                }
            });
    }

    ngAfterViewInit(): void {
        this.initializeKeywordFilter();
        this.setListOrGraphTabIndex(
            this.userNavigatedToGraphOrListView === ListOrGraphOptions.Graph ? 'graph' : 'list',
        );
    }

    setupVendorTiering(): void {
        // Wipe out any cached stats when we visit the page as they might be stale.
        this._store$.dispatch(clearVendorTierStats());
        this._store$.dispatch(getRiskThresholdsForVendorTierStats());
        this._store$.dispatch(getRelationshipCountsRequest());
        this.selectedTierId$ = this._store$.select(getSelectedTierId);

        this.vendorTiersVM$ = combineLatest([
            this.selectedTierId$,
            this._store$.select(getVendorTiers),
            this._store$.select(getVendorTierStats),
            this._store$.select(getProfileRiskTolerance),
            this._store$.select(getRiskToleranceThresholds),
        ]).pipe(
            map(([selectedTierId, tiers, stats, riskTolerance, riskToleranceThresholds]) => ({
                allVendorTiers: tiers ?? [],
                vendorTierStats: stats.find((s) => s.vendorTierId === selectedTierId),
                description: tiers?.find((s) => s.id === selectedTierId)?.description,
                riskTolerance,
                riskToleranceThresholds,
            })),
        );

        this.selectedTierId$
            .pipe(
                distinctUntilChanged(),
                withLatestFrom(
                    this._store$.select(getVendorTierStats),
                    this._store$.select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support])),
                ),
                filter(([, , isAuditorOrSupport]) => !isAuditorOrSupport),
                tap(([selectedTierId, stats, isAuditorOrSupport]) => {
                    // As a side effect, lazy-load stats for each tier, and only
                    // if we haven't already loaded stats for that tier id.
                    if (!stats.some((stat) => stat.vendorTierId === selectedTierId)) {
                        this._store$.dispatch(getVendorTierStatsRequest({ vendorTierId: selectedTierId }));
                    }
                }),
                // De-select any selected relationships when moving between tiers.
                tap(() => this.selectedRelationshipIds$.next([])),
                takeUntil(this._unsub$),
            )
            .subscribe(([selectedTierId]) => {
                if (!!selectedTierId) {
                    this.applyFilter(RelationshipProps.vendorTierId, true, [selectedTierId.toString()]);
                } else if (!this.tierFilter || this.tierFilter?.length === 0) {
                    this.removeFilter(RelationshipProps.vendorTierId);
                }
            });

        this._actions$
            .pipe(
                ofType(updateVendorTiersCountRequestSuccess),
                withLatestFrom(this.selectedTierId$),
                takeUntil(this._unsub$),
            )
            .subscribe(([{ vendorTiers }, selectedTierId]) => {
                // Reset the selected tier if we removed the one we had selected.
                if (!vendorTiers.some((t) => t.id === selectedTierId)) {
                    this.setSelectedTierId(null);
                }
            });

        this._actions$
            .pipe(
                ofType(assignRelationshipsToTierRequest),
                withLatestFrom(this.selectedTierId$),
                takeUntil(this._unsub$),
                tap(() => (this.relationshipsLoading = true)),
                tap(([{ tier }, selectedTierId]) => {
                    if ((tier?.id ?? null) !== selectedTierId) {
                        this._store$.dispatch(clearVendorTierStats());
                    }
                }),
                switchMap(([{ tier }]) =>
                    // Jump to the tier we just assigned (or 'All relationships' if we unassigned).
                    this._actions$.pipe(
                        ofType(assignRelationshipsToTierRequestSuccess),
                        tap(() => this.setSelectedTierId(tier?.id ?? null)),
                        tap(() => (this.relationshipsLoading = false)),
                    ),
                ),
            )
            .subscribe();
    }

    startAssessmentsButtonTooltipMessage(bulkActionInProgress: boolean): Observable<string> {
        if (bulkActionInProgress) {
            return this.bulkActionInProgressMessage$;
        }
        return of(
            this.doesCurrentUserHaveAvailableAssessments
                ? null
                : 'You do not have any more available assessments. Please contact sales@visotrust.com for more information.',
        );
    }

    isRelationshipUpForRecertification(relationship: VendorRelationship): boolean {
        return (
            relationship.drStatus === RequestStatus.ONBOARDED &&
            (relationship.upcomingRecertification || relationship.overdueRecertification)
        );
    }

    doesRelationshipHaveInProgressAssessment(row: VendorRelationship): boolean {
        return this._assessmentUtils.isAssessmentInProgress(row.assessmentStatusCode);
    }

    getRelationshipRecertificationTooltipMessage(relationship: VendorRelationship): string {
        if (relationship.drStatus !== RequestStatus.ONBOARDED) {
            return null;
        }
        if (this.doesRelationshipHaveInProgressAssessment(relationship)) {
            if (relationship.upcomingRecertification || relationship.overdueRecertification) {
                return 'Recertification in progress';
            }
        } else {
            if (relationship.upcomingRecertification) {
                return 'Recertification due soon';
            }
            if (relationship.overdueRecertification) {
                return 'Recertification overdue';
            }
        }
        return null;
    }

    initializeColumnsState(columnGroups: RelationshipColumnGroup[]) {
        this.columnGroups = columnGroups;
        this.lastColumnsInGroup = [];
        if (this.columnGroups.length === 1) {
            this.columns = this.columnGroups[0].columns;
        } else {
            const columns: RelationshipColumn[] = [];
            for (const columnGroup of this.columnGroups) {
                columns.push(...columnGroup.columns);
                const matchingColumn = [...columnGroup.columns].reverse().find((c) => c.visible);
                if (matchingColumn) {
                    this.lastColumnsInGroup.push(matchingColumn);
                }
            }
            this.lastColumnsInGroup = this.lastColumnsInGroup.slice(0, -1);
            this.columns = columns;
        }
    }

    initializeKeywordFilter(): void {
        this.keywordFilter$
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                filter((s) => s !== null),
                map((searchText) => {
                    if (searchText.trim().length > 0) {
                        this.filters = {
                            ...this.filters,
                            keyword: { values: [searchText], keyword: true },
                        };

                        this.keywordFilterApplied = true;
                        this.loadRelationships();
                        this.addFilterParamToUrl();
                    } else {
                        this.removeFilter('keyword');
                        this.keywordFilterApplied = false;
                        return EMPTY;
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    loadRelationships(): void {
        this.relationshipsLoading = true;
        const listOrGraphValue = this.listOrGraphRadioFormControl.value;
        if (listOrGraphValue === ListOrGraphOptions.List) {
            this._store$.dispatch(
                relationshipsQueryRequest({
                    params: {
                        page: this.page,
                        size: this.itemsPerPage,
                        sort: this.sort(),
                        search: this.filter(this.filters),
                        networkExposureOrgId: this.networkExposureOrgId,
                    },
                }),
            );
        } else {
            this._store$.dispatch(
                getRiskNetworkGraphRequest({
                    searchString: this.filter(this.filters),
                    networkExposureOrgId: this.networkExposureOrgId,
                }),
            );
        }
    }

    formatRelationships(relationships: VendorRelationship[]): VendorRelationship[] {
        return relationships.map((r) => {
            const assessmentStatus =
                RelationshipAssessmentPhases[r.assessmentStatus] || this._notAssessedAssessmentPhase;
            return {
                ...r,
                assessmentStatus: assessmentStatus,
                assessmentPhaseTooltipMessage: this.getAssessmentPhaseToolTip(assessmentStatus),
                assessmentStatusCode: r.assessmentStatus as AssessmentStatus,
            };
        });
    }

    getAssessmentPhaseCellClass = ({ row, column }): { [className: string]: boolean } => {
        return {
            ['order-' + this.getColumnOrder(column.name)]: true,
            'group-ending': this.getLastColumnInGroup(column.name),
            'text-muted': row.assessmentStatus === RelationshipAssessmentPhases.NOT_ASSESSED,
            'text-bold': row.assessmentStatus === RelationshipAssessmentPhases.COMPLETED,
        };
    };

    getAssessmentPhaseTextClass(assessmentStatus: RelationshipAssessmentPhases) {
        return {
            'status-pill': assessmentStatus === RelationshipAssessmentPhases.AUDIT_COMPLETED,
        };
    }

    getDrStatusPhaseClass = ({ row, column }): { [className: string]: boolean } => {
        return {
            ['order-' + this.getColumnOrder(column.name)]: true,
            'group-ending': this.getLastColumnInGroup(column.name),
            'text-bold': row.drStatus === RequestStatus.ONBOARDED,
        };
    };

    getArtifactUpdateClass = ({ row, column }): { [className: string]: boolean } => {
        return {
            ['order-' + this.getColumnOrder(column.name)]: true,
            'group-ending': this.getLastColumnInGroup(column.name),
        };
    };

    getAssessmentPhaseToolTip(assessmentStatus: string) {
        switch (assessmentStatus) {
            case RelationshipAssessmentPhases.STARTED:
                return 'Request initiated';
            case RelationshipAssessmentPhases.REVIEW_STARTED:
                return 'Request being processed by VISO';
            case RelationshipAssessmentPhases.COMPLETED:
                return 'Risk summary generated';
            case RelationshipAssessmentPhases.COLLECTING_INFORMATION:
                return 'Awaiting response from vendor';
            case RelationshipAssessmentPhases.AUDIT_COMPLETED:
                return 'Waiting on client decision for follow-up';
            default:
                return 'Request not initiated';
        }
    }

    getRecertificationType(recertificationType: string) {
        switch (recertificationType) {
            case RecertificationType.MANUAL:
                return 'Reminders Only';
            case RecertificationType.AUTOMATIC:
                return 'Automatic';
            case RecertificationType.NONE:
                return 'None';
            default:
                return 'Recertification not enabled';
        }
    }

    getArtifactUpdateType(artifactUpdateType: RecertificationType) {
        switch (artifactUpdateType) {
            case RecertificationType.MANUAL:
                return 'Reminders Only';
            case RecertificationType.AUTOMATIC:
                return 'Automatic';
            case RecertificationType.NONE:
                return 'None';
            default:
                return 'Artifact update not enabled';
        }
    }

    getRowInactiveClass = (relationship: VendorRelationship) => ({
        inactive: relationship.drStatus === this.relationshipArchivedStatus,
    });

    getColumnClass(columnName: string) {
        const classes = [];
        classes.push(`order-${this.getColumnOrder(columnName)}`);
        classes.push(`${this.getLastColumnInGroup(columnName) ? 'group-ending' : ''}`);
        return classes.join(' ');
    }

    getColumnVisibility(columnName: string) {
        const matchingColumn = this.columns.find((c) => c.columnName === columnName);
        return !!matchingColumn?.visible;
    }

    startAssessments() {
        this._store$.dispatch(
            bulkStartAssessmentsRequest({
                ids: this.selectedRelationshipIds$.value,
                assessmentsInProgress: this.doRelationshipsHaveInProgressAssessments(
                    this.selectedRelationshipIds$.value,
                ),
                countToEagerlyProcess: this.itemsPerPage,
            }),
        );
    }

    doRelationshipsHaveInProgressAssessments(relationshipsSelectedIds: number[]): boolean {
        let doesRelationshipHaveInProgressAssessment: boolean = false;

        relationshipsSelectedIds.forEach((selectedId) => {
            let filtered = this.relationships.find((r) => r.id === selectedId);
            if (
                !!filtered &&
                (filtered.assessmentStatus === RelationshipAssessmentPhases.STARTED ||
                    filtered.assessmentStatus === RelationshipAssessmentPhases.REVIEW_STARTED ||
                    filtered.assessmentStatus === RelationshipAssessmentPhases.COLLECTING_INFORMATION ||
                    filtered.assessmentStatus === RelationshipAssessmentPhases.AUDIT_COMPLETED)
            ) {
                doesRelationshipHaveInProgressAssessment = true;
            }
        });

        return doesRelationshipHaveInProgressAssessment;
    }

    cancelAssessments(): void {
        this._store$.dispatch(
            bulkCancelAssessmentsRequest({
                ids: this.selectedRelationshipIds$.value,
                showConfirmationModal: this.doRelationshipsHaveInProgressAssessments(
                    this.selectedRelationshipIds$.value,
                ),
                countToEagerlyProcess: this.itemsPerPage,
            }),
        );
    }

    disableArtifactUpdates(relationshipsId = []): void {
        const relationshipsSelectedIds = relationshipsId.length ? relationshipsId : this.selectedRelationshipIds$.value;

        this._store$.dispatch(
            bulkDisableArtifactUpdatesRequest({
                ids: [...relationshipsSelectedIds],
            }),
        );
    }

    enableArtifactUpdates(relationshipsId = []) {
        const relationshipsSelectedIds = relationshipsId.length ? relationshipsId : this.selectedRelationshipIds$.value;

        this._store$.dispatch(
            bulkEnableArtifactUpdatesRequest({
                ids: [...relationshipsSelectedIds],
            }),
        );
    }

    archiveRelationships(relationshipsId = []) {
        const relationshipsSelectedIds = relationshipsId.length ? relationshipsId : this.selectedRelationshipIds$.value;
        this._store$.dispatch(
            bulkArchiveRelationshipsRequest({
                relationshipIds: [...relationshipsSelectedIds],
                countToEagerlyProcess: this.itemsPerPage,
            }),
        );
    }

    offboardSelectedRelationships(relationshipsId?: number[]): void {
        const selectedRelationshipIds = !!relationshipsId?.length
            ? relationshipsId
            : this.selectedRelationshipIds$.value;
        this._store$.dispatch(
            bulkOffboardRelationshipsRequest({
                relationshipIds: [...selectedRelationshipIds],
                countToEagerlyProcess: this.itemsPerPage,
            }),
        );
    }

    setPagination(value) {
        this.rowLabel = value;
        this.itemsPerPage = value;
        this.loadRelationships();
    }

    onSort({ sorts }) {
        this.predicate = sorts[0].prop;
        this.reverse = sorts[0].dir !== 'desc';
        this.relationshipsTable.offset = this.page;
        this.selectedRelationshipIds$.next([]);
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { sort: `${this.predicate},${sorts[0].dir}` },
            queryParamsHandling: 'merge',
        });
        this.loadRelationships();
    }

    onSelect({ selected }) {
        this._store$.dispatch(clearAllUnpaginatedRelationshipIds());
        this.selectedRelationshipIds$.next(
            selected.filter((x) => x.drStatus !== this.relationshipArchivedStatus).map((r) => r.id),
        );

        if (this.selectedRelationshipIds$.value.length === this.unarchivedRelationshipsOnPage.length) {
            // Lazily load all of the relationship ids for the current filter, putting
            // it off until & unless the user selects all relationships on the page.
            const copiedFilters = JSON.parse(JSON.stringify(this.filters));
            copiedFilters['drStatus'] = { equals: true, values: ['NOT_ONBOARDED', 'ONBOARDED'] };
            this._store$.dispatch(getAllRelationshipIdsQueryRequest({ search: this.filter(copiedFilters) }));
        }
    }

    onSelectAllPages(): void {
        this._store$
            .select(getUnpaginatedIdsForCurrentFilter)
            .pipe(take(1))
            .subscribe((ids) => this.selectedRelationshipIds$.next(ids));
    }

    clearSelection() {
        this.selectedRelationshipIds$.next([]);
    }

    setCurrentPage({ page }) {
        this.page = +page - 1;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { page: this.page },
            queryParamsHandling: 'merge',
        });
        this.selectedRelationshipIds$.next([]);
        this.loadRelationships();
    }

    keywordFilter(event: any) {
        this.page = 0;
        this.keywordFilter$.next(event.target.value);
    }

    clearKeywordFilter() {
        this.page = 0;
        this.keywordInputFilter.nativeElement.value = '';
        this.keywordFilter$.next('');
    }

    applyFilter(property: string, equals: boolean, values: string[], keyword = false) {
        this.filters = {
            ...this.filters,
            [property]: {
                equals,
                values,
                keyword,
            },
        };
        this.page = 0;
        this.loadRelationships();
        this.addFilterParamToUrl();
    }

    applyMultipleFilters(filters: any) {
        this.isNthPartiesFilterApplied = !!filters[AdditionalFilters.nthParties];
        for (const key in filters) {
            if (
                (Array.isArray(filters[key]) && filters[key].length > 0) ||
                (!Array.isArray(filters[key]) && filters[key])
            ) {
                this.filters = {
                    ...this.filters,
                    [key]: {
                        date:
                            Array.isArray(filters[key]) &&
                            filters[key].some((f: any) => this._dateUtilsService.isAValidDate(f)),
                        equals: true,
                        values: Array.isArray(filters[key]) ? filters[key] : [filters[key]],
                    },
                };
            } else if (this.filters[key]) {
                delete this.filters[key];
            }
        }
        this.page = 0;
        this.loadRelationships();
        this.addFilterParamToUrl();
    }

    removeFilter(property: string): void {
        if (!!this.filters[property]) {
            delete this.filters[property];
            this.page = 0;
            this.loadRelationships();
            this.addFilterParamToUrl();
        }
    }

    resetAllFilters() {
        this.drStatusFilter = [...this._drStatusFilterInitialValue];
        this.assessmentPhaseFilter = [...this._assessmentPhaseInitialValue];
        this.riskLevelFilter = [...this._riskLevelFilterInitialValue];
        this.tierFilter = [...this._tierFilterInitialValue];
        this.selectedTagNamesControl.setValue([]);

        for (const key in this.filters) {
            if (key !== RelationshipProps.vendorTierId) {
                delete this.filters[key];
            }
        }

        this.clearAllUrlParams();

        if (this.keywordInputFilter && this.keywordInputFilter.nativeElement.value) {
            this.filters = { drStatus: { equals: true, values: this.drStatusFilter } };
            this.clearKeywordFilter();
        } else {
            this.applyFilter(RelationshipProps.drStatus, true, this.drStatusFilter);
        }
    }

    clearAllFilters() {
        this.networkExposureOrgId = null;
        this._store$.dispatch(clearAllFilters());
        this.resetAllFilters();
    }

    applyAdditionalFilters(event: any) {
        this.additionalFiltersApplied = event.additionalFiltersApplied;
        if (!event.clearFilters) {
            this.applyMultipleFilters(event.filters);
        }
    }

    changeStatusFilter(event: any) {
        const { checked, value } = event.target;
        if (checked) {
            this.drStatusFilter.push(value);
        } else {
            this.drStatusFilter = this.drStatusFilter.filter((s) => s !== value);
        }

        this.applyFilter(RelationshipProps.drStatus, true, this.drStatusFilter);
    }

    setStatusFilter(requestStatus: RequestStatus): void {
        this.drStatusFilter = [];
        this.drStatusFilter.push(requestStatus);
        this.applyFilter(RelationshipProps.drStatus, true, this.drStatusFilter);
    }

    changeAssessmentPhaseFilter(event: any) {
        const { checked, value } = event.target;
        if (checked) {
            this.assessmentPhaseFilter.push(value);
        } else {
            this.assessmentPhaseFilter = this.assessmentPhaseFilter.filter((s) => s !== value);
        }

        const includeEmptyValues = this.assessmentPhaseFilter.some((s) => !s);
        if (includeEmptyValues) {
            const emptyValueFilter = this._assessmentPhaseFullValues.filter(
                (s) => !this.assessmentPhaseFilter.includes(s),
            );
            this.applyFilter(RelationshipProps.assessmentStatus, false, emptyValueFilter);
        } else {
            this.applyFilter(RelationshipProps.assessmentStatus, true, this.assessmentPhaseFilter);
        }
    }

    changeTierFilter(event: any) {
        const { checked, value } = event.target;
        if (checked) {
            this.tierFilter.push(value);
        } else {
            this.tierFilter = this.tierFilter.filter((s) => s !== value);
        }
        this.applyFilter(RelationshipProps.vendorTierId, true, this.tierFilter);
    }

    changeRiskLevelFilter(event: any) {
        if (event) {
            const { checked, value } = event.target;
            if (checked) {
                this.riskLevelFilter.push(value);
            } else {
                this.riskLevelFilter = this.riskLevelFilter.filter((s) => s !== value);
            }
        }
        this.applyFilter(RelationshipProps.residualRisk, true, this.riskLevelFilter);
    }

    toggleAllStatuses() {
        if (this.drStatusFilter.length == this._drStatusFilterAllValues.length) {
            this.drStatusFilter = [];
        } else {
            this.drStatusFilter = [...this._drStatusFilterAllValues];
        }
        this.applyFilter(RelationshipProps.drStatus, true, this.drStatusFilter);
    }

    toggleAllAssessmentPhases() {
        if (this.assessmentPhaseFilter.length === this._assessmentPhaseInitialValue.length) {
            this.assessmentPhaseFilter = [];
            this.applyFilter(RelationshipProps.assessmentStatus, true, this.assessmentPhaseFilter);
        } else {
            this.assessmentPhaseFilter = [...this._assessmentPhaseFullValues];
            this.removeFilter(RelationshipProps.assessmentStatus);
        }
    }

    toggleAllTiers() {
        if (this.tierFilter.length == this._tierFilterInitialValue.length) {
            this.tierFilter = [];
        } else {
            this.tierFilter = [...this._tierFilterInitialValue];
        }
        this.applyFilter(RelationshipProps.vendorTierId, true, this.tierFilter);
    }

    toggleAllRiskLevels() {
        if (this.riskLevelFilter.length == this._riskLevelFilterInitialValue.length) {
            this.riskLevelFilter = [];
        } else {
            this.riskLevelFilter = [...this._riskLevelFilterInitialValue];
        }
        this.applyFilter(RelationshipProps.residualRisk, true, this.riskLevelFilter);
    }

    openEditColumnsModal() {
        this._modalService.open(RelationshipsEditColumnsComponent, { size: 'sm' });
    }

    toggleFilterPanel(): void {
        this.additionalFiltersPanel.toggleFilterPanel();
    }

    exportToCsv() {
        this.relationshipsLoading = true;
        this._store$.dispatch(
            queryRelationshipsForCsvRequest({
                params: {
                    size: 999999,
                    sort: ['updatedDate,desc'],
                    search: this.filter(this.filters),
                },
            }),
        );
    }

    getResidualRiskTooltipText(relationship: VendorRelationship, riskLevelDisplayName: string | null): string {
        return this._riskUtils.getResidualRiskTooltipTextForVendorRelationship(relationship, riskLevelDisplayName);
    }

    getInherentRiskTooltipText(relationship: VendorRelationship): string {
        return this._riskUtils.getInherentRiskTooltipTextForImportedRelationship(
            relationship?.latestRiskAssessmentLegacy,
            relationship?.createdDate,
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private addFilterParamToUrl(): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
                search: this.filter(this.filters),
                tier: this._tierIdFromUrl,
            },
        });
    }

    private clearAllUrlParams(): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {},
        });
    }

    private getColumnOrder(columnName: string): number {
        const matchingColumn = this.columns.find((c) => c.columnName === columnName);
        return this.columns.indexOf(matchingColumn);
    }

    private getLastColumnInGroup(columnName: string): boolean {
        const matchingColumn = this.lastColumnsInGroup.find((c) => c.columnName === columnName);
        return !!matchingColumn;
    }

    private filter(filters: any): string {
        let filtersParamsString: string = '';
        for (const key in filters) {
            let finalFilterName = key;
            if (key === AdditionalFilters.contextTypes) {
                finalFilterName = RelationshipProps.contextTypes;
            }
            if (key === AdditionalFilters.dataTypes) {
                finalFilterName = RelationshipProps.dataTypes;
            }
            if (key === AdditionalFilters.nthParties) {
                finalFilterName = RelationshipProps.nthParties;
            }
            const isFirstParam = filtersParamsString.length === 0;
            filtersParamsString += isFirstParam ? '' : ',';
            if (filters[key].keyword) {
                filtersParamsString += filters[key].values.map((v: string) => `text~${v}`);
            } else if (filters[key].date) {
                if (filters[key].values[0]) {
                    filtersParamsString += `${finalFilterName}>:${filters[key].values[0]},`;
                }

                if (filters[key].values[1]) {
                    filtersParamsString += `${finalFilterName}<:${filters[key].values[1]}`;
                }
            } else {
                if (filters[key].equals) {
                    const singleValue = filters[key].values.length === 1;
                    filtersParamsString += singleValue
                        ? `${finalFilterName}:${filters[key].values[0]}`
                        : `${finalFilterName}:[${filters[key].values.join('|')}]`;
                } else {
                    filtersParamsString += filters[key].values.map((v: string) => `${key}!${v}`);
                }
            }
        }
        return filtersParamsString;
    }

    private setUrlFilters(searchString: string): void {
        const filters = searchString.split(/,(?![^\[]*\])/g);
        const notIncludedValues = [];

        let createdByValues = [];
        let phaseDateValues = [];
        let recertificationDateValues = [];
        let businessUnitValues = [];
        let businessOwnerValues = [];
        let recertificationTypeValues = [];
        let lastUpdatedValues = [];
        let createdOnValues = [];
        let clientValues = [];
        let tagsValues = [];
        let contextTypesValues = [];
        let dataTypesValues = [];
        let vendorOrgValues = [];
        let nthPartiesNames = [];
        let overdueValue = null;
        let upcomingValue = null;
        let hasCompletedValue = null;
        let assessedValue = null;
        let licenseTypeValues = [];
        let artifactUpdateTypeValues = [];
        let remediationRequestedValues = null;
        let riskAcceptedValue = null;

        for (const filter of filters) {
            if (filter.includes(RelationshipProps.drStatus)) {
                const drStatusFilter = filter.split(':');
                const valueGroup = drStatusFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = drStatusFilter[1].slice(1, -1);
                    this.drStatusFilter = valueString.split('|');
                } else {
                    this.drStatusFilter = [drStatusFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.drStatus]: {
                        equals: true,
                        values: this.drStatusFilter,
                    },
                };
            }

            if (filter.includes(RelationshipProps.assessmentStatus)) {
                const isEqualOperator = filter.includes(':');
                const isNotEqualOperator = filter.includes('!');

                if (isEqualOperator) {
                    const assessmentPhaseFilter = filter.split(':');
                    this.assessmentPhaseFilter = [];

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.STARTED)) {
                        this.assessmentPhaseFilter.push(AssessmentStatus.STARTED);
                    }

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.REVIEW_STARTED)) {
                        this.assessmentPhaseFilter.push(AssessmentStatus.REVIEW_STARTED);
                    }

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.COMPLETED)) {
                        this.assessmentPhaseFilter.push(AssessmentStatus.COMPLETED);
                    }

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.COLLECTING_INFORMATION)) {
                        this.assessmentPhaseFilter.push(AssessmentStatus.COLLECTING_INFORMATION);
                    }

                    this.filters = {
                        ...this.filters,
                        [RelationshipProps.assessmentStatus]: {
                            equals: true,
                            values: this.assessmentPhaseFilter,
                        },
                    };
                } else if (isNotEqualOperator) {
                    const assessmentPhaseFilter = filter.split('!');
                    this.assessmentPhaseFilter =
                        this.assessmentPhaseFilter.length === 0
                            ? [...this._assessmentPhaseInitialValue]
                            : this.assessmentPhaseFilter;

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.STARTED)) {
                        this.assessmentPhaseFilter = this.assessmentPhaseFilter.filter(
                            (s) => s !== AssessmentStatus.STARTED,
                        );
                        notIncludedValues.push(AssessmentStatus.STARTED);
                    }

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.REVIEW_STARTED)) {
                        this.assessmentPhaseFilter = this.assessmentPhaseFilter.filter(
                            (s) => s !== AssessmentStatus.REVIEW_STARTED,
                        );
                        notIncludedValues.push(AssessmentStatus.REVIEW_STARTED);
                    }

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.COMPLETED)) {
                        this.assessmentPhaseFilter = this.assessmentPhaseFilter.filter(
                            (s) => s !== AssessmentStatus.COMPLETED,
                        );
                        notIncludedValues.push(AssessmentStatus.COMPLETED);
                    }

                    if (assessmentPhaseFilter[1].includes(AssessmentStatus.COLLECTING_INFORMATION)) {
                        this.assessmentPhaseFilter = this.assessmentPhaseFilter.filter(
                            (s) => s !== AssessmentStatus.COLLECTING_INFORMATION,
                        );
                        notIncludedValues.push(AssessmentStatus.COLLECTING_INFORMATION);
                    }

                    this.filters = {
                        ...this.filters,
                        [RelationshipProps.assessmentStatus]: {
                            equals: false,
                            values: [...notIncludedValues],
                        },
                    };
                }
            }

            if (filter.includes(RelationshipProps.residualRisk)) {
                const residualRiskFilter = filter.split(':');
                this.riskLevelFilter = [];

                if (residualRiskFilter[1].includes('NO_CONTEXT')) {
                    this.riskLevelFilter.push('NO_CONTEXT');
                }
                if (residualRiskFilter[1].includes('LOW')) {
                    this.riskLevelFilter.push('LOW');
                }
                if (residualRiskFilter[1].includes('MEDIUM')) {
                    this.riskLevelFilter.push('MEDIUM');
                }
                if (residualRiskFilter[1].includes('HIGH')) {
                    this.riskLevelFilter.push('HIGH');
                }
                if (residualRiskFilter[1].includes('EXTREME')) {
                    this.riskLevelFilter.push('EXTREME');
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.residualRisk]: {
                        equals: true,
                        values: this.riskLevelFilter,
                    },
                };
            }

            if (filter.includes(RelationshipProps.inherentRisk)) {
                const inherentRiskFilter = filter.split(':');
                this.inherentRiskLevelFilter = [];

                if (inherentRiskFilter[1].includes('NO_CONTEXT')) {
                    this.inherentRiskLevelFilter.push('NO_CONTEXT');
                }
                if (inherentRiskFilter[1].includes('LOW')) {
                    this.inherentRiskLevelFilter.push('LOW');
                }
                if (inherentRiskFilter[1].includes('MEDIUM')) {
                    this.inherentRiskLevelFilter.push('MEDIUM');
                }
                if (inherentRiskFilter[1].includes('HIGH')) {
                    this.inherentRiskLevelFilter.push('HIGH');
                }
                if (inherentRiskFilter[1].includes('EXTREME')) {
                    this.inherentRiskLevelFilter.push('EXTREME');
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.inherentRisk]: {
                        equals: true,
                        values: this.inherentRiskLevelFilter,
                    },
                };
            }

            if (filter.includes(RelationshipProps.vendorTierId)) {
                const tierFilter = filter.split(':');
                this.tierFilter = [];

                const ids = tierFilter[1].match(/\d+|null/g) || [];
                this.tierFilter.push(...ids);
                this.applyFilter(RelationshipProps.vendorTierId, true, this.tierFilter);

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.vendorTierId]: {
                        equals: true,
                        values: this.tierFilter,
                    },
                };
                this.setupVendorTiering();
            }

            if (filter.includes('text~')) {
                const keyWordFilter = filter.split('~');
                setTimeout(() => {
                    this.keywordInputFilter.nativeElement.value = keyWordFilter[1];
                });
                this.keywordFilterApplied = true;

                this.filters = {
                    ...this.filters,
                    keyword: { values: [keyWordFilter[1]], keyword: true },
                };
            }

            if (filter.includes(RelationshipProps.creatorId)) {
                const creatorFilter = filter.split(':');
                const valueGroup = creatorFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = creatorFilter[1].slice(1, -1);
                    createdByValues = valueString.split('|');
                } else {
                    createdByValues = [creatorFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.creatorId]: {
                        date: false,
                        equals: true,
                        values: createdByValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.businessOwnerId)) {
                const businessOwnerFilter = filter.split(':');
                const valueGroup = businessOwnerFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = businessOwnerFilter[1].slice(1, -1);
                    businessOwnerValues = valueString.split('|');
                } else {
                    businessOwnerValues = [businessOwnerFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.businessOwnerId]: {
                        date: false,
                        equals: true,
                        values: businessOwnerValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.vendorOrgId)) {
                const vendorOrgFilter = filter.split(':');
                const valueGroup = vendorOrgFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = vendorOrgFilter[1].slice(1, -1);
                    vendorOrgValues = valueString.split('|');
                } else {
                    vendorOrgValues = [vendorOrgFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.vendorOrgId]: {
                        date: false,
                        equals: true,
                        values: vendorOrgValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.nthParties)) {
                const nthPartiesFilter = filter.split(':');
                const valueGroup = nthPartiesFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = nthPartiesFilter[1].slice(1, -1);
                    nthPartiesNames = valueString.split('|');
                } else {
                    nthPartiesNames = [nthPartiesFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.nthParties]: {
                        date: false,
                        equals: true,
                        values: nthPartiesNames,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.recertificationType)) {
                const recertificationTypeFilter = filter.split(':');
                const valueGroup = recertificationTypeFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = recertificationTypeFilter[1].slice(1, -1);
                    recertificationTypeValues = valueString.split('|');
                } else {
                    recertificationTypeValues = [recertificationTypeFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.recertificationType]: {
                        date: false,
                        equals: true,
                        values: recertificationTypeValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.assessmentCompletedDate)) {
                const isStartDate = filter.includes('>:');
                if (isStartDate) {
                    phaseDateValues[0] = filter.split('>:')[1];
                } else {
                    phaseDateValues[1] = filter.split('<:')[1];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.assessmentCompletedDate]: {
                        date: true,
                        equals: true,
                        values: phaseDateValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.recertificationDate)) {
                const startDate = filter.includes('>:');
                if (startDate) {
                    recertificationDateValues[0] = filter.split('>:')[1];
                } else {
                    recertificationDateValues[1] = filter.split('<:')[1];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.recertificationDate]: {
                        date: true,
                        equals: true,
                        values: recertificationDateValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.businessUnitId)) {
                const businessUnitFilter = filter.split(':');
                const valueGroup = businessUnitFilter[1].includes('[');
                if (valueGroup) {
                    const valueString = businessUnitFilter[1].replace(/,/g, '|').slice(1, -1);
                    businessUnitValues = valueString.split('|');
                } else {
                    businessUnitValues = [businessUnitFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.businessUnitId]: {
                        date: false,
                        equals: true,
                        values: businessUnitValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.updatedDate)) {
                const startDate = filter.includes('>:');
                if (startDate) {
                    lastUpdatedValues[0] = filter.split('>:')[1];
                } else {
                    lastUpdatedValues[1] = filter.split('<:')[1];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.updatedDate]: {
                        date: true,
                        equals: true,
                        values: lastUpdatedValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.createdDate)) {
                const predeterminedValue = Object.values(MetabasePredeterminedDatesRanges).find((value) =>
                    filter.includes(value as string),
                );
                if (predeterminedValue) {
                    const range = this._dateUtilsService.getDatesFromDateRangeOption(
                        predeterminedValue as MetabasePredeterminedDatesRanges,
                    );
                    createdOnValues[0] = range[0].format('YYYY-MM-DD');
                    createdOnValues[1] = range[1].format('YYYY-MM-DD');
                } else {
                    const startDate = filter.includes('>:');
                    if (startDate) {
                        createdOnValues[0] = filter.split('>:')[1];
                    } else {
                        createdOnValues[1] = filter.split('<:')[1];
                    }
                }
                this.filters = {
                    ...this.filters,
                    [RelationshipProps.createdDate]: {
                        date: true,
                        equals: true,
                        values: createdOnValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.customerOrgId)) {
                const clientFilter = filter.split(':');
                const valueGroup = clientFilter[1].includes('[');
                if (valueGroup) {
                    const valueString = clientFilter[1].slice(1, -1);
                    clientValues = valueString.split('|');
                } else {
                    clientValues = [clientFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.customerOrgId]: {
                        date: false,
                        equals: true,
                        values: clientValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.tags)) {
                const tagsFilter = filter.split(':');
                const valueGroup = tagsFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = tagsFilter[1].replace(/,/g, '|').slice(1, -1);
                    tagsValues = valueString.split('|');
                } else {
                    tagsValues = [tagsFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.tags]: {
                        date: false,
                        equals: true,
                        values: tagsValues,
                    },
                };

                this._actions$.pipe(ofType(getTagsRequestSuccess), first()).subscribe(({ tags }) => {
                    this.selectedTagNamesControl.setValue(
                        tags.filter((tag) => tagsValues.includes(tag.name), { emitValue: false }),
                    );
                });
            }

            if (filter.includes(RelationshipProps.contextTypes)) {
                const contextTypeFilter = filter.split(':');
                const valueGroup = contextTypeFilter[1].includes('[');
                if (valueGroup) {
                    const valueString = contextTypeFilter[1].slice(1, -1);
                    const cleanedString = valueString.replace(/,/g, '|');
                    contextTypesValues = cleanedString.split('|');
                } else {
                    contextTypesValues = [contextTypeFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.contextTypes]: {
                        date: false,
                        equals: true,
                        values: contextTypesValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.dataTypes)) {
                const dataTypeFilter = filter.split(':');
                const valueGroup = dataTypeFilter[1].includes('[');
                if (valueGroup) {
                    const valueString = dataTypeFilter[1].slice(1, -1);
                    const cleanedString = valueString.replace(/,/g, '|');
                    dataTypesValues = cleanedString.split('|');
                } else {
                    dataTypesValues = [dataTypeFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.dataTypes]: {
                        date: false,
                        equals: true,
                        values: dataTypesValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.hasCompleted)) {
                const hasCompletedFilter = filter.split(':');
                hasCompletedValue = Boolean(hasCompletedFilter[1] === 'false' ? false : hasCompletedFilter[1]);

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.hasCompleted]: {
                        date: false,
                        equals: true,
                        values: hasCompletedValue,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.assessed)) {
                const assessedFilter = filter.split(':');
                assessedValue = Boolean(assessedFilter[1] === 'false' ? false : assessedFilter[1]);

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.assessed]: {
                        date: false,
                        equals: true,
                        values: assessedValue,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.overdueRecertification)) {
                overdueValue = filter.split(':')[1];
                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.overdueRecertification]: {
                        date: false,
                        equals: true,
                        values: [overdueValue],
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.upcomingRecertification)) {
                upcomingValue = filter.split(':')[1];
                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.upcomingRecertification]: {
                        date: false,
                        equals: true,
                        values: [upcomingValue],
                    },
                };
                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.hasCompleted)) {
                const hasCompleted = filter.split(':');
                let hasCompletedValue = [hasCompleted[1]];

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.hasCompleted]: {
                        date: false,
                        equals: true,
                        values: hasCompletedValue,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.assessed)) {
                const assessed = filter.split(':');
                const assessedValue = [assessed[1]];

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.assessed]: {
                        date: false,
                        equals: true,
                        values: assessedValue,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.riskAccepted)) {
                const riskAccepted = filter.split(':');
                let riskAcceptedValue = [riskAccepted[1]];

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.riskAccepted]: {
                        date: false,
                        equals: true,
                        values: riskAcceptedValue,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.artifactUpdateType)) {
                const artifactUpdateTypeFilter = filter.split(':');
                const valueGroup = artifactUpdateTypeFilter[1].includes('[');

                if (valueGroup) {
                    const valueString = artifactUpdateTypeFilter[1].slice(1, -1);
                    artifactUpdateTypeValues = valueString.split('|');
                } else {
                    artifactUpdateTypeValues = [artifactUpdateTypeFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [RelationshipProps.artifactUpdateType]: {
                        date: false,
                        equals: true,
                        values: artifactUpdateTypeValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.remediationRequested)) {
                const remediationRequested = filter.split(':');
                let remediationRequestedValues = [remediationRequested[1]];

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.remediationRequested]: {
                        date: false,
                        equals: true,
                        values: remediationRequestedValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }

            if (filter.includes(RelationshipProps.licenseType)) {
                const licenseTypeFilter = filter.split(':');
                const valueGroup = licenseTypeFilter[1].includes('[');
                if (valueGroup) {
                    const valueString = licenseTypeFilter[1].slice(1, -1);
                    const cleanedString = valueString.replace(/,/g, '|');
                    licenseTypeValues = cleanedString.split('|');
                } else {
                    licenseTypeValues = [licenseTypeFilter[1]];
                }

                this.filters = {
                    ...this.filters,
                    [AdditionalFilters.licenseType]: {
                        date: false,
                        equals: true,
                        values: licenseTypeValues,
                    },
                };

                this.additionalFiltersApplied = true;
            }
        }

        if (this.additionalFiltersApplied) {
            this._store$.dispatch(
                setAdditionalFiltersState({
                    filters: {
                        startPhaseDate: phaseDateValues[0],
                        endPhaseDate: phaseDateValues[1],
                        startRecertificationDate: recertificationDateValues[0],
                        endRecertificationDate: recertificationDateValues[1],
                        businessUnit: businessUnitValues.map((v: string) => +v),
                        createdBy: createdByValues.map((v: string) => +v),
                        recertificationType: recertificationTypeValues[0],
                        businessOwner: businessOwnerValues.map((v: string) => +v),
                        startLastUpdated: lastUpdatedValues[0],
                        endLastUpdated: lastUpdatedValues[1],
                        startCreatedOn: createdOnValues[0],
                        endCreatedOn: createdOnValues[1],
                        client: clientValues.map((v: string) => +v),
                        contextTypes: contextTypesValues.map((v: string) => +v),
                        dataTypes: dataTypesValues.map((v: string) => +v),
                        upcomingRecertification: upcomingValue,
                        overdueRecertification: overdueValue,
                        hasCompleted: hasCompletedValue,
                        assessed: assessedValue,
                        vendorOrgs: vendorOrgValues,
                        nthParties: nthPartiesNames,
                        licenseType: licenseTypeValues,
                        artifactUpdateType: artifactUpdateTypeValues[0],
                        remediationRequested: remediationRequestedValues,
                        riskAccepted: riskAcceptedValue,
                    },
                }),
            );
        }

        this.drStatusFilter = this.drStatusFilter.length === 0 ? this._drStatusFilterInitialValue : this.drStatusFilter;
        this.assessmentPhaseFilter =
            this.assessmentPhaseFilter.length === 0 ? this._assessmentPhaseInitialValue : this.assessmentPhaseFilter;
        this.riskLevelFilter =
            this.riskLevelFilter.length === 0 ? this._riskLevelFilterInitialValue : this.riskLevelFilter;

        this.loadRelationships();
    }

    private sort() {
        let result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];

        if (this.predicate === this._creatorFirstNameColumn) {
            result.push(`${this._creatorLastNameColumn},${this.reverse ? 'asc' : 'desc'}`);
        } else if (this.predicate === this._tagsColumn) {
            result = [`${this._tagsNameField},${this.reverse ? 'asc' : 'desc'}`];
        }

        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    // Hack for ensure NO CONTEXT risk appears always at the bottom
    private sortByRisk(relationships: VendorRelationship[]): VendorRelationship[] {
        let noContextItems = relationships.filter((d) => d.residualRisk.toString() === this._noContextRiskLabel);
        let allButNoContextItems = relationships.filter((d) => d.residualRisk.toString() !== this._noContextRiskLabel);

        if (this.reverse) {
            relationships = noContextItems.concat(allButNoContextItems);
        } else {
            relationships = allButNoContextItems.concat(noContextItems);
        }
        return relationships;
    }

    private sortByRecommendationCount(relationships: VendorRelationship[]): VendorRelationship[] {
        let noRecommendationItems = relationships.filter((d) => d.recommendationCount === null);
        let allButNoRecommendationItems = relationships.filter((d) => d.recommendationCount !== null);
        return allButNoRecommendationItems.concat(noRecommendationItems);
    }

    private sortByArtifactUpdateStatus(relationships: VendorRelationship[]): VendorRelationship[] {
        let notOnboardedEnabledItems = relationships.filter(
            (d) => d.drStatus !== RequestStatus.ONBOARDED && d.artifactUpdateType !== RecertificationType.NONE,
        );
        let allButNotOnboardedItems = relationships.filter((d) => d.drStatus === RequestStatus.ONBOARDED);

        if (this.reverse) {
            relationships = notOnboardedEnabledItems.concat(allButNotOnboardedItems);
        } else {
            relationships = allButNotOnboardedItems.concat(notOnboardedEnabledItems);
        }
        return relationships;
    }

    private onConvertToCsvSuccess(data: VendorRelationship[], vendorTiers: VendorTier[]): void {
        const csvData = data.map((r) => ({
            vendorOrgName: r.vendorOrgName,
            drStatus: r.drStatus,
            risk: r.residualRisk,
            inherentRisk: r.inherentRisk,
            inherentRiskScore: Math.round(r.inherentRiskScore * 100 * 100) / 100 + '%',
            residualRisk: r.latestNonTransitionalRiskAssessmentResidualRisk
                ? r.latestNonTransitionalRiskAssessmentResidualRisk
                : r.residualRisk,
            residualRiskScore: Math.round(r.score * 100 * 100) / 100 + '%',
            updatedDate: moment(r.updatedDate).format('MM/DD/YYYY'),
            assessmentStatus: RelationshipAssessmentPhases[r.assessmentStatus] || this._notAssessedAssessmentPhase,
            assessmentCreatedDate: r.assessmentCreatedDate ? moment(r.assessmentCreatedDate).format('MM/DD/YYYY') : '',
            assessmentCompletedDate: r.assessmentCompletedDate
                ? moment(r.assessmentCompletedDate).format('MM/DD/YYYY')
                : '',
            recertificationDate: r.recertificationDate ? moment(r.recertificationDate).format('MM/DD/YYYY') : '',
            recertificationType: r.recertificationType,
            artifactUpdateType: r.artifactUpdateType,
            businessUnitName: r.businessUnitName || '',
            businessOwnerName: `${r.businessOwnerFirstName} ${r.businessOwnerLastName}`,
            businessOwner: `${r.businessOwnerEmail}`,
            primaryContactName: r.primaryContactId ? `${r.primaryContactFirstName} ${r.primaryContactLastName}` : '',
            primaryContactEmail: r.primaryContactId ? `${r.primaryContactEmail}` : '',
            creator: `${r.creatorFirstName} ${r.creatorLastName}`,
            createdDate: moment(r.createdDate).format('MM/DD/YYYY'),
            tags: !!r.tags ? '"' + r.tags.map((t) => t.name).join(', ') + '"' : '',
            tier: !!r.vendorTierId ? vendorTiers?.find((t) => t.id === r.vendorTierId).name : 'Unassigned',
        }));

        const headers = [
            ColumnNames.relationship,
            ColumnNames.status,
            ColumnNames.risk,
            ColumnNames.inherentRisk,
            ColumnNames.inherentRiskScore,
            ColumnNames.residualRisk,
            ColumnNames.score,
            ColumnNames.lastUpdated,
            ColumnNames.assessmentPhase,
            ColumnNames.assessmentCreatedDate,
            ColumnNames.phaseDate,
            ColumnNames.recertificationDate,
            ColumnNames.recertificationType,
            ColumnNames.artifactUpdateType,
            ColumnNames.businessUnit,
            ColumnNames.businessOwnerName,
            ColumnNames.businessOwnerEmail,
            ColumnNames.primaryContactName,
            ColumnNames.primaryContactEmail,
            ColumnNames.createdBy,
            ColumnNames.createdOn,
            ColumnNames.tags,
            ColumnNames.tier,
        ];

        this._convertToCsvService.downloadFile(csvData, `relationships-data-${Date.now()}`, headers);
        this.relationshipsLoading = false;
    }

    private onSuccess(data: VendorRelationship[], headers: HttpHeaders, selectedTierId: number | null): void {
        this.totalCurrentItems = +headers.get('x-total-count');
        const areFiltersApplied = !!selectedTierId || this.areNonTieringFiltersApplied;
        if (!areFiltersApplied) {
            this.totalFilteredRelationships = this.totalCurrentItems;
        } else if (areFiltersApplied && this.filtersIncludeArchived) {
            this.totalFilteredRelationships = this.totalCurrentItems;
        }

        // TODO: remove this when risk model output is refactored.
        if (this.predicate === this._residualRiskColumn) {
            data = this.sortByRisk(data);
        }

        if (this.predicate === this._recommendationCountColumn) {
            data = this.sortByRecommendationCount(data);
        }

        if (this.predicate === this._artifactUpdateColumn) {
            data = this.sortByArtifactUpdateStatus(data);
        }

        this.relationships = this.formatRelationships(data);
        this.relationshipsLoaded = true;
        this.relationshipsLoading = false;
    }

    private setListOrGraphTabIndex(view: string): void {
        if (this.listGraphTabGroup) {
            this.listGraphTabGroup.selectedIndex = view === 'graph' ? 1 : 0;
        }
    }

    addTagsToRelationships(event: MouseEvent, selectedTagNamesControl: NgModel) {
        event.stopPropagation();
        const selectedTagIds = selectedTagNamesControl.value.map((t) => t.id);
        this._store$.dispatch(
            bulkLinkTagsRequest({ tagIds: selectedTagIds, relationshipIds: this.selectedRelationshipIds$.value }),
        );
        selectedTagNamesControl.control.reset();
    }

    tableResized({ column, newValue }: { column: any; newValue: number }) {
        if (column?.name === 'Tags') {
            this.tagsColumnWidth = newValue;
        }
    }

    applyVendorIdFilter(vendorOrgId: string) {
        this.applyFilter(RelationshipProps.vendorOrgId, true, [vendorOrgId]);
        this.additionalFiltersApplied = true;
    }

    manageTiers(): void {
        this._store$.dispatch(openManageTiersDialog());
    }

    assignSelectedRelationshipsToTier(tier: VendorTier, forUnpaginated: boolean): void {
        const relationshipsAlreadyAssignedToTiers = this.relationships.some(
            (r) => this.selectedRelationshipIds$.value.includes(r.id) && !!r.vendorTierId,
        );
        this._store$.dispatch(
            openAssignRelationshipsToTierDialog({
                relationshipIds: this.selectedRelationshipIds$.value,
                tier,
                relationshipsAlreadyAssignedToTiers: relationshipsAlreadyAssignedToTiers,
                forUnpaginatedRelationships: forUnpaginated,
            }),
        );
    }

    updateVendorTierDescription(): void {
        this._store$.dispatch(openVendorTierDescriptionDialog());
    }

    noRelationshipsHintText(currentTierTotal: number): Observable<string> {
        return this.selectedTierId$.pipe(
            map((selectedTierId) =>
                currentTierTotal > 0
                    ? 'Consider adjusting your filters.'
                    : selectedTierId
                      ? 'Assign relationships to this tier to view statistics on their inherent and residual risk.'
                      : 'Add a relationship to start tracking your vendors.',
            ),
        );
    }

    getTierName(allVendorTiers: VendorTier[], vendorTierId: number): string {
        return allVendorTiers.find((t) => t.id === vendorTierId)?.name;
    }

    restoreRelationship(relationshipId: number) {
        this._store$.dispatch(restoreRelationshipRequest({ relationshipId }));
    }

    setSelectedTierId(tierId: number): void {
        if (tierId === null) {
            this._tierIdFromUrl = 'allRelationships';
            this.applyFilter(RelationshipProps.vendorTierId, true, this.tierFilter);
        } else {
            this._tierIdFromUrl = String(tierId);
        }
        this._store$.dispatch(setSelectedTierId(tierId));
        this.addFilterParamToUrl();
    }

    isInAllRelationshipsTab(): boolean {
        return this._tierIdFromUrl === 'allRelationships';
    }
}
