import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { ControlValidationDetection, FileArtifact, QuestionnaireArtifact, UrlArtifact } from '@entities/artifact';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import {
    AdditionalAssessmentQuestionViewModel,
    AdditionalInformationSubSteps,
    AssessmentSubmissionFormPayload,
    PublicAssessmentRTPFileArtifact,
    StepIds,
} from '../../../models';
import {
    cancelAssessment,
    declineRemediation,
    extendExpiration,
    forwardRequest,
    onWhenAddingFileFailed,
    removeArtifact,
    saveQuestionnaire,
    saveSubprocessorsUrl,
    setAdditionalAssessmentQuestion,
    setArtifactPassword,
    submitAssessment,
    updateSubprocessorDetections,
    uploadFiles,
    uploadFileToReplace,
    uploadSubprocessorsFile,
} from '../../../redux/actions';
import {
    getActiveSupplementalQuestionnaires,
    getAdditionalAssessmentQuestions,
    getAssessmentCollectionType,
    getBusinessCases,
    getClientBrandingColor,
    getClientId,
    getClientLogoUrl,
    getClientName,
    getExpiredArtifactReplacements,
    getIsExpirationExtensionAllowed,
    getIsFollowupRequested,
    getIsRemediationAssessment,
    getIsSubmitted,
    getPreviousAssessmentRecommendations,
    getQuestionnaireArtifact,
    getRemediationTargetDate,
    getSenderEmail,
    getSortedRtpFileArtifactsForInitialUploadScreen,
    getSubprocessorDetections,
    getSubprocessorsUrlArtifact,
    getUploadedStatusesOfInScopeFileTypes,
    getVendorName,
} from '../../../redux/selectors';
import { FilterFunction } from '@shared/file-upload/interfaces';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { BreakpointObserver } from '@angular/cdk/layout';
import { STATIC_SITE_URL, VISO_LOGO_URL } from '@shared/constants/url.constants';
import { SUPPORT_EMAIL } from '@shared/constants/email.constants';
import { StepNavigationActions } from '../../../redux/actions/step-navigation';
import * as ControlDomainSelectors from '../../../redux/selectors/control-domain.selectors';
import * as StepNavigationSelectors from '../../../redux/selectors/step-navigation.selectors';
import { FeatureFlagService } from '@shared/services/featureflag.service';

@Component({
    selector: 'app-assessment-collection',
    templateUrl: './assessment-collection.component.html',
    styleUrls: ['./assessment-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentCollectionComponent implements AfterViewInit, OnDestroy {
    @ViewChild(CdkStepper)
    stepper: CdkStepper;

    private _store$: Store = inject(Store);
    private _bpObserver = inject(BreakpointObserver);
    private _featureFlagService = inject(FeatureFlagService);

    senderEmail$ = this._store$.select(getSenderEmail);
    clientId$ = this._store$.select(getClientId);
    clientName$ = this._store$.select(getClientName);
    clientLogoUrl$ = this._store$.select(getClientLogoUrl);
    clientBrandingColor$ = this._store$.select(getClientBrandingColor);
    vendorName$ = this._store$.select(getVendorName);
    businessCases$ = this._store$.select(getBusinessCases);
    rtpFileArtifactsForInitialUploadScreen$ = this._store$.select(getSortedRtpFileArtifactsForInitialUploadScreen);
    additionalAssessmentQuestions$ = this._store$.select(getAdditionalAssessmentQuestions);
    uploadedStatusesOfInScopeFileTypes$ = this._store$.select(getUploadedStatusesOfInScopeFileTypes);
    currentCollectArtifactsSubStep$ = this._store$.select(StepNavigationSelectors.getCurrentCollectArtifactsSubStep);
    expiredArtifactReplacements$ = this._store$.select(getExpiredArtifactReplacements);
    questionnaire$ = this._store$.select(getQuestionnaireArtifact);
    isSubmitted$ = this._store$.select(getIsSubmitted);
    isExpirationExtensionAllowed$ = this._store$.select(getIsExpirationExtensionAllowed);
    isFollowupRequested$ = this._store$.select(getIsFollowupRequested);
    isRemediationAssessment$ = this._store$.select(getIsRemediationAssessment);
    remediationTargetDate$ = this._store$.select(getRemediationTargetDate);
    previousAssessmentRecommendations$ = this._store$.select(getPreviousAssessmentRecommendations);
    additionalInformationStepLabel$ = this.isFollowupRequested$.pipe(
        map((followupRequested) => (followupRequested ? 'Additional questions' : 'Additional information')),
    );
    collectionType$ = this._store$.select(getAssessmentCollectionType);
    followupControlDomainIds$ = this._store$.select(ControlDomainSelectors.getFollowupControlDomainIds);
    relevantControlDomainIds$ = this._store$.select(ControlDomainSelectors.getRelevantControlDomainIds);
    activeSupplementalQuestionnaires$ = this._store$.select(getActiveSupplementalQuestionnaires);
    disableInitialUploadContinue$ = this.shouldInitialUploadContinueBeDisabled();
    artifactClassificationInProgress$ = this.isClassificationInProgress();
    hasBrandingEnabled$ = this._featureFlagService.hasFeatureFlagEnabled(FeatureFlags.DOMAINS_BRANDING);
    useCompactOrientation$: Observable<boolean>;
    subprocessorsUrlArtifact$ = this._store$.select(getSubprocessorsUrlArtifact);
    subprocessorDetections$ = this._store$.select(getSubprocessorDetections);

    StepIds = StepIds;

    readonly STATIC_SITE_URL = STATIC_SITE_URL;
    readonly VISO_LOGO_URL = VISO_LOGO_URL;
    readonly SUPPORT_EMAIL = SUPPORT_EMAIL;
    readonly VISO_ORG_ID = 1;

    private readonly MAX_WIDTH_BREAKPOINT = '(max-width: 1350px)';
    private _unsub$ = new Subject<void>();

    ngAfterViewInit(): void {
        this._store$
            .select(StepNavigationSelectors.getCurrentStepId)
            .pipe(takeUntil(this._unsub$))
            .subscribe((stepId) => this.selectStep(stepId));

        this.useCompactOrientation$ = this._bpObserver.observe([this.MAX_WIDTH_BREAKPOINT]).pipe(
            takeUntil(this._unsub$),
            map((result) => result.matches),
        );
    }

    continueFromWelcomePage(): void {
        this._store$.dispatch(StepNavigationActions.continueFromWelcomePage());
    }

    navigateToCollectArtifacts(): void {
        this._store$.dispatch(StepNavigationActions.navigateToCollectArtifacts());
    }

    optOut(): void {
        this._store$.dispatch(cancelAssessment());
    }

    forwardRequest(): void {
        this._store$.dispatch(forwardRequest());
    }

    extendExpiration(): void {
        this._store$.dispatch(extendExpiration());
    }

    declineRemediation(): void {
        this._store$.dispatch(declineRemediation());
    }

    goBackFromInitialUpload(): void {
        this._store$.dispatch(StepNavigationActions.goBackFromInitialUpload());
    }

    continueFromInitialUpload(): void {
        this._store$.dispatch(StepNavigationActions.continueFromInitialUpload());
    }

    uploadArtifacts(files: File[]): void {
        this._store$.dispatch(uploadFiles({ files }));
    }

    uploadArtifactToReplace(file: File, artifactIdToReplace: number): void {
        this._store$.dispatch(uploadFileToReplace({ file, artifactIdToReplace }));
    }

    uploadAttestationArtifactForAdditionalQuestion(additionalQuestionId: string, file: File): void {
        this._store$.dispatch(uploadFiles({ forAdditionalQuestionId: additionalQuestionId, files: [file] }));
    }

    continueFromSubStep(fromId: AdditionalInformationSubSteps): void {
        this._store$.dispatch(StepNavigationActions.continueFromSubStep(fromId));
    }

    goBackFromSubStep(fromId: AdditionalInformationSubSteps): void {
        this._store$.dispatch(StepNavigationActions.goBackFromSubStep(fromId));
    }

    onWhenAddingFileFailed(filter: FilterFunction): void {
        this._store$.dispatch(
            onWhenAddingFileFailed({
                message:
                    filter.name === 'fileSize'
                        ? 'File size must not be empty and less than 100MB'
                        : `Filter failed: ${filter.name}`,
            }),
        );
    }

    saveQuestionnaire(questionnaire: QuestionnaireArtifact): void {
        this._store$.dispatch(saveQuestionnaire({ questionnaire }));
    }

    submissionSubmit(payload: AssessmentSubmissionFormPayload): void {
        this._store$.dispatch(submitAssessment({ payload }));
    }

    submissionWentBack(): void {
        this._store$.dispatch(StepNavigationActions.goBackFromSubmission());
    }

    setPassword({ artifact, password }: { artifact: PublicAssessmentRTPFileArtifact; password: string }): void {
        const artifactId = artifact.artifact.id;
        this._store$.dispatch(setArtifactPassword({ artifactId, password }));
    }

    removePublicAssessmentArtifact(artifact: PublicAssessmentRTPFileArtifact): void {
        this.removeFileArtifact(artifact.artifact);
    }

    removeFileArtifact({ id: artifactId, fileName }: FileArtifact): void {
        this._store$.dispatch(removeArtifact({ artifactId, fileName }));
    }

    removeUrlArtifact(artifact: UrlArtifact): void {
        this._store$.dispatch(removeArtifact({ artifactId: artifact.id, fileName: artifact.url }));
    }

    updateAdditionalQuestion(recommendation: AdditionalAssessmentQuestionViewModel): void {
        this._store$.dispatch(setAdditionalAssessmentQuestion({ additionalQuestion: recommendation }));
    }

    navigateToQuestionnaire(): void {
        this._store$.dispatch(StepNavigationActions.navigateToQuestionnaire());
    }

    showPoweredByVisoTrust(
        hasBrandingEnabled: boolean,
        clientBrandingColorSet: boolean,
        clientLogoUrlSet: boolean,
        clientId: number,
    ) {
        return hasBrandingEnabled && (clientBrandingColorSet || clientLogoUrlSet) && clientId !== this.VISO_ORG_ID;
    }

    uploadSubprocessorsFile(subprocessorsFile: File) {
        this._store$.dispatch(uploadSubprocessorsFile({ file: subprocessorsFile }));
    }

    updateSubprocessorDetections(detections: ControlValidationDetection[]) {
        this._store$.dispatch(updateSubprocessorDetections({ subprocessorDetections: detections }));
    }

    saveSubprocessorsUrl(subprocessorsUrl: string) {
        this._store$.dispatch(saveSubprocessorsUrl({ subprocessorsUrl }));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private selectStep(stepId: StepIds): void {
        const index = this.stepper._steps.toArray().findIndex((step) => this.getStepId(step) === stepId);
        this.stepper.selectedIndex = index;
    }

    private getStepId(step: CdkStep): StepIds {
        return +step.content.elementRef.nativeElement.parentNode.id;
    }

    private shouldInitialUploadContinueBeDisabled(): Observable<boolean> {
        return combineLatest([this.additionalAssessmentQuestions$, this.rtpFileArtifactsForInitialUploadScreen$]).pipe(
            map(([recommendations, uploadedArtifacts]) => {
                const artifactIdsAttachedToRecommendations = recommendations
                    .filter((rec) => !!rec.attestationArtifact)
                    .map((rec) => rec.attestationArtifact.artifact.id);

                const incompleteArtifacts = uploadedArtifacts.filter(
                    (a) =>
                        a.waitingOnRequiredRTPValidations &&
                        !artifactIdsAttachedToRecommendations.includes(a.artifact.id),
                );

                return !uploadedArtifacts.length || incompleteArtifacts.length !== 0;
            }),
        );
    }

    private isClassificationInProgress(): Observable<boolean> {
        return this.rtpFileArtifactsForInitialUploadScreen$.pipe(
            map((uploadedArtifacts) => uploadedArtifacts.some((a) => a.waitingOnRequiredRTPValidations)),
        );
    }
}
