import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs';
import {
    ConfirmCancellationDialogComponent,
    ConfirmDeclineRemediationDialogComponent,
    ForwardAssessmentDialogComponent,
} from '../../components';
import { AssessmentSubmissionContext, StepIds, VendorDetails } from '../../models';
import {
    cancelAssessment,
    cancelAssessmentCancel,
    cancelAssessmentRequest,
    createQuestionnaire,
    createQuestionnaireRequest,
    declineRemediation,
    declineRemediationCancel,
    declineRemediationRequest,
    extendAssessmentExpirationCancel,
    extendAssessmentExpirationRequest,
    extendExpiration,
    forwardRequest,
    forwardRequestCancel,
    forwardRequestRequest,
    getPublicAssessmentRequest,
    getRecommendationsRequest,
    getSecurityControlDomainRequest,
    loadRecommendations,
    loadSecurityControlDomain,
    onRtpFileArtifactCreation,
    refreshScopeRelatedAdditionalQuestions,
    refreshSiblingOrExpiredAdditionalQuestions,
    removeArtifact,
    removeArtifactRequest,
    saveQuestionnaire,
    saveQuestionnaireRequest,
    setArtifactPassword,
    setPasswordForArtifactRequest,
    submitAssessment,
    submitAssessmentRequest,
    submitPasscode,
    uploadFiles,
    uploadFilesRequest,
    uploadFileToReplace,
    uploadSubprocessorsFile,
    uploadSubprocessorsFileRequest,
} from '../actions';
import {
    getAdditionalAssessmentQuestions,
    getAssessmentExpiration,
    getAssessmentToken,
    getAttestationGroupsFromRecommendations,
    getClientName,
    getExpiredArtifactsUploadedBeforeAssessmentCreation,
    getIsArtifactUpdateFlow,
    getSecret,
    getSubmissionContext,
    getVendorName,
} from '../selectors';
import moment from 'moment';
import * as StepNavigationSelectors from '../selectors/step-navigation.selectors';
import { generateSubmissionPayload } from '../../submission-util';
import * as AdditionalQuestionSelectors from '../selectors/additional-question.selectors';
import { FileUploadHelperService } from '../../services/file-upload-helper.service';

@Injectable()
export class CommandsEffects {
    submitPasscode$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitPasscode),
            withLatestFrom(this._store$.select(getAssessmentToken)),
            map(([{ passcode: secret }, assessmentToken]) => getPublicAssessmentRequest({ assessmentToken, secret })),
        ),
    );

    extendAssessmentExpiration$ = createEffect(() =>
        this._actions$.pipe(
            ofType(extendExpiration),
            withLatestFrom(
                this._store$.select(getAssessmentExpiration),
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
            ),
            switchMap(([, expirationDate, assessmentToken, secret]) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Need more time?',
                        message:
                            `The deadline to provide information is ${moment(expirationDate).format('M/D/yyyy')}. ` +
                            `If you need more time to complete this request, you can extend the deadline to ${moment(expirationDate).add(7, 'days').format('M/D/yyyy')}.`,
                        cancelButtonColor: 'primary',
                        confirmLabel: 'Yes, extend deadline',
                        confirmButtonColor: 'primary',
                    })
                    .pipe(
                        first(),
                        map((confirm) =>
                            confirm
                                ? extendAssessmentExpirationRequest({ assessmentToken, secret })
                                : extendAssessmentExpirationCancel(),
                        ),
                    ),
            ),
        ),
    );

    cancelAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelAssessment),
            withLatestFrom(
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getClientName),
                this._store$.select(getVendorName),
            ),
            switchMap(([, assessmentToken, secret, clientName, vendorName]) =>
                this._dialogService
                    .open<ConfirmCancellationDialogComponent, VendorDetails | false>(
                        ConfirmCancellationDialogComponent,
                        {
                            inputs: {
                                clientName,
                                vendorName,
                            },
                        },
                    )
                    .afterClosed()
                    .pipe(
                        first(),
                        map((vendorDetails) =>
                            !!vendorDetails
                                ? cancelAssessmentRequest({ assessmentToken, secret, vendorDetails })
                                : cancelAssessmentCancel(),
                        ),
                    ),
            ),
        ),
    );

    forwardRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(forwardRequest),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            switchMap(([, assessmentToken, secret]) =>
                this._dialogService
                    .open<ForwardAssessmentDialogComponent, VendorDetails | false>(ForwardAssessmentDialogComponent)
                    .afterClosed()
                    .pipe(
                        first(),
                        map((vendorDetails) =>
                            !!vendorDetails
                                ? forwardRequestRequest({ assessmentToken, secret, vendorDetails })
                                : forwardRequestCancel(),
                        ),
                    ),
            ),
        ),
    );

    declineRemediation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(declineRemediation),
            withLatestFrom(
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getClientName),
                this._store$.select(getVendorName),
            ),
            switchMap(([, assessmentToken, secret, clientName, vendorName]) =>
                this._dialogService
                    .open<ConfirmDeclineRemediationDialogComponent, VendorDetails | false>(
                        ConfirmDeclineRemediationDialogComponent,
                        {
                            inputs: {
                                clientName,
                                vendorName,
                            },
                        },
                    )
                    .afterClosed()
                    .pipe(
                        first(),
                        map((vendorDetails) =>
                            !!vendorDetails
                                ? declineRemediationRequest({ assessmentToken, secret, vendorDetails })
                                : declineRemediationCancel(),
                        ),
                    ),
            ),
        ),
    );

    loadRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(loadRecommendations),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => getRecommendationsRequest({ assessmentToken, secret })),
        ),
    );

    uploadFiles$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFiles),
            withLatestFrom(
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getAdditionalAssessmentQuestions),
            ),
            map(([{ files, forAdditionalQuestionId }, assessmentToken, secret, recommendations]) => {
                const uploadRecommendation = recommendations.find((rec) => rec.id === forAdditionalQuestionId);
                return uploadFilesRequest({
                    assessmentToken,
                    secret,
                    uploadFileArtifactRequests: this._fileUploadHelperService.createFilesUploadRequest(
                        files,
                        uploadRecommendation,
                    ),
                });
            }),
        ),
    );

    uploadDocumentReplacement$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFileToReplace),
            withLatestFrom(
                this._store$.select(getExpiredArtifactsUploadedBeforeAssessmentCreation),
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
            ),
            map(([{ file, artifactIdToReplace }, expiredArtifacts, assessmentToken, secret]) =>
                uploadFilesRequest({
                    assessmentToken,
                    secret,
                    uploadFileArtifactRequests: [
                        this._fileUploadHelperService.createFileUploadReplacementRequest(
                            file,
                            expiredArtifacts.find((a) => a.id === artifactIdToReplace),
                        ),
                    ],
                }),
            ),
        ),
    );

    setArtifactPassword$ = createEffect(() =>
        this._actions$.pipe(
            ofType(setArtifactPassword),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ artifactId, password }, assessmentToken, secret]) =>
                setPasswordForArtifactRequest({ assessmentToken, secret, artifactId, password }),
            ),
        ),
    );

    loadSecurityControlDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(loadSecurityControlDomain),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => getSecurityControlDomainRequest({ assessmentToken, secret })),
        ),
    );

    removeArtifact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifact),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            switchMap(([{ artifactId, fileName }, assessmentToken, secret]) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Delete artifact',
                        message: `Are you sure you want to delete <strong>${fileName}</strong>?`,
                        cancelButtonColor: 'primary',
                        confirmLabel: 'Yes, delete artifact',
                        confirmButtonColor: 'warn',
                    })
                    .pipe(
                        filter((confirm) => confirm),
                        map(() => removeArtifactRequest({ artifactId, assessmentToken, secret })),
                    ),
            ),
        ),
    );

    createQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createQuestionnaire),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => createQuestionnaireRequest({ assessmentToken, secret })),
        ),
    );

    saveQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(saveQuestionnaire),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ questionnaire }, assessmentToken, secret]) =>
                saveQuestionnaireRequest({ assessmentToken, secret, questionnaire }),
            ),
        ),
    );

    submitAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitAssessment),
            withLatestFrom(
                this._store$.select(getSubmissionContext),
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getAttestationGroupsFromRecommendations),
                this._store$.select(AdditionalQuestionSelectors.getSubprocessorsQuestion),
            ),
            map(
                ([
                    { payload: formPayload },
                    submissionContext,
                    assessmentToken,
                    secret,
                    attestationsGroup,
                    subprocessorsStepData,
                ]) =>
                    submitAssessmentRequest({
                        payload: generateSubmissionPayload(
                            formPayload,
                            assessmentToken,
                            secret,
                            submissionContext,
                            subprocessorsStepData,
                            submissionContext === AssessmentSubmissionContext.FILE_ARTIFACTS ? attestationsGroup : null,
                        ),
                    }),
            ),
        ),
    );

    triggerRefreshArtifactUploadRecommendations = createEffect(() =>
        this._actions$.pipe(
            ofType(onRtpFileArtifactCreation),
            withLatestFrom(
                this._store$.select(getIsArtifactUpdateFlow),
                this._store$.select(StepNavigationSelectors.getCurrentStepId),
            ),
            filter(
                ([, isArtifactUpdate, currentStepId]) =>
                    !isArtifactUpdate &&
                    (currentStepId === StepIds.WELCOME || currentStepId === StepIds.INITIAL_ARTIFACT_UPLOAD),
            ),
            map(() => refreshScopeRelatedAdditionalQuestions()),
        ),
    );

    triggerRefreshSiblingOrExpiredArtifactUploadRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onRtpFileArtifactCreation),
            map(() => refreshSiblingOrExpiredAdditionalQuestions()),
        ),
    );

    uploadSubprocessorsFile$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadSubprocessorsFile),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ file }, assessmentToken, secret]) =>
                uploadSubprocessorsFileRequest({ assessmentToken, secret, file }),
            ),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _dialogService: MatDialogWrapperService,
        private _confirmDialogService: MatConfirmDialogService,
        private _fileUploadHelperService: FileUploadHelperService,
    ) {}
}
