import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-assessment-submitted',
    templateUrl: './assessment-submitted.component.html',
    styleUrls: ['./assessment-submitted.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSubmittedComponent {
    @Input({ required: true })
    submittedByEmail: string;

    @Input({ required: true })
    sentToEmail: string;
}
