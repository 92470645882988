import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../assessment-collection.state';
import { AdditionalInformationSubSteps, StepIds } from '../../models';
import { getAssessmentCollectionState } from './main.selectors';

export const getCurrentStepId: MemoizedSelector<AppState, StepIds> = createSelector(
    getAssessmentCollectionState,
    (state) => state.currentStepId,
);

export const getCurrentCollectArtifactsSubStep: MemoizedSelector<AppState, AdditionalInformationSubSteps> =
    createSelector(getAssessmentCollectionState, (state) => state.collectArtifactsSubStep);
