<div class="container-top-half pb-0">
    <div class="row flex-nowrap">
        <div class="col-4 fixed-height">
            <div class="row">
                <div class="col">
                    <h1 class="assessment-collection-step-title">
                        {{ title }}
                    </h1>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <p class="mb-0">
                        {{ description }}
                    </p>
                </div>
            </div>
            @if (showExpiredArtifactsDescription) {
                <div class="row mb-3">
                    <div class="col">
                        <p class="mb-0">
                            Additionally, please provide any replacements for artifacts that have expired since your
                            previous assessment.
                        </p>
                    </div>
                </div>
            }
            @if (showArtifactExamples) {
                <div class="row mb-3">
                    <div class="col">
                        <app-artifact-examples
                            [uploadedStatusesOfInScopeFileTypes]="uploadedStatusesOfInScopeFileTypes"
                            [classificationInProgress]="classificationInProgress"
                        />
                    </div>
                </div>
            }
            @if (showPreviousAssessmentRecommendations) {
                <div class="row mb-3 pt-3">
                    <div class="col">
                        <app-recommendations-list
                            [recommendations]="previousAssessmentRecommendations"
                            [vendorName]="vendorName"
                        />
                    </div>
                </div>
            }
        </div>

        <div class="col-auto ps-0 pb-4">
            <mat-divider class="h-100" [vertical]="true"></mat-divider>
        </div>

        <div class="col">
            @if (artifactListPopulated) {
                <div class="d-flex justify-content-between align-items-center pb-3">
                    <h5 class="m-0">Your artifacts</h5>
                    <app-upload-file
                        #prompt
                        (uploadFiles)="uploadArtifacts.emit($event)"
                        (uploadFailed)="uploadFailed.emit($event)"
                    >
                        <div>
                            Drag and drop anywhere on this page or
                            <span
                                class="color-primary text-decoration-underline"
                                role="button"
                                aria-label="Upload files button"
                                (click)="prompt.openFilePrompt()"
                            >
                                select files </span
                            >.
                        </div>
                    </app-upload-file>
                </div>
                <app-artifact-list
                    class="h-100"
                    [rtpFileArtifacts]="uploadedArtifacts"
                    [expiredArtifactReplacements]="expiredArtifactReplacements"
                    (uploadAndReplace)="uploadAndReplace.emit($event)"
                    (setPassword)="setPassword.emit($event)"
                    (removeArtifact)="removeArtifact.emit($event)"
                    (uploadAndReplaceFailed)="uploadFailed.emit($event)"
                />
            } @else {
                <h5 class="mb-3">Your artifacts</h5>
                <app-upload-file
                    (uploadFiles)="uploadArtifacts.emit($event)"
                    (uploadFailed)="uploadFailed.emit($event)"
                />
            }
        </div>
    </div>
</div>
<div class="d-flex justify-content-end container-bottom-half">
    <button mat-stroked-button color="primary" class="me-auto" (click)="wentBack.emit()">Go back</button>
    <button
        mat-flat-button
        color="primary"
        [disabled]="disableContinueButton"
        (click)="continued.emit()"
        data-pw="continueButton"
    >
        Continue
    </button>
</div>
