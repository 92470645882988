import { AssessmentSubmission, AssessmentSubmissionType } from '@entities/assessment';
import {
    AssessmentSubmissionContext,
    AssessmentSubmissionFormPayload,
    AttestationsGroups,
    SubprocessorsQuestion,
} from './models';

export function generateSubmissionPayload(
    formPayload: AssessmentSubmissionFormPayload,
    assessmentToken: string,
    secret: string,
    submissionContext: AssessmentSubmissionContext,
    subprocessorsStepData: SubprocessorsQuestion | null,
    attestationsGroup: AttestationsGroups | null,
): AssessmentSubmission {
    const { email, firstName, lastName } = formPayload;
    const attestations = attestationsGroup?.attestations ?? {};
    const siblingArtifactAttestations = attestationsGroup?.siblingArtifactAttestations ?? {};
    const expiredArtifactAttestations = attestationsGroup?.expiredArtifactAttestations ?? {};

    return {
        assessmentToken,
        secret,
        email,
        firstName,
        lastName,
        attestations,
        siblingArtifactAttestations,
        expiredArtifactAttestations,
        notifyClient: true,
        tosAccepted: true,
        submissionType:
            submissionContext === AssessmentSubmissionContext.FILE_ARTIFACTS
                ? AssessmentSubmissionType.ARTIFACTS
                : AssessmentSubmissionType.QUESTIONNAIRE,
        subprocessorSubmissionType: subprocessorsStepData?.submissionType,
    };
}
