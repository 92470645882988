import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { EMPTY, from, of } from 'rxjs';
import {
    catchError,
    concatAll,
    filter,
    last,
    map,
    mergeMap,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { AssessmentStatus } from '../../../../entities/assessment';
import { Artifact, ArtifactService } from '../../../../entities/artifact';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';
import {
    downloadWithAnchorElement,
    openFileFromResBlob,
    saveFileFromResBlob,
} from '../../../../shared/file-download/redux/actions';
import { NgbModalWrapperService } from '../../../../shared/modal/ngb-modal-wrapper.service';
import { MatDialogWrapperService } from '../../../../shared/modal/mat-dialog-wrapper.service';
import { UploadArtifactsModalComponent, UploadArtifactsSections } from '../../../../shared/upload-artifacts';
import { AddControlModalComponent } from '../../../../shared/artifact-intelligence/components/artifacts/add-control-modal/add-control-modal.component';
import { AssessmentUtilsService } from '../../../../shared/utils/assessment-utils.service';
import { openPDFArtifactView } from '../../../pdf-artifact-viewer/redux/pdf-artifact-viewer.actions';
import { ArtifactAddedModalComponent } from '../../../../shared/upload-artifacts/components/artifact-added-modal/artifact-added-modal.component';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';
import {
    deleteAllArtifactControlValidationsRequest,
    deleteAllArtifactControlValidationsRequestFailed,
    deleteAllArtifactControlValidationsRequestSuccess,
    deleteArtifactControlDomainValidationsRequest,
    deleteArtifactControlDomainValidationsRequestFailed,
    deleteArtifactControlDomainValidationsRequestSuccess,
    deleteRequestArtifactRequest,
    deleteRequestArtifactRequestFailed,
    deleteRequestArtifactRequestSuccess,
    downloadArtifact,
    downloadArtifactFailed,
    downloadArtifactMetadataFile,
    downloadArtifactMetadataFileFailed,
    downloadRelationshipArtifactsAsZip,
    downloadRelationshipArtifactsAsZipFailed,
    getArtifactIntelligenceRequest,
    getArtifactIntelligenceRequestFailed,
    getArtifactIntelligenceRequestSuccess,
    getArtifactMetadataRequest,
    getArtifactMetadataRequestFailed,
    getArtifactMetadataRequestSuccess,
    getArtifactPasswordRequest,
    getArtifactPasswordRequestFailed,
    getArtifactPasswordRequestSuccess,
    getArtifactRequest,
    getArtifactRequestFailed,
    getArtifactRequestSuccess,
    getRequestArtifactsRequest,
    getRequestArtifactsRequestFailed,
    getRequestArtifactsRequestSuccess,
    openAddControlModal,
    openArtifactFile,
    uploadRequestArtifactRequest,
    uploadRequestArtifactRequestFailed,
    uploadRequestArtifactRequestSuccess,
} from '../actions/artifacts.actions';
import {
    getRelationshipPopulatedLatestAssessment,
    isRelationshipLatestAssessmentLoaded,
} from '../selectors/assessment.selectors';
import { confirmProceedWithAvailableData } from '../actions/assessments.actions';

@Injectable()
export class ArtifactEffects {
    private _latestAssessmentInProgress$ = this._store$.select(isRelationshipLatestAssessmentLoaded).pipe(
        filter((isLoaded) => isLoaded),
        withLatestFrom(this._store$.select(getRelationshipPopulatedLatestAssessment)),
        map(([, latestAssessment]) => this._assessmentUtilsService.isAssessmentInProgress(latestAssessment?.status)),
    );

    getArtifactIntelligenceRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getArtifactIntelligenceRequest),
            switchMap(({ artifactId }) =>
                this._artifactService.getArtifactIntelligence(artifactId).pipe(
                    map((response) => response.body),
                    map((artifactIntelligence) => getArtifactIntelligenceRequestSuccess({ artifactIntelligence })),
                    catchError(() => of(getArtifactIntelligenceRequestFailed())),
                ),
            ),
        ),
    );

    getRequestArtifactsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestArtifactsRequest),
            switchMap(({ requestId }) =>
                this._artifactService.findByRelationship(requestId).pipe(
                    map((response) => response.body),
                    map((artifacts) => getRequestArtifactsRequestSuccess({ artifacts })),
                    catchError(() => of(getRequestArtifactsRequestFailed())),
                ),
            ),
        ),
    );

    deleteRequestArtifactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteRequestArtifactRequest),
            switchMap(({ requestId, artifactId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Delete Artifact',
                        message: 'Are you sure you want to permanently delete this artifact?',
                        confirmLabel: 'Yes, do it!',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._artifactService.deleteRequestArtifact(requestId, artifactId).pipe(
                                map(() => deleteRequestArtifactRequestSuccess({ requestId })),
                                catchError(() => of(deleteRequestArtifactRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    openAddControlModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openAddControlModal),
                tap(({ artifact, artifactControl, allArtifactControlDomains, currentAccount, controlValidation }) =>
                    this._modalService.open<AddControlModalComponent>(
                        AddControlModalComponent,
                        {
                            artifact,
                            artifactControl,
                            allArtifactControlDomains,
                            currentAccount,
                            controlValidation,
                        },
                        { scrollable: true, size: 'xl' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    downloadArtifact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadArtifact),
            switchMap(({ artifactId }) =>
                this._artifactService.downloadArtifact(artifactId).pipe(
                    map((fileResponseBlob) => saveFileFromResBlob({ fileResponseBlob })),
                    catchError(() => of(downloadArtifactFailed())),
                ),
            ),
        ),
    );

    openArtifact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openArtifactFile),
            switchMap(({ artifactId, artifactName, selectedDetectionId, viewerPage }) =>
                /.+\.pdf$/.test(artifactName)
                    ? of(openPDFArtifactView({ artifactId, selectedDetectionId, viewerPage }))
                    : this._artifactService.downloadArtifact(artifactId).pipe(
                          map((fileResponseBlob) => openFileFromResBlob({ fileResponseBlob })),
                          catchError(() => of(downloadArtifactFailed())),
                      ),
            ),
        ),
    );

    downloadRelationshipArtifactsAsZip$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadRelationshipArtifactsAsZip),
            switchMap(({ requestId, artifactIds }) =>
                this._artifactService.downloadArtifactsOnRelationshipAsZip(requestId, artifactIds).pipe(
                    map((fileResponseBlob) => saveFileFromResBlob({ fileResponseBlob })),
                    catchError(() => of(downloadRelationshipArtifactsAsZipFailed())),
                ),
            ),
        ),
    );

    downloadArtifactMetadataFile$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadArtifactMetadataFile),
            switchMap(({ artifactId, artifactMetadataFile }) =>
                this._artifactService.getSignedURLForArtifactMetadataFile(artifactId, artifactMetadataFile).pipe(
                    map((res) => res.body),
                    map(({ url }) => downloadWithAnchorElement({ url: url.toString() })),
                    catchError(() => of(downloadArtifactMetadataFileFailed())),
                ),
            ),
        ),
    );

    getArtifactMetadata$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getArtifactMetadataRequest),
            switchMap(({ artifactId, clientId, clientName, relationshipId, relationshipName }) =>
                this._artifactService.getArtifactMetadata(artifactId).pipe(
                    map((response) => response.body),
                    map((artifactMetadata) =>
                        getArtifactMetadataRequestSuccess({
                            artifactId,
                            artifactMetadata,
                            clientId,
                            clientName,
                            relationshipId,
                            relationshipName,
                        }),
                    ),
                    catchError(() => of(getArtifactMetadataRequestFailed())),
                ),
            ),
        ),
    );

    deleteAllArtifactControlValidationsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteAllArtifactControlValidationsRequest),
            switchMap(({ artifact }) => {
                if (artifact.validation.detectedControls.length === 0) {
                    return of(deleteAllArtifactControlValidationsRequestFailed);
                }
                return from([
                    ...artifact.validation.detectedControls
                        .map((controls) => controls.id)
                        .map((controlId) => {
                            return this._artifactService.deleteControlValidation(artifact.id, controlId);
                        }),
                ]).pipe(
                    concatAll(),
                    last(),
                    map(() =>
                        deleteAllArtifactControlValidationsRequestSuccess({ artifactId: (artifact as Artifact).id }),
                    ),
                    catchError(() => of(deleteAllArtifactControlValidationsRequestFailed)),
                );
            }),
        ),
    );

    deleteArtifactControlDomainValidationsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteArtifactControlDomainValidationsRequest),
            switchMap(({ artifact, controlDomain }) => {
                if (artifact.validation.detectedControls.length === 0) {
                    return of(deleteArtifactControlDomainValidationsRequestFailed);
                }
                return from([
                    ...artifact.validation.detectedControls
                        .filter((controls) => controls.controlDomainId === controlDomain.controlId)
                        .map((controls) => controls.id)
                        .map((controlId) => {
                            return this._artifactService.deleteControlValidation(artifact.id, controlId);
                        }),
                ]).pipe(
                    concatAll(),
                    last(),
                    map(() =>
                        deleteArtifactControlDomainValidationsRequestSuccess({ artifactId: (artifact as Artifact).id }),
                    ),
                    catchError(() => of(deleteArtifactControlDomainValidationsRequestFailed)),
                );
            }),
        ),
    );

    openAddArtifactModalForRequest$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => !!route && route.routeConfig.path === 'relationships/:requestId/add-artifact'),
                map((route) => route.params.requestId),
                mergeMap((requestId) =>
                    this._latestAssessmentInProgress$.pipe(
                        map((assessmentInProgress) => ({ requestId, assessmentInProgress })),
                        take(1),
                    ),
                ),
                tap(({ requestId, assessmentInProgress }) =>
                    this._modalService.open<UploadArtifactsModalComponent>(
                        UploadArtifactsModalComponent,
                        {
                            invokedFor: UploadArtifactsSections.RELATIONSHIP_DETAILS_PAGE,
                            actions: {
                                requestAction: uploadRequestArtifactRequest,
                                requestSuccessAction: uploadRequestArtifactRequestSuccess,
                                requestFailedAction: uploadRequestArtifactRequestFailed,
                                requestExtraParams: { requestId },
                            },
                            assessmentInProgress,
                        },
                        { size: 'xl' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    uploadRequestArtifactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadRequestArtifactRequest),
            switchMap(({ fileRequests, urlRequests, requestId }) =>
                this._artifactService.uploadArtifacts(requestId, fileRequests, urlRequests).pipe(
                    map(({ duplicateArtifactFileNames }) =>
                        uploadRequestArtifactRequestSuccess({ requestId, duplicateArtifactFileNames }),
                    ),
                    catchError(() => of(uploadRequestArtifactRequestFailed())),
                ),
            ),
        ),
    );

    displayModalForDocumentsUploaded$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadRequestArtifactRequestSuccess),
            filter(({ duplicateArtifactFileNames }) => duplicateArtifactFileNames.length === 0),
            withLatestFrom(this._store$.select(getRelationshipPopulatedLatestAssessment)),
            switchMap(([, latestAssessment]) => {
                if (
                    latestAssessment?.status === AssessmentStatus.STARTED ||
                    latestAssessment?.status === AssessmentStatus.COLLECTING_INFORMATION
                ) {
                    const dialogRef = this._matDialogService.open(ArtifactAddedModalComponent, {
                        inputs: {
                            assessmentId: latestAssessment?.id,
                        },
                    });

                    return from(dialogRef.afterClosed()).pipe(
                        filter((result) => result !== undefined),
                        map((assessmentId) => confirmProceedWithAvailableData({ assessmentId })),
                    );
                } else {
                    this._snackbarService.success(
                        latestAssessment?.status === AssessmentStatus.REVIEW_STARTED ||
                            latestAssessment?.status === AssessmentStatus.AUDIT_COMPLETED
                            ? 'Documents added to current assessment'
                            : 'Assessment started with uploaded documents',
                    );
                    return EMPTY;
                }
            }),
        ),
    );

    getArtifactPasswordRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getArtifactPasswordRequest),
            switchMap(({ artifactId }) =>
                this._artifactService.getArtifactPassword(artifactId).pipe(
                    map((response) => response.body),
                    map(({ password }) => getArtifactPasswordRequestSuccess({ password })),
                    catchError(() => of(getArtifactPasswordRequestFailed())),
                ),
            ),
        ),
    );

    getArtifactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getArtifactRequest),
            switchMap(({ artifactId }) =>
                this._artifactService.find(artifactId).pipe(
                    map((response) => response.body),
                    map((artifact) => getArtifactRequestSuccess({ artifact })),
                    catchError(() => of(getArtifactRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _artifactService: ArtifactService,
        private _assessmentUtilsService: AssessmentUtilsService,
        private _confirmDialogService: ConfirmDialogService,
        private _modalService: NgbModalWrapperService,
        private _matDialogService: MatDialogWrapperService,
        private _snackbarService: SnackbarService,
    ) {}
}
