import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ContextType } from '@entities/context-type';
import { AssessmentStatus } from '@entities/assessment';
import { AppState } from '../assessment-collection.state';
import { getPublicAssessment } from './main.selectors';
import { AssessmentRecommendation } from '@entities/recommendation';
import { isRemediationAssessment } from '@shared/utils/assessment-utils.service';
import { ControlValidationDetection } from '@entities/artifact';

export const getAssessmentStatus: MemoizedSelector<AppState, AssessmentStatus> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.status,
);

export const getClientName: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.clientName,
);

export const getClientId: MemoizedSelector<AppState, number> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.clientId,
);

export const getSenderEmail: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.senderEmail,
);

export const getSubmittedByEmail: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.submittedByEmail,
);

export const getSentToEmail: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.sentToEmail,
);

export const getClientLogoUrl: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.clientLogoUrl,
);

export const getClientBrandingColor: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.clientBrandingColor,
);

export const getVendorName: MemoizedSelector<AppState, string> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.vendorName,
);

export const getBusinessCases: MemoizedSelector<AppState, ContextType[]> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.contextTypes || [],
);

export const getAssessmentCreatedDate: MemoizedSelector<AppState, Date> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.createdDate,
);

export const getPreviousAssessmentRecommendations: MemoizedSelector<AppState, AssessmentRecommendation[]> =
    createSelector(getPublicAssessment, (publicAssessment) => publicAssessment?.previousAssessmentRecommendations);

export const getIsRemediationAssessment: MemoizedSelector<AppState, boolean> = createSelector(
    getPublicAssessment,
    (publicAssessment) => isRemediationAssessment(publicAssessment?.type),
);

export const getRemediationTargetDate: MemoizedSelector<AppState, Date> = createSelector(
    getPublicAssessment,
    (assessment) => assessment?.remediationTargetDate,
);

export const getSubprocessorDetections: MemoizedSelector<AppState, ControlValidationDetection[]> = createSelector(
    getPublicAssessment,
    (assessment) => assessment?.subprocessorDetections ?? [],
);
