import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ArtifactFilePageLink,
    ArtifactType,
    ControlValidationDetectionType,
    SubservicerType,
} from '@entities/artifact';
import { Detection } from '@shared/artifact-intelligence/models/detection.model';
import { ArtifactPageLinkViewType } from '../artifact-page-link/artifact-page-link.component';
import { FrameworkMappingType, FrameworkMappingTypeLabels } from '@entities/framework/models/framework-mapping.model';

@Component({
    selector: 'app-detection-details',
    templateUrl: './detection-details.component.html',
    styleUrls: ['./detection-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectionDetailsComponent implements OnInit {
    @Input() detection: Detection;
    @Input() controlName: string;
    @Input() domainControlName: string;
    @Input() frameworkType: FrameworkMappingType;
    @Output() close = new EventEmitter<void>();

    ArtifactType = ArtifactType;
    ArtifactPageLinkViewType = ArtifactPageLinkViewType;
    DetectionType = ControlValidationDetectionType;
    subservicerNames: string;

    get showSubprocessorFields(): boolean {
        // Subprocessors can only be a singular entry within the subservicers array.
        return (
            this.detection.subservicers.length === 1 &&
            this.detection.subservicers[0].type === SubservicerType.SUBPROCESSOR
        );
    }

    ngOnInit() {
        this.subservicerNames = this.detection.subservicers.map((s) => s.name).join(', ');
    }

    onClose() {
        this.close.emit();
    }

    getPages(pageList: string): ArtifactFilePageLink[] {
        if (!pageList) {
            return null;
        }
        const pageNumbers = pageList.replace(/, +/g, ',').split(',');

        return pageNumbers.map((page) => {
            if (isNaN(Number(page)) && page.includes('-')) {
                const pageRange = page.split('-');
                return {
                    initialPage: +pageRange[0],
                    finalPage: +pageRange[1],
                };
            } else if (typeof page === 'number') {
                return page;
            } else {
                return page;
            }
        });
    }

    getFrameworkTypeLabel(frameworkType: string) {
        return FrameworkMappingTypeLabels[frameworkType];
    }
}
