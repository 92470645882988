<div class="pill" [@pillSizeAndColor]="state">
    @if (aiProcessed) {
        <mat-icon class="icon" [@iconSlideRight]="state" (@iconSlideRight.done)="moveToNextState()" [inline]="true"
            >auto_awesome</mat-icon
        >
    }
    <div class="text-container" [class.no-sparkle]="!aiProcessed" [@contentReveal]="state">
        {{ fileArtifactTypeLabel }}
    </div>
</div>
