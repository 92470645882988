import { Injectable } from '@angular/core';
import { FileArtifact, UploadFileArtifactRequest } from '@entities/artifact';
import {
    AdditionalAssessmentQuestionViewModel,
    formatThirdPartyProvidedAuditReportType,
    isExpiredDocumentationQuestion,
    isSiblingDocumentationQuestion,
} from '../models';
import { AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES } from '@entities/audit-report';

@Injectable()
export class FileUploadHelperService {
    createFilesUploadRequest(
        files: File[],
        forAdditionalQuestion?: AdditionalAssessmentQuestionViewModel,
    ): UploadFileArtifactRequest[] {
        const artifactType = forAdditionalQuestion?.fileArtifactType;
        return files.map<UploadFileArtifactRequest>((file) => {
            const artifactThirdPartyProvidedAuditType = !!forAdditionalQuestion
                ? formatThirdPartyProvidedAuditReportType(forAdditionalQuestion, file.name)
                : null;
            const vendorAuditReportType = !!forAdditionalQuestion
                ? isExpiredDocumentationQuestion(forAdditionalQuestion)
                    ? forAdditionalQuestion.expiredAuditType
                    : isSiblingDocumentationQuestion(forAdditionalQuestion)
                      ? forAdditionalQuestion.requiredSiblingAuditType
                      : null
                : null;
            return {
                file,
                fileArtifactMetaData: {
                    artifactType,
                    artifactThirdPartyProvidedAuditType,
                    vendorAuditReportType,
                },
            };
        });
    }

    createFileUploadReplacementRequest(file: File, fileArtifactToReplace: FileArtifact): UploadFileArtifactRequest {
        return {
            file,
            fileArtifactMetaData: {
                artifactType: AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES[fileArtifactToReplace.validation.auditReportType],
                artifactIdToSupersede: fileArtifactToReplace.id,
                vendorAuditReportType: fileArtifactToReplace.validation.auditReportType,
            },
        };
    }
}
