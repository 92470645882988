import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { AssessmentCancelledReason } from '@entities/assessment';
import {
    getCancelledReason,
    getClientBrandingColor,
    getClientName,
    getJustCancelled,
    getJustForwarded,
    getRemediationJustDeclined,
    getSenderEmail,
    getSentToEmail,
    getShowCancelledOrDeleted,
    getShowCompleted,
    getShowSubmitted,
    getSubmittedByEmail,
} from '../../../redux/selectors';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-assessment-no-longer-in-progress',
    templateUrl: './assessment-no-longer-in-progress.component.html',
    styleUrls: ['./assessment-no-longer-in-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentNoLongerInProgressComponent implements OnInit {
    showSubmitted$: Observable<boolean>;
    showCompleted$: Observable<boolean>;
    showCancelled$: Observable<boolean>;
    showForwarded$: Observable<boolean>;

    clientName$: Observable<string>;
    clientBrandingColor$: Observable<string>;
    senderEmail$: Observable<string>;
    submittedByEmail$: Observable<string>;
    sentToEmail$: Observable<string>;
    cancelledReason$: Observable<AssessmentCancelledReason>;
    justCancelled$: Observable<boolean>;
    remediationJustDeclined$: Observable<boolean>;

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this.showSubmitted$ = this._store$.select(getShowSubmitted);
        this.showCompleted$ = this._store$.select(getShowCompleted);
        this.showCancelled$ = merge(
            this._store$.select(getShowCancelledOrDeleted),
            this._store$.select(getJustCancelled),
            this._store$.select(getRemediationJustDeclined),
        ).pipe(filter(Boolean));
        this.showForwarded$ = this._store$.select(getJustForwarded);

        this.clientName$ = this._store$.select(getClientName);
        this.clientBrandingColor$ = this._store$.select(getClientBrandingColor);
        this.senderEmail$ = this._store$.select(getSenderEmail);
        this.submittedByEmail$ = this._store$.select(getSubmittedByEmail);
        this.sentToEmail$ = this._store$.select(getSentToEmail);
        this.justCancelled$ = this._store$.select(getJustCancelled);
        this.remediationJustDeclined$ = this._store$.select(getRemediationJustDeclined);
        this.cancelledReason$ = this._store$.select(getCancelledReason);
    }
}
