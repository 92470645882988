import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LongRunningTaskName, LongRunningTaskProjection } from '@entities/long-running-tasks/long-running-task.model';

export const LongRunningTaskActions = createActionGroup({
    source: 'Long Running Task',
    events: {
        'Get In Progress Long Running Tasks Request': emptyProps(),
        'Get In Progress Long Running Tasks Request Success': props<{ tasks: LongRunningTaskName[] }>(),
        'Get In Progress Long Running Tasks Request Failed': emptyProps(),

        'Get All Tasks For Record Id Request': props<{ recordId: string }>(),
        'Get All Tasks For Record Id Request Success': props<{ tasks: LongRunningTaskProjection[] }>(),
        'Get All Tasks For Record Id Request Failed': emptyProps(),
    },
});
