import { createActionGroup, emptyProps } from '@ngrx/store';
import { AdditionalInformationSubSteps, StepIds } from '../../models';

export const StepNavigationActions = createActionGroup({
    source: 'Step Navigation',
    events: {
        'Navigate To Questionnaire': emptyProps(),
        'Navigate To Questionnaire For Followup': emptyProps(),
        'Navigate To Collect Artifacts': emptyProps(),
        'Set Current Step': (stepId: StepIds) => ({ stepId }),
        'Set Current Collect Artifacts SubStep': (subStep: AdditionalInformationSubSteps) => ({
            collectArtifactsSubStep: subStep,
        }),
        'Continue From Welcome Page': emptyProps(),
        'Go Back From Initial Upload': emptyProps(),
        'Continue From Initial Upload': emptyProps(),
        'Go Back From Submission': emptyProps(),
        'Go Back From SubStep': (fromId: AdditionalInformationSubSteps) => ({ fromId }),
        'Continue From SubStep': (fromId: AdditionalInformationSubSteps) => ({ fromId }),
    },
});
