import {
    AssessmentRecommendation,
    CertOnlyArtifactAssessmentRecommendation,
    ExpiredArtifactAssessmentRecommendation,
    RecommendationType,
} from './recommendation.model';

export function isExpiredArtifactRiskRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is ExpiredArtifactAssessmentRecommendation {
    return recommendation.type === RecommendationType.EXPIRED;
}

export function isCertOnlyArtifactRiskRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is CertOnlyArtifactAssessmentRecommendation {
    return recommendation.type === RecommendationType.CERT_ONLY;
}
