<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/relationships']">Relationships</a></li>
        <li class="breadcrumb-item">
            <a [routerLink]="['/requests', relationshipId]">{{ relationshipName }}</a>
        </li>
        @if (!!assessmentId) {
            <li class="breadcrumb-item">
                <a [routerLink]="['/assessments', assessmentId]">Assessment</a>
            </li>
        }
        <li class="breadcrumb-item active">
            {{ attestationsArtifact.fileName ? attestationsArtifact.fileName : 'Attestations Response' }}
        </li>
    </ol>
</div>

<div class="viso-heading">
    <div class="col">
        Attestations Response
        <div class="viso-subheading">Completed {{ relativeCompletedDate }}</div>
    </div>
</div>

<mat-drawer-container>
    <mat-drawer-content>
        <mat-tab-group [mat-stretch-tabs]="false" animationDuration="0" class="viso-tabset">
            <mat-tab>
                @if (subprocessorDetections) {
                    <ng-template mat-tab-label>
                        Subprocessor List
                        <app-badge type="light" [noBorderRadius]="true" class="ms-2">
                            {{ subprocessorDetections.length }}
                        </app-badge>
                    </ng-template>

                    <app-subprocessor-list [detections]="subprocessorDetections" [readonly]="true" />
                } @else {
                    <ng-template mat-tab-label>
                        All Responses
                        <app-badge type="light" [noBorderRadius]="true" class="ms-2">
                            {{ attestationsArtifact?.attestations?.length }}
                        </app-badge>
                    </ng-template>

                    <p class="no-answers mt-3" *ngIf="!attestationsArtifact?.attestations?.length">
                        <strong>Nothing to show here!</strong>
                    </p>

                    <mat-expansion-panel
                        *ngFor="let answer of attestationsArtifact?.attestations"
                        class="mt-3 supplemental"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <strong>{{ answer | callback: getAttestationName }}</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="answer-response-container">
                            <div class="mt-3" *ngIf="answer.attestationType; else noResponses">
                                <strong>Answer</strong>
                            </div>
                            <p>
                                {{ relationshipName | callback: buildAttestationResponse : answer }}
                            </p>
                        </div>
                    </mat-expansion-panel>
                }
            </mat-tab>
        </mat-tab-group>
        <ng-template #noResponses>
            <div class="mt-3"><strong>Answer</strong></div>
            <p>No responses have been provided for this question.</p>
        </ng-template>
    </mat-drawer-content>
</mat-drawer-container>
