import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { filter, map, of, withLatestFrom } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { AdditionalInformationSubSteps, AssessmentSubmissionContext, StepIds } from '../../models';
import { setSubmissionContext, submitPasscodeSuccess } from '../actions';
import * as MainSelectors from '../selectors/main.selectors';
import * as SubStepSelectors from '../selectors/sub-steps.selectors';
import * as ArtifactsSelectors from '../selectors/artifacts.selectors';
import { StepNavigationActions } from '../actions/step-navigation';

@Injectable()
export class SubStepNavigationEffects {
    initiallySetExpiredDocumentsSubStepWhenRequired$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitPasscodeSuccess),
            withLatestFrom(this._store$.select(SubStepSelectors.getShouldExpiredDocumentsScreenBePresented)),
            filter(([, shouldExpiredDocumentsScreenBePresented]) => shouldExpiredDocumentsScreenBePresented),
            map(() =>
                StepNavigationActions.setCurrentCollectArtifactsSubStep(
                    AdditionalInformationSubSteps.EXPIRED_DOCUMENTS,
                ),
            ),
        ),
    );

    goBackFromUploadRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.goBackFromSubStep),
            filter(({ fromId }) => fromId === AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS),
            map(() => StepNavigationActions.setCurrentStep(StepIds.INITIAL_ARTIFACT_UPLOAD)),
        ),
    );

    continueFromAdditionalQuestions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.continueFromSubStep),
            filter(({ fromId }) => fromId === AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS),
            map(() => StepNavigationActions.setCurrentStep(StepIds.SUBMIT)),
        ),
    );

    goBackFromQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.goBackFromSubStep),
            filter(({ fromId }) => fromId === AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE),
            map(() => StepNavigationActions.setCurrentStep(StepIds.WELCOME)),
        ),
    );

    continueFromQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.continueFromSubStep),
            filter(({ fromId }) => fromId === AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE),
            map(() => StepNavigationActions.setCurrentStep(StepIds.SUBMIT)),
        ),
    );

    navigateToQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StepNavigationActions.navigateToQuestionnaire),
            withLatestFrom(
                this._store$.select(ArtifactsSelectors.areThereUploadedArtifacts),
                this._store$.select(MainSelectors.getIsFollowupRequested),
            ),
            switchMap(([, areThereArtifacts, isFollowupRequested]) =>
                !isFollowupRequested && areThereArtifacts
                    ? this._confirmDialogService.confirm({
                          title: 'Proceed to Questionnaire?',
                          message:
                              'One or more artifacts have already been uploaded for this assessment. If you choose to answer a questionnaire instead, we will discard these artifacts.',
                          confirmLabel: 'Yes, continue',
                          confirmButtonColor: 'warn',
                          cancelButtonColor: 'primary',
                      })
                    : of(true),
            ),
            filter(Boolean),
            mergeMap(() => [
                setSubmissionContext({ context: AssessmentSubmissionContext.QUESTIONNAIRE }),
                StepNavigationActions.setCurrentStep(StepIds.ADDITIONAL_INFORMATION),
                StepNavigationActions.setCurrentCollectArtifactsSubStep(
                    AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE,
                ),
            ]),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _confirmDialogService: MatConfirmDialogService,
    ) {}
}
