<app-full-page-file-drop (onFileDropped)="checkAndEmit($event)" />

<input
    #fileInput
    type="file"
    ng2FileSelect
    class="d-none"
    [multiple]="multiple"
    [uploader]="uploader"
    [accept]="supportedFileTypes.join(', ')"
    (onFileSelected)="uploadFiles.emit($event)"
/>
<div #customContent>
    <ng-content />
</div>
@if (!customContent.childElementCount) {
    <div class="display-2">
        <mat-icon [inline]="true" color="primary">upload_file</mat-icon>
    </div>
    <p>
        Artifacts you upload will appear here.<br />
        Drag and drop anywhere on this page or
        <span
            class="color-primary text-decoration-underline"
            role="button"
            aria-label="Upload files button"
            (click)="fileInput.click()"
        >
            select files</span
        >.
    </p>
}
@if (showSupportedExtensions && supportedFileTypes.length) {
    <small>
        Accepted file types:
        <span>
            @for (supportedFileTypes of supportedFileTypes; track $index) {
                {{ $last ? 'or ' : '' }} {{ supportedFileTypes }}{{ $last ? '' : ', ' }}
            }
        </span>
    </small>
}
