<header class="detection-details-header">
    <div class="d-flex flex-column">
        <h4>{{ domainControlName }}</h4>
        <h1>{{ controlName }}</h1>
    </div>
    <button mat-icon-button (click)="onClose()" class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</header>

@if (detection.description) {
    <section class="detection-section border-none">
        <h2 class="detection-section-title">Control</h2>
        <p>{{ detection.description }}</p>
    </section>
}

@if (detection.notes) {
    <section class="detection-section border-none">
        <h2 class="detection-section-title">Detected language</h2>
        <p [class.multiline]="detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT">
            "{{ detection.notes }}"
        </p>
    </section>
}

<section class="detection-section detection-details">
    <div class="d-flex flex-column">
        <h5>Assurance</h5>
        @if (detection.auditReportAssuranceLevel) {
            <app-assurance-level-meter [assuranceLevel]="detection.auditReportAssuranceLevel" [hideLabel]="true" />
        }
    </div>

    <div class="d-flex flex-column">
        <h5>Location</h5>
        <div class="d-flex align-items-center">
            @for (page of detection.viewerPage | callback: getPages; track page; let isLast = $last) {
                <app-artifact-page-link
                    [detection]="detection"
                    [viewType]="ArtifactPageLinkViewType.locationLink"
                    [viewerPage]="page"
                    [isLast]="isLast"
                />
                @if (!isLast) {
                    <span>, </span>
                }
            }
            @if (detection.sectionName) {
                <span>; {{ detection.sectionName }}</span>
            }
            @if (!detection.viewerPage && !detection.sectionName) {
                <span>–</span>
            }
        </div>
    </div>

    <div class="d-flex flex-column">
        <h5>Artifact <em *ngIf="detection.artifactExpired" class="fa fa-exclamation-circle text-danger me-1"></em></h5>
        <app-artifact-page-link
            [detection]="detection"
            [viewType]="ArtifactPageLinkViewType.artifactLink"
            (click)="$event.stopPropagation()"
        />
    </div>
</section>

@if (detection.explanation) {
    <section class="detection-section">
        <h2 class="detection-section-title">Explanation</h2>
        <p>{{ detection.explanation }}</p>
    </section>
}

@if (detection.test) {
    <section class="detection-section">
        <h2 class="detection-section-title">Test</h2>
        <p>{{ detection.test }}</p>
    </section>
}

@if (detection.auditorComment) {
    <section class="detection-section">
        <h2 class="detection-section-title">Auditor comments</h2>
        <p>{{ detection.auditorComment }}</p>
    </section>
}

@if (detection.subservicers?.length) {
    @if (showSubprocessorFields) {
        <section class="detection-section">
            <h2 class="detection-section-title">Sub-processor</h2>
            <p>{{ detection.subservicers[0].name }}</p>
        </section>
        <section class="detection-section">
            <h2 class="detection-section-title">Purpose</h2>
            <p>{{ detection.subservicers[0].purpose || '-' }}</p>
        </section>
        <section class="detection-section">
            <h2 class="detection-section-title">Location of processing</h2>
            <p>{{ detection.subservicers[0].location || '-' }}</p>
        </section>
    } @else {
        <section class="detection-section">
            <h2 class="detection-section-title">
                {{ detection.subservicers.length === 1 ? 'Subservicer' : 'Subservicers' }}
            </h2>
            <p>{{ subservicerNames }}</p>
        </section>
    }
}

@if (!!frameworkType) {
    <section class="detection-section">
        <h2 class="detection-section-title">Identifiers</h2>
        <p class="mat-text-secondary">{{ frameworkType | callback: getFrameworkTypeLabel }}</p>
        <p>{{ detection.frameworkMapping || '-' }}</p>
        <p class="label">Source:</p>
        {{ detection.controlIds || '–' }}
    </section>
}

@if (detection.managementResponse && detection.detectionType === DetectionType.EXCEPTION) {
    <section class="detection-section">
        <h2 class="detection-section-title">Management Response</h2>
        <p>{{ detection.managementResponse }}</p>
    </section>
}
